.partyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: $lakersUniform;
  &.krogerus {
    background-color: $krogerusClientColor;
  }
  margin-bottom: 1px;
  border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
  padding: 5px;
  max-width: 70%;

  .partyContainerText {
    color: white;
    font-size: 14px;
    max-lines: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.singlClaimUpperPartClaim {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $mariaViolet;
  border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
  width: 100%;
  min-height: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
  padding: 7px 10px 4px 10px;
  user-select: none;

  &.forReport {
    border-radius: 0 $borderRadiusMedium 0 0;
  }

  .claimTypetIdContainer {
    color: white;
    margin-left: 6px;
    font-size: 16px;
    font-weight: $boldWeight;
    width: 78.5px;
    height: 23px;
  }
  .verticalLine {
    height: 29px;
    width: 1px;
    background-color: white;
  }

  .previewClaimName {
    color: white;
    font-size: 14px;
    text-align: center;
    width: 100%;
    font-family: 'poppins-local-medium';

    text-overflow: ellipsis;
    overflow: hidden;
  }
  .errors {
    @extend .sharable-error;
  }
}

.singlClaimDownPartClaim {
  width: 100%;
  height: 55px;
  min-height: 55px;
  border-radius: 0 0 $borderRadiusMedium $borderRadiusMedium;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;

  .firstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 22.5px;

    height: 22.5px;
    width: 100%;
    .claimAmountTextContainer {
      width: 170px;
      margin-left: 14.5px;

      .claimAmountText {
        margin-left: 18px;
        cursor: pointer;

        color: $clientColor;
        &.krogerus {
          color: $krogerusClientColor;
        }
        &.negative {
          color: $opposingPartyColor;
          &.krogerus {
            color: $krogerusOpposingPartyColor;
          }
        }
        span {
          color: $clientColor;
          &.krogerus {
            color: $krogerusClientColor;
          }
          &.negative {
            color: $opposingPartyColor;
            &.krogerus {
              color: $krogerusOpposingPartyColor;
            }
          }
        }
      }
    }
    .interestRateText {
      white-space: nowrap;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .secondRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 22.5px;
    height: 22.5px;

    .interestDate {
      margin-right: 15px;
      color: $ukNavy;
      cursor: pointer;
      &.error {
        color: $greekEaster;
      }
    }
  }
}

.pdfPreview {
  height: fit-content;
  // border: 0.5px solid $ageanMidnight;
  // box-shadow: 0px 0px 0px;
}
.moreInfoMenuContainer {
  position: relative;
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  .moreInfoMenu {
    width: 100%;
    background-color: white;
    z-index: 16;
    box-shadow: 0px 6px 6px #00000029;
    &.moreInfoMenuPreviewPdf {
      box-shadow: 0px 0px 0px #00000029;
    }
    border-radius: 0 0 $borderRadiusMedium $borderRadiusMedium;
    .liabilityListTitle {
      margin-top: 20px;
      font-family: 'poppins-local-bold';
      margin-left: 14.5px;
    }
    .liabilityListComponent {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .singleLiabilityRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        .liabilityQuestion {
          word-wrap: normal;
          font-size: 16px;
          margin-left: 14px;
          min-width: 216px;
          max-width: 216px;
        }
        .liabilityProbability {
          width: 100%;
          font-size: 16px;
          margin-right: 15px;
          text-align: right;
        }
      }
      .totalLiabilityRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 2px;
        width: 100%;

        .totalLiabilityTitle {
          margin-left: 14.5px;
          font-size: 16px;
          font-weight: 600;
          min-width: 216px;
          max-width: 216px;
        }
        .totalLiabilityProbability {
          font-size: 16px;
          font-family: 'poppins-local-medium';

          margin-right: 15px;
          width: 100%;
          text-align: right;
          align-self: center;
        }
      }
    }
    .quantumTitle {
      margin-top: 15px;
      font-family: 'poppins-local-bold';
      margin-left: 14.5px;
    }

    .quantumScenariosListComponent {
      margin-top: 5px;
      margin-bottom: 13px;
      display: flex;
      flex-direction: column;

      justify-content: flex-start;
      .quantumSubtitlesContainer {
        width: 300px;
        display: flex;
        flex-direction: row;
        margin-top: -5px;
        .quantumSubtitleAmount {
          font-size: 14px;
          margin-left: 14.5px;
          font-family: 'poppins-local-medium';
          min-width: 138px;
          line-height: 15px;
        }

        .quantumSubtitleExternalValue {
          margin-top: -3px;
          font-family: 'poppins-local-medium';
          margin-left: 29px;
          line-height: 15px;
          height: 50px;
          text-align: center;

          font-size: 14px;
        }
      }
      .quantumRowContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        height: 33px;
        margin: 10.5px 15px 10.5px 14.5px;
        .positive {
          color: $clientColor !important;
          &.krogerus {
            color: $krogerusClientColor !important;
          }
        }
        .negative {
          color: $opposingPartyColor !important;
          &.krogerus {
            color: $krogerusOpposingPartyColor !important;
          }
        }
        .quantumProbability {
          color: $ageanMidnight;
        }
        p {
          font-size: 12px;
        }
        .quantumAmountAndProbabilityContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .externalConsequenceContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }
  .moreInfoButtonContainer {
    background-color: white;
    display: flex;
    flex-direction: row;
    z-index: 1;
    margin-top: 5px;
    margin-right: 226px;
    box-shadow: 0px 3px 2px #00000029;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    &.collapse {
      margin-top: -5px;
      margin-right: 215px;
      position: relative;
      z-index: 900;
    }
    .moreInfoButtonText {
      cursor: pointer;
      margin-left: 5px;
      color: $lakersUniform;
    }
    cursor: pointer;
    .moreInfoButtonOff {
      transform: rotate(180deg);
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-top: 4px;
      margin-right: 5px;
    }
    .moreInfoButtonOn {
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-top: 3px;
      margin-right: 5px;
    }
  }
}

.active {
  background-color: $lakersUniform;
  &.krogerus {
    background-color: $krogerusClientColor;
  }
}
