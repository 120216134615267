.pasteFromClipboardContainer {
  position: relative;
  background-color: white;
  &:hover {
    background-color: $springLevanter;
  }
  box-shadow: 0px 3px 6px #00000029;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  .pasteFromClipboardImg {
    cursor: pointer;
  }
  .nothingToPasteTextContainer {
    position: absolute;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    left: 40px;
    top: 10px;
    width: 200px;
    height: fit-content;
    padding: 8px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
}
