.notSupportedBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;

  .notSupportedMessageBox {
    max-width: 696px;
    min-height: 403px;
    box-shadow: 0px 0px 30px #c9c9c9;
    border-radius: 30px;
    background-color: white;
    margin-top: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 72px;
    padding-bottom: 57px;
    padding-left: 21px;
    padding-right: 21px;

    .titleText {
      font-weight: bold;
      color: #201a2d;
      margin-bottom: 44px;
      text-align: center;
    }

    .descriptionText {
      color: #373c58;
      margin-bottom: 44px;
      text-align: center;
    }

    .mailText {
      color: #373c58;
      text-align: center;
    }

    .eperotoIcon {
      position: absolute;
      top: -75px;
      right: -50px;
      height: 130px;
    }
  }

  .eperotoLogo {
    position: absolute;
    top: 24px;
    left: calc(100vw / 10);
    height: 65px;
    cursor: pointer;
  }
}
