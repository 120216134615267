@import './hoverResultsComponents/hoverResultsComponents';
$columnsWidht: 200px;
$headersWidth: 510px;

.horizontalResultsContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  height: fit-content;
  width: 100%;
  .headerBackground {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 35px;
    width: 100%;
    background-color: $hovercraftPurple;
    border-radius: 5px;
    &.previewPdf {
      background-color: $thursdayGray;
    }
  }

  .line {
    margin-top: 7px;
    height: 1px;
    background-color: #c9c9c9;
  }
  .headersContainer {
    z-index: 2;
    width: $headersWidth;
    min-width: calc(#{$headersWidth - 20px});
    &.bestWorstHeadersContainer {
      width: calc(#{$headersWidth + 80px});
      min-width: calc(#{$headersWidth + 80px});
    }
    .title {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .resultsTableTitleInRow {
        font-size: 20px;
        color: $ageanMidnight;
        margin-left: 30px;
        cursor: pointer;
      }
    }

    .title2 {
      .resultsTableTitleInRow {
        color: $ukNavy;
      }
    }
    .row {
      position: relative;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .resultsTableTitleInRow {
        color: #2e394b;
        margin-left: 50px;
        cursor: pointer;
        span {
          font-weight: $boldWeight;
        }
      }
    }
    .row11 {
      .resultsTableTitleInRow {
        font-size: 20px;
        font-weight: $boldWeight;
      }
    }
    .row12 {
      .resultsTableTitleInRow {
        font-weight: normal;
      }
    }
    .line {
      margin-left: 30px;
    }
    img {
      height: 12px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .row,
  .title {
    height: 35px;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .row12 {
    margin-top: 6px;
  }
  .row10 {
  }

  .resultsColumnsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    overflow-x: auto;
    .resultsRows {
      width: $columnsWidht;
      .title1 {
        p {
          font-size: 16px;
          color: $ageanMidnight;
          margin: auto;
          font-weight: $boldWeight;
          margin-right: 60px;
        }
      }
      .row {
        p {
          color: $ageanMidnight;
          max-width: 140px;
          margin: auto;
          margin-right: 60px;
          text-align: right;
        }
      }
      .row1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 60px;
      }

      .row11 {
        p {
          font-weight: $boldWeight;
        }
      }

      .resultsTitle {
        width: $columnsWidht;
      }
    }
  }
  .maxMinColumnsContainer {
    overflow-x: auto;
    @media screen and (max-width: 685px) {
      overflow-x: visible;
    }
    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      border-radius: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $scrollBarBackground;
      border-radius: 10px;
      box-shadow: -1px 3px 6px #00000029;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $lakersUniform;
      border-radius: 10px;
      border: $scrollBarHandleBorder;
    }
  }
}

.textForHover {
  &:hover {
    color: $popCulture !important;
    text-decoration: underline;
    cursor: pointer;
  }
}
.negativeInResultsList {
  color: $greekEaster !important;
}

.horizontalResults {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  /* width */
  &::-webkit-scrollbar {
    height: 20px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $scrollBarBackground;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $lakersUniform;
    border-radius: 10px;
    border: $scrollBarHandleBorder;
  }
}

.resultsInfoPopUp {
  position: absolute;
  z-index: 5;
  text-align: left !important;
}
