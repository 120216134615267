.bankruptcy {
  width: fit-content;
  margin-bottom: 30px;
  .errorMsg {
    left: 315px !important;
    width: 320px !important;
  }
}
.inlcudeBankruptcy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 80px;
  img {
    margin-left: 9px;
    margin-right: 5px;
    width: 32px;
    cursor: pointer;
  }
  p {
    cursor: pointer;

    font-size: 16px;
    font-weight: 400;
  }
}

.bankruptcyHelperText {
  margin: 47px 0 35px 55px;
}

#bankruptcyComp {
  .infoClickText {
    p {
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }
}

.advancedTabSubTitleContainer {
  margin-top: 39px;
  margin-left: 44px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;

  .bankruptcyImg {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
}
