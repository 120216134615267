p,
h1,
h2,
h3,
h4,
h5,
h6 * {
  cursor: default;
  margin: 0;
}

.inputDate.smallField .MuiFormHelperText-root {
  margin-top: -1px;
}

.stickySuffixContainer {
  position: absolute;
  top: 80px;
  height: 20px;
  display: flex;
  flex-direction: row;
}
.stickySuffixContainer .stickySuffixPlaceholder {
  opacity: 0;
}
.stickySuffixContainer .stickySuffix {
  cursor: text;
  position: absolute;
  top: -61px;
  right: -7px;
  font-family: "Helvetica";
  font-size: 18px;
  font-weight: 500;
}

.input {
  position: relative;
  line-height: 0;
  height: auto;
}
.input input::-webkit-datetime-edit-day-field:focus,
.input input::-webkit-datetime-edit-month-field:focus,
.input input::-webkit-datetime-edit-year-field:focus {
  background-color: #462777;
  color: white;
  outline: none;
}
.input p {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.input .input-label {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  position: relative;
}
.input .input-label .input-container {
  height: 57px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.input .input-label .input-label-text {
  position: absolute;
  z-index: 1;
  transition: width 0.2s, top 0.2s, font-size 0.2s, padding 0.2s, left 0.2s;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  border-radius: 2px;
}
.input .input-label .input-label-text-label {
  color: red;
  top: -8.5px;
  left: 12.5px;
  font-size: 13px;
  font-weight: 600;
  pointer-events: none;
}
.input .input-label .input-label-text-label.smallfield-label-num {
  top: -9px;
}
.input .input-label .input-label-text-label .input-label-text-p {
  padding: 0 3px;
}
.input .input-label .input-label-text-label.input-label-selected {
  color: #462777;
}
.input .input-label .input-label-text-label .input-label-background {
  width: auto;
  background-color: #fff;
  height: 3px;
  position: relative;
  top: -4.5px;
  z-index: -1;
}
.input .input-label .input-label-text-label .smallfield-label-background {
  top: -4px;
}
.input .input-label input {
  border-color: #818181;
  border-width: 1px;
  border-style: solid;
  padding: 8.5px 14px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  background-color: #fff;
  outline: #fff 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
}
.input .input-label input[type=date],
.input .input-label input [type=month] {
  height: 50px;
}
.input .input-label input[type=date]::-webkit-calendar-picker-indicator,
.input .input-label input [type=month]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.input .input-label input.input-inputfield-selected {
  border-color: #462777;
  border-width: 2px;
  padding: calc(8.5px - 1px) calc(14px - 1px);
}
.input .input-label .smallfield {
  height: 43px;
  font-size: 14px;
}
.input .input-label input:focus {
  outline: none;
}
.input .input-label input.input-noButton::-webkit-outer-spin-button,
.input .input-label input.input-noButton::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input .input-label .inputNum-prefix {
  position: relative;
  line-height: 1;
  font-size: 16px;
  height: 50px;
}
.input .input-label .inputNum-prefix .input-prefix-content {
  position: absolute;
  width: 45px;
  left: 10px;
  top: 19%;
  color: #8e8e8e;
}
.input .input-label .input-suffix {
  position: relative;
  line-height: 1;
  font-size: 16px;
  height: 50px;
}
.input .input-label .input-suffix .input-suffix-content {
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  right: calc(100% + 8.5px);
}
.input .input-label .input-suffix .input-suffix-content p {
  margin-left: 0px;
  word-break: normal;
}
.input .input-label .input-suffix .input-suffix-content img {
  height: 22px;
  width: auto;
  margin-left: 0px;
  cursor: pointer;
}
.input .input-label .input-suffix .input-suffix-content {
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  right: calc(100% + 8.5px + 8.5px + 6px) !important;
}
.input .input-label .input-suffix .input-suffix-content p {
  margin-left: 0px;
}
.input .input-label .input-suffix .input-suffix-content img {
  height: 22px;
  width: auto;
  margin-left: 0px;
  cursor: pointer;
}
.input .input-label .input-suffix .input-suffix-content-smallfield {
  top: 21px;
}

.input-helper-text {
  margin: 5px 14px 0 14px;
  line-height: 1.4;
  height: 8px;
  width: -moz-fit-content;
  width: fit-content;
}
.input-helper-text p {
  height: 13px;
  font-size: 12px;
  margin-top: 12px;
  margin-block-start: 0;
  margin-block-end: 0.5em;
  color: #818181;
}
.input-helper-text .input-error-message {
  font-size: 12px;
  color: #b00020;
  font-weight: 900 !important;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: -0.7px;
}

/*Datalist*/
.dataList .input-label .input-suffix .input-suffix-content {
  display: flex;
  flex-direction: row-reverse;
}
.dataList .input-label .input-suffix .input-suffix-content .input-dropDown-button {
  height: 100%;
  margin-top: 5px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.dataList .input-label .input-suffix .input-suffix-content .input-dropDown-button img {
  width: 12px;
  padding: 0 2px 1px;
  margin: 0;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.dataList .input-dropdown-container {
  position: relative;
}
.dataList .input-dropdown-container .input-dropdown {
  position: absolute;
  top: -1px;
  z-index: 300;
  background-color: white;
  border-width: thin;
  border-color: #462777;
  border-style: none solid solid solid;
  width: calc(100% - 6px);
  font-size: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dataList .input-dropdown-container .input-dropdown li {
  padding: 10px 14px;
  list-style: none;
  font-size: 16px;
}
.dataList .input-dropdown-container .input-dropdown li:first-child {
  padding: 16px 14px 10px;
  list-style: none;
  font-size: 16px;
}
.dataList .input-dropdown-container .input-dropdown li:hover {
  background-color: rgb(233, 232, 232);
}
.dataList .input-dropdown-container .input-dropdown-background {
  position: absolute;
  top: -13px;
  background-color: #fff;
  border-width: thin;
  border-color: #2e394b;
  border-style: none solid none solid;
  width: calc(100% - 2px);
  height: 13px;
  margin: 0;
  padding: 0;
  z-index: -30;
}

.input.boldBorder .input-label input {
  border-width: 2px;
  padding: 8.5px 14px;
}
.input.boldBorder .input-label input.input-inputfield-selected {
  border-width: 3px;
  padding: calc(8.5px - 1px) calc(14px - 1px);
}
.input.boldBorder .input-label .input-suffix .input-suffix-content {
  right: calc(100% + 8.5px + 1px);
}

.suffix-hidden {
  display: none;
}

.input-error.input-label .input-label-text-label {
  z-index: 1;
}
.input-error.input-label .input-label-text-label p {
  color: #b00020;
  font-weight: 900 !important;
}
.input-error.input-label .input-label-text-label.input-label-selected p {
  color: #b00020;
  font-weight: 900 !important;
}
.input-error.input-label input {
  border-color: #b00020 !important;
  color: #b00020;
}
.input-error.input-label input.input-inputfield-selected {
  border-color: #b00020 !important;
}
.input-error.input-label .input-suffix p {
  color: #b00020;
}
.input-error.input-label .input-helper-text {
  color: #b00020;
}
.input-error.input-label.dataList .input-dropdown-container .input-dropdown {
  border: thin #b00020;
  border-style: none solid solid solid;
}

.input.boldBorder .input-label input {
  border-width: 2px;
  border-style: solid;
  padding: calc(8.5px - 1px) calc(14px - 1px);
}
.input.boldBorder .input-label input.input-inputfield-selected {
  border-width: 3px;
  padding: calc(8.5px - 2px) calc(14px - 2px);
}
.input.boldBorder.inputdate .input-label input {
  padding: 0.5px 14px;
}
.input.boldBorder.inputdate .input-label input.input-inputfield-selected {
  padding: calc(0.5px - 1px) calc(14px - 1px);
}/*# sourceMappingURL=input.css.map */