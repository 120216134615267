.sideMenuAndTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: $tutorialsMainContainerTopMargin;

  @media screen and (max-width: 1590px) {
    //.tutorialTabsContainer becomes fixed so this has to go down
    margin-top: calc(#{$tutorialsMainContainerTopMargin} + 65px);
  }

  &.tutorialComponent {
    .userManualSideMenuContainer {
      height: calc(100vh - 180px) !important;
    }
    .userManualTextComponentContainer {
      height: calc(100vh - 180px) !important;
    }
  }
  .userManualSideMenuContainer {
    width: 265px;
    min-width: 265px;
    @media screen and (max-width: 1590px) {
      margin-left: 42px;
    }
    background-color: white;
    border-radius: $borderRadiusLarge;
    padding: 20px;
    height: calc(100vh - 150px);
    overflow-y: auto;
    box-shadow: 0px 3px 20px #00000029;
    z-index: 1;

    .h1Title {
      font-size: 20px;
    }
    .h2Title {
      font-size: 16px;
      margin-left: 12px;
    }
    .titleText {
      cursor: pointer;
      &:hover {
        color: $popCulture !important;
      }
      line-height: 1.2;
      margin-bottom: 6px;
    }
    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      border-radius: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $scrollBarBackground;
      border-radius: 10px;
      box-shadow: -1px 3px 6px #00000029;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $lakersUniform;
      border-radius: 10px;
      border: $scrollBarHandleBorder;
    }
  }
  .userManualTextComponentContainer {
    max-width: 1130px;
    min-width: 620px;
    margin-right: 10px;
    background-color: white;
    border-radius: $borderRadiusLarge;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 0px 3px 20px #00000029;
    margin-left: 40px;
    height: calc(100vh - 150px);

    .userManualTextTitle {
      font-size: 26px;
    }
    .userManualTextContainer {
      h1,
      h2,
      a,
      p {
        user-select: initial !important;
        cursor: default;
        a[target='_blank'] {
          text-decoration: underline !important;
          color: $popCulture !important;
        }

        a[target='_blank']:hover {
          font-weight: bold;
        }
      }
      h1 {
        a {
          cursor: default !important;
        }
      }
      h1 {
        font-size: 20px;
        color: $popCulture;
        margin-top: 20px;
        margin-bottom: 10px;
        a {
          font-size: 20px;
          color: $popCulture;
        }
      }
      h2 {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
        color: $popCulture;
        a {
          font-size: 16px;
          color: $popCulture;
        }
      }

      .wp-block-video {
        max-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        video {
          min-width: 90%;
          max-width: 100%;
        }
      }
      figure {
        max-width: 90%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 0;
        img {
          z-index: 0;
        }
      }

      .customLink {
        text-decoration: underline !important;
        color: $popCulture !important;
      }
      .customLink:hover {
        font-weight: bold;
      }
    }

    .userManualTextEmpty {
      height: 400px;
    }

    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      border-radius: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $scrollBarBackground;
      border-radius: 10px;
      box-shadow: -1px 3px 6px #00000029;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $lakersUniform;
      border-radius: 10px;
      border: $scrollBarHandleBorder;
    }
  }
}
