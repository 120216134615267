@import './accessComponentContainer';

@keyframes closeAnimationContainer {
  to {
    left: 70px;
    top: calc(100vh - 80px);
    width: 145px;
    height: 35px;
  }
}

.fullScreenPopMessageContainer {
  position: fixed;
  width: 100vw;
  height: 80vh;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1900;
  &#fullScreenPopMessageContainer-AWADetails {
    height: 98vh;
    max-height: 800px;
    overflow-y: scroll;
  }
  &.forInfoVideo {
    height: 100vh;
    overflow: scroll;
  }

  &.close {
    animation-name: closeAnimationContainer;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    overflow: hidden;
  }

  .popUpContainerGeneral {
    @keyframes closeAnimation {
      from {
        height: 1235px;
      }
      to {
        width: 145px;
        height: 35px;
      }
    }

    &#popUpContainer-AWADetails {
      height: 600px;
      width: 860px;
      min-width: 860px;
    }

    position: relative;
    background-color: white;
    box-shadow: 0px 0px 30px #00000029;
    border-radius: $borderRadiusLarge;
    max-width: 1030px;
    min-width: 1030px;
    height: fit-content;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.close {
      animation-name: closeAnimation;
      animation-duration: 300ms;
      animation-fill-mode: forwards;
    }

    &.biggerWidth {
      max-width: 1045px;
      min-width: 860px;
      transform: scale(1) !important;
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 50px;

      @media screen and (max-height: 900px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 900px) {
        margin-left: 10vw;
      }
      @media screen and (max-width: 800px) {
        margin-left: 20vw;
      }
      @media screen and (max-width: 700px) {
        margin-left: 40vw;
      }
      @media screen and (max-width: 600px) {
        margin-left: 60vw;
      }
      @media screen and (max-width: 500px) {
        margin-left: 80vw;
      }
    }

    .circularProgressDiv {
      @media screen and (max-height: 620px) {
        max-height: 60vh;
      }
      @media screen and (max-height: 330px) {
        min-height: none;
        max-height: 60vh;
      }
      max-height: 80vh;
      min-height: 200px;
    }

    @media screen and (max-width: 1080px) {
      transform: scale(0.8);
    }
    @media screen and (max-width: 880px) {
      transform: scale(0.7);
    }
    @media screen and (max-width: 780px) {
      transform: scale(0.5);
    }
    @media screen and (max-height: 340px) {
      margin-top: 50px;
      transform: scale(0.7);
    }

    .exitButtonContainer {
      position: absolute;
      top: 11px;
      right: 15px;
      cursor: pointer;
      .exitButtonImg {
        cursor: pointer;
      }
      .exitX {
        font-size: 25px;
        cursor: pointer;
      }
    }
    .warningMessageContainerGeneral {
      position: relative;
      height: fit-content;
      padding: 10px 40px 50px 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .preTitleContainer {
        position: relative;
        margin-top: 30px;

        .bigDoneImage {
          width: 90px;
          height: 94px;
          color: $lakersUniform;
        }
      }
      .warningTitle {
        position: relative;
        font-size: 32px;
        margin-top: 70px;
        text-align: center;

        &#popUp4-warningTitle-AWADetails {
          margin-top: 30px;
          user-select: auto !important;
          cursor: text;
        }

        &.celebration {
          font-size: 50px;
          color: #321b59;
        }

        &.withPreTitle {
          margin-top: 30px;
        }
      }
      .warningTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 52px;
        width: 490px;

        .settlementDetailsParagraphContainer {
          display: flex;
          flex-direction: column;
          margin-top: -80px;
          justify-content: center;
          align-items: center;
          .settlementCalcuationsTable {
            display: flex;
            flex-direction: column;
            width: 450px;
            .settlementCalculationsRow {
              display: flex;
              flex-direction: row;
              .bold {
                font-weight: bold;
              }
              .settlementCalculationsLeftPart {
                width: 250px;
                text-align: right;
                margin-right: 20px;
              }
              .settlementCalculationsRightPart {
                width: 200px;
                text-align: right;
              }
            }
            .settlementCalculationsLine {
              background-color: #d8d8d8;
              width: 100%;
              height: 1px;
              margin-top: 5px;
              margin-bottom: 8px;
            }
          }
          .settlementDetailsContainer {
            margin-top: 30px;
            .settlementDetailsTitle {
              font-weight: bold;
            }
            .settlementDetailsParagraph {
            }
          }
        }

        .statusImage {
          margin-bottom: 20px;
          margin-top: -40px;
        }

        &.celebration {
          width: 100%;
          font-size: 30px;
        }

        .warningText {
          margin-bottom: 6px;
          text-align: center;
          font-size: 20px;
          line-height: 1.1;
          user-select: auto !important;
          cursor: text;
        }
        .warningButton {
          margin-top: 10px;
        }
      }
      .warningSubTextContainer {
        width: 680px;
        margin-top: 76px;
        .warningSubText {
          font-size: 16px;
          text-align: center;
          line-height: 1.1;
        }
        &.withPreTitle {
          margin-top: 45px;
        }
      }
      .link {
        color: $popCulture;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.fullScreenPopMessageBackground {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 130vw;
  height: 150vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1600;
}
