.tabsContainer {
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: calc(#{$toolComponentMaxWidth} + 100px);
  &.tutorialsTabsContainer {
    width: calc(#{$toolComponentMaxWidth} + 200px);

    justify-content: flex-start;
  }

  &.settingsTabsContainer {
    justify-content: flex-start;
  }

  @media screen and (max-width: 1500px) {
    width: 90vw;
    &.tutorialsTabsContainer {
      width: calc(#{$toolComponentMaxWidth} + 200px);
      position: absolute;
      left: 70px;
    }
  }

  @media screen and (max-width: 1590px) {
    &.tutorialsTabsContainer {
      width: calc(#{$toolComponentMaxWidth} + 200px);
      position: absolute;
      left: 70px;
    }
  }
  @media screen and (max-width: 836px) {
    min-width: 704px;
    width: 704px;
    margin-left: 10px;
  }
  min-width: $toolComponentMinWidth;
  .singleTabContainer {
    position: relative;
    &.singleTutorialTabContainer {
      &:first-of-type {
        margin-left: 0;
      }
      margin-left: 40px;
    }

    cursor: pointer;
    .tabName {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      font-size: 23px;
      @media screen and (max-width: 1200px) {
        font-size: 20px !important;
        &.tutorialsTabName {
          font-size: 23px !important;
        }
      }
      @media screen and (max-width: 1050px) {
        font-size: 18px !important;
        &.tutorialsTabName {
          font-size: 23px !important;
        }
      }
      @media screen and (max-width: 950px) {
        font-size: 16px !important;
        &.tutorialsTabName {
          font-size: 23px !important;
        }
      }
      color: $mondayGray;
      font-weight: $boldWeight;
      display: flex;
      position: relative;
      cursor: pointer;
      .errors {
        @extend .sharable-error;
        top: -11px !important;
        left: -15px !important;
      }
    }
    .tabNameActive {
      color: $lakersUniform;
      font-weight: $boldWeight;
    }

    .tabUnderline {
      width: 100%;
      height: 3.5px;
      background-color: transparent;
      cursor: pointer;
    }
    .tabUnderlineActive {
      background-color: $lakersUniform;
    }
    .analyseCaseButton {
      @media screen and (max-width: 1050px) {
        height: 40px !important;
      }
      @media screen and (max-width: 950px) {
        padding: 1px 15px 1px 15px !important;
      }
      height: 44px;
      background-color: $lakersUniform;
      padding: 1px 30px 1px 30px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: $borderRadiusSmall;
      cursor: pointer;
      &:hover {
        background-color: $lakersUniformHover;
        cursor: pointer;
      }
      &:disabled {
        background-color: $sundayGray !important;
        cursor: default;
        border: none;
      }
      .analyseCaseButtonText {
        cursor: pointer;
        color: white;
        font-size: 23px;
        @media screen and (max-width: 1200px) {
          font-size: 20px !important;
        }
        @media screen and (max-width: 1050px) {
          font-size: 18px !important;
        }
        @media screen and (max-width: 950px) {
          font-size: 16px !important;
        }
        font-weight: $boldWeight;

        &.disabled {
          cursor: default;
        }
      }
    }
    .failedCaseButton {
      background-color: $laCandyfloss !important;
      border: none;
      &:hover {
        background-color: $laCandyfloss !important;
        cursor: pointer;
      }
      .failedCaseButtonText {
        color: $ageanMidnight;
      }
    }
  }
  .inactive {
    cursor: default !important;
  }
}

.sharable-error {
  border: solid 1px $greekEaster;
  color: $greekEaster;
  background-color: white;
  position: absolute;
  top: -8px;
  left: -10px;
  font-weight: $boldWeight;
  font-size: 13px;
  border-radius: 20px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
  }
}
