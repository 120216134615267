$widthOfTextarea: 1030px;
$widthOfClaimTextarea: 666px;
.reportParagraphContainer {
  padding-left: $reportPaddingLeft;
  margin-top: 10px;
  position: relative;
  margin-bottom: 10px;
  z-index: 1;

  .textarea-size {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    white-space: pre-line;
    width: $widthOfTextarea;
    &.claimParagraph {
      width: $widthOfClaimTextarea;
      margin-left: 3px;
    }
    padding: 5px 5px 60px 5px;
  }
  .reportParagraphTextAreaContainer {
    width: fit-content;
    position: relative;
  }
  .reportParagraphTextArea {
    width: $widthOfTextarea;
    box-sizing: border-box;
    &.claimParagraph {
      width: $widthOfClaimTextarea;
    }
    border: $lakersUniform solid 1px;
    border-radius: $borderRadiusMedium;
    overflow-y: hidden;
    color: $grayForReport;

    &::placeholder {
      color: rgb(201, 197, 197);
    }
    &.hideBorders {
      border: white solid 1px;
    }
    resize: none;
    padding: 5px 5px 60px 5px;
  }
  .reportParagraphTextFromSnapshot {
    cursor: text;
    width: $widthOfTextarea;
    &.claimParagraph {
      width: $widthOfClaimTextarea;
    }
    border: transparent solid 1px;
    &:hover {
      border: $lakersUniform solid 1px;
    }
    border-radius: $borderRadiusMedium;

    resize: none;
    padding: 5px 5px 30px 5px;
    overflow-y: hidden;
  }
  .saveParagraphContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .revertTextAndDeleteContainer {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .thumbnailContainer {
      margin-left: 10px;
      border-radius: $borderRadiusSmall;
      border: transparent solid 0.5px;
      &.inactive {
        cursor: default;
        &:hover {
          border: none;
        }
      }
      cursor: pointer;
      padding: 3px;
      &:hover {
        border: $lakersUniform solid 0.5px;
      }
      .undoImg {
        width: 12px;
        cursor: pointer;
      }
      .redoImg {
        width: 12px;
        cursor: pointer;
      }
      .resetImg {
        cursor: pointer;
      }
      .deleteImg {
        cursor: pointer;
      }
    }
  }
  .wordCounterDiv {
    position: absolute;
    left: 12px;
    bottom: 10px;
    color: $ageanMidnight;
    &.wordForClaims {
      left: 6px;
    }
  }
}
