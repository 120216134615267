@mixin exitButtonImgContainer() {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;

  .exitButtonImg {
    cursor: pointer;
  }
}

.userPopUp {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 5999;
  font-family: 'Roboto', sans-serif;

  &.for-settings {
    background-color: $transparentBackgroundGray;
    position: fixed;
  }

  &.for-users {
    background-color: $transparentBackgroundGray;
    overflow: scroll;
    position: fixed;
  }

  .header {
    top: 0;
    padding-top: 18px;
    padding-bottom: 18px;
    justify-content: space-between;
    z-index: 10;
    flex-wrap: nowrap;
    align-items: flex-start;
    display: flex;
    width: 1170px;
  }

  .header-out {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .logo {
    height: 70.44px;
    width: 127px;
    position: relative;
    padding-left: 15px;
  }
  .loginBox {
    background-color: white;
    box-shadow: 0px 0px 30px #0000002c;
    width: 550px;
    height: 620px;
    &.ssoLoginBox {
      height: 253px;
      margin-top: 100px;
      width: 496px;
    }

    &.ssoError {
      height: 335px;
    }
    position: absolute;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &.freemium {
      width: 496px;
      height: 485px;
    }

    &.only-owner {
      width: 700px;
      height: 700px;

      &.small-owner {
        height: 300px;
      }
    }

    &.small {
      height: 400px;
    }

    &.very-small {
      height: 300px;
    }

    &.big-form {
      height: 680px;
    }

    .form {
      width: 470px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &.freemium {
        width: 400px;
      }
    }

    .successfullMessageContainer {
      margin-bottom: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      .successfullImg {
        height: 120px;
        margin-bottom: 52px;
      }
      .successfullTitle {
        font-size: 24px;
        font-weight: $boldWeight;
      }
    }
    .welcomeMessageContainer {
      position: absolute;
      width: 470px;
      overflow-wrap: break-word;
      top: 45px;
      &.ssoWelcomeMessageContainer {
        top: 47px;
        width: 470px;
        display: flex;
        justify-content: center;
      }

      .welcome {
        font-family: 'poppins-local-bold';
        padding: 0px;
      }

      &.freemium {
        width: 400px;
      }

      &.middle {
        position: relative;
        margin: auto;
        text-align: center;
        top: 0;
      }

      &.only-owner {
        width: 660px;
        top: 25px;
      }
      .instructions {
        font-size: 16px;
        margin-top: 0px !important;

        .link {
          text-decoration: underline !important;
          color: $popCulture !important;
        }
        .additionalInfo {
          font-size: 16px;
          margin-top: 12px !important;
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }
    .ssoErroLoginContainer {
      width: 470px;
      display: flex;
      justify-content: center;
      top: 90px;
      position: absolute;
      .ssoErroLoginText {
        color: $greekEaster;
        font-size: 16px;
        width: 392px;
        text-align: center;
      }
    }

    .inputsContainer {
      margin: auto;
      width: 100%;
      .input {
        margin-top: 10px;
      }
      .lower {
        margin-top: 90px;
      }
      .higher {
        margin-bottom: 50px;
      }
    }

    .inputEmail {
      margin-bottom: 50px;
    }
    .inputResetPassword1 {
      margin-top: 30px;
      margin-bottom: 60px;
      &.for-settings {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;
      }
    }

    .inputCode {
      margin-bottom: 40px;
    }

    .inputResetPassword2 {
      margin-bottom: 35px;
      &.for-settings {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;
      }
    }

    .inputResetPassword3 {
      &.for-settings {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .selectOwnersContainer {
      margin-top: 40px;
      height: 420px;
      width: 660px;
      display: flex;
      flex-direction: column;

      .titles {
        display: flex;
        flex-direction: row;
        .first {
          flex: 1;
          padding-left: 15px;
          font-size: 17px;
          font-weight: bold;
        }
        .centered {
          flex: 1;
          font-size: 17px;
          font-weight: bold;
        }
      }

      .cases-column {
        overflow-y: auto;

        /* width */
        &::-webkit-scrollbar {
          width: $scrollBarWidth;
          border-radius: 10px;
          margin-right: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $lakersUniform;
          border-radius: 10px;
        }
        .case-row {
          margin: auto;
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 96%;
          height: 60px;
          border-radius: $borderRadiusMedium;
          box-shadow: 0px 0px 6px #00000029;

          &:hover {
            background-color: $hovercraftPurple;
          }

          .subrow {
            flex: 1;
            font-size: 16px;

            &.withPadding {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
        }
      }
    }

    .errorClass {
      color: $greekEaster !important;
    }
    .inputLabel {
      font-size: 10px;
    }

    .backImg {
      position: absolute;
      top: 2px;
      left: -68px;
      height: 58px;
      width: 58px;
      cursor: pointer;
      z-index: 1200px;
    }

    .submitButtonContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      bottom: 45px;
      &.admin {
        bottom: 125px;
      }

      &.row {
        width: 470px;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      &.only-owner {
        width: 660px;
        margin-right: 40px;
      }

      .helperText {
        color: $greekEaster;
        margin-top: 10px;
      }
    }

    .ssoButtonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
      width: 470px;

      .ssoText {
        color: $mondayGray;
      }

      .ssoButton {
        margin-top: 6px;
        background-color: white;
        width: 404px;
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        border-style: none;
        outline-style: none;
        box-shadow: 0px 3px 6px #00000029;

        .ssoButtonText {
          color: #212121;
          font-size: 16px;
          font-family: 'poppins-local-bold';
          cursor: pointer;
        }

        .ssoButtonImg {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
      }
    }

    .submitButton {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      width: 470px;
      height: 42px;
      background-color: $lakersUniform;
      color: white;
      font-weight: $boldWeight;
      border-radius: 4px;
      font-size: 16px;
      border-style: none;
      outline-style: none;
      cursor: pointer;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;

      .MuiCircularProgress-circle {
        color: white;
      }

      &.freemium {
        width: 400px;
      }

      &.small {
        width: 220px;
        height: 38px;
        margin-top: 0px;
      }

      &.only-owner {
        width: 400px;
        height: 38px;
        margin-top: 0px;
      }
    }

    .disabledButton {
      background-color: $sundayGray !important;
      cursor: default;
    }

    .inputsCenterContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .inputsColumnContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
    }

    .inputsRowContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .radioButton {
      background-color: white;
      height: 45px;
      border-color: $lakersUniform;
      font-weight: $boldWeight;
      border-radius: $borderRadiusSmall;
      font-size: 16px;
      outline-style: none;
      border: 1px solid;
      cursor: pointer;
      color: $lakersUniform;
      padding-left: 30px;
      padding-right: 30px;
      &:hover {
        background-color: $hovercraftPurple;
      }

      &.disabled {
        background-color: $sundayGray !important;
        border-color: transparent;
        color: #8e8e8e !important;
        cursor: default;
      }
    }

    .radioButtonSelected {
      box-shadow: 0px 3px 6px #00000029;
      background-color: $lakersUniform !important;
      color: white !important;
      border-color: $lakersUniform;
    }

    .orContainer {
      margin-top: 15px;
      margin-bottom: 15px;
      color: $lakersUniform;
      font-weight: $boldWeight;

      &.disabled-sms {
        color: #6a6a6a;
        margin-top: 2px;
        font-weight: 100;
        margin-bottom: 30px;
        font-size: 14px;
        width: 210px;
        text-align: center;
      }
    }

    .lettersContainer {
      margin-top: 25px;
      margin-bottom: 10px;
      color: $lakersUniform;
      font-size: 14px;
      font-weight: $boldWeight;
      text-align: center;
    }

    .bigletters {
      font-size: 21px !important;
    }

    .digitsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 300px;
    }

    .oneDigitInput {
      width: 35px;
      height: 35px;
      border-color: $lakersUniform;
      color: $lakersUniform;
      font-weight: $boldWeight;
      font-size: 16px;
      border-radius: 4px;
      border: 2px solid;
      text-align: center;
      outline-style: none;
    }

    .oneDigitInput:focus {
      border: 2px solid $popCulture !important;
    }

    .errorletersContainer {
      color: $greekEaster !important;
    }

    .qrContainer {
      box-shadow: 0px 0px 15px #0000002c;
      background-color: white;
      padding: 10px 10px 10px 10px;
      border-radius: 4px;
    }

    .copyButton {
      background-color: white;
      height: 75px;
      width: 108px;
      border-color: $lakersUniform;
      border-radius: 4px;
      font-size: 13px;
      outline-style: none;
      border-style: none;
      cursor: pointer;
      color: $lakersUniform;
      box-shadow: 0px 0px 15px #0000002c;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .copyImage {
      width: 23px;
      height: 25px;
      color: $lakersUniform;
      margin-bottom: 5px;
    }

    .bigDoneImage {
      width: 112px;
      height: 118px;
      color: $lakersUniform;
      margin-bottom: 5px;
    }

    .visibleTick {
      width: 29px;
      height: 29px;
      cursor: pointer;
    }
    .hiddenTick {
      visibility: hidden;
      position: fixed;
    }
    .showPassword {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 13px;
      p {
        cursor: pointer;
      }
    }
    .tickText {
      font-size: 16px;
      margin: 0;
      margin-left: 6px;

      &.freemium {
        cursor: default;
      }
    }
    .forgotContainer {
      margin-top: 27px;
      .forgot {
        font-size: 16px;
        color: $lakersUniformHover;
        text-decoration: none;
        cursor: pointer;
      }
      .centered {
        text-align: center;
      }
    }

    .exitButtonImgContainer {
      @include exitButtonImgContainer();
    }
  }
}

.log-out-text {
  color: $industrialEggYolk;
  font-size: 16px;
  position: fixed;
  right: 7%;
  top: 30px;
  float: right;
}

.mailTo {
  color: $popCulture;
}
