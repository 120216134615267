@import './createPdfReportComponents/pdfReportMenu';
@import './createPdfReportComponents/previewPdf';

.resultsOptionsContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent !important;
  box-shadow: none;
  padding-bottom: 0px;

  @media screen and (max-width: 1500px) {
    width: 100% !important;
  }

  @media screen and (max-width: 1400px) {
    width: 95% !important;
  }
  @media screen and (max-width: 1300px) {
    width: 90% !important;
    margin-left: 42px;
  }
  &.fixed {
    position: fixed;
    top: 10px;
    left: 3%;
    .optionsContainer {
      border: 0.3px solid $mondayGray;
    }
    @media screen and (max-width: 1400px) {
      width: 91% !important;
    }
    @media screen and (max-width: 1300px) {
      width: 85.5% !important;
      margin-left: 0px;
    }
    @media screen and (max-width: 1100px) {
      width: 85% !important;
      margin-left: 0px;
    }
    @media screen and (max-width: 836px) {
      top: -33px;
      margin-left: 0px;
    }
  }
  .optionsContainer {
    height: 58px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;

    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    .resultsPartySwitchContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      max-width: 500px;
      height: 36px;
      bottom: 28px;
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 18px;
      padding-left: 8px;
      padding-right: 8px;
      margin-right: 12px;
      margin-left: 25px;
      &.higher {
        bottom: 90px;
      }

      .resultsPartySwitch {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 18px;

        cursor: pointer;

        &.first {
          margin-right: 5px;
        }

        .img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          cursor: pointer;
        }

        &.selected {
          background-color: $springLevanter;
        }

        .resultsPartyText {
          color: #201a2c;
          font-size: 13px;
          cursor: pointer;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .incurredCostsButton,
    .roundedResultsButton {
      font-size: 13px;
      padding: 7px;
      @media screen and (max-width: 850px) {
        padding: 4px;
      }
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 22px;
      }
      .incurredCostsButtonText,
      .roundedResultsButtonText {
        margin-left: 6px;
        cursor: pointer;
        &.inactive {
          color: $mondayGray;
        }
      }
    }
    .incurredCostsPopUpContainer {
      position: absolute;
      width: 220px;
      height: 80px;
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      top: 50px;
      left: 8px;
      padding: 8px;
      .incurredCostsPopUpText {
        line-height: 1.2;
      }

      .oldNewSwitchText {
        cursor: pointer;
        user-select: none !important;
        color: $popCulture;
        text-decoration: underline;
        margin-top: 6px;
      }
    }
  }
  #createPdfReportButton {
    margin-right: 10px;
  }
}
