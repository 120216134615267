.tutorialTileBackground {
  position: relative;
  width: 413px;
  height: 243px;
  background-color: white;
  box-shadow: 0px 5px 35px $shadowColor;
  border-radius: $borderRadiusLarge;
  padding: 28px 26px 5px 26px;
  margin-right: 49px;
  margin-bottom: 40px;
  cursor: pointer;
  &:hover {
    background-color: $hovercraftPurple;
  }

  .tutorialTileTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .thumbnailContainer {
      width: 56px;
      max-height: 56px;
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;

      .tutorialTileThumbnail {
        max-height: 53px;
        cursor: pointer;
      }
    }
    .titleContainer {
      max-width: 282px;
      cursor: pointer;

      .tutorialTileTitle {
        font-size: 26px;
        font-weight: 500;
        color: $popCulture;
        line-height: 1.1;
        cursor: pointer;
      }
    }
  }
  .tutorialTileSummaryContainer {
    max-width: 360px;
    max-height: 85px;
    margin-top: 20px;
    cursor: pointer;
    .tutorialTileSummary {
      cursor: pointer;
    }
  }
  .tutorialTileEstimatedTimeContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    bottom: 10px;
    cursor: pointer;

    .estimatedTimeImg {
      width: 28px;
      margin-right: 9px;
      margin-bottom: 5px;
      cursor: pointer;
    }
    .tutorialTileEstimatedTimeText {
      cursor: pointer;
    }
  }
}
