.infoContainer {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 44px;
  padding-bottom: 40px;

  .infoTitle {
    color: #331a5b;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .infoText {
    font-size: 16px;
    color: #201a2c;
    margin-bottom: 10px;
  }

  .infoVideo {
    margin: auto;
    margin-bottom: 28px;
  }

  .infoActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    margin-top: 19px;

    .cancelButton {
      cursor: pointer;
      min-width: 85px;
      height: 44px;
      margin-right: 27px;
      border: 1px solid #331a5b;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 8px;

      .text {
        cursor: pointer;
        color: #331a5b;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .doneButton {
      cursor: pointer;
      width: 85px;
      height: 44px;
      margin-right: 27px;
      border: 1px solid #331a5b;
      background-color: #331a5b;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        cursor: pointer;
        color: white;
        font-size: 18px;
        font-weight: bold;
      }

      .MuiCircularProgress-circle {
        color: white;
      }
    }
  }
}
