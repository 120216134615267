.userThumbnailObjectContainer {
  &:first-of-type {
    margin-left: 0;
  }
  &.inMenu {
    @media screen and (max-width: 850px) {
      display: none;
    }
  }
  &.inMenu2 {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  &.active {
    &:last-of-type {
      margin-right: 4px;
    }
  }

  &.wrapped {
    &:first-of-type {
      margin-top: 4px;
    }
    margin-bottom: 4px;
  }

  margin-left: 3px;
  .userThumbnailContainer {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .userThumbnailText {
      cursor: pointer;
      font-size: 13px;
    }

    &.active {
      border: 1px solid $infantryWest;
      box-shadow: 0px 0px 6px 1px $infantryWest;
    }
  }
}

.addUserButton {
  &.inMenu2 {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 3px;

  .addUserButtonImg {
    cursor: pointer;
  }
}

.userThumbnailHoverContainermyScenarios {
  @media screen and (max-width: 700px) {
    right: 40px;
  }
  position: absolute;
  top: 50px;
  background-color: white;
  width: fit-content;
  padding: 4px 8px;
  border-radius: $borderRadiusMedium;
  z-index: 10;
  box-shadow: 0px 3px 6px #00000029;
}
