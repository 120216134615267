.lowScreenMessageContainer {
  position: fixed;
  width: calc(100% - 120px);
  margin-left: 120px;
  bottom: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  .shake {
    animation: shake 0.7s;
  }

  @keyframes shake {
    0% {
      transform: rotate(2deg);
    }
    10% {
      transform: rotate(-2deg);
    }
    20% {
      transform: rotate(2deg);
    }
    30% {
      transform: rotate(-2deg);
    }
    40% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(-2deg);
    }
    60% {
      transform: rotate(2deg);
    }
    70% {
      transform: rotate(-2deg);
    }
    80% {
      transform: rotate(2deg);
    }
    90% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .lowScreenMessageBox {
    cursor: pointer;
    min-height: 41px;
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 9px 27px;
    margin-right: 120px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: $borderRadiusMedium;

    &.non-clickable {
      cursor: default;
    }

    &.no-margin {
      margin-right: 20px;
    }

    .lowScreenMessageImgContainer {
      margin-right: 11px;
      cursor: pointer;
      .lowScreenMessageImg {
        cursor: pointer;
      }
    }
    .lowScreenMessageTextContainer {
      display: flex;
      flex-direction: row;
      height: fit-content;

      cursor: pointer;

      &.non-clickable {
        cursor: default;
      }

      .lowScreenMessageText {
        cursor: pointer;

        &.non-clickable {
          cursor: default;
        }
      }
    }
  }
  .hoverPink {
    &:hover {
      background-color: $laCandyflossHover !important;
    }
  }

  .hoverError {
    &:hover {
      background-color: $greekEasterHover !important;
    }
  }
  .noInternetHover {
    &:hover {
      background-color: $greekEasterHover;
    }
  }
}
