.emptyCaseMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: -25px;
  .emptyCaseMessageTitle {
    font-size: 16px;
    font-weight: $boldWeight;
    margin-left: 25px;
    color: $lakersUniform;
  }
  .emptyCaseMessageSubTitle {
    margin-left: 25px;
  }
  .emptyCaseArrow {
    height: 58px;
  }
}
