.videoInfoButtonImg {
  cursor: pointer;
  margin-bottom: 0 !important;
  margin-top: 2px;
}

.videoInfoButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .fullScreenPopMessageBackground {
    width: 100vw;
    height: 100vh;
  }
  .fullScreenPopMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    align-self: center;
    .videoInfoContainer {
      position: relative;
      max-width: 70%;
      max-width: 1310px;
      max-height: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .videoFromCms {
        max-width: 1310px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
      .exitButtonContainer {
        position: absolute;
        top: -30px;
        right: -30px;
        cursor: pointer;
        .exitButtonImg {
          cursor: pointer;
        }
        .exitX {
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }
}
