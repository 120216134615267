@import './emptyClaimsComponent';
@import './pasteClaimButton';

.claimsPreviewMain {
  margin-top: 53px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  &.noClaims {
    margin-top: 150px;
  }
  .claimsPreviewTopMenuContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .claimsPreviewDisplayOptions {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      &.on-list {
        margin-left: -7px;
      }

      .claimsPreviewOptionContainer {
        padding: 2px 2px 2px 2px;
        margin-bottom: 25px;
        margin-right: 8px;
        cursor: pointer;

        &.selected {
          background-color: #edeaf5;
          border: 1px solid #321b59;
          border-radius: 3px;
        }

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .claimsPreviewInterestOptionsContainer {
      margin-right: 15px;
    }
  }

  .claimsPreviewContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 1500px) {
      max-width: 90vw;
      min-width: 90vw;
      margin-left: 20px;
    }
    @media screen and (max-width: 836px) {
      max-width: $toolComponentMinWidth;
      min-width: $toolComponentMinWidth;
    }
    max-width: calc(#{$toolComponentMaxWidth + 32px});
    min-width: calc(#{$toolComponentMaxWidth + 32px});

    min-height: 111px;
    z-index: 10;
  }

  .claimsPreviewContainerList {
    @import './independentClaimPreviewList/independentClaimPreviewList';

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 1430px) {
      max-width: 90vw;
      min-width: 90vw;
    }
    @media screen and (max-width: 900px) {
      max-width: $toolComponentMinWidth;
      min-width: $toolComponentMinWidth;
      margin-right: 0px;
    }
    max-width: $toolComponentMaxWidth;
    min-width: $toolComponentMaxWidth;
    margin-right: 16px;

    z-index: 10;

    .listPreviewTableNamesDiv {
      width: 100%;
      height: 29px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .listPreviewTitleDiv {
        width: 43%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-right: 40px;

        &.forSum {
          justify-content: flex-end;
        }

        &.noSums {
          margin-right: 7px;
        }

        .listPreviewSortImg {
          padding-left: 5px;
          cursor: pointer;
        }
      }

      .listPreviewOtherTableNamesContainer {
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: space-around;
        align-items: center;

        .listPreviewOtherTableNamesDiv {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;
          max-width: 160px;
          position: relative;

          .listPreviewSortImg {
            position: absolute;
            right: -12px;
            padding-left: 5px;

            cursor: pointer;
          }
        }

        .calculateButton {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .listPreviewTableName {
        font-size: 16px;
        color: #201a2d;
        text-align: end;

        &.forSum {
          color: #201a2d;
          font-weight: $boldWeight;
          margin-right: 5px;
        }

        &.noMargin {
          margin-right: 0px;
        }

        &.client {
          color: $clientColor;
          &.krogerus {
            color: $krogerusClientColor;
          }
          font-weight: $boldWeight;
        }

        &.opposing {
          color: $opposingPartyColor;
          &.krogerus {
            color: $krogerusOpposingPartyColor;
          }
          font-weight: $boldWeight;
        }

        &.selectable {
          user-select: auto !important;
          cursor: text;
        }
      }

      .listPreviewTableNameClickable {
        font-size: 16px;
        color: #201a2d;
        text-align: end;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &.noSort {
          cursor: default;
        }
      }
    }
  }
}

.singleClaimPreviewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 255px;
  width: 312px;
  height: 123px;
  margin-right: 17px;

  margin-bottom: 30px;
  @import './independentClaimPreview/independentClaimPreview';
  @import './treePreview/treePreview';
  .claimPositionHighlight {
    position: absolute;
    top: -10px;
    &.left {
      left: -10px;
    }
    &.right {
      right: -10px;
    }
    cursor: pointer;
    background-color: $lakersUniformHover;
    z-index: -2;
    width: 30px;
    height: 140px;
    border-radius: 5px;
    opacity: 0.7;
  }
  .threeDotsMenuContainer {
    width: 182px;
    height: 123px;
    /* UI Properties */
    background-color: white;
    border: 1px solid $lakersUniform;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 5px 8px 10px 10px;
    cursor: default;

    .copyingClaimMessageContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .threeDotsMenuRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      width: 100%;
      margin-bottom: 3px;
      &:hover {
        font-weight: 600;
      }
      .threeDotsMenuRowText {
        margin-left: 5px;
        &.red {
          color: $greekEaster;
        }
        cursor: pointer;
        &:hover {
          font-weight: 600;
        }
      }
      .threeDotsMenuRowImg {
        width: 13px;
        cursor: pointer;
      }
    }
  }
  .moveTileHandle {
    position: absolute;
    top: 0px;
    left: -34px;
    cursor: pointer;
    background-color: white;
    z-index: 0;
    width: 34px;
    height: 35px;
    box-shadow: -3px 3px 3px #00000012;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .moveTileTransparentCover {
    position: absolute;
    top: 0px;
    left: -34px;
    cursor: pointer;
    z-index: 0;
    width: 34px;
    height: 35px;
    border-radius: 5px 0px 0px 5px;
  }
  .moveTileHandleBackground {
    position: absolute;
    top: 0px;
    left: -34px;
    cursor: pointer;
    background-color: white;
    z-index: -1;
    width: 37px;
    height: 35px;
    border-radius: 5px 0px 0px 5px;
  }
  .moveTileHandleinvisibleLayer {
    position: absolute;
    top: -10px;
    left: -42px;
    cursor: default;
    z-index: 0;

    width: 45px;
    height: 120px;
  }
  .threeDotsButtonContainer {
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid $lakersUniform;
    width: 23px;
    height: 23px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.pdfPreview {
  height: fit-content;
  align-items: flex-start;
}
