$rowHeight: 40px;

.expectedValueBreakdownGraphContainer {
  margin-top: 40px;
  &.forReport {
    margin-top: 0px;
  }
  .expectedValueBreakdownSubtitleForReport {
    margin-bottom: 20px;
    margin-left: -40px;
    width: 100%;
    text-align: center;
  }
  .expectedValueBreakdownContainer {
    margin-left: 60px;
    margin-right: 150px;
    @media screen and (max-width: 1200px) {
      margin-right: 40px;
    }
    @media screen and (max-width: 900px) {
      margin-left: 0px;
    }
    &.forReport {
      margin-left: 0px;
      margin-right: 100px;
    }
    .valueBreakdownContainer {
      margin-bottom: 30px;

      .valueBreakdownTitle {
        user-select: auto !important;
        cursor: text;
        font-family: 'poppins-local-medium';
        font-size: 18px;
        @media screen and (max-width: 900px) {
          padding-left: 40px;
        }
      }
      .valueBreakdownComponent {
        display: flex;
        flex-direction: row;

        .valueBreakdownTextContainer {
          width: 60%;
          @media screen and (max-width: 1200px) {
            width: 50%;
          }
          padding-left: 40px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .valueBreakdownText {
            user-select: auto !important;
            cursor: text;
            height: $rowHeight;
            display: flex;
            align-items: center;
            color: $grayForReport;
            &.expectedValue {
              user-select: auto !important;
              cursor: text;
              font-family: 'poppins-local-medium';
              font-size: 18px;
              padding-left: 0px;
              color: $ageanMidnight;
              margin-left: -40px;
              @media screen and (max-width: 900px) {
                padding-left: 40px;
              }
            }
          }
        }
        .valueBreakdownGraph {
          width: 40%;
          @media screen and (max-width: 1200px) {
            width: 50%;
          }
          display: flex;
          flex-direction: row;
          position: relative;

          .breakdownValuesContainer {
            width: 50%;
            .breakdownValue {
              user-select: auto !important;
              cursor: text;
              height: $rowHeight;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-right: 8px;
              margin-left: 2px;
              &.expectedValue {
                font-family: 'poppins-local-bold' !important;
              }
            }
          }
          .breakdownValuesDivider {
            position: absolute;
            width: 6px;
            left: calc(50% - 3px);
            top: -20px;
            height: calc(100% + 40px);
            background-color: $sundayGray;
            border-radius: 20px;
          }
          .breakdownBarsContainer {
            width: 50%;
            display: flex;
            flex-direction: column;
            .breakdownBarComponent {
              height: $rowHeight;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .breakdownBar {
                height: 19px;
                display: flex;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 0px 19px 19px 0px;
                &.positive {
                  background-color: $infantryWest;
                }
                &.negative {
                  background-color: $greekEaster;
                }
              }
            }
          }
        }
      }
    }
  }
}
