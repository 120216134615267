@import '../../../styles/variables';
$buttonHeight: 30px;

.switchContainer {
  height: $buttonHeight;
  position: relative;
  cursor: pointer;

  .switchMainButton {
    box-shadow: 0px 3px 6px #0000002b;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    &.switchState2 {
      justify-content: flex-start;
    }
    height: $buttonHeight;
    padding: auto;
  }
  .switchLabelTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    &.inTree {
      min-width: 160px;
    }
    .switchLabel {
      font-size: 14px;
      font-weight: $boldWeight;

      text-align: center;

      line-height: 12px;
      cursor: pointer;
      margin: auto;
      width: 100%;

      &.inTree {
        font-size: 12px;
        font-weight: 400;
        //color: $ageanMidnight !important;
      }
    }
  }
  .switchCircle-right {
    position: absolute;
    top: 5px;

    right: -2px;
    width: 37px;
    height: 37px;
    border: 2px solid;
    border-radius: 19px;
    transform: translate(0, -5px);

    background: transparent
      radial-gradient(
        closest-side at 50% 50%,
        #ffffff 0%,
        #fafafa 83%,
        #e9e9e9 100%
      )
      0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
  }
  .switchCircle-left {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 37px;
    height: 37px;
    border: 2px solid;
    border-radius: 19px;
    transform: translate(0, -5px);

    background: transparent
      radial-gradient(
        closest-side at 50% 50%,
        #ffffff 0%,
        #fafafa 83%,
        #e9e9e9 100%
      )
      0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
  }
}
