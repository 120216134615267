$marginY: calc(50% - 30px);

.hoverBackground {
  max-width: 600px;
  min-width: 400px;
  max-height: 320px;
  overflow-y: auto;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 3px 8px #0000005d;
  padding: 10px 5px 20px 10px;
  margin-top: -23px;

  .descriptionContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    .descriptionText {
      font-size: 16px;
      margin-left: 20px;
    }
  }
  .hoverTitle {
    margin-top: 14px;
    margin-left: 10px;
    font-size: 16px;
    text-align: left !important;
    margin-right: 2px;

    font-weight: normal !important;
  }
  .pathElementContainer {
    max-height: 246px;
    width: 415px;
    overflow-y: scroll;
    margin-top: 10px;
    .pathElement {
      width: 390px;
      min-height: 50px;
      border-radius: 10px;
      margin-top: 12px;
      padding: 10px;
      &:first-of-type {
        margin-top: 0px;
      }
      .claimTitle {
        font-size: 16px;
        font-weight: $boldWeight;
        margin-left: 10px;
        text-align: left !important;
      }
      .scenarioBreakdownContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0px;
        &:first-of-type {
          margin-top: 20px;
        }
        .treeQuestions {
          font-size: 16px;
          font-weight: normal;
          margin-left: 20px;
          width: 230px;
          text-align: left !important;
          margin-right: 7px;
          margin-bottom: 15px;
          padding-bottom: 2px;
        }
        .scenarioTitle {
          font-size: 16px;
          font-weight: normal;
          margin-left: 50px;
          width: 170px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left !important;
          margin-right: 7px;
          padding-bottom: 2px;
        }
        .amount {
          font-weight: normal;
          margin-right: 2px;

          font-size: 16px;
        }
      }
    }
    /* width */
    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      border-radius: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $scrollBarBackground;
      border-radius: 10px;
      box-shadow: -1px 3px 6px #00000029;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $lakersUniform;
      border-radius: 10px;
      border: $scrollBarHandleBorder;
    }
  }
}

.hoverTextContainer {
  margin: 8px 0px;
  &:first-of-type {
    margin-top: 20px;
  }
  .hoverText {
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 20px !important;
    line-height: 1.6;
    text-align: left !important;
    margin-right: 10px !important;
    span {
      font-weight: $boldWeight;
    }
  }
}

.hoverTriangle {
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid gray;
}

.descriptionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 7px 0px;
  .descriptionImgContainer {
    width: 65px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .descriptionImg {
      height: 20px;
    }
  }
  .descriptionText {
    font-size: 16px !important;
    font-weight: normal !important;
    margin-left: 9px !important;
    max-width: 350px;
  }
}
