.tutorialBreadCrumpbContainer {
  width: calc(#{$toolComponentMaxWidth} + 200px);

  margin-top: 20px;
  margin-bottom: -15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 1590px) {
    //.tutorialTabsContainer becomes fixed so this has to go down
    margin-top: 85px;
    margin-left: 20px;
    margin-bottom: -80px;
  }
  .breadcrumbTitleLeft {
    .breadcrumbTitleLeftText {
      cursor: pointer;
      &:hover {
        font-weight: $boldWeight;
      }
    }
    .breadcrumbTitleLeftTextHidden {
      font-weight: $boldWeight;
      visibility: hidden;
      height: 0;
    }
    margin-right: 5px;
  }

  .breadcrumbTitleRight {
    color: $popCulture;
    font-weight: $boldWeight;
    margin-left: 5px;
  }
}
