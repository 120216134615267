.chipContainer {
  height: 40px;
  padding: 12px 34px;
  border: 1px solid #462777;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  margin-right: 22px;
  cursor: pointer;
  border-radius: 20px;
}
.chipContainer:hover {
  background-color: #edeaf5;
}
.chipContainer .chipImage {
  margin-top: -5px;
  cursor: pointer;
  height: 20px;
  vertical-align: unset !important;
}/*# sourceMappingURL=Chip.css.map */