.button {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02857em;
  border-radius: 4px;
  padding: 8.5px 34px;
  line-height: 1.3;
  outline: none;
  text-transform: uppercase;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}
.button.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button.button-NoUpperCase {
  text-transform: none;
}
.button.danger {
  background-color: #ac2828 !important;
}
.button.button:disabled {
  background-color: #d9d9d9 !important;
  cursor: default;
  box-shadow: none !important;
  border: none !important;
}
.button .button-container {
  height: 18px;
}
.button .button-icon {
  height: 18px;
}
.button.button-type-contained {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1607843137);
  background-color: #331a5b;
}
.button.button-type-contained.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button.button-type-contained .circularProgressDiv {
  width: 80%;
}
.button.button-type-contained .MuiCircularProgress-root {
  margin-top: 5px;
}
.button.button-type-contained .MuiCircularProgress-circle {
  color: white;
}
.button.button-type-contained .button-text {
  color: white;
}
.button.button-type-contained:hover {
  background-color: #462777;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3450980392);
}
.button.button-type-contained:active {
  background-color: #331a5b;
}
.button.button-type-outlined {
  background-color: white;
  border: thin #331a5b solid;
  padding: calc(7.5px) calc(34px - 1px);
  box-shadow: none;
}
.button.button-type-outlined .button-text {
  color: #331a5b;
}
.button.button-type-outlined.button-with-icon.button-icon-on-side-left {
  padding: calc(7.5px) calc(21px) calc(7.5px) calc(10px);
}
.button.button-type-outlined.button-with-icon.button-icon-on-side-right {
  padding: calc(7.5px) calc(10px) calc(7.5px) calc(21px);
}
.button.button-type-outlined:hover {
  background-color: #edeaf5;
}
.button.button-type-outlined:active {
  background-color: white;
}
.button.button-type-yellow {
  background-color: #ffdc94;
  border: thin #331a5b solid;
  padding: calc(7.5px) calc(34px - 1px);
}
.button.button-type-yellow .button-text {
  color: #331a5b;
}
.button.button-type-yellow.button-with-icon.button-icon-on-side-left {
  padding: calc(7.5px) calc(21px) calc(7.5px) calc(10px);
}
.button.button-type-yellow.button-with-icon.button-icon-on-side-right {
  padding: calc(7.5px) calc(10px) calc(7.5px) calc(21px);
}
.button.button-type-yellow:hover {
  background-color: #ffd68b;
}
.button.button-type-yellow:active {
  background-color: #ffdc94;
}
.button.button-type-text {
  background-color: white;
  box-shadow: 0px 0px 0px white !important;
}
.button.button-type-text .button-text {
  color: #331a5b;
}
.button.button-type-text:hover {
  background-color: #edeaf5;
}
.button.button-type-text:active {
  background-color: white;
}
.button.button-type-nonactive {
  background-color: #d9d9d9;
  cursor: default;
}
.button.button-type-nonactive .button-text {
  color: white;
}
.button.button-with-icon.button-icon-on-side-left {
  flex-direction: row-reverse;
  padding: calc(8.5px) calc(23px) calc(8.5px) calc(10px);
}
.button.button-with-icon.button-icon-on-side-left img {
  margin-right: 12px;
}
.button.button-with-icon.button-icon-on-side-right {
  flex-direction: row;
  padding: calc(8.5px) calc(10px) calc(8.5px) calc(23px);
}
.button.button-with-icon.button-icon-on-side-right img {
  margin-left: 12px;
}
.button.noText {
  padding: calc(8.5px) 0px calc(8.5px) 10px !important;
}

.button.button-type-small {
  font-size: 13px;
  padding: 6.8px 27.2px;
}
.button.button-type-small .button-container {
  height: 14.4px;
}
.button.button-type-small .button-icon {
  height: 14.4px;
}
.button.button-type-small.button-type-outlined {
  padding: calc(5.8px) calc(27.2px - 1px);
}
.button.button-type-small.button-type-outlined.button-with-icon.button-icon-on-side-left {
  padding: calc(5.8px) calc(14.2px) calc(5.8px) calc(14.2px);
}
.button.button-type-small.button-type-outlined.button-with-icon.button-icon-on-side-right {
  padding: calc(5.8px) calc(3.2px) calc(5.8px) calc(14.2px);
}
.button.button-type-small.button-type-yellow {
  padding: calc(5.8px) calc(27.2px - 1px);
}
.button.button-type-small.button-type-yellow.button-with-icon.button-icon-on-side-left {
  padding: calc(5.8px) calc(14.2px) calc(5.8px) calc(3.2px);
}
.button.button-type-small.button-type-yellow.button-with-icon.button-icon-on-side-right {
  padding: calc(5.8px) calc(3.2px) calc(5.8px) calc(14.2px);
}
.button.button-type-small.button-with-icon.button-icon-on-side-left {
  flex-direction: row-reverse;
  padding: calc(6.8px) calc(3.2px) calc(6.8px) calc(16.2px);
}
.button.button-type-small.button-with-icon.button-icon-on-side-left img {
  margin-right: 9.6px;
}
.button.button-type-small.button-with-icon.button-icon-on-side-right {
  padding: calc(6.8px) calc(3.2px) calc(6.8px) calc(16.2px);
}
.button.button-type-small.button-with-icon.button-icon-on-side-right img {
  margin-left: 9.6px;
}

.button.button-type-very-small {
  font-size: 12px;
  padding: 6.8px 6.8px;
}
.button.button-type-very-small .button-container {
  height: 14.4px;
}

.button-type-nonactiveText {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #331a5b;
  border: thin White solid;
  padding: calc(7.5px) calc(34px - 1px);
  box-shadow: 0px 3px 6px white;
  background-color: transparent;
  cursor: default;
}
.button-type-nonactiveText.button-with-icon.button-icon-on-side-left {
  padding: calc(7.5px) calc(21px) calc(7.5px) calc(10px);
}
.button-type-nonactiveText.button-with-icon.button-icon-on-side-right {
  padding: calc(7.5px) calc(10px) calc(7.5px) calc(21px);
}/*# sourceMappingURL=Button.css.map */