@import './header/header';
@import './sideMenu/sideMenu';
@import './savePopUp/savePopUp';

.fixedReportMenu {
  width: 100%;
  position: fixed;
  top: 43px;
  left: 0px;
  margin-left: -9px;
  margin-right: 30px;
  min-height: 69px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  @media screen and (max-width: 1400px) {
    margin-left: 65px;
    width: calc(100vw - 90px);
  }
  @media screen and (max-width: 810px) {
    position: absolute;
    width: $toolComponentMinWidth;
  }

  .exitButtonContainer {
    position: absolute;
    overflow-x: auto;
    top: 100px;
    width: 100%;
    max-width: $toolComponentMaxWidth;
    min-width: $toolComponentMaxWidth;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 70px;
    z-index: 1;

    @media screen and (max-width: 1500px) {
      width: 95% !important;
    }
  }
  .exitButtonImg {
    cursor: pointer;
  }
  .downloadReportMenuContainer {
    .downloadReportLeftSide {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .generateReportButton {
        margin-right: 20px;
      }
      .saveButton {
        margin-right: 20px;
      }
    }
    border-radius: 0 0 $borderRadiusMedium $borderRadiusMedium;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 6px 6px #00000029;
    width: 100%;
    background-color: white;
    min-height: 69px;
    padding: 16px;

    max-width: 1330px;
    min-width: $toolComponentMinWidth;
  }
  .exitButtonImg {
    cursor: pointer;
  }
}
.savePopUpContainer {
  max-width: 1250px;
  width: 100%;

  min-width: $toolComponentMinWidth;
  position: relative;
  display: flex;
}

.textForReportWithDifferentResultsContainer {
  margin-right: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 644px;
  min-height: 57px;
  background-color: $lakersUniform;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  position: fixed;
  z-index: 600;
  bottom: 30px;
  @media screen and (max-width: 880px) {
    min-width: 300px;
    width: 80%;
    margin-right: 60px;
    margin-left: 90px;
  }
  .textForReportWithDifferentResults {
    max-width: 582px;
    color: white;
    &.grayText {
      opacity: 0.46;
    }
    @media screen and (max-width: 880px) {
      min-width: 300px;
      width: 80%;
      font-size: 2vw;
    }
  }
  .textForReportWithDifferentResultsButtonContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.noOpacity {
      opacity: 1;
      background-color: transparent;
    }
  }
}
