@import '../../../../components/failedCase';

.circularProgressDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  height: 100%;
}

.loadingResultsContainer {
  position: relative;
  display: flex;
  margin-top: 198px;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 500px;

  .progressImagesContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: flex-end;

    .queue {
      height: 80px;
    }
    .calculate {
      width: 86px;
    }
    .results {
      width: 133px;
    }
    .progressImageInnerContainer {
      width: 200px;
      height: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }
    .statusImage {
      //animation-name: statusImage;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    .cancelBecauseOfUpdateMessageContainer {
      color: $greekEaster;
    }
    @keyframes statusImage {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(0.9);
        opacity: 0.5;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    .progressImageLabel {
      font-size: 16px;
      align-self: center;
      text-align: center;
      margin-top: 15px;
    }
  }
  .progressContainer {
    position: relative;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1200px) {
      width: 80vw;
    }
    width: 1042px;

    .progressBarContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 836px) {
        width: 650px;
      }

      .progressBarBackground {
        position: relative;
        @media screen and (max-width: 1200px) {
          width: 80vw;
        }
        @media screen and (max-width: 836px) {
          width: 650px;
        }
        width: 1042px;
        height: 27px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0px 3px 6px #00000029;
        .progressBar {
          height: 27px;
          border-radius: 50px;
          background-color: $lakersUniform;
        }
        margin-left: -40px;
      }

      .cancelContainer {
        position: absolute;
        right: -20px;
        top: 0px;
        cursor: pointer;

        .cancelCase {
          width: 28px;
          border-radius: 15px;
          height: 28px;
          cursor: pointer;
          box-shadow: 0px 3px 6px #00000029;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: white;
          .cancelCaseSquare {
            width: 10px;
            height: 10px;
            background-color: $lakersUniform;
          }
        }
      }
    }
    .progressText {
      margin-top: 18px;
      font-size: 20px;
      text-align: center;
    }
  }
}
