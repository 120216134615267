.sideMenuTitleContainer {
  &.inactiveSideMenuTitleContainer {
    background-color: white;
    z-index: 1;

    &.top {
      box-shadow: 0px 5px 6px #00000029;
      border-radius: 0 0 $borderRadiusLarge 0;
      &.bottomActive {
        border-radius: 0 0 0 0;
      }
    }
    &.middle {
      box-shadow: 0px 5px 6px #00000029;
      border-radius: 0 $borderRadiusLarge 0 0;
      &.bottomActive {
        border-radius: 0 0 $borderRadiusLarge 0;
      }
    }
    &.bottom {
      border-radius: 0 $borderRadiusLarge 0 0;
      box-shadow: 0px 5px 6px #00000029;
      &.topActive {
        border-radius: 0 0 0 0;
      }
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  cursor: pointer;
  background-color: $backGroundGray;
  &.sideMenuTitleContainerOpen {
    justify-content: flex-start;
  }
  .sideMenuTitleThumbnailContainer {
    width: 49px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .sideMenuTitleText {
    font-size: 18px;
    margin-left: 8px;
    color: $lakersUniform;
    cursor: pointer;
    &.inactiveSideMenuTitleText {
      color: $mariaViolet;
    }
    &.hoverSideMenuTitleText {
      color: $ukNavy;
    }
  }
}
