$caseDetailsContainer-width-large: 135px;
$caseDetailsContainer-width-small: 123px;
$caseTitleWidth: 490px;
$enabledColor: #321b59;
@import './emptyCaseMessage';
@import '../../components/userThumbnail/userThumbnail';

.myCasesBox {
  @media screen and (max-width: 525px) {
    .caseDetailsContainer-title-lastEdited {
      margin-right: -17px !important;
    }
    .caseDetailsContainer-LastEdited-Date {
      width: 70px !important;
    }
  }
  @media screen and (max-width: 650px) {
    .caseDetailsContainer-title-actions,
    .caseDetailsContainer-actions {
      display: none !important;
    }
    .caseDetailsContainer-status,
    .caseDetailsContainer-title-status {
      margin-right: 20px;
    }

    .caseDetailsContainer-LastEdited-Date,
    .caseDetailsContainer-title-lastEdited {
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 890px) {
    .caseDetailsContainer-title-date-created,
    .caseDetailsContainer-Creation-Date {
      display: none !important;
    }
  }

  @media screen and (max-width: 1270px) {
    .caseTitle {
      width: 20vw !important;
      height: fit-content !important;
      &.userlist {
        width: 37vw !important;
        height: fit-content !important;
      }
      .caseTitleText {
        word-wrap: break-word;
        white-space: normal !important;
        white-space: normal;
      }
    }
    .hide {
      visibility: hidden;
    }
    .caseTitleContainer-wrap {
      width: 20vw !important;
      padding: 5px 0px;
      z-index: 2px;
      .caseTitleContainer {
        .renameCaseInput {
          width: 20vw !important;
        }
        .saveCaseButton {
          z-index: 2;
        }
      }
    }
    .myCasesList-titles-left {
      width: 20vw !important;
      &.userlist {
        width: 37vw !important;
      }
    }
  }
  @media screen and (max-width: 970px) {
    .myCasesBox-content-container {
      padding: 0 20px !important;
    }
  }
  @import './loadingCasesPlaceholder';
  position: relative;
  width: 100%;
  height: 100%;
  margin: 108px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1540px;

  &.for-settings {
    margin-top: 30px;
  }

  .myCasesBox-content-container {
    max-width: 1540px;

    min-width: 1230px;
    @media screen and (max-width: 1270px) {
      min-width: 330px;
    }
    margin: 0px auto 0px auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 50px;
    height: calc(100% - 100px);

    &.for-users {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      @media screen and (max-width: 1330px) {
        min-width: 330px;
      }
    }

    .myCasesBox-searchBar {
      margin: 0 23px 0 11px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .searchBarContainer {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 305px;
        .searchBarIcon {
          position: absolute;
          height: 19px;
          left: 8px;
          top: 15px;
        }
        .searchBarInput {
          width: 305px;
          border: 0;
          background-color: $hovercraftPurple;
          border-radius: $borderRadiusMedium;
          box-shadow: 0px 3px 6px #0000004a;
          height: 18px;
          font-size: 16px;
          padding-left: 43px;
          height: 50px;
          color: $ageanMidnight;
          &:focus {
            outline: 2px solid $lakersUniformHover;
          }
        }
      }
      .sharedWithContainer {
        height: 50px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: $industrialEggYolk;
        border-radius: $borderRadiusMedium;
        box-shadow: 0px 3px 6px #00000029;
        cursor: pointer;
        &:hover {
          background-color: $industrialEggYolkHover;
        }
        .sharedWithImgContainer {
          cursor: pointer;
          &.inMenu3 {
            @media screen and (max-width: 660px) {
              margin-right: 6px;
            }
          }
          margin-left: 6px;
          .shaerdWithImg {
            cursor: pointer;

            margin-top: 10px;
          }
        }
        .sharedWithTextContainer {
          &.inMenu3 {
            @media screen and (max-width: 660px) {
              display: none;
            }
          }
          cursor: pointer;

          margin-left: 6px;
          .sharedWithText {
            cursor: pointer;
            white-space: nowrap;

            font-weight: $boldWeight;
          }
        }
        .sharedWithThumbnailsContainer {
          cursor: pointer;
          &.inMenu3 {
            @media screen and (max-width: 660px) {
              display: none;
            }
          }
          margin-left: 23px;
          margin-right: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .caseDetailsContainer-small {
      width: $caseDetailsContainer-width-small;
    }
    .caseDetailsContainer-shareCase {
      .caseDetail {
        max-width: 160px;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }
    }
    .caseDetailsContainer-large {
      width: $caseDetailsContainer-width-large;
    }
    .caseDetailsContainer {
      word-break: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .myCasesList-titles {
      display: flex;
      justify-content: flex-start;
      margin-top: 29px;
      margin-left: 10px;
      max-width: 1409px;
      .myCasesList-titles-left {
        display: flex;
        width: $caseTitleWidth;
        font-size: 18px;
        h5 {
          width: $caseTitleWidth;
          position: relative;
          margin-left: 10px;
        }
      }
      .myCasesList-titles-left-userlist {
        display: flex;
        width: $caseTitleWidth;
        font-size: 18px;
        width: 309px;
        h5 {
          width: $caseTitleWidth;
          position: relative;
          margin-left: 10px;
        }
      }
      .myCasesList-titles-right {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        box-sizing: border-box;
        .caseDetailsContainer-title-owner {
          text-align: center;
          cursor: default !important;
          margin-left: 10px;
        }
        .caseDetailsContainer-title {
          text-align: center;
          position: relative;
        }
        .caseDetailsContainer-title-status {
          text-align: center;
        }
        .caseDetailsContainer-title-actions {
          text-align: center;
          margin-right: 20px;
        }
        .caseDetailsContainer-title-user-actions {
          text-align: center;
          margin-right: 20px;
        }
      }
    }

    .myCasesListContainer {
      @media screen and (max-height: 600px) {
        max-height: 160px;
      }

      margin-top: 7px;
      max-height: calc(90% - 200px);
      overflow-y: auto;
      overflow-x: hidden;

      &.for-users {
        flex-grow: 1;
      }

      .loadingScenarios {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-self: center;
        height: 150px;
        p {
          margin: auto;
          text-align: center;
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: $scrollBarWidth;
        border-radius: 10px;
        margin-right: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background-color: $scrollBarBackground;
        border-radius: 10px;
        box-shadow: -1px 3px 6px #00000029;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $lakersUniform;
        border-radius: 10px;
        border: $scrollBarHandleBorder;
      }

      .caseContainer {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: $borderRadiusMedium;
        background-color: white;
        max-width: 1406px;
        &:hover {
          background-color: $hovercraftPurple;
        }
        box-shadow: 0px 3px 6px #00000029;
        min-height: 56px;
        height: fit-content;
        box-sizing: border-box;
        margin: 15px 10px;
        &:first-of-type {
          margin-top: 0px;
        }
        .caseLeftPart {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .caseTitle,
        .caseTitleUserList {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: auto;
          min-height: 40px;
          margin-left: 10px;
          width: $caseTitleWidth;
          text-overflow: ellipsis;
          cursor: pointer;
          text-decoration: none;
          overflow: hidden;

          &.userlist {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          &.nonActive {
            cursor: default !important;
          }

          .caseTitleText {
            cursor: pointer;
            text-decoration: transparent;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.nonActive {
              cursor: default !important;
              text-decoration: none !important;
            }

            padding-right: 3px;
            &:hover {
              text-decoration: underline;
            }
          }
          .caseTitleEmail {
            cursor: pointer;
            font-size: 12px;
            color: $lakersUniformHover;
          }
        }
        .caseTitleEmail {
          cursor: pointer;
          font-size: 12px;
          color: $lakersUniformHover;
        }
        .caseTitleContainer-wrap {
          width: $caseTitleWidth;
          margin-right: 10px;
          .caseTitleContainer {
            padding-left: 10px;
            width: calc(#{$caseTitleWidth} + 50px);
            display: flex;
            flex-direction: row;
            align-items: center;
            input {
              font-size: 13px;
              width: 260px;
              height: 26px;
              border: 1px solid $ageanMidnight;
              border-radius: 5px;
              padding-left: 6px;
              padding-right: 5px;
              background-color: white;
              &:focus {
                outline: 2px solid $lakersUniformHover;
              }
            }
            .cancelRename {
              position: relative;
              align-self: center;
              transform: rotate(45deg);
              height: 9px;
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
        .caseDetailsContainer {
          text-align: center;

          .scenarioDetail {
            font-size: 13px;
            width: 75px;
            margin: auto;
          }
          .caseLabel {
            font-size: 10px;
            color: $ageanMidnight;
            margin: auto;
            margin-bottom: 2px;
            margin-top: 2px;
          }
          .caseDetail {
            font-size: 14px;
            margin-bottom: 2px;
            margin-top: 2px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .addUserImg {
              margin-top: 10px;
              cursor: pointer;
            }
          }
          .caseDetailTime {
            color: $lakersUniformHover;
          }
        }
        .caseMenuContainer {
          margin-right: 20px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          &.freemium {
            margin-right: 0px;
          }

          .caseMenuSubContainer {
            position: relative;
            display: flex;
            width: 102px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &.only-one {
              justify-content: center;
            }

            img {
              padding-left: 3px;
              height: 14px;
              cursor: pointer;
            }

            .caseMenuDropDown {
              position: absolute;
              right: 7%;
              border: thin solid;
              border-radius: 8px;
              margin-top: 7px;
              height: 72px;
              text-align: center;
              background-color: white;
              z-index: 20;
              width: 80px;
              text-align: right;
              p {
                z-index: 21;
                font-size: 12px;
                padding-right: 3px;
                margin: 7px auto;
                cursor: pointer;
              }
            }
            .deleteMessage {
              position: absolute;
              right: 49px;
              transform: translate(-100%, 0);
              z-index: 1;
            }
            .deleteMessage-background {
              height: 100vh;
              width: 100vw;
              position: fixed;
              left: 0;
              top: 0;
            }

            .copyMessage {
              position: 'absolute';
              top: '-10px';
              left: '50px';
              background-color: 'white';
              padding: '5px';
              box-shadow: '0px 3px 6px #00000029';
              border-radius: '5px';
              display: 'flex';
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.addNewCaseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin: 10px;

  &.onlyContainer {
    justify-content: end;
  }

  .input {
    margin-top: 7px;
  }

  .actionsContainer {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .descriptionTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    p {
      font-size: 14px;
      color: $enabledColor;
    }
  }

  .createCaseContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.newCaseInputContainer {
  margin-right: 30px;
  margin-top: -12px;
}

.addNewCaseContainerEmpty {
  @extend .addNewCaseContainer;
  position: relative;
  bottom: 0;
  align-self: start;
}

.cancelAddCase {
  position: relative;
  align-self: center;
  transform: rotate(45deg);
  height: 9px;
  margin-left: 5px;
  cursor: pointer;
}

.saveCaseButton {
  margin-left: 10px;
}

.sortImgContainer {
  position: absolute;
  top: -11px;
  padding: 4px 7px;
  text {
    font-family: 'poppins-local', sans-serif, verdana;
  }
}

//---------------------

@media screen and (max-width: 1390px) {
  .caseDetailsContainer-hide1 {
    display: none !important;
  }
}
@media screen and (max-width: 1190px) {
  .caseDetailsContainer-hide2 {
    display: none !important;
  }
}
