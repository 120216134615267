.liabilityContainer {
  position: relative;

  background-color: #ebf2fc;
  border-radius: 10px;
  padding: 15px 20px 24px 22px;
  margin-bottom: 60px;
  margin-top: 35px;
  width: 490px;
  margin-left: 78px;
  z-index: 2;

  .infoElementContainer {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .liabilityTitleContainer {
    .libailityTitle {
      font-size: 20px;
      font-weight: $boldWeight;
      margin-bottom: 10px;
    }
    .liabilityHelperText {
      width: 375px;
      font-size: 16px;
    }
  }

  .requirementsContainer {
    margin-top: 30px;
  }
  .addLiabilityContainer {
    margin-top: 35px;
  }

  .requirement {
    @extend .flexRow;
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 35px;
    .inputNum {
      margin-left: 28px;
      .input-label-text-p {
        letter-spacing: -0.04em;
      }
    }
    .liabilityMultiLine {
      margin-bottom: -38px;
    }
    .deleteContainer {
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      img {
        height: 20.5px;
        width: 17.3px;
      }
    }
  }

  .requirementLabels {
    label {
      margin-right: 165px;
    }
  }
  .finalLiabilityTextContainer {
    margin-top: 30px;
  }
  .liabilityArrow {
    position: absolute;
    left: 47px;
    bottom: -28px;
    background-color: #ebf2fc;
    height: 65px;
    width: 65px;
    transform: rotate(45deg);
    z-index: -1;
  }
}
