.undoRedoContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 46px;
  .undoContainer .redoContainer {
    img {
      height: 18px;
    }

    .undoGray {
      height: 200px;
      g {
        path,
        line {
          color: 'gray';
        }
      }
    }
  }
}
