.listPreviewMainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px 5px 5px 5px;

  &.expanded {
    box-shadow: 0px 3px 6px #00000029;
  }

  &.hovered {
    border-radius: 5px 5px 0px 5px;
  }

  .errorsList {
    @extend .sharable-error;
  }

  .listTreeAddedContainer {
    width: calc(43% - 7px);
    display: flex;
    align-items: center;
  }

  .listPreviewNumber {
    font-size: 13px;
    color: #bfbfbf;
    width: 5px;
    margin-left: -10px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .listPreviewNameContainer {
    display: flex;
    width: 43%;
    height: 29px;
    background-color: #d0d3e8;
    padding: 3px 9px 3px 9px;
    border-radius: 5px 0px 0px 5px;

    &.tree {
      border-radius: 5px 5px 5px 5px;
      width: 100%;
    }

    &.expanded {
      height: 75px;
      align-items: center;
      width: 25%;
    }

    &.treeExpanded {
      height: 37px;
      align-items: center;
    }

    &.consequence-on {
      height: 95px;
    }

    .listPreviewName {
      font-size: 14px;
      color: white;
      max-lines: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;

      &.expanded {
        max-lines: 2;
        white-space: normal;
      }

      .titleImg {
        width: 17px;
        margin-right: 5px;
      }
    }

    &.selected {
      background-color: #321b59;
      &.krogerus {
        background-color: $krogerusClientColor;
      }
    }
  }

  .listTreeContectLine {
    width: 14px;
    height: 2px;
    background-color: #201a2d;
  }

  .listPreviewInfoContainer {
    justify-self: flex-end;
    display: flex;
    width: 57%;
    height: 27px;
    border-radius: 0px 5px 5px 0px;
    flex-direction: column;

    &.tree {
      border-radius: 5px 5px 5px 5px;
      width: calc(57% - 7px);
    }

    &.expanded {
      height: 75px;
      width: 75%;
    }

    &.treeExpanded {
      height: 37px;
    }

    &.consequence-on {
      height: 95px;
    }

    &.isHovered {
      border-radius: 0px 5px 0px 0px;
    }

    .listPreviewInfoRow {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      flex: 1;
      justify-content: space-around;
      padding-left: 40px;
      background-color: white;
      border-radius: 0px 5px 5px 0px;

      &.isHovered {
        border-radius: 0px 5px 0px 0px;
      }

      .calculateButton {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: center;
      }

      &.tree {
        padding-left: 33px;
        border-radius: 5px 5px 5px 5px;
      }

      &.noTitle {
        background-color: #f7f7f7;
      }

      &.small {
        flex: 0.8;
      }

      .listPreviewSingleInfo {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10px;
        max-width: 160px;
        min-width: 100px;

        &.center {
          justify-content: center;
        }

        &.start {
          justify-content: flex-start;
        }

        .listPreviewInfoValue {
          font-size: 16px;
          color: #201a2d;
          padding-top: 4px;
          cursor: pointer;

          &.client {
            color: $clientColor;
            &.krogerus {
              color: $krogerusClientColor;
            }
          }

          &.opposing {
            color: $opposingPartyColor !important;
            &.krogerus {
              color: $krogerusOpposingPartyColor !important;
            }
          }

          &.noValue {
            color: #201a2d;
          }

          &.date {
            color: #a1a6c7;
            margin-left: 10px;
            font-size: 15px;
          }

          &.small {
            font-size: 14px;
            color: #8e8e8e;
            padding-top: 0px;
            margin-top: -5px;
          }

          &.consequence {
            margin-top: -12px;
          }
        }

        .listPreviewInfoValueNoFitty {
          font-size: 16px;
          color: #201a2d;
          padding-top: 4px;
          cursor: pointer;

          &.opposing {
            color: #ac2828 !important;
            margin-left: 0px !important;
          }

          &.date {
            color: #a1a6c7;
            margin-left: 10px;
            font-size: 15px;
          }
        }
      }

      .greyArea {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #f7f7f7;
        opacity: 45%;
        width: 100%;
        height: 100%;
      }
    }
  }

  .claimPositionHighlightList {
    position: absolute;
    left: -10px;
    &.top {
      top: -10px;
    }
    &.bottom {
      bottom: -10px;
    }
    cursor: pointer;
    background-color: $lakersUniformHover;
    z-index: -2;
    width: calc(100% + 20px);
    height: 29px;
    border-radius: 5px;
    opacity: 0.7;

    &.expanded {
      height: 75px;
    }
  }

  .threeDotsMenuContainerList {
    z-index: 3;
    width: 182px;
    height: 123px;
    /* UI Properties */
    background-color: white;
    border: 1px solid $lakersUniform;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 5px 8px 10px 10px;
    cursor: default;

    .copyingClaimMessageContainerList {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .threeDotsMenuRowList {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      width: 100%;
      margin-bottom: 3px;
      &:hover {
        font-weight: 600;
      }
      .threeDotsMenuRowTextList {
        margin-left: 5px;
        &.red {
          color: $greekEaster;
        }
        cursor: pointer;
        &:hover {
          font-weight: 600;
        }
      }
      .threeDotsMenuRowImgList {
        width: 13px;
        cursor: pointer;
      }
    }
  }

  .moveTileHandleList {
    position: absolute;
    top: -3px;
    left: -34px;
    cursor: pointer;
    background-color: white;
    z-index: 0;
    width: 34px;
    height: 35px;
    box-shadow: -3px 3px 3px #00000012;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .moveTileTransparentCoverList {
    position: absolute;
    top: -10px;
    left: -34px;
    cursor: pointer;
    z-index: 0;
    width: 34px;
    height: 35px;
    border-radius: 5px 5px 5px 5px;
  }

  .moveTileHandleBackgroundList {
    position: absolute;
    top: -3px;
    left: -34px;
    cursor: pointer;
    background-color: white;
    z-index: -1;
    width: 37px;
    height: 35px;
    border-radius: 5px 5px 5px 5px;
  }

  .moveTileHandleinvisibleLayerList {
    position: absolute;
    top: -10px;
    left: -42px;
    cursor: default;
    z-index: 0;

    width: 45px;
    height: 120px;
  }

  .threeDotsButtonContainerList {
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid $lakersUniform;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .expandImg {
    top: 27px;
    right: 0;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 3px 2px #00000029;
    border-radius: 0 0 4px 4px;
    cursor: pointer;

    &.expanded {
      top: 73px;
      background-color: #f7f7f7;
    }

    &.consequence {
      top: 93px;
    }

    .expandImgText {
      cursor: pointer;
      margin-left: 5px;
      color: $lakersUniform;
      font-size: 13px;
    }

    img {
      transform: rotate(180deg);

      &.collapse {
        transform: rotate(0deg);
      }
    }
  }
}
