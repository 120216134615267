.singleSegmentContainer {
  min-height: 45px;
  margin-top: 1px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
}
.singleSegmentContainer .possibleOutcomeRangeContainer {
  height: 45px;
  width: 308px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .outcomeContainer {
  max-width: 140px;
  min-width: 140px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .outcomeContainer .outcomeRangeAmount {
  font-weight: 400;
  color: #5a45c9;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .outcomeContainer .outcomeRangeAmount.krogerus {
  color: #000000;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .outcomeContainer .red {
  color: #ff703e;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .outcomeContainer .red.krogerus {
  color: #ffcb2b;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .outcomeContainer .outcomeRangePercentage {
  font-size: 12px;
  color: #8e8e8e;
  margin-top: -3px;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .maxmin {
  width: 100%;
  max-width: 308px !important;
  min-width: 308px !important;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .maxmin p {
  text-align: center;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .maxmin .outcomeRangeAmount {
  font-weight: 700;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .dashContainer {
  width: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.singleSegmentContainer .possibleOutcomeRangeContainer .dashContainer .dash {
  font-size: 20px;
  color: #8e8e8e;
}
.singleSegmentContainer .legalFeesRangeContainer {
  padding: 7px 27px 7px 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 642px;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer {
  width: 233px;
  text-align: center;
  line-height: 20px;
  padding-top: 5px;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer.forNewLCD {
  width: 100%;
  max-width: 642px;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText {
  width: 233px;
  font-size: 15px;
}
@media screen and (max-width: 900px) {
  .singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText {
    width: 20vw;
  }
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText.forNewLCD {
  width: 100%;
  max-width: 642px;
}
@media screen and (max-width: 900px) {
  .singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText.forNewLCD {
    width: 40vw;
  }
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeTextContainer .bold {
  font-weight: 600;
}
.singleSegmentContainer .legalFeesRangeContainer .pdfPreviewlegalFeesRangeTextContainer {
  width: 560px;
}
.singleSegmentContainer .legalFeesRangeContainer .pdfPreviewlegalFeesRangeTextContainer .pdfPreviewLegalFeesRangeText {
  width: 560px;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeSliderContainer {
  position: relative;
  width: 100%;
  margin-left: 26px;
  margin-top: 4px;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeSliderContainer .rangeBackground {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeSliderContainer .legalFeesRangeSlider {
  margin-top: 3px;
  -webkit-appearance: none;
  background-color: transparent;
  top: 0px;
  left: 0px;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 20px;
  z-index: 2;
}
.singleSegmentContainer .legalFeesRangeContainer .legalFeesRangeSliderContainer .legalFeesRangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: white;
  opacity: 1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #2e394b;
  border-radius: 5px;
  width: 18px;
  height: 39px;
}

.pdfPreviewSingleSegment {
  box-shadow: none;
  border-radius: 0;
  width: 900px;
}
.pdfPreviewSingleSegment.forNewLCD {
  width: 100%;
}

.lFDAppContainer {
  max-width: 1270px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  margin-left: 430px;
}
@media screen and (max-width: 1500px) {
  .lFDAppContainer {
    margin-left: 30%;
  }
}
@media screen and (max-width: 1160px) {
  .lFDAppContainer {
    margin-left: 270px;
  }
}
.lFDAppContainer .lFDAppNew {
  max-width: 600px;
  width: 100%;
  padding-right: 20px;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 90px;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer {
  float: right;
  margin-right: -6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer .imgContainer {
  border-radius: 5px 5px 0 0;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer .imgContainer:hover {
  background-color: #edeaf5;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer .remove {
  border-radius: 0 0 5px 5px;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer .addRemoveSegmentImg {
  height: 21px;
  width: 21px;
  cursor: pointer;
  padding: 2px;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer .inactive {
  cursor: default;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveButtonsContainer .inactive:hover {
  background-color: transparent;
}
.lFDAppContainer .lFDAppNew .lFDAppHeader .addRemoveSegmentContainer .addRemoveSegmentText {
  font-size: 20px;
  font-weight: 600;
  margin: 0 6px;
  width: 40px;
  text-align: center;
}
.lFDAppContainer .lFDAppNew .lFDAppTitle {
  line-height: 1.25;
  width: 245px;
  color: #201a2d;
  font-size: 18px;
}
.lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleLeft {
  position: absolute;
  left: -235px;
  text-align: right;
  width: 210px;
}
@media screen and (max-width: 1050px) {
  .lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleLeft {
    left: -270px;
  }
}
@media screen and (max-width: 960px) {
  .lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleLeft {
    left: -215px;
    font-size: 15px;
    width: 125px;
  }
}
@media screen and (max-width: 885px) {
  .lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleLeft {
    left: -260px;
    font-size: 15px;
    width: 125px;
  }
}
.lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleRight {
  position: absolute;
  width: 233px;
  left: 52px;
  text-align: left;
}
@media screen and (max-width: 1050px) {
  .lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleRight {
    left: 22px;
  }
}
@media screen and (max-width: 960px) {
  .lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleRight {
    left: -32px;
  }
}
@media screen and (max-width: 885px) {
  .lFDAppContainer .lFDAppNew .lFDAppTitle#lFDAppTitleRight {
    left: -98px;
    font-size: 15px;
  }
}
.lFDAppContainer .lFDAppNew .distributionButtonsContainer {
  display: flex;
  flex-direction: row;
  margin-left: 410px;
}
@media screen and (max-width: 1050px) {
  .lFDAppContainer .lFDAppNew .distributionButtonsContainer {
    margin-left: 380px;
  }
}
@media screen and (max-width: 960px) {
  .lFDAppContainer .lFDAppNew .distributionButtonsContainer {
    margin-left: 330px;
  }
}
@media screen and (max-width: 885px) {
  .lFDAppContainer .lFDAppNew .distributionButtonsContainer {
    margin-left: 255px;
  }
}
.lFDAppContainer .lFDAppNew .distributionButtonsContainer .imgContainer {
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background-color: #ffffff;
  width: 62px;
  height: 42px;
}
.lFDAppContainer .lFDAppNew .distributionButtonsContainer .imgContainer.first {
  margin-right: 25px;
}
.lFDAppContainer .lFDAppNew .distributionButtonsContainer .imgContainer.column {
  width: 44px;
  height: 84px;
  position: absolute;
  top: 231px;
  left: -275px;
}
@media screen and (max-width: 1160px) {
  .lFDAppContainer .lFDAppNew .distributionButtonsContainer .imgContainer.column {
    left: -255px;
  }
}
@media screen and (max-width: 960px) {
  .lFDAppContainer .lFDAppNew .distributionButtonsContainer .imgContainer.column {
    display: none;
  }
}
.lFDAppContainer .lFDAppNew .distributionButtonsContainer .linearImg,
.lFDAppContainer .lFDAppNew .distributionButtonsContainer .resetImg {
  cursor: pointer;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew {
  position: relative;
  padding-bottom: 10px;
  z-index: 1;
}
@media screen and (max-width: 1050px) {
  .lFDAppContainer .lFDAppNew .lFDAppBodyNew {
    margin-left: -30px;
  }
}
@media screen and (max-width: 960px) {
  .lFDAppContainer .lFDAppNew .lFDAppBodyNew {
    margin-left: -80px;
  }
}
@media screen and (max-width: 885px) {
  .lFDAppContainer .lFDAppNew .lFDAppBodyNew {
    margin-left: -150px;
  }
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .transparentLayer {
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  cursor: default;
  width: 100%;
  z-index: 2;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea {
  position: absolute;
  min-width: 60px;
  min-height: 60px;
  width: -moz-fit-content;
  width: fit-content;
  left: -37px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea.top {
  margin-top: -62px;
  left: -37px;
  top: -2px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea.top .limitSegmentButtonContainer {
  align-items: flex-end;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea .limitSegmentButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 5px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea .limitSegmentButtonContainer .limitSegmentButtonTextContainer {
  display: inline-block;
  min-width: 450px;
  height: 60px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  padding: 11px 11px 11px 11px;
  cursor: pointer;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea .limitSegmentButtonContainer .limitSegmentButtonTextContainer:hover {
  background-color: #e1d6f4;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .limitSegmentHoverArea .limitSegmentButtonContainer .limitSegmentButtonTextContainer .limitSegmentButtonText {
  cursor: pointer;
  line-height: 1.3;
  text-align: center;
  height: 40px;
  max-width: 100%; /* Allow the text to wrap within the div */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Display a maximum of 2 lines */
  -webkit-box-orient: vertical;
  word-break: break-word; /* Allow breaking words if necessary */
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .deleteLimitSegmentImgContainer {
  position: relative;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .deleteLimitSegmentImgContainer:hover {
  background-color: #e1d6f4;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentContainer .deleteLimitSegmentImgContainer .deleteSegmentImg {
  cursor: pointer;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentArrowImgContainer {
  position: absolute;
  top: -38px;
  left: -37px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentArrowImgContainer.bottom {
  top: 0px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentArrowImg {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-top: -1px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .limitSegmentArrowImg.bottom {
  margin-top: 1px;
  transform: rotate(180deg);
  transform: scaleY(-1);
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew {
  position: relative;
  height: 100%;
  z-index: 10;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 642px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew.limit {
  position: absolute;
  top: -63px;
  left: 0px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew.limit.bottom {
  top: 9px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer {
  padding: 16px 27px 16px 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 540px;
  height: 100%;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeTextContainer {
  width: 50px;
  text-align: left;
  line-height: 20px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText {
  width: 270px;
  font-size: 16px;
  font-family: "poppins-local";
  text-align: center;
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Display a maximum of 2 lines */
  -webkit-box-orient: vertical;
  word-break: break-word; /* Allow breaking words if necessary */
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText.oneLine {
  -webkit-line-clamp: 1; /* Display a maximum of 2 lines */
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeTextContainer .legalFeesRangeText.bold {
  font-weight: 600;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .pdfPreviewlegalFeesRangeTextContainer {
  width: 433px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .pdfPreviewlegalFeesRangeTextContainer .pdfPreviewLegalFeesRangeText {
  width: 433px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeSliderContainer {
  position: relative;
  width: 100%;
  margin-left: 230px;
  margin-top: -1px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeSliderContainer .rangeBackground {
  width: 100%;
  height: 10.5px;
  z-index: 0;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeSliderContainer .legalFeesRangeSlider {
  margin-top: 3px;
  -webkit-appearance: none;
  background-color: transparent;
  top: 0px;
  left: 0px;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 19px;
  z-index: 2;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .singleSegmentContainerNew .legalFeesRangeContainer .legalFeesRangeSliderContainer .legalFeesRangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: white;
  opacity: 1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #2e394b;
  border-radius: 24px;
  width: 24px;
  height: 24px;
}
.lFDAppContainer .lFDAppNew .lFDAppBodyNew .pdfPreviewSingleSegment {
  box-shadow: none;
  border-radius: 0;
  width: 800px;
}
.lFDAppContainer .lFDAppNew .awardedAmountLCDIndicator {
  width: 9px;
  height: 9px;
  border-radius: 10px;
  position: absolute;
  border: 1.5px solid black;
  left: 9.4px;
  background-color: white;
}
.lFDAppContainer .lFDAppNew .rangeBarContainerBackground {
  position: absolute;
  height: 500px;
  width: 24px;
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.lFDAppContainer .lFDAppNew .rangeBarContainerBackground img {
  width: 25px;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer {
  position: relative;
  height: 500px;
  width: 25px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment {
  position: absolute;
  width: 40px;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .segmentTriangleImg {
  height: 100%;
  width: 50px;
  position: absolute;
  right: -13.5px;
  z-index: -1;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .addSegmentContainer {
  position: absolute;
  left: -80px;
  width: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .addSegmentContainer .addSegmentImg {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer {
  width: 200px;
  height: 32px;
  position: absolute;
  left: -220px;
  top: -18px;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer.top {
  top: -30px;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer.bottom {
  top: -10px;
}
@media screen and (max-width: 1050px) {
  .lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer {
    width: 140px;
    left: -160px;
  }
}
@media screen and (max-width: 885px) {
  .lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer {
    width: 110px;
    left: -130px;
  }
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer {
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  float: right;
  padding: 5px;
  padding-left: 25px;
  border-radius: 5px;
  position: relative;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .deleteSegmentImgContainer {
  position: absolute;
  left: -22px;
  top: -5px;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .deleteSegmentImgContainer:hover {
  background-color: #e1d6f4;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .deleteSegmentImgContainer .deleteSegmentImg {
  cursor: pointer;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText {
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  cursor: default;
}
@media screen and (max-width: 1050px) {
  .lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText {
    font-size: 13px;
  }
}
@media screen and (max-width: 885px) {
  .lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText {
    font-size: 9px;
  }
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText.hoverable {
  cursor: text;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText.positive {
  color: #5a45c9;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText.positive.krogerus {
  color: #000000;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText.negative {
  color: #ff703e;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountText.negative.krogerus {
  color: #ffcb2b;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountTextInput {
  margin-right: -13px;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangesBarSegment .rangeBarAmountContainer .rangeBarAmountTextContainer .rangeBarAmountTextInput .MuiInputBase-input {
  text-align: right;
}
.lFDAppContainer .lFDAppNew .rangesBarContainer .rangeBarHandle {
  cursor: pointer;
  position: absolute;
  height: 18px;
  width: 39px;
  background-color: white;
  border: 1px solid #2e394b;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  top: -7px;
  left: -5px;
  z-index: 1;
}/*# sourceMappingURL=NewLegalFeesDistributionApp.css.map */