.legalFees {
  .errorMsg {
    position: absolute;
    width: 300px;
    left: 35px;
    top: 33px;

    color: #eb3030;
  }
  .errorMsg3 {
    position: absolute;
    width: 320px !important;
    left: 317px;
    top: -11px !important;

    color: #eb3030;
  }

  .legalCostsContainer {
    display: flex;
    flex-direction: row;
    button {
      align-self: flex-end;
      margin-left: 35px;
      margin-bottom: -3px;
    }
    .legalFeesCalculatorButton {
      visibility: hidden;
    }
  }

  .insuranceContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding-bottom: 10px;
    max-width: 67%;
    margin-left: 40px;
    .dropdown,
    .inputNum,
    .inputdate,
    .inputNum-RO {
      margin-left: 15px !important;
    }

    .insuranceInputsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: 10px;
    }
  }

  .deleteContainer {
    align-self: center;
    margin-left: 10px;
    margin-top: 28px;
    position: relative;
    cursor: pointer;
    img {
      height: 17px;
    }
  }
}

.addInsuranceContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  cursor: pointer;
  margin: 20px 0;

  .addSignContainer {
    position: relative;
    width: 14px;
    height: 14px;
    margin: 30px 7px 30px 15px;
    img {
      position: absolute;
      width: 16px;
      font-size: 12px;
      margin: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p {
    cursor: pointer;

    font-size: 12px;
    font-weight: $boldWeight;
  }
}

.excludeLegalFeesContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  img {
    margin-left: 55px;
    margin-right: 5px;
    width: 32px;
    cursor: pointer;
  }
  p {
    cursor: pointer;

    font-size: 16px;
    font-weight: 400;
  }
}

#legalFeesComp {
  margin-bottom: 200px;
}

.partyLegalCostsComponentContainer {
  width: 1039px;
  margin-top: 34px;
  border: solid 1px $lightPurple;
  border-radius: 16px;
  margin-left: 69px;
  padding-bottom: 100px;
  @media screen and (max-width: 1320px) {
    width: 627px;
  }
  &.noSecondTrial {
    padding-bottom: 70px;
  }
  .partyLegalCostsTitle {
    margin-top: 14px;
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .legalCostsInputOuterContainer {
    margin-left: 30px;
    margin-top: 8px;

    &.additional {
      margin-top: 90px;
    }

    .proceedingsTitle {
      color: $popCulture;
    }
    .legalCostsInputContainer {
      margin-top: -20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      .estimatedCostsInputContainer,
      .recoverableCostsInputContainer,
      .incurredCostsInputContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 20px;
      }
      .recoverableCostsInputOuterContainer,
      .incurredCostsInputOuterContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 20px;
        .legalCostsSwitchButtonContainer {
          min-width: 86px;
          height: 30px;
          box-shadow: 0px 2px 3px #0000002b;
          border: 1px solid #dcdcdc;
          border-radius: 25px;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          margin-right: 20px !important;
          margin-bottom: 7px;
          padding: 2px;
          cursor: pointer;
          .legalCostsSwitchButton {
            cursor: pointer;
            background-color: transparent;
            border: solid 1px transparent;
            border-radius: 25px;
            padding: 1px 6px 1px 6px;
            min-width: 36px;
            max-height: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &.selected {
              background-color: $hovercraftPurple;
              border: solid 1px $lakersUniform;
            }
            .legalCostsSwitchButtonText {
              color: $lakersUniform;
              cursor: pointer;
              font-size: 18px;
              &.currency {
                font-size: 14px;
              }
            }
          }
        }
      }
      @media screen and (max-width: 1320px) {
        margin-top: 20px;
        margin-left: 20px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .recoverableCostsInputOuterContainer {
          margin-top: 30px;
          margin-left: 0px;
        }
        .incurredCostsInputOuterContainer {
          margin-top: 80px;
          margin-left: 0px;
        }
        .legalCostsSwitchButtonContainer {
          margin-right: 20px !important;
        }
      }
    }
  }
}
