.executiveSummaryParagraphContainer {
  padding-right: 10px;
  position: relative;
  margin-top: 38px;
  &.forReport {
    padding-left: 80px;
  }
  &:first-of-type {
    margin-top: 52px;
    margin-bottom: -18px;
  }
  &:last-of-type {
    position: relative;

    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: row;
  .executiveSummarySideTitleContainer {
    margin-left: 43px;
    width: 190px;
    max-height: 60px;
    .executiveSummarySideTitle {
      line-height: 1.25;
      max-width: 155px;
      font-family: 'poppins-local-bold';
    }
  }
  .valueOfCaseParagraph {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-direction: flex-start;
    position: relative;
    .valueOfCaseImg {
      position: absolute;

      left: -32px;
    }
    #valueOfCase-2-image {
      top: 3px;
    }
  }
  .executiveSummaryParagraphText {
    max-width: 900px;
    @media screen and (max-width: 1320px) {
      max-width: 700px;
    }
    @media screen and (max-width: 1090px) {
      max-width: 600px;
    }
    @media screen and (max-width: 980px) {
      max-width: 500px;
    }
    @media screen and (max-width: 870px) {
      max-width: 450px;
    }
    &.forReport {
      max-width: 800px;
    }
    cursor: text;
    span {
      font-family: 'poppins-local-bold';
      &.noBold {
        font-family: 'poppins-local';
      }
    }
    .link {
      font-family: 'poppins-local-medium';
      color: $popCulture;
      cursor: pointer;
      text-decoration: underline;
      font-weight: 300;
    }
    .infoCircle {
      cursor: pointer;
      margin-top: -2px;
      height: 14px;
    }

    .legalCostsContainer {
      display: flex;
      flex-direction: row;
      width: 700px;
      @media screen and (max-width: 1320px) {
        width: 700px;
      }
      @media screen and (max-width: 1090px) {
        width: 600px;
      }
      @media screen and (max-width: 980px) {
        width: 500px;
      }
      @media screen and (max-width: 870px) {
        width: 450px;
      }
      &.forReport {
        width: 700px;
      }
      align-items: flex-start;
      justify-content: space-between;

      .legalCostsMainColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 32%;

        &.for-titles {
          align-items: flex-start !important;
          max-width: 36%;
        }

        .smallColumn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &.divider {
            margin-bottom: 29px;
          }

          &.maxWidth {
            width: 100%;
          }
        }

        .legalCostsText {
          width: 100%;
          color: #201a2d;
          font-size: 16px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: text;
          user-select: auto !important;

          @media screen and (max-width: 1000px) {
            font-size: 14px;
          }

          @media screen and (max-width: 870px) {
            font-size: 12px;
          }
          &.forReport {
            font-size: 16px;
          }

          &.bold {
            font-weight: bold;
            margin-bottom: 3px;
            text-align: end;

            &.divider {
              margin-top: 29px;
            }
          }
          &.semi-bold {
            font-family: 'poppins-local-medium';
            margin-bottom: 3px;
            text-align: start;
          }
          &.party {
            margin-bottom: 17px;
          }
          &.invisible {
            visibility: hidden;
          }
          &.description {
            margin-left: 17px;
            text-align: start;
          }
          &.value {
            text-align: end;
            overflow: visible;
          }
        }
      }
    }
  }

  #calculateZopa {
    margin-top: 10px;
  }

  .zopaParagraph {
    cursor: text;
    user-select: auto !important;
  }

  .zopaComponent {
    width: 980px;
    margin-left: -160px;
    margin-top: 15px;
  }
}

.zopaGraphOuterContainer {
  height: 283px;
  width: 980px;
  padding-left: 140px;
  padding-top: 1px;
  position: relative;
  .zopaInnerContainer {
    height: 120px;
    width: 100px;
    .zopaGraphAppContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 50px;
      .zopaGraphContainer {
        height: 130px;
        margin-left: 0px;
        .zopaGraph {
          display: flex;
          flex-direction: column;
          width: 600px;
          height: 111px;
          margin-left: 92px;
          .zopaBar {
            height: 37px;

            .zopaTick {
              position: absolute;
              width: 1px;
              height: 32px;
              background-color: $ageanMidnight;
              &.top {
                top: -13.5px;
              }
              &.bottom {
                top: 95px;
              }
            }
            .zopaTickText {
              position: absolute;
              width: 230px;
              line-height: 1.1;
              font-size: 12px;
              &.left {
                text-align: right;
              }
              &.top {
                top: -28px;
              }
              &.bottom {
                top: 110px;
              }
            }
            .zopaTickTop {
              position: absolute;
              width: 7.5px;
              height: 1px;
              background-color: $ageanMidnight;

              &.top {
                top: -13.5px;
              }

              &.bottom {
                top: 126px;
              }
            }
            .zopaTickNumber {
              position: absolute;
              text-align: right;
              width: 200px;
              top: 8px;
              font-size: 12px;
              &.right {
                text-align: left;
              }
              &.zopa {
                font-size: 16px;
                top: 43px;
                font-weight: bold;
              }
              &.client {
                font-size: 12px;
                top: 84px;
              }
            }
            .nozopaText {
              width: 315px;
              position: absolute;
              top: 45px;
              left: 240px;
            }
          }
          #zopaGraphOpposingParty {
            background-color: $opposingPartyColor;
            &.krogerus {
              background-color: $krogerusOpposingPartyColor;
            }
          }
          #zopaGraphZOPA {
            background-color: #000000;
            &.krogerus {
              background-color: $tuesdayGray;
            }
          }
          #zopaGraphClient {
            background-color: $clientColor;
            &.krogerus {
              background-color: $krogerusClientColor;
            }
          }
        }

        .currencyText {
          position: absolute;
          left: 690px;
          top: 110px;
          font-size: 12px;
        }
      }
    }
  }
  .zopaLegendContainer {
    margin-top: 40px;
    .zopaLegendRow {
      display: flex;
      flex-direction: row;
      margin-top: 3px;
      .zopaLegendColor {
        margin-left: 30px;
        margin-right: 9px;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: $ageanMidnight;
        &.opposingParty {
          background-color: $opposingPartyColor;
          &.krogerus {
            background-color: $krogerusOpposingPartyColor;
          }
        }
        &.client {
          background-color: $clientColor;
          &.krogerus {
            background-color: $krogerusClientColor;
          }
        }
        &.black {
          background-color: #000000;
          &.krogerus {
            background-color: $tuesdayGray;
          }
        }
      }
      .zopaLegendText {
        font-size: 12px;
        margin-top: 4px;
        @media screen and (max-width: 1080px) {
          width: 400px;
        }
      }
    }
  }
  .hiddenSectionLayerContainer {
    background-color: white;
    position: absolute;
    width: 105%;
    height: 120%;
    top: -40px;
    left: 0px;
    opacity: 0.8;
  }
}
