@keyframes closeRecoveryAnimationContainer {
  to {
    margin-top: calc(100vh - 64px);
    width: 600px;
    height: 41px;
  }
}

.recoveryBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: $transparentBackgroundGray;

  .recoveryPopUp {
    background-color: white;
    box-shadow: 0px 0px 30px #0000002c;
    height: 225px;
    width: 630px;
    border-radius: 10px;
    padding-right: 28px;
    padding-left: 28px;
    padding-top: 21px;
    position: relative;
    margin-top: calc((100vh - 225px) / 5);

    &.close {
      animation-name: closeRecoveryAnimationContainer;
      animation-duration: 300ms;
      animation-fill-mode: forwards;
      overflow: hidden;
    }

    .title {
      color: #201a2c;
      font-size: 22px;
      font-weight: bold;
    }

    .subTitle {
      color: #321b59;
      font-size: 16px;
    }

    .buttonsContainer {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
