.failedCaseMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: $borderRadiusLarge;
  position: relative;
  margin-top: 55px;
  width: 700px;

  .failedCaseMessageTitle {
    position: relative;
    font-size: 26px;
    margin-top: 15px;
    text-align: center;
  }
  .failedCaseMessageTextContainer {
    position: relative;
    width: 600px;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 25px;
    p {
      -webkit-touch-callout: text; /* iOS Safari */
      -webkit-user-select: text; /* Safari */
      -khtml-user-select: text; /* Konqueror HTML */
      -moz-user-select: text; /* Old versions of Firefox */
      -ms-user-select: text; /* Internet Explorer/Edge */
      user-select: text;
      cursor: text;
    }
    .failedCaseMessageText {
      font-size: 16px;
      line-height: 1.1;

      .link {
        color: $popCulture;
        cursor: pointer;
      }
    }
  }
}

.cancellingMessageContainer {
  padding-top: 220px;
}
