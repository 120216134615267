$ageanMidnight: #201a2d;
$saintNicolasBlue: #383c59;
$lakersUniform: #331a5b;
$lakersUniformHover: #462777;
$popCulture: #ff7336;
$popCultureHover: #f86221;
$hovercraftPurple: #edeaf5;
$industrialEggYolk: #ffdc94;
$industrialEggYolkHover: #ffd68b;
$ukNavy: #a1a6c7;
$lightPurple: #a699f2;
$beePassion: #faf8ff;
$springLevanter: #e1d6f4;
$swedenSundayMorning: #f7f7f7;
$newBornPeach: #fff0ea;
$mexicoSunrise: #ffc6af;
$infantryWest: #47743d;
$fujiApple: #c7ffba;
$greekEaster: #ac2828;
$greekEasterHover: #c42f37;
$laCandyfloss: #ffdddd;
$laCandyflossHover: #ffc6c6;
$quantum2: #76b9e3;
$quantum3: #d0a7f8;
$quantum4: #f6a072;
$quantum5: #ef7066;
$fiatDusk: #d0ddf0;
$mariaViolet: #d0d3e8;
$zopaBackground: #d5d5d5;
$lightYellow: #fff9eb;
$shadowColor: #cbcbcb;

$mondayGray: #8e8e8e;
$grayForReport: #8b8b8b;
$reportBlack: #414141;
$sundayGray: #d9d9d9;
$tuesdayGray: #6f6f6f;
$thursdayGray: #e9e9e9;
$backGroundGray: #f6f6f6;
$transparentBackgroundGray: rgba(
  $color: grey,
  $alpha: 0.8,
);
$clientColor: #5a45c9;
$opposingPartyColor: #ff703e;
$krogerusClientColor: #000000;
$krogerusOpposingPartyColor: #ffcb2b;
$krogerusActiveColor: #e1d6f4;
$krogerusTreeBackground: #ffeaaa;

$tableBackgroundHeight: 430px;
$tableBackgroundWidth: 700px;

$borderRadiusSmall: 4px;
$borderRadiusMedium: 5px;
$borderRadiusLarge: 10px;

$toolComponentMaxWidth: 1284px;
$toolComponentMinWidth: 725px;

$boldWeight: 600;

$scrollBarBackground: $hovercraftPurple;
$scrollBarHandleColor: $lakersUniform;
$scrollBarHandleBorder: $hovercraftPurple 2px solid;
$scrollBarWidth: 10px;

$tutorialsMainContainerTopMargin: 30px;
$reportPaddingLeft: 114px;
