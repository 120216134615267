.legalCostsPreviewContainer {
  padding-left: $reportPaddingLeft;

  display: flex;
  flex-direction: row;
  width: 970px;
  align-items: flex-start;
  justify-content: space-between;

  .legalCostsPreviewMainColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 34%;
    margin-right: 8px;

    &.for-titles {
      align-items: flex-start !important;
    }

    .legalCostsPreviewSmallColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.divider {
        margin-bottom: 29px;
      }

      &.maxWidth {
        width: 100%;
      }

      .legalCostsPreviewEditButtonContainer {
        position: absolute;
        left: 50px;
        margin-top: -50px;
        width: 63px;
        height: 23.5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        cursor: pointer;

        img {
          cursor: pointer;
          margin-right: 8px;
          width: 16px;
        }

        p {
          cursor: pointer;
          margin-top: 1px;
          font-size: 15px;
        }
      }

      .proceedingsTextInput {
        width: 190px;
        height: 24px;
        font-size: 16px;
        margin-bottom: 3px;
      }
    }

    .legalCostsPreviewText {
      width: 100%;
      color: #201a2d;
      font-size: 16px;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: text;
      user-select: auto !important;

      &.noEllipsis {
        overflow: initial;
        text-overflow: initial;
      }

      &.bold {
        font-weight: bold;
        margin-bottom: 3px;
        text-align: end;

        &.divider {
          margin-top: 29px;
        }
      }
      &.semi-bold {
        font-family: 'poppins-local-medium';
        margin-bottom: 3px;
        text-align: start;
      }
      &.editable {
        cursor: text;
      }
      &.party {
        margin-bottom: 17px;
        font-weight: bold;
      }
      &.invisible {
        visibility: hidden;
      }
      &.description {
        margin-left: 17px;
        text-align: start;
      }
      &.value {
        text-align: end;
        overflow: visible;
      }
    }
  }
}
