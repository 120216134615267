.deleteMessageContainer {
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  .deleteMessage {
    filter: blur(0);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 376px;
    height: 166px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 910;
    .claimNameContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .deleteQuestion {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
    border-radius: 5px;
    padding: 7px;
    p {
      font-size: 16px;
      word-break: normal;
    }
    .buttonsContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 40px;
    }
    .cancellingMessageContainer {
      padding-top: 63px;
      align-content: center;
      text-align: center;
    }
  }
}
