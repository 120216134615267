@mixin treeButtonWithLabelContainer() {
  position: absolute;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

@mixin treeButtonWithLabelImgContainer() {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid $lakersUniform;
  &.hover {
    border: 2px solid $lakersUniform;
  }
  margin-right: 3px;
  cursor: pointer;
}

@mixin treeButtonWithLabelImg() {
  cursor: pointer;
  z-index: 1;
  height: 15px;
}

@mixin treeButtonWithLabelTextContainer() {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  height: 26px;
  border-radius: $borderRadiusSmall;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  p {
    color: $lakersUniform;
    cursor: pointer;
    &.hover {
      font-weight: 600;
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.treeNode {
  position: absolute;
  margin: 35px 0 0 40px;
  &.minimised {
    margin: 35px 0 0 30px;
  }
  &.fadeout {
    @include fadeOutAnimation();
  }
  width: fit-content;
  z-index: 4;

  .treeNodeBackground {
    //position: relative;
    width: fit-content;
    height: fit-content;
    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: center;

    border-radius: 0px $borderRadiusMedium $borderRadiusMedium
      $borderRadiusMedium;
    box-shadow: 0px 3px 6px #00000029;

    &.minimised {
      background-color: transparent;
      box-shadow: 0px 0px 0px #00000029;
    }

    .connectionCreatesLoopMessageContainerNode {
      position: absolute;
      left: 120px;
      top: -50px;
      width: 250px;
      z-index: 1000;
      background-color: pink;
      box-shadow: 0px 2px 2px #00000029;
      padding: 3px;
      text-align: center;
      border-radius: 3px;
      &.shake {
        animation: shake 0.5s;
      }
    }

    .connectionCircleNodeAvailable {
      position: absolute;
      left: calc(50% - 70px);
      top: -70px;
      width: 140px;
      height: 140px;
      border-radius: 70px;
      opacity: 0.3;
      z-index: 200;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        transform: rotate(180deg);
        margin-top: -31px;
      }
    }
    .connectionCircle {
      position: absolute;
      left: calc(50% - 5px);
      top: -5px;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      padding: auto;
      text-align: center;
      margin: auto;
      background-color: $lakersUniform;
      &.selected {
        background-color: $mexicoSunrise;
      }
      &.krogerus {
        background-color: $krogerusClientColor;
      }
      &.clicked {
        left: calc(50% - 10px);
        top: -20px;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background-color: white;
        border: solid 1px black;
        cursor: pointer;
      }
    }

    .treeNodeMoveHandle {
      background-color: white;
      display: flex;
      justify-content: flex-end;
      padding: 5px;
      position: absolute;
      left: -35px;
      top: 0px;
      height: 10px;
      width: fit-content;
      height: fit-content;
      border-radius: $borderRadiusSmall 0px 0px $borderRadiusSmall;
      box-shadow: -3px 4px 7px -4px #999999;
      z-index: 5;
      &.minimised {
        left: -25px;
        top: 16px;
        border-radius: $borderRadiusSmall;
      }
      cursor: pointer;

      .treeNodeMoveHandleTransparentCover {
        position: absolute;
        left: 2px;
        bottom: 2px;
        width: 36px;
        height: 36px;
        background: transparent;
      }
    }
    .treeNodeTitle {
      position: absolute;
      background-color: $lakersUniform;
      &.krogerus {
        background-color: $krogerusClientColor;
      }
      left: 21px;
      bottom: calc(100% - 17px);
      width: calc(100% - 42px);
      border-radius: 5px;
      min-height: 50px;
      padding: 0px 0px 10px 0px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;
      &.selected {
        background-color: $mexicoSunrise;
        color: black;
      }
      &.viewerPlaceholder {
        min-height: 11px;
      }
      .deleteNodeButtonContainer,
      .duplicateNodeContainer,
      .copyNodeButtonContainer,
      .selectNodeContainer {
        @include treeButtonWithLabelContainer();

        &.deleteNodeButtonContainer {
          right: -86px;
          width: 110px;
          top: -40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          height: 50px;
          padding-top: 10px;
          padding-left: 10px;
        }
        &.duplicateNodeContainer {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          right: -115px;
          top: 8px;
          height: 42px;
          width: 140px;
          padding-left: 10px;
        }
        &.copyNodeButtonContainer {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          right: -170px;
          top: 46px;
          width: 185px;
          height: 40px;
        }
        &.selectNodeContainer {
          left: -71px;
          &.selected {
            left: -92px;
            width: 121px;
          }
          top: -12px;
          height: 53px;
          width: 100px;
        }

        .deleteNodeButtonImgContainer,
        .duplicateNodeImgContainer,
        .copyNodeButtonImgContainer,
        .selectNodeImgContainer {
          @include treeButtonWithLabelImgContainer();

          &.selectNodeImgContainer {
            margin-right: 0px;
            margin-left: 3px;
            background-color: transparent;
            border: 0px solid transparent;
          }

          .deleteNodeButtonImg,
          .duplicateNodeImg,
          .copyNodeButtonImg,
          .selectNodeImg {
            @include treeButtonWithLabelImg();
            &.selectNodeImg {
              height: 22px;
            }
          }
        }
        .deleteNodeButtonTextContainer,
        .duplicateNodeTextContainer,
        .copyNodeButtonTextContainer,
        .selectNodeTextContainer {
          @include treeButtonWithLabelTextContainer();

          &.deleteNodeButtonTextContainer {
            width: 58px;
          }
          &.duplicateNodeTextContainer {
            width: 88px;
          }
          &.copyNodeButtonTextContainer {
            padding-left: 5px;
            padding-right: 5px;
          }
          &.selectNodeTextContainer {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }

      .createLineContainer {
        position: absolute;
        top: -65px;
        left: calc(50% - 46px);
        width: 76px;
        height: 73px;
        cursor: pointer;
        padding-top: 10px;
      }

      .createLineDot {
        position: absolute;
        left: calc(50% - 5px);
        top: -5px;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        padding: auto;
        text-align: center;
        margin: auto;
        background-color: $lakersUniform;
      }

      .treeNodeTItleHoverBottomReference {
        height: 0.1px;
        width: 100%;
        position: relative;
      }
      .container-treeNodeTitleTextarea {
        width: 100%;
        .treeNodeTitleTextarea {
          color: white;
          border-radius: 5px;
          border: none;
          width: 100%;
          height: fit-content;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          resize: none;
          bottom: 0px;
          padding: 10px 23px 5px 23px;

          &::placeholder {
            color: rgb(201, 197, 197);
            font-weight: 400;
          }
          &.selected {
            background-color: $mexicoSunrise;
            color: $ageanMidnight;
            &::placeholder {
              color: $lakersUniformHover;
              font-weight: 400;
            }
          }
        }
      }
    }

    .treeAddEvent {
      position: absolute;
      right: -30px;
      top: calc(47% - 22.5px);
      height: 50px;
      width: 50px;
      border-radius: $borderRadiusSmall;
      border: 1px solid transparent;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      &.purple {
        background-color: white;
        border: 1px solid $ageanMidnight;
        width: 140px;
        cursor: pointer;
        right: -120px;
        box-shadow: 0px 3px 6px #00000029;
      }
      cursor: pointer;
      .treeAddEventImg {
        cursor: pointer;
        margin-left: 7px;
        width: 28px;
      }
      .treeAddEventText {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
