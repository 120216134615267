.zoomMessageBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: $transparentBackgroundGray;

  .zoomMessagePopUp {
    background-color: white;
    box-shadow: 0px 0px 30px #0000002c;
    height: 336px;
    width: 496px;
    border-radius: 10px;
    padding-right: 46px;
    padding-left: 46px;
    padding-top: 46px;
    padding-bottom: 46px;
    position: relative;
    margin-top: calc((100vh - 150px) / 5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
      color: #201a2d;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: left;
    }

    .subtitle {
      color: #201a2d;
      font-size: 1rem;
      text-align: left;
      padding-bottom: 42px;
    }

    .contentRow {
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .bullet {
        margin-right: 5px;
        color: #201a2d;
        font-size: 16px;
      }

      .text {
        color: #201a2d;
        font-size: 16px;
      }
    }

    .closeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 27px;
      height: 27px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
