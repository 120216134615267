.movingShadow {
  position: absolute;
  height: 100%;
  left: 0px;
  width: 120px;
  background-color: gray;
  opacity: 0.05;
  box-shadow: 0px 0px 60px gray;
  filter: blur(4px);
  animation: moveShadow 2s infinite;
}

.noscroll {
  overflow-y: hidden !important;
}

@keyframes moveShadow {
  0% {
    left: 0px;
  }
  100% {
    left: 1184px;
  }
}

.movingShadow1 {
  @extend .movingShadow;
}
.movingShadow2 {
  @extend .movingShadow;
  margin-left: 70px;
}
.movingShadow3 {
  @extend .movingShadow;
}
