/*Here add all the custom fonts as follows*/
/*CustomFont*/
/*Prompt*/
@font-face {
  font-family: "Prompt-Local-Regular";
  src: url(../resources/fonts/Prompt/Prompt-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Prompt-Local-Medium";
  src: url(../resources/fonts/Prompt/Prompt-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Prompt-Local-Bold";
  src: url(../resources/fonts/Prompt/Prompt-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*Gotham*/
@font-face {
  font-family: "Gotham-Local-Regular";
  src: url(../resources/fonts/Gotham/Gotham-Medium.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Gotham-Local-Medium";
  src: url(../resources/fonts/Gotham/Gotham-Medium.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Gotham-Local-Bold";
  src: url(../resources/fonts/Gotham/Gotham-Bold.otf);
  font-style: normal;
  font-stretch: 100%;
}
/*Arial*/
@font-face {
  font-family: "Arial-Local-Regular";
  src: url(../resources/fonts/Arial/Arial-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Arial-Local-Medium";
  src: url(../resources/fonts/Arial/Arial-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Arial-Local-Bold";
  src: url(../resources/fonts/Arial/Arial-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*Heebo*/
@font-face {
  font-family: "Heebo-Local-Regular";
  src: url(../resources/fonts/Heebo/Heebo-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Heebo-Local-Medium";
  src: url(../resources/fonts/Heebo/Heebo-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Heebo-Local-Bold";
  src: url(../resources/fonts/Heebo/Heebo-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*Rubik*/
@font-face {
  font-family: "Rubik-Local-Regular";
  src: url(../resources/fonts/Rubik/Rubik-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Rubik-Local-Medium";
  src: url(../resources/fonts/Rubik/Rubik-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Rubik-Local-Bold";
  src: url(../resources/fonts/Rubik/Rubik-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*Calibri*/
@font-face {
  font-family: "Calibri-Local-Regular";
  src: url(../resources/fonts/Calibri/Calibri-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Calibri-Local-Medium";
  src: url(../resources/fonts/Calibri/Calibri-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Calibri-Local-Bold";
  src: url(../resources/fonts/Calibri/Calibri-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*PlayfairDisplay*/
@font-face {
  font-family: "PlayfairDisplay-Local-Regular";
  src: url(../resources/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "PlayfairDisplay-Local-Medium";
  src: url(../resources/fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "PlayfairDisplay-Local-Bold";
  src: url(../resources/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*Roboto*/
@font-face {
  font-family: "Roboto-Local-Regular";
  src: url(../resources/fonts/Roboto/Roboto-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Roboto-Local-Medium";
  src: url(../resources/fonts/Roboto/Roboto-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Roboto-Local-Bold";
  src: url(../resources/fonts/Roboto/Roboto-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
/*Chap*/
@font-face {
  font-family: "Chap-Local-Regular";
  src: url(../resources/fonts/Chap/ChapLight.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Chap-Local-Medium";
  src: url(../resources/fonts/Chap/ChapMedium.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "Chap-Local-Bold";
  src: url(../resources/fonts/Chap/ChapMedium.otf);
  font-style: normal;
  font-stretch: 100%;
}
.notSupportedBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.notSupportedBackground .notSupportedMessageBox {
  max-width: 696px;
  min-height: 403px;
  box-shadow: 0px 0px 30px #c9c9c9;
  border-radius: 30px;
  background-color: white;
  margin-top: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;
  padding-bottom: 57px;
  padding-left: 21px;
  padding-right: 21px;
}
.notSupportedBackground .notSupportedMessageBox .titleText {
  font-weight: bold;
  color: #201a2d;
  margin-bottom: 44px;
  text-align: center;
}
.notSupportedBackground .notSupportedMessageBox .descriptionText {
  color: #373c58;
  margin-bottom: 44px;
  text-align: center;
}
.notSupportedBackground .notSupportedMessageBox .mailText {
  color: #373c58;
  text-align: center;
}
.notSupportedBackground .notSupportedMessageBox .eperotoIcon {
  position: absolute;
  top: -75px;
  right: -50px;
  height: 130px;
}
.notSupportedBackground .eperotoLogo {
  position: absolute;
  top: 24px;
  left: 10vw;
  height: 65px;
  cursor: pointer;
}

.userPopUp {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 5999;
  font-family: "Roboto", sans-serif;
}
.userPopUp.for-settings {
  background-color: rgba(128, 128, 128, 0.8);
  position: fixed;
}
.userPopUp.for-users {
  background-color: rgba(128, 128, 128, 0.8);
  overflow: scroll;
  position: fixed;
}
.userPopUp .header {
  top: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  justify-content: space-between;
  z-index: 10;
  flex-wrap: nowrap;
  align-items: flex-start;
  display: flex;
  width: 1170px;
}
.userPopUp .header-out {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.userPopUp .logo {
  height: 70.44px;
  width: 127px;
  position: relative;
  padding-left: 15px;
}
.userPopUp .loginBox {
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  width: 550px;
  height: 620px;
  position: absolute;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.userPopUp .loginBox.ssoLoginBox {
  height: 253px;
  margin-top: 100px;
  width: 496px;
}
.userPopUp .loginBox.ssoError {
  height: 335px;
}
.userPopUp .loginBox.freemium {
  width: 496px;
  height: 485px;
}
.userPopUp .loginBox.only-owner {
  width: 700px;
  height: 700px;
}
.userPopUp .loginBox.only-owner.small-owner {
  height: 300px;
}
.userPopUp .loginBox.small {
  height: 400px;
}
.userPopUp .loginBox.very-small {
  height: 300px;
}
.userPopUp .loginBox.big-form {
  height: 680px;
}
.userPopUp .loginBox .form {
  width: 470px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.userPopUp .loginBox .form.freemium {
  width: 400px;
}
.userPopUp .loginBox .successfullMessageContainer {
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.userPopUp .loginBox .successfullMessageContainer .successfullImg {
  height: 120px;
  margin-bottom: 52px;
}
.userPopUp .loginBox .successfullMessageContainer .successfullTitle {
  font-size: 24px;
  font-weight: 600;
}
.userPopUp .loginBox .welcomeMessageContainer {
  position: absolute;
  width: 470px;
  overflow-wrap: break-word;
  top: 45px;
}
.userPopUp .loginBox .welcomeMessageContainer.ssoWelcomeMessageContainer {
  top: 47px;
  width: 470px;
  display: flex;
  justify-content: center;
}
.userPopUp .loginBox .welcomeMessageContainer .welcome {
  font-family: "poppins-local-bold";
  padding: 0px;
}
.userPopUp .loginBox .welcomeMessageContainer.freemium {
  width: 400px;
}
.userPopUp .loginBox .welcomeMessageContainer.middle {
  position: relative;
  margin: auto;
  text-align: center;
  top: 0;
}
.userPopUp .loginBox .welcomeMessageContainer.only-owner {
  width: 660px;
  top: 25px;
}
.userPopUp .loginBox .welcomeMessageContainer .instructions {
  font-size: 16px;
  margin-top: 0px !important;
}
.userPopUp .loginBox .welcomeMessageContainer .instructions .link {
  text-decoration: underline !important;
  color: #ff7336 !important;
}
.userPopUp .loginBox .welcomeMessageContainer .instructions .additionalInfo {
  font-size: 16px;
  margin-top: 12px !important;
  color: rgba(0, 0, 0, 0.38);
}
.userPopUp .loginBox .ssoErroLoginContainer {
  width: 470px;
  display: flex;
  justify-content: center;
  top: 90px;
  position: absolute;
}
.userPopUp .loginBox .ssoErroLoginContainer .ssoErroLoginText {
  color: #ac2828;
  font-size: 16px;
  width: 392px;
  text-align: center;
}
.userPopUp .loginBox .inputsContainer {
  margin: auto;
  width: 100%;
}
.userPopUp .loginBox .inputsContainer .input {
  margin-top: 10px;
}
.userPopUp .loginBox .inputsContainer .lower {
  margin-top: 90px;
}
.userPopUp .loginBox .inputsContainer .higher {
  margin-bottom: 50px;
}
.userPopUp .loginBox .inputEmail {
  margin-bottom: 50px;
}
.userPopUp .loginBox .inputResetPassword1 {
  margin-top: 30px;
  margin-bottom: 60px;
}
.userPopUp .loginBox .inputResetPassword1.for-settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
.userPopUp .loginBox .inputCode {
  margin-bottom: 40px;
}
.userPopUp .loginBox .inputResetPassword2 {
  margin-bottom: 35px;
}
.userPopUp .loginBox .inputResetPassword2.for-settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
.userPopUp .loginBox .inputResetPassword3.for-settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.userPopUp .loginBox .selectOwnersContainer {
  margin-top: 40px;
  height: 420px;
  width: 660px;
  display: flex;
  flex-direction: column;
}
.userPopUp .loginBox .selectOwnersContainer .titles {
  display: flex;
  flex-direction: row;
}
.userPopUp .loginBox .selectOwnersContainer .titles .first {
  flex: 1;
  padding-left: 15px;
  font-size: 17px;
  font-weight: bold;
}
.userPopUp .loginBox .selectOwnersContainer .titles .centered {
  flex: 1;
  font-size: 17px;
  font-weight: bold;
}
.userPopUp .loginBox .selectOwnersContainer .cases-column {
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
}
.userPopUp .loginBox .selectOwnersContainer .cases-column::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  margin-right: 10px;
}
.userPopUp .loginBox .selectOwnersContainer .cases-column::-webkit-scrollbar-track {
  border-radius: 10px;
}
.userPopUp .loginBox .selectOwnersContainer .cases-column::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
}
.userPopUp .loginBox .selectOwnersContainer .cases-column .case-row {
  margin: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 96%;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
}
.userPopUp .loginBox .selectOwnersContainer .cases-column .case-row:hover {
  background-color: #edeaf5;
}
.userPopUp .loginBox .selectOwnersContainer .cases-column .case-row .subrow {
  flex: 1;
  font-size: 16px;
}
.userPopUp .loginBox .selectOwnersContainer .cases-column .case-row .subrow.withPadding {
  padding-left: 8px;
  padding-right: 8px;
}
.userPopUp .loginBox .errorClass {
  color: #ac2828 !important;
}
.userPopUp .loginBox .inputLabel {
  font-size: 10px;
}
.userPopUp .loginBox .backImg {
  position: absolute;
  top: 2px;
  left: -68px;
  height: 58px;
  width: 58px;
  cursor: pointer;
  z-index: 1200px;
}
.userPopUp .loginBox .submitButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 45px;
}
.userPopUp .loginBox .submitButtonContainer.admin {
  bottom: 125px;
}
.userPopUp .loginBox .submitButtonContainer.row {
  width: 470px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.userPopUp .loginBox .submitButtonContainer.only-owner {
  width: 660px;
  margin-right: 40px;
}
.userPopUp .loginBox .submitButtonContainer .helperText {
  color: #ac2828;
  margin-top: 10px;
}
.userPopUp .loginBox .ssoButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 470px;
}
.userPopUp .loginBox .ssoButtonContainer .ssoText {
  color: #8e8e8e;
}
.userPopUp .loginBox .ssoButtonContainer .ssoButton {
  margin-top: 6px;
  background-color: white;
  width: 404px;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  border-style: none;
  outline-style: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.userPopUp .loginBox .ssoButtonContainer .ssoButton .ssoButtonText {
  color: #212121;
  font-size: 16px;
  font-family: "poppins-local-bold";
  cursor: pointer;
}
.userPopUp .loginBox .ssoButtonContainer .ssoButton .ssoButtonImg {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.userPopUp .loginBox .submitButton {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
  width: 470px;
  height: 42px;
  background-color: #331a5b;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  font-size: 16px;
  border-style: none;
  outline-style: none;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.userPopUp .loginBox .submitButton .MuiCircularProgress-circle {
  color: white;
}
.userPopUp .loginBox .submitButton.freemium {
  width: 400px;
}
.userPopUp .loginBox .submitButton.small {
  width: 220px;
  height: 38px;
  margin-top: 0px;
}
.userPopUp .loginBox .submitButton.only-owner {
  width: 400px;
  height: 38px;
  margin-top: 0px;
}
.userPopUp .loginBox .disabledButton {
  background-color: #d9d9d9 !important;
  cursor: default;
}
.userPopUp .loginBox .inputsCenterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.userPopUp .loginBox .inputsColumnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}
.userPopUp .loginBox .inputsRowContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.userPopUp .loginBox .radioButton {
  background-color: white;
  height: 45px;
  border-color: #331a5b;
  font-weight: 600;
  border-radius: 4px;
  font-size: 16px;
  outline-style: none;
  border: 1px solid;
  cursor: pointer;
  color: #331a5b;
  padding-left: 30px;
  padding-right: 30px;
}
.userPopUp .loginBox .radioButton:hover {
  background-color: #edeaf5;
}
.userPopUp .loginBox .radioButton.disabled {
  background-color: #d9d9d9 !important;
  border-color: transparent;
  color: #8e8e8e !important;
  cursor: default;
}
.userPopUp .loginBox .radioButtonSelected {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background-color: #331a5b !important;
  color: white !important;
  border-color: #331a5b;
}
.userPopUp .loginBox .orContainer {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #331a5b;
  font-weight: 600;
}
.userPopUp .loginBox .orContainer.disabled-sms {
  color: #6a6a6a;
  margin-top: 2px;
  font-weight: 100;
  margin-bottom: 30px;
  font-size: 14px;
  width: 210px;
  text-align: center;
}
.userPopUp .loginBox .lettersContainer {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #331a5b;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.userPopUp .loginBox .bigletters {
  font-size: 21px !important;
}
.userPopUp .loginBox .digitsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 300px;
}
.userPopUp .loginBox .oneDigitInput {
  width: 35px;
  height: 35px;
  border-color: #331a5b;
  color: #331a5b;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
  border: 2px solid;
  text-align: center;
  outline-style: none;
}
.userPopUp .loginBox .oneDigitInput:focus {
  border: 2px solid #ff7336 !important;
}
.userPopUp .loginBox .errorletersContainer {
  color: #ac2828 !important;
}
.userPopUp .loginBox .qrContainer {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1725490196);
  background-color: white;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
}
.userPopUp .loginBox .copyButton {
  background-color: white;
  height: 75px;
  width: 108px;
  border-color: #331a5b;
  border-radius: 4px;
  font-size: 13px;
  outline-style: none;
  border-style: none;
  cursor: pointer;
  color: #331a5b;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1725490196);
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userPopUp .loginBox .copyImage {
  width: 23px;
  height: 25px;
  color: #331a5b;
  margin-bottom: 5px;
}
.userPopUp .loginBox .bigDoneImage {
  width: 112px;
  height: 118px;
  color: #331a5b;
  margin-bottom: 5px;
}
.userPopUp .loginBox .visibleTick {
  width: 29px;
  height: 29px;
  cursor: pointer;
}
.userPopUp .loginBox .hiddenTick {
  visibility: hidden;
  position: fixed;
}
.userPopUp .loginBox .showPassword {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
}
.userPopUp .loginBox .showPassword p {
  cursor: pointer;
}
.userPopUp .loginBox .tickText {
  font-size: 16px;
  margin: 0;
  margin-left: 6px;
}
.userPopUp .loginBox .tickText.freemium {
  cursor: default;
}
.userPopUp .loginBox .forgotContainer {
  margin-top: 27px;
}
.userPopUp .loginBox .forgotContainer .forgot {
  font-size: 16px;
  color: #462777;
  text-decoration: none;
  cursor: pointer;
}
.userPopUp .loginBox .forgotContainer .centered {
  text-align: center;
}
.userPopUp .loginBox .exitButtonImgContainer {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}
.userPopUp .loginBox .exitButtonImgContainer .exitButtonImg {
  cursor: pointer;
}

.log-out-text {
  color: #ffdc94;
  font-size: 16px;
  position: fixed;
  right: 7%;
  top: 30px;
  float: right;
}

.mailTo {
  color: #ff7336;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.mainContainer .logoContainer {
  position: fixed;
  top: 20px;
  left: 20px;
}
.mainContainer .logoContainer .logoImg {
  height: 70.44px;
  width: 127px;
}
.mainContainer .messageContainer {
  width: 298px;
  height: 109px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 30px #c9c9c9;
  border-radius: 10px;
}
.mainContainer .messageContainer .messageText {
  color: #201a2d;
  font-size: 16px;
}

.undoRedoContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 46px;
}
.undoRedoContainer .undoContainer .redoContainer img {
  height: 18px;
}
.undoRedoContainer .undoContainer .redoContainer .undoGray {
  height: 200px;
}
.undoRedoContainer .undoContainer .redoContainer .undoGray g path,
.undoRedoContainer .undoContainer .redoContainer .undoGray g line {
  color: "gray";
}

.headerPathContainer {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headerPathContainer .logoContainer {
  width: 42px;
  height: 27px;
}
.headerPathContainer .logoContainer .logo {
  width: 42px;
  height: 27px;
}
.headerPathContainer .pathComponentContainer {
  margin-left: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: -moz-fit-content;
  width: fit-content;
}
.headerPathContainer .pathComponentContainer .pathArrow {
  height: 19px;
  width: 11px;
}
.headerPathContainer .pathComponentContainer .finalPathComponent,
.headerPathContainer .pathComponentContainer .scenarioPathComponent,
.headerPathContainer .pathComponentContainer .notFinalPathComponent {
  position: relative;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .hiddenPlaceholder,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .hiddenPlaceholder,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .hiddenPlaceholder {
  font-size: 16px;
  font-weight: 600;
  visibility: hidden;
  height: 0px;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .word-limit,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .word-limit,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .word-limit {
  display: flex;
  box-sizing: border-box;
  max-width: 15vw;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .word-limit :first-child,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .word-limit :first-child,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .word-limit :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .word-limit span:last-child,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .word-limit span:last-child,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .word-limit span:last-child {
  white-space: nowrap;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .pathTitleText,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .pathTitleText,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .pathTitleText {
  color: #201a2d;
  font-size: 16px;
  white-space: nowrap;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .final,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .final,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .final {
  color: #ff7336;
  font-weight: 600;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .final span,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .final span,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .final span {
  color: #ff7336;
  font-weight: 600;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .pathTitle .link:hover,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .pathTitle .link:hover,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .pathTitle .link:hover {
  color: #201a2d;
  font-weight: 600;
  cursor: pointer;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenariosArrow,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenariosArrow,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenariosArrow {
  width: 19px;
  height: 11px;
  margin-top: 2px;
  margin-left: 5px;
  cursor: pointer;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .up,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .up,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .up {
  transform: rotate(180deg);
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenarioSubMenuContainer,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenarioSubMenuContainer,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenarioSubMenuContainer {
  background-color: white;
  margin-left: -5px;
  min-width: 190px;
  max-width: 430px;
  width: calc(100% + 10px);
  position: absolute;
  top: 31px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 220px;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenarioSubMenuContainer .scenarioNameContainer,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenarioSubMenuContainer .scenarioNameContainer,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenarioSubMenuContainer .scenarioNameContainer {
  width: 100%;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenarioSubMenuContainer .scenarioNameContainer .scenarioName,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenarioSubMenuContainer .scenarioNameContainer .scenarioName,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenarioSubMenuContainer .scenarioNameContainer .scenarioName {
  margin-top: 5px;
  margin-left: 10px;
  min-height: 25px;
  width: 90%;
  text-align: left;
  color: #201a2d;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenarioSubMenuContainer .scenarioNameContainer .scenarioName:hover,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenarioSubMenuContainer .scenarioNameContainer .scenarioName:hover,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenarioSubMenuContainer .scenarioNameContainer .scenarioName:hover {
  color: #ff7336;
  cursor: pointer;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenarioSubMenuContainer::-webkit-scrollbar,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenarioSubMenuContainer::-webkit-scrollbar,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenarioSubMenuContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .scenarioSubMenuContainer::-webkit-scrollbar-thumb,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .scenarioSubMenuContainer::-webkit-scrollbar-thumb,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .scenarioSubMenuContainer::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.headerPathContainer .pathComponentContainer .finalPathComponent .wordLimit-cut,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .wordLimit-cut,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .wordLimit-cut {
  display: inline-flex;
  box-sizing: border-box;
  max-width: 15vw;
  transition: 0.1s width;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .wordLimit-cut :first-child,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .wordLimit-cut :first-child,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .wordLimit-cut :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.headerPathContainer .pathComponentContainer .finalPathComponent .wordLimit-cut span:last-child,
.headerPathContainer .pathComponentContainer .scenarioPathComponent .wordLimit-cut span:last-child,
.headerPathContainer .pathComponentContainer .notFinalPathComponent .wordLimit-cut span:last-child {
  white-space: nowrap;
}

.savingCaseMessageBackground {
  position: fixed;
  top: 43px;
  left: 0px;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.savingCaseMessageBackground.generatingReportMessage {
  z-index: 9;
}
.savingCaseMessageBackground .savingCaseMessageContainer {
  margin-top: 240px;
  padding: 28px 35px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.savingCaseMessageBackground .savingCaseMessageContainer .savingCaseMessageText {
  font-size: 16px;
  text-align: center;
}

.saveCaseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 33px;
}
.saveCaseContainer .savedMessageContainer {
  position: absolute;
  width: 120px;
  text-align: center;
  top: 60px;
  right: 0px;
  border-radius: 5px;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  background-color: white;
  padding: 10px;
  z-index: 999;
  animation-name: savedMessageAnimation;
  animation-duration: 3s;
}
.saveCaseContainer .savedMessageContainer .savedScenarioText {
  font-size: 14px;
}
.saveCaseContainer .left {
  right: 128px;
}
.saveCaseContainer .savedErrorContainer {
  position: absolute;
  width: 120px;
  text-align: center;
  top: 60px;
  right: 0px;
  border-radius: 5px;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  background-color: #ffdddd;
  padding: 10px;
  z-index: 999;
  animation-name: savedMessageAnimation;
  animation-duration: 3s;
}
.saveCaseContainer .savedErrorContainer .savedScenarioText {
  font-size: 14px;
}
.saveCaseContainer .saveAsButtonContainer {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-right: 20px;
}
.saveCaseContainer .saveAsButtonContainer .saveAsButtonSmall {
  border-radius: 5px;
  border: 1px solid #201a2d;
  cursor: pointer;
  height: 40px;
  padding: 0 10px;
}
.saveCaseContainer .saveAsButtonContainer .saveAsButtonSmall:hover {
  background-color: #edeaf5;
}
.saveCaseContainer .saveAsButtonContainer .saveAsButtonSmall .saveAsImg {
  height: 20px;
}
.saveCaseContainer .saveAsButtonContainer .saveAsButtonSmall.saveAsButtonSmall:disabled {
  cursor: auto;
  background-color: #d9d9d9;
  border: none;
}
.saveCaseContainer .saveAsButtonContainer .saveAsNewMenuContainer {
  position: absolute;
  top: 0px;
  width: 470px;
  height: 140px;
  right: 100px;
  transform: translate(50%, 0%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  z-index: 900;
}
.saveCaseContainer .saveAsButtonContainer .saveAsNewMenuContainer .SaveAsButtonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.saveCaseContainer .saveTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.saveCaseContainer .saveTextContainer p {
  cursor: pointer;
  margin-right: 4px;
}
.saveCaseContainer .saveButtonContainer {
  position: relative;
}
.saveCaseContainer .saveButtonContainer .button-text {
  color: #331a5b;
  width: 45px;
  text-align: left;
}
.saveCaseContainer .saveButtonContainer .button-text.disabled {
  color: #8e8e8e !important;
}
.saveCaseContainer .saveButtonContainer .tickImg {
  top: 10px;
  right: 6px;
  position: absolute;
  height: 13px;
  align-self: center;
}

@keyframes savedMessageAnimation {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.moreMenu {
  position: absolute;
  top: 44px;
  right: 160px;
  width: 244px;
  background-color: white;
  border: 1px solid #201a2d;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.moreMenu .moreMenuOption {
  width: 100%;
  cursor: pointer;
}
.moreMenu .moreMenuOption :hover {
  background-color: #edeaf5;
}
.moreMenu .moreMenuOption .moreMenuText {
  padding: 15px;
  color: #321b59;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.moreMenu .moreMenuOption .moreMenuText.first {
  border-radius: 5px 5px 0px 0px;
}
.moreMenu .moreMenuOption .moreMenuText.last {
  border-radius: 0px 0px 5px 5px;
}

.moreBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: rgba(128, 128, 128, 0.8);
}
.moreBackgroundPopUp .morePopUp {
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  height: 280px;
  width: 680px;
  border-radius: 10px;
  padding-right: 28px;
  padding-left: 28px;
  padding-top: 21px;
  position: relative;
  margin-top: calc((100vh - 280px) / 5);
}
.moreBackgroundPopUp .morePopUp.loading {
  width: 485px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreBackgroundPopUp .morePopUp.withSubtitle {
  height: 400px;
  margin-top: calc((100vh - 400px) / 5);
}
.moreBackgroundPopUp .morePopUp .title {
  color: #201a2c;
  font-size: 22px;
  font-weight: bold;
}
.moreBackgroundPopUp .morePopUp .subTitle {
  color: #321b59;
  font-size: 16px;
}
.moreBackgroundPopUp .morePopUp .loadingMessage {
  text-align: center;
  color: #321b59;
  font-size: 20px;
}
.moreBackgroundPopUp .morePopUp .inputContainer {
  margin-top: 45px;
  margin-bottom: 57px;
}
.moreBackgroundPopUp .morePopUp .inputContainer.withSubtitle {
  margin-top: 40px;
  margin-bottom: 40px;
}
.moreBackgroundPopUp .morePopUp .buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.moreBackgroundPopUp .morePopUp .exitButtonImgContainer {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}
.moreBackgroundPopUp .morePopUp .exitButtonImgContainer .exitButtonImg {
  cursor: pointer;
}

.headerContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 10;
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 836px) {
  .headerContainer {
    min-width: 836px;
    position: absolute;
  }
}
.headerContainer .leftHeader {
  max-width: calc(100vw - 520px);
}
.headerContainer .rightHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 32px;
}
.headerContainer .rightHeader .changeLanguageContainer {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.headerContainer .rightHeader .changeLanguageContainer .languagePrefix {
  cursor: pointer;
}
.headerContainer .rightHeader .changeLanguageContainer .languageFlagImg {
  cursor: pointer;
  margin-right: 3px;
  width: 23px;
}
.headerContainer .rightHeader .changeLanguageContainer .languageDropdownContainer {
  position: absolute;
  top: 43px;
  right: 0px;
  width: 175px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
}
.headerContainer .rightHeader .changeLanguageContainer .languageDropdownContainer .languageDropdownItem {
  display: flex;
  flex-direction: row;
  height: 40px;
  border-radius: 10px;
  align-items: center;
  padding: 0px 10px;
  border-radius: 0px;
  cursor: pointer;
}
.headerContainer .rightHeader .changeLanguageContainer .languageDropdownContainer .languageDropdownItem:first-of-type {
  border-radius: 10px 10px 0px 0px;
}
.headerContainer .rightHeader .changeLanguageContainer .languageDropdownContainer .languageDropdownItem:last-of-type {
  border-radius: 0px 0px 10px 10px;
}
.headerContainer .rightHeader .changeLanguageContainer .languageDropdownContainer .languageDropdownItem:hover {
  background-color: #edeaf5;
}
.headerContainer .rightHeader .changeLanguageContainer .languageDropdownContainer .languageDropdownItem .languageFlagImg {
  margin-right: 6px;
  width: 23px;
}

.sideMenuTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  cursor: pointer;
  background-color: #f6f6f6;
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer {
  background-color: white;
  z-index: 1;
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer.top {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 10px 0;
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer.top.bottomActive {
  border-radius: 0 0 0 0;
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer.middle {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 10px 0 0;
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer.middle.bottomActive {
  border-radius: 0 0 10px 0;
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer.bottom {
  border-radius: 0 10px 0 0;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
}
.sideMenuTitleContainer.inactiveSideMenuTitleContainer.bottom.topActive {
  border-radius: 0 0 0 0;
}
.sideMenuTitleContainer.sideMenuTitleContainerOpen {
  justify-content: flex-start;
}
.sideMenuTitleContainer .sideMenuTitleThumbnailContainer {
  width: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sideMenuTitleContainer .sideMenuTitleText {
  font-size: 18px;
  margin-left: 8px;
  color: #331a5b;
  cursor: pointer;
}
.sideMenuTitleContainer .sideMenuTitleText.inactiveSideMenuTitleText {
  color: #d0d3e8;
}
.sideMenuTitleContainer .sideMenuTitleText.hoverSideMenuTitleText {
  color: #a1a6c7;
}

.sideMenuLogOutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 2;
}
.sideMenuLogOutContainer .mnmActivationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.sideMenuLogOutContainer .mnmActivationContainer .mnmActivationText {
  font-size: 10px;
}
.sideMenuLogOutContainer .userAvatarContainer {
  position: relative;
  margin-bottom: 12px;
}
.sideMenuLogOutContainer .userAvatarContainer .userThumbnailContainer {
  width: 39px;
  height: 39px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sideMenuLogOutContainer .userAvatarContainer .userThumbnailContainer.bigUserThumbnailContainer {
  width: 45px;
  height: 45px;
  border-radius: 35px;
}
.sideMenuLogOutContainer .userAvatarContainer .userThumbnailContainer.bigUserThumbnailContainer .userThumbnailText {
  cursor: pointer;
  font-size: 16px;
}
.sideMenuLogOutContainer .userAvatarContainer .userThumbnailContainer .userThumbnailText {
  cursor: pointer;
  font-size: 13px;
}
.sideMenuLogOutContainer .userAvatarContainer .userHoverContainer {
  position: absolute;
  left: 70px;
  top: 0px;
  width: -moz-fit-content;
  width: fit-content;
  height: 51px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3019607843);
  padding: 0px 10px;
}
.sideMenuLogOutContainer .userAvatarContainer .userHoverContainer .greeting {
  color: #ff7336;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.sideMenuLogOutContainer .userAvatarContainer .userHoverContainer .userName {
  font-size: 16px;
  white-space: nowrap;
  margin-left: 10px;
}
.sideMenuLogOutContainer .logOutContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 81px;
}
.sideMenuLogOutContainer .logOutContainer .logoutText {
  font-size: 14px;
  margin-right: 5.5px;
  cursor: pointer;
}
.sideMenuLogOutContainer .logOutContainer .logOutMenuContainer {
  padding: 28px 20px;
  position: fixed;
  width: 318px;
  height: 155px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3019607843);
  left: 174px;
  bottom: 70px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: 5px;
  z-index: 900;
}
.sideMenuLogOutContainer .logOutContainer .logOutMenuContainer .circularProgressDiv {
  width: 100%;
}
.sideMenuLogOutContainer .logOutContainer .logOutMenuContainer .logOutMenuTextContainer .logOutMenuText {
  text-align: center;
  font-size: 16px;
  margin-bottom: 37px;
}
.sideMenuLogOutContainer .logOutContainer .logOutMenuContainer .logOutMenuButtonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.checklistContainer {
  position: fixed;
  left: 70px;
  bottom: 25px;
  z-index: 900;
}
.checklistContainer .checklistMenuContainer {
  padding: 5px 11px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(50, 27, 89, 0.3960784314);
}
@media screen and (max-width: 940px) {
  .checklistContainer .checklistMenuContainer {
    zoom: 0.8;
  }
}
.checklistContainer .checklistMenuContainer .checklistText {
  margin-left: 5px;
  font-size: 16px;
  color: #331a5b;
  cursor: pointer;
}
.checklistContainer .checklistMenuContainer .checklistText:hover {
  font-weight: 600;
}
.checklistContainer .checklistMenuContainer.on {
  padding: 7px 11px 7px 11px;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow.title {
  margin-bottom: 5px;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow .checklistMenuRowImgContainer {
  width: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow .checklistMenuRowText {
  margin-left: 5px;
  color: #331a5b;
  min-width: 115px;
  cursor: pointer;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow .checklistMenuRowText:hover {
  font-weight: 600;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow .checklistMenuRowText.done {
  text-decoration: line-through;
}
.checklistContainer .checklistMenuContainer .checklistMenuRow .checklistMenuTitleRowText {
  margin-left: 5px;
  color: #331a5b;
  min-width: 115px;
  font-weight: 600;
}

.open {
  left: 180px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 44px;
  padding-bottom: 40px;
}
.infoContainer .infoTitle {
  color: #331a5b;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}
.infoContainer .infoText {
  font-size: 16px;
  color: #201a2c;
  margin-bottom: 10px;
}
.infoContainer .infoVideo {
  margin: auto;
  margin-bottom: 28px;
}
.infoContainer .infoActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  margin-top: 19px;
}
.infoContainer .infoActions .cancelButton {
  cursor: pointer;
  min-width: 85px;
  height: 44px;
  margin-right: 27px;
  border: 1px solid #331a5b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
}
.infoContainer .infoActions .cancelButton .text {
  cursor: pointer;
  color: #331a5b;
  font-size: 18px;
  font-weight: bold;
}
.infoContainer .infoActions .doneButton {
  cursor: pointer;
  width: 85px;
  height: 44px;
  margin-right: 27px;
  border: 1px solid #331a5b;
  background-color: #331a5b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoContainer .infoActions .doneButton .text {
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.infoContainer .infoActions .doneButton .MuiCircularProgress-circle {
  color: white;
}

.transparentSideMenu {
  position: fixed;
  background-color: transparent;
  left: 0px;
  top: 0px;
  width: 162px;
  height: 100%;
  z-index: 12;
}

.sideMenuContainer {
  position: fixed;
  background-color: transparent;
  left: 0px;
  top: 43px;
  width: 49px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
}
@media only screen and (max-width: 836px) {
  .sideMenuContainer {
    position: absolute;
  }
}
.sideMenuContainer .upperSideMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f6f6f6;
}
.sideMenuContainer .upperSideMenu .upperSideMenufiller {
  position: relative;
  width: 100%;
  background-color: white;
  height: 65px;
  border-radius: 0 0 10px 0;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
}
.sideMenuContainer .upperSideMenu .upperSideMenufiller.nocorner {
  border-radius: 0 0 0 0;
}
.sideMenuContainer .upperSideMenu .upperSideMenufiller .cornerFiller {
  position: absolute;
  right: -10px;
  top: 0px;
}
.sideMenuContainer .downSideMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.sideMenuContainer .downSideMenu .appVersion {
  position: absolute;
  width: 35px;
  top: 10px;
}
.sideMenuContainer .downSideMenu .appVersion .appVersionText {
  color: #201a2d;
  font-size: 8px;
}
.sideMenuContainer .downSideMenu .downSideMenufiller {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 1;
  border-radius: 0 10px 0 0;
}
.sideMenuContainer .downSideMenu .downSideMenufiller.nocorner {
  border-radius: 0 0 0 0;
}

.sideMenuContainerOpen {
  width: 162px;
}

.saveMenuContainer,
.saveMenuContainer2 {
  position: absolute;
  width: 780px;
  height: 155px;
  background-color: white;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  border-radius: 5px;
  z-index: 900;
  padding: 28px 30px 10px 29px;
  box-sizing: border-box;
}
.saveMenuContainer .circularProgressDiv,
.saveMenuContainer2 .circularProgressDiv {
  width: 100%;
  height: 100%;
}
.saveMenuContainer .saveMenuMessage,
.saveMenuContainer2 .saveMenuMessage {
  text-align: left;
}
.saveMenuContainer .saveMenuMessage p,
.saveMenuContainer2 .saveMenuMessage p {
  font-size: 16px;
}
.saveMenuContainer .saveMenuButtons,
.saveMenuContainer2 .saveMenuButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}
.saveMenuContainer .saveMenuButtons button,
.saveMenuContainer2 .saveMenuButtons button {
  display: flex;
  justify-content: center;
}

.saveMenuContainer2 {
  top: 82px;
  right: -20px;
}

@media screen and (max-width: 930px) {
  .saveMenuContainer,
  .saveMenuContainer2 {
    position: absolute;
    width: 300px;
    height: 270px;
  }
  .saveMenuContainer .saveMenuMessage p,
  .saveMenuContainer2 .saveMenuMessage p {
    margin-top: 2px;
  }
  .saveMenuContainer .saveMenuButtons,
  .saveMenuContainer2 .saveMenuButtons {
    margin-top: 10px;
    padding-right: 0px;
    flex-direction: column !important;
    align-items: center;
  }
  .saveMenuContainer .saveMenuButtons button,
  .saveMenuContainer2 .saveMenuButtons button {
    margin: 15px 0 0 0 !important;
  }
  .saveMenuContainer .saveMenuButtons .continueButton,
  .saveMenuContainer2 .saveMenuButtons .continueButton {
    margin-right: 5px;
  }
  .saveMenuContainer2 {
    right: 0px;
    top: 80px;
  }
}
.fixedReportMenu {
  width: 100%;
  position: fixed;
  top: 43px;
  left: 0px;
  margin-left: -9px;
  margin-right: 30px;
  min-height: 69px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
@media screen and (max-width: 1400px) {
  .fixedReportMenu {
    margin-left: 65px;
    width: calc(100vw - 90px);
  }
}
@media screen and (max-width: 810px) {
  .fixedReportMenu {
    position: absolute;
    width: 725px;
  }
}
.fixedReportMenu .exitButtonContainer {
  position: absolute;
  overflow-x: auto;
  top: 100px;
  width: 100%;
  max-width: 1284px;
  min-width: 1284px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 70px;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  .fixedReportMenu .exitButtonContainer {
    width: 95% !important;
  }
}
.fixedReportMenu .exitButtonImg {
  cursor: pointer;
}
.fixedReportMenu .downloadReportMenuContainer {
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1607843137);
  width: 100%;
  background-color: white;
  min-height: 69px;
  padding: 16px;
  max-width: 1330px;
  min-width: 725px;
}
.fixedReportMenu .downloadReportMenuContainer .downloadReportLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.fixedReportMenu .downloadReportMenuContainer .downloadReportLeftSide .generateReportButton {
  margin-right: 20px;
}
.fixedReportMenu .downloadReportMenuContainer .downloadReportLeftSide .saveButton {
  margin-right: 20px;
}
.fixedReportMenu .exitButtonImg {
  cursor: pointer;
}

.savePopUpContainer {
  max-width: 1250px;
  width: 100%;
  min-width: 725px;
  position: relative;
  display: flex;
}

.textForReportWithDifferentResultsContainer {
  margin-right: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 644px;
  min-height: 57px;
  background-color: #331a5b;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  position: fixed;
  z-index: 600;
  bottom: 30px;
}
@media screen and (max-width: 880px) {
  .textForReportWithDifferentResultsContainer {
    min-width: 300px;
    width: 80%;
    margin-right: 60px;
    margin-left: 90px;
  }
}
.textForReportWithDifferentResultsContainer .textForReportWithDifferentResults {
  max-width: 582px;
  color: white;
}
.textForReportWithDifferentResultsContainer .textForReportWithDifferentResults.grayText {
  opacity: 0.46;
}
@media screen and (max-width: 880px) {
  .textForReportWithDifferentResultsContainer .textForReportWithDifferentResults {
    min-width: 300px;
    width: 80%;
    font-size: 2vw;
  }
}
.textForReportWithDifferentResultsContainer .textForReportWithDifferentResultsButtonContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textForReportWithDifferentResultsContainer .textForReportWithDifferentResultsButtonContainer.noOpacity {
  opacity: 1;
  background-color: transparent;
}

.emptyCaseMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: -25px;
}
.emptyCaseMessageContainer .emptyCaseMessageTitle {
  font-size: 16px;
  font-weight: 600;
  margin-left: 25px;
  color: #331a5b;
}
.emptyCaseMessageContainer .emptyCaseMessageSubTitle {
  margin-left: 25px;
}
.emptyCaseMessageContainer .emptyCaseArrow {
  height: 58px;
}

.userThumbnailObjectContainer {
  margin-left: 3px;
}
.userThumbnailObjectContainer:first-of-type {
  margin-left: 0;
}
@media screen and (max-width: 850px) {
  .userThumbnailObjectContainer.inMenu {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .userThumbnailObjectContainer.inMenu2 {
    display: none;
  }
}
.userThumbnailObjectContainer.active:last-of-type {
  margin-right: 4px;
}
.userThumbnailObjectContainer.wrapped {
  margin-bottom: 4px;
}
.userThumbnailObjectContainer.wrapped:first-of-type {
  margin-top: 4px;
}
.userThumbnailObjectContainer .userThumbnailContainer {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.userThumbnailObjectContainer .userThumbnailContainer .userThumbnailText {
  cursor: pointer;
  font-size: 13px;
}
.userThumbnailObjectContainer .userThumbnailContainer.active {
  border: 1px solid #47743d;
  box-shadow: 0px 0px 6px 1px #47743d;
}

.addUserButton {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 3px;
}
@media screen and (max-width: 700px) {
  .addUserButton.inMenu2 {
    display: none;
  }
}
.addUserButton .addUserButtonImg {
  cursor: pointer;
}

.userThumbnailHoverContainermyScenarios {
  position: absolute;
  top: 50px;
  background-color: white;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 700px) {
  .userThumbnailHoverContainermyScenarios {
    right: 40px;
  }
}

.myCasesBox {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 108px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1540px;
}
@media screen and (max-width: 525px) {
  .myCasesBox .caseDetailsContainer-title-lastEdited {
    margin-right: -17px !important;
  }
  .myCasesBox .caseDetailsContainer-LastEdited-Date {
    width: 70px !important;
  }
}
@media screen and (max-width: 650px) {
  .myCasesBox .caseDetailsContainer-title-actions,
  .myCasesBox .caseDetailsContainer-actions {
    display: none !important;
  }
  .myCasesBox .caseDetailsContainer-status,
  .myCasesBox .caseDetailsContainer-title-status {
    margin-right: 20px;
  }
  .myCasesBox .caseDetailsContainer-LastEdited-Date,
  .myCasesBox .caseDetailsContainer-title-lastEdited {
    margin-right: 20px;
  }
}
@media screen and (max-width: 890px) {
  .myCasesBox .caseDetailsContainer-title-date-created,
  .myCasesBox .caseDetailsContainer-Creation-Date {
    display: none !important;
  }
}
@media screen and (max-width: 1270px) {
  .myCasesBox .caseTitle {
    width: 20vw !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .myCasesBox .caseTitle.userlist {
    width: 37vw !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .myCasesBox .caseTitle .caseTitleText {
    word-wrap: break-word;
    white-space: normal !important;
    white-space: normal;
  }
  .myCasesBox .hide {
    visibility: hidden;
  }
  .myCasesBox .caseTitleContainer-wrap {
    width: 20vw !important;
    padding: 5px 0px;
    z-index: 2px;
  }
  .myCasesBox .caseTitleContainer-wrap .caseTitleContainer .renameCaseInput {
    width: 20vw !important;
  }
  .myCasesBox .caseTitleContainer-wrap .caseTitleContainer .saveCaseButton {
    z-index: 2;
  }
  .myCasesBox .myCasesList-titles-left {
    width: 20vw !important;
  }
  .myCasesBox .myCasesList-titles-left.userlist {
    width: 37vw !important;
  }
}
@media screen and (max-width: 970px) {
  .myCasesBox .myCasesBox-content-container {
    padding: 0 20px !important;
  }
}
.myCasesBox .movingShadow, .myCasesBox .movingShadow3, .myCasesBox .movingShadow2, .myCasesBox .movingShadow1 {
  position: absolute;
  height: 100%;
  left: 0px;
  width: 120px;
  background-color: gray;
  opacity: 0.05;
  box-shadow: 0px 0px 60px gray;
  filter: blur(4px);
  animation: moveShadow 2s infinite;
}
.myCasesBox .noscroll {
  overflow-y: hidden !important;
}
@keyframes moveShadow {
  0% {
    left: 0px;
  }
  100% {
    left: 1184px;
  }
}
.myCasesBox .movingShadow2 {
  margin-left: 70px;
}
.myCasesBox.for-settings {
  margin-top: 30px;
}
.myCasesBox .myCasesBox-content-container {
  max-width: 1540px;
  min-width: 1230px;
  margin: 0px auto 0px auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px;
  height: calc(100% - 100px);
}
@media screen and (max-width: 1270px) {
  .myCasesBox .myCasesBox-content-container {
    min-width: 330px;
  }
}
.myCasesBox .myCasesBox-content-container.for-users {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media screen and (max-width: 1330px) {
  .myCasesBox .myCasesBox-content-container.for-users {
    min-width: 330px;
  }
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar {
  margin: 0 23px 0 11px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .searchBarContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 305px;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .searchBarContainer .searchBarIcon {
  position: absolute;
  height: 19px;
  left: 8px;
  top: 15px;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .searchBarContainer .searchBarInput {
  width: 305px;
  border: 0;
  background-color: #edeaf5;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  height: 18px;
  font-size: 16px;
  padding-left: 43px;
  height: 50px;
  color: #201a2d;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .searchBarContainer .searchBarInput:focus {
  outline: 2px solid #462777;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer {
  height: 50px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffdc94;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer:hover {
  background-color: #ffd68b;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithImgContainer {
  cursor: pointer;
  margin-left: 6px;
}
@media screen and (max-width: 660px) {
  .myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithImgContainer.inMenu3 {
    margin-right: 6px;
  }
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithImgContainer .shaerdWithImg {
  cursor: pointer;
  margin-top: 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithTextContainer {
  cursor: pointer;
  margin-left: 6px;
}
@media screen and (max-width: 660px) {
  .myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithTextContainer.inMenu3 {
    display: none;
  }
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithTextContainer .sharedWithText {
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
}
.myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithThumbnailsContainer {
  cursor: pointer;
  margin-left: 23px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 660px) {
  .myCasesBox .myCasesBox-content-container .myCasesBox-searchBar .sharedWithContainer .sharedWithThumbnailsContainer.inMenu3 {
    display: none;
  }
}
.myCasesBox .myCasesBox-content-container .caseDetailsContainer-small {
  width: 123px;
}
.myCasesBox .myCasesBox-content-container .caseDetailsContainer-shareCase .caseDetail {
  max-width: 160px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.myCasesBox .myCasesBox-content-container .caseDetailsContainer-large {
  width: 135px;
}
.myCasesBox .myCasesBox-content-container .caseDetailsContainer {
  word-break: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles {
  display: flex;
  justify-content: flex-start;
  margin-top: 29px;
  margin-left: 10px;
  max-width: 1409px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-left {
  display: flex;
  width: 490px;
  font-size: 18px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-left h5 {
  width: 490px;
  position: relative;
  margin-left: 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-left-userlist {
  display: flex;
  width: 490px;
  font-size: 18px;
  width: 309px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-left-userlist h5 {
  width: 490px;
  position: relative;
  margin-left: 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  box-sizing: border-box;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-right .caseDetailsContainer-title-owner {
  text-align: center;
  cursor: default !important;
  margin-left: 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-right .caseDetailsContainer-title {
  text-align: center;
  position: relative;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-right .caseDetailsContainer-title-status {
  text-align: center;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-right .caseDetailsContainer-title-actions {
  text-align: center;
  margin-right: 20px;
}
.myCasesBox .myCasesBox-content-container .myCasesList-titles .myCasesList-titles-right .caseDetailsContainer-title-user-actions {
  text-align: center;
  margin-right: 20px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer {
  margin-top: 7px;
  max-height: calc(90% - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  /* width */
  /* Track */
  /* Handle */
}
@media screen and (max-height: 600px) {
  .myCasesBox .myCasesBox-content-container .myCasesListContainer {
    max-height: 160px;
  }
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer.for-users {
  flex-grow: 1;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .loadingScenarios {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: center;
  height: 150px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .loadingScenarios p {
  margin: auto;
  text-align: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  margin-right: 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white;
  max-width: 1406px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  min-height: 56px;
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  margin: 15px 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer:hover {
  background-color: #edeaf5;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer:first-of-type {
  margin-top: 0px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseLeftPart {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: auto;
  min-height: 40px;
  margin-left: 10px;
  width: 490px;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle.userlist,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList.userlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle.nonActive,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList.nonActive {
  cursor: default !important;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle .caseTitleText,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList .caseTitleText {
  cursor: pointer;
  -webkit-text-decoration: transparent;
          text-decoration: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 3px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle .caseTitleText.nonActive,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList .caseTitleText.nonActive {
  cursor: default !important;
  text-decoration: none !important;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle .caseTitleText:hover,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList .caseTitleText:hover {
  text-decoration: underline;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitle .caseTitleEmail,
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleUserList .caseTitleEmail {
  cursor: pointer;
  font-size: 12px;
  color: #462777;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleEmail {
  cursor: pointer;
  font-size: 12px;
  color: #462777;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleContainer-wrap {
  width: 490px;
  margin-right: 10px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleContainer-wrap .caseTitleContainer {
  padding-left: 10px;
  width: calc(490px + 50px);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleContainer-wrap .caseTitleContainer input {
  font-size: 13px;
  width: 260px;
  height: 26px;
  border: 1px solid #201a2d;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 5px;
  background-color: white;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleContainer-wrap .caseTitleContainer input:focus {
  outline: 2px solid #462777;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseTitleContainer-wrap .caseTitleContainer .cancelRename {
  position: relative;
  align-self: center;
  transform: rotate(45deg);
  height: 9px;
  margin-left: 5px;
  cursor: pointer;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseDetailsContainer {
  text-align: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseDetailsContainer .scenarioDetail {
  font-size: 13px;
  width: 75px;
  margin: auto;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseDetailsContainer .caseLabel {
  font-size: 10px;
  color: #201a2d;
  margin: auto;
  margin-bottom: 2px;
  margin-top: 2px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseDetailsContainer .caseDetail {
  font-size: 14px;
  margin-bottom: 2px;
  margin-top: 2px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseDetailsContainer .caseDetail .addUserImg {
  margin-top: 10px;
  cursor: pointer;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseDetailsContainer .caseDetailTime {
  color: #462777;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer.freemium {
  margin-right: 0px;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer {
  position: relative;
  display: flex;
  width: 102px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer.only-one {
  justify-content: center;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer img {
  padding-left: 3px;
  height: 14px;
  cursor: pointer;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer .caseMenuDropDown {
  position: absolute;
  right: 7%;
  border: thin solid;
  border-radius: 8px;
  margin-top: 7px;
  height: 72px;
  text-align: center;
  background-color: white;
  z-index: 20;
  width: 80px;
  text-align: right;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer .caseMenuDropDown p {
  z-index: 21;
  font-size: 12px;
  padding-right: 3px;
  margin: 7px auto;
  cursor: pointer;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer .deleteMessage {
  position: absolute;
  right: 49px;
  transform: translate(-100%, 0);
  z-index: 1;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer .deleteMessage-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
}
.myCasesBox .myCasesBox-content-container .myCasesListContainer .caseContainer .caseMenuContainer .caseMenuSubContainer .copyMessage {
  position: "absolute";
  top: "-10px";
  left: "50px";
  background-color: "white";
  padding: "5px";
  box-shadow: "0px 3px 6px #00000029";
  border-radius: "5px";
  display: "flex";
  align-items: center;
  justify-content: center;
}

.addNewCaseContainer, .addNewCaseContainerEmpty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin: 10px;
}
.addNewCaseContainer.onlyContainer, .onlyContainer.addNewCaseContainerEmpty {
  justify-content: end;
}
.addNewCaseContainer .input, .addNewCaseContainerEmpty .input {
  margin-top: 7px;
}
.addNewCaseContainer .actionsContainer, .addNewCaseContainerEmpty .actionsContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}
.addNewCaseContainer .descriptionTextContainer, .addNewCaseContainerEmpty .descriptionTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.addNewCaseContainer .descriptionTextContainer p, .addNewCaseContainerEmpty .descriptionTextContainer p {
  font-size: 14px;
  color: #321b59;
}
.addNewCaseContainer .createCaseContainer, .addNewCaseContainerEmpty .createCaseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.newCaseInputContainer {
  margin-right: 30px;
  margin-top: -12px;
}

.addNewCaseContainerEmpty {
  position: relative;
  bottom: 0;
  align-self: start;
}

.cancelAddCase {
  position: relative;
  align-self: center;
  transform: rotate(45deg);
  height: 9px;
  margin-left: 5px;
  cursor: pointer;
}

.saveCaseButton {
  margin-left: 10px;
}

.sortImgContainer {
  position: absolute;
  top: -11px;
  padding: 4px 7px;
}
.sortImgContainer text {
  font-family: "poppins-local", sans-serif, verdana;
}

@media screen and (max-width: 1390px) {
  .caseDetailsContainer-hide1 {
    display: none !important;
  }
}
@media screen and (max-width: 1190px) {
  .caseDetailsContainer-hide2 {
    display: none !important;
  }
}
.tabsContainer {
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: calc(1284px + 100px);
  min-width: 725px;
}
.tabsContainer.tutorialsTabsContainer {
  width: calc(1284px + 200px);
  justify-content: flex-start;
}
.tabsContainer.settingsTabsContainer {
  justify-content: flex-start;
}
@media screen and (max-width: 1500px) {
  .tabsContainer {
    width: 90vw;
  }
  .tabsContainer.tutorialsTabsContainer {
    width: calc(1284px + 200px);
    position: absolute;
    left: 70px;
  }
}
@media screen and (max-width: 1590px) {
  .tabsContainer.tutorialsTabsContainer {
    width: calc(1284px + 200px);
    position: absolute;
    left: 70px;
  }
}
@media screen and (max-width: 836px) {
  .tabsContainer {
    min-width: 704px;
    width: 704px;
    margin-left: 10px;
  }
}
.tabsContainer .singleTabContainer {
  position: relative;
  cursor: pointer;
}
.tabsContainer .singleTabContainer.singleTutorialTabContainer {
  margin-left: 40px;
}
.tabsContainer .singleTabContainer.singleTutorialTabContainer:first-of-type {
  margin-left: 0;
}
.tabsContainer .singleTabContainer .tabName {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
  font-size: 23px;
  color: #8e8e8e;
  font-weight: 600;
  display: flex;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .tabsContainer .singleTabContainer .tabName {
    font-size: 20px !important;
  }
  .tabsContainer .singleTabContainer .tabName.tutorialsTabName {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1050px) {
  .tabsContainer .singleTabContainer .tabName {
    font-size: 18px !important;
  }
  .tabsContainer .singleTabContainer .tabName.tutorialsTabName {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 950px) {
  .tabsContainer .singleTabContainer .tabName {
    font-size: 16px !important;
  }
  .tabsContainer .singleTabContainer .tabName.tutorialsTabName {
    font-size: 23px !important;
  }
}
.tabsContainer .singleTabContainer .tabName .errors {
  top: -11px !important;
  left: -15px !important;
}
.tabsContainer .singleTabContainer .tabNameActive {
  color: #331a5b;
  font-weight: 600;
}
.tabsContainer .singleTabContainer .tabUnderline {
  width: 100%;
  height: 3.5px;
  background-color: transparent;
  cursor: pointer;
}
.tabsContainer .singleTabContainer .tabUnderlineActive {
  background-color: #331a5b;
}
.tabsContainer .singleTabContainer .analyseCaseButton {
  height: 44px;
  background-color: #331a5b;
  padding: 1px 30px 1px 30px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (max-width: 1050px) {
  .tabsContainer .singleTabContainer .analyseCaseButton {
    height: 40px !important;
  }
}
@media screen and (max-width: 950px) {
  .tabsContainer .singleTabContainer .analyseCaseButton {
    padding: 1px 15px 1px 15px !important;
  }
}
.tabsContainer .singleTabContainer .analyseCaseButton:hover {
  background-color: #462777;
  cursor: pointer;
}
.tabsContainer .singleTabContainer .analyseCaseButton:disabled {
  background-color: #d9d9d9 !important;
  cursor: default;
  border: none;
}
.tabsContainer .singleTabContainer .analyseCaseButton .analyseCaseButtonText {
  cursor: pointer;
  color: white;
  font-size: 23px;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .tabsContainer .singleTabContainer .analyseCaseButton .analyseCaseButtonText {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1050px) {
  .tabsContainer .singleTabContainer .analyseCaseButton .analyseCaseButtonText {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 950px) {
  .tabsContainer .singleTabContainer .analyseCaseButton .analyseCaseButtonText {
    font-size: 16px !important;
  }
}
.tabsContainer .singleTabContainer .analyseCaseButton .analyseCaseButtonText.disabled {
  cursor: default;
}
.tabsContainer .singleTabContainer .failedCaseButton {
  background-color: #ffdddd !important;
  border: none;
}
.tabsContainer .singleTabContainer .failedCaseButton:hover {
  background-color: #ffdddd !important;
  cursor: pointer;
}
.tabsContainer .singleTabContainer .failedCaseButton .failedCaseButtonText {
  color: #201a2d;
}
.tabsContainer .inactive {
  cursor: default !important;
}

.sharable-error, .reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .errors,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .errors, .reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .errors,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .errors, .reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .errorsList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .errorsList, .reportSectionContainer .listPreviewMainContainer .errorsList,
.disclaimerContainer .listPreviewMainContainer .errorsList, .singleClaimPreviewContainer .singlClaimUpperPartTree .errors, .singleClaimPreviewContainer .singlClaimUpperPartClaim .errors, .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .errorsList, .tabsContainer .singleTabContainer .tabName .errors {
  border: solid 1px #ac2828;
  color: #ac2828;
  background-color: white;
  position: absolute;
  top: -8px;
  left: -10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 20px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sharable-error p, .reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .errors p,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .errors p, .reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .errors p,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .errors p, .reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .errorsList p,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .errorsList p, .reportSectionContainer .listPreviewMainContainer .errorsList p,
.disclaimerContainer .listPreviewMainContainer .errorsList p, .singleClaimPreviewContainer .singlClaimUpperPartTree .errors p, .singleClaimPreviewContainer .singlClaimUpperPartClaim .errors p, .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .errorsList p, .tabsContainer .singleTabContainer .tabName .errors p {
  text-align: center;
}

.tool-component {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 55px #cbcbcb;
  float: left;
  width: 100%;
  max-width: 1284px;
  min-width: 725px;
  box-sizing: border-box;
  padding-bottom: 37px;
  margin-top: 55px;
}
.tool-component .firstProceedingsImg {
  width: 24px;
  position: relative;
  margin-left: 10px;
}
.tool-component .secondProceedingsImg {
  margin-left: 10px;
  width: 55px;
}
.tool-component .downloadImageComponentContainer {
  position: absolute;
  right: 0px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tool-component .mnmMessageContainer {
  position: absolute;
  color: #ac2828;
  left: 500px;
  top: 20px;
  font-size: 20px;
}
.tool-component .asteriskMessageContainer {
  margin-left: 47px;
  margin-top: 10px;
  color: #8b8b8b;
}
.tool-component .asteriskMessageContainer.newExecutiveSummary {
  margin-top: 0px;
  padding-left: 0px;
  color: #201a2d;
}
.tool-component .asteriskMessageContainer.newExecutiveSummary .robotoNumbers {
  color: #201a2d;
}
.tool-component .asteriskMessageContainer.previewPDF {
  margin-left: 110px;
}
.tool-component .asteriskMessageContainer span {
  color: #8b8b8b;
}
.tool-component .asteriskMessageContainer .asteriskMessageContainer-learnMoreSpan {
  color: #ff7336;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
}
.tool-component .asteriskMessageContainer .asteriskMessageContainer-learnMoreSpan:hover {
  font-weight: 600;
}
.tool-component #settlement-asteriskMessageContainer {
  margin-top: 30px;
}
.tool-component #graph-asteriskMessageContainer {
  margin-top: 30px;
}
.tool-component #graph-asteriskMessageContainer.distribution {
  margin-top: 150px;
}
.tool-component#eReportComp {
  margin-top: 20px;
}
@media screen and (max-width: 1500px) {
  .tool-component {
    width: 95% !important;
  }
}
@media screen and (max-height: 900px) {
  .tool-component:last-of-type {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 836px) {
  .tool-component {
    max-width: 725px !important;
  }
}
.tool-component.for-settings {
  margin-bottom: 200px;
}
.tool-component.for-users {
  height: calc(90vh - 150px);
  min-height: 440px;
}
.tool-component #graphTitle-expectedValueBreakdown,
.tool-component #executiveSummary-title {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.tool-component .tool-component-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  margin-left: 19px;
  margin-bottom: 0px;
  padding: 0px;
  font-size: 26px;
  font-family: "poppins-local-bold";
  white-space: nowrap;
  margin-right: 50px;
  background-color: transparent;
}
.tool-component .tool-component-title.graphTitle {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.tool-component .tool-component-title img {
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 3px;
}
.tool-component .tool-component-title .claimTitle {
  font-size: 26px;
  font-weight: 600;
}
.tool-component .tool-component-title .titleClaimName {
  font-size: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: -5px;
}
.tool-component .tool-component-second-title {
  font-size: 20px;
  margin-top: 37px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: calc(600 - 100);
}
.tool-component .tool-component-second-title.for-settings {
  font-weight: calc(600 + 100);
}
.tool-component .tool-component-third-title {
  margin-top: 37px;
}
.tool-component .tool-component-spacer {
  visibility: hidden;
}
.tool-component .tool-component-subtitle {
  margin-top: 22px;
  font-size: 16px;
  margin-right: 20px;
  max-width: 830px;
}
.tool-component .formatDropdownOuterContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.tool-component .formatDropdownOuterContainer .formatDropdownSaveButtonContainer {
  margin-top: 43px;
}
.tool-component .tool-component-input-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.tool-component .tool-component-input-container img {
  margin-right: 30px;
}
.tool-component .tool-component-input-container.first-container {
  margin-top: 43px;
}
.tool-component .tool-component-input-container .claimTitle {
  margin-bottom: -38px;
}
.tool-component .tool-component-add-button-container {
  position: relative;
  margin-top: 48px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.tool-component .tool-component-add-button-container.subtitleTickboxContainer {
  margin-top: 15px;
}
.tool-component .tool-component-add-button-container .addSignContainer {
  position: relative;
  cursor: pointer;
}
.tool-component .tool-component-add-button-container .addSignContainer img {
  width: 21px;
  margin-right: 12px;
  cursor: pointer;
}
.tool-component .tool-component-add-button-container p {
  font-size: 16px;
  cursor: pointer;
}
.tool-component .tool-component-add-button-container p.disabled {
  cursor: default;
}
.tool-component .tool-component-add-button-container .tickImg {
  width: 36px;
  margin-right: 12px;
  cursor: pointer;
}
.tool-component .tool-component-add-button-container .tickImg.disabled {
  cursor: default;
}
.tool-component .opposingPartySymbolImg {
  margin-left: 8px;
  margin-top: -1px;
}
.tool-component .tool-component-submenu-container {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  padding-bottom: 35px;
  margin-top: 38px;
  margin-left: 78px;
  margin-right: 20px;
}
.tool-component .tool-component-submenu-container .tool-component-remove-button-container {
  margin-left: 17px;
  margin-top: 17px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.tool-component .tool-component-submenu-container .tool-component-remove-button-container .addSignContainer {
  position: relative;
  cursor: pointer;
}
.tool-component .tool-component-submenu-container .tool-component-remove-button-container .addSignContainer img {
  width: 21px;
  margin-right: 12px;
  cursor: pointer;
}
.tool-component .tool-component-submenu-container .tool-component-remove-button-container p {
  font-size: 16px;
  cursor: pointer;
}
.tool-component .tool-component-submenu-container .tool-component-submenu-input-container {
  margin-left: 22px;
  margin-top: 35px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.tool-component .tool-component-submenu-container .tool-component-submenu-input-container:first-of-type {
  margin-top: 47px;
}
.tool-component .tool-component-submenu-container .tool-component-submenu-input-container img {
  margin-right: 30px;
}
.tool-component .tool-component-submenu-container .secondProbContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 130px;
  left: 361.5px;
}
.tool-component .tool-component-submenu-container .secondProbContainer .tool-component-submenu-text {
  margin-top: 19px;
  margin-left: 4px;
  margin-bottom: 13px;
  margin-right: 13px;
}
@media screen and (max-width: 900px) {
  .tool-component .tool-component-submenu-container .secondProbContainer .tool-component-submenu-text {
    font-size: 15px;
  }
}
.tool-component .tool-component-submenu-container .secondProbContainer .tool-component-submenu-text span {
  font-weight: 600;
}
.tool-component .tool-component-switch-container {
  margin-top: 50px;
}
.tool-component .addSecondTrialContainer {
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.tool-component .addSecondTrialContainer p {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}
.tool-component h6 {
  position: absolute;
}
.tool-component .errorMsg3 {
  position: absolute;
  width: 320px;
  top: -20px;
  color: #eb3030;
}
.tool-component .errorMessagesContainer {
  height: 20px;
  position: relative;
}
.tool-component .errorMessagesContainer .firstError {
  left: 35px;
}
.tool-component .errorMessagesContainer .secondErrror {
  left: 295px;
}

.results-tool-component {
  margin-top: 40px;
}
@media screen and (max-width: 1300px) {
  .results-tool-component {
    width: 90% !important;
    margin-left: 42px;
  }
}
.results-tool-component .graphSubtitle {
  margin-left: 18px;
  color: #8b8b8b;
  margin-right: 40px;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.results-tool-component .graphSubtitle span {
  color: #8b8b8b;
}
.results-tool-component .oldNewSwitch {
  margin-left: 3px;
  cursor: pointer;
}
.results-tool-component .oldNewSwitch .oldNewSwitchText {
  cursor: pointer;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  color: #ff7336;
  text-decoration: underline;
  font-size: 16px;
}

.results-tool-component-first {
  margin-top: 20px;
}

.marginLeft1 {
  margin-left: 50px;
}

.marginLeft2 {
  margin-left: 95px;
}

.marginLeft3 {
  margin-left: 100px;
}

.marginLeft4 {
  margin-left: 73px;
  margin-bottom: -26px;
}

.marginLeft5 {
  margin-left: 73px;
  margin-bottom: -26px;
}

.marginLeft6 {
  margin-left: 85px;
}

.marginLeft7 {
  margin-left: 100px;
  margin-bottom: -26px;
  margin-top: 15px !important;
}

.secondTrialOptionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  padding-bottom: 10px;
  width: 92%;
  margin-left: 40px;
}
.secondTrialOptionContainer .dropdown,
.secondTrialOptionContainer .inputNum,
.secondTrialOptionContainer .inputdate,
.secondTrialOptionContainer .inputNum-RO {
  margin-left: 15px !important;
}
.secondTrialOptionContainer .secTrialContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 18px;
  flex-wrap: wrap;
  margin-bottom: 12px;
  margin-right: 12px;
}
.secondTrialOptionContainer .secTrialObj {
  position: relative;
  width: 240px;
  margin-right: 30px;
}
.secondTrialOptionContainer .deleteContainer {
  position: absolute;
  right: 20px;
  top: 3px;
  cursor: pointer;
}
.secondTrialOptionContainer .deleteContainer img {
  height: 17px;
}

.probSecondTrialstep1 .input-label-text-p {
  letter-spacing: 0em;
}

#courtDetailsComp {
  margin-bottom: 200px;
}

.customPartyFormatInput {
  margin-left: 80px !important;
  margin-top: -28px !important;
}

.addClaimContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  min-width: 255px;
  z-index: -1;
}
.addClaimContainer .addClaimsMenuButtonContainer {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 5px;
  border: 1px solid #201a2d;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addClaimContainer .addClaimsMenuButtonContainer:hover {
  background-color: #edeaf5;
}
.addClaimContainer .addClaimsMenuButtonContainer .addClaimButtonImg {
  width: 29px;
  height: 29px;
}
.addClaimContainer .addClaimMenu1Container,
.addClaimContainer .addClaimMenu2Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.addClaimContainer .addClaimMenu1Container .addClaimButtonContainer img,
.addClaimContainer .addClaimMenu2Container .addClaimButtonContainer img {
  margin-bottom: 10px !important;
  margin-left: 10px;
}
.addClaimContainer .addClaimMenu1Container button:first-child,
.addClaimContainer .addClaimMenu2Container button:first-child {
  margin-bottom: 10px;
}

#claimsComp {
  padding: 35px 30px;
}
@media screen and (max-width: 1170px) {
  #claimsComp {
    padding: 35px 10px;
  }
}

.addClaimText {
  margin-left: 25px;
}
@media screen and (max-width: 1170px) {
  .addClaimText {
    margin-left: 45px;
  }
}

.claims {
  margin-top: 0px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 130px;
}
.claims .addClaimButton {
  position: relative;
}
.claims .mainButton {
  white-space: nowrap;
}
@media screen and (max-width: 1170px) {
  .claims .treeButtonContainer {
    margin-left: 20px !important;
  }
}
.claims .claimOrCounterclaimContainer {
  width: 400px;
  margin: 0px 10px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.claims .claimOrCounterclaimContainer .cancelAddClaim {
  position: relative;
  align-self: center;
  height: 17px;
  margin-left: 5px;
  cursor: pointer;
}
@media screen and (min-width: 1040px) and (max-width: 1070px) {
  .claims .claimOrCounterclaimContainer .cancelAddClaim {
    margin-left: -8px !important;
  }
}
.claims .claimOrCounterclaimContainer button {
  margin: 0 20px 0 0;
  white-space: nowrap;
}
.claims .treeMenuContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  padding: 20px;
  background-color: white;
  width: 350px;
  right: 10px;
  top: -25px;
  z-index: 16;
}
.claims .treeMenuContainer .inputText {
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.claims .treeMenuContainer .treeMenuButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 330px;
}
.claims .treeMenuContainer .treeMenuButtonsContainer .createButton {
  white-space: nowrap;
}

.emptyClaimsPreviewContainer {
  max-width: 1284px;
  width: 100%;
}
.emptyClaimsPreviewContainer .emptyClaimContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -25px;
  margin-left: 20px;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 47px;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .videoInfoButtonClaimsContainer {
  margin-left: 10px;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .emptyClaimsTitle {
  color: #201a2d;
  font-size: 32px;
  font-weight: 600;
  margin-right: 4px;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .emptyClaimsTitle.error {
  color: #ac2828;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice .emptyClaimsInfoBulletList {
  padding-left: 25px;
  font-size: 16px;
  color: #201a2d;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice .emptyClaimsButtonContainer img {
  margin-left: 10px;
}
.emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice:first-child {
  margin-right: 170px;
}

.pasteFromClipboardContainer {
  position: relative;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.pasteFromClipboardContainer:hover {
  background-color: #e1d6f4;
}
.pasteFromClipboardContainer .pasteFromClipboardImg {
  cursor: pointer;
}
.pasteFromClipboardContainer .nothingToPasteTextContainer {
  position: absolute;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  left: 40px;
  top: 10px;
  width: 200px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.claimsPreviewMain {
  margin-top: 53px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.claimsPreviewMain.noClaims {
  margin-top: 150px;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions.on-list {
  margin-left: -7px;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer {
  padding: 2px 2px 2px 2px;
  margin-bottom: 25px;
  margin-right: 8px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer.selected {
  background-color: #edeaf5;
  border: 1px solid #321b59;
  border-radius: 3px;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer img {
  width: 28px;
  height: 28px;
}
.claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewInterestOptionsContainer {
  margin-right: 15px;
}
.claimsPreviewMain .claimsPreviewContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(1316px);
  min-width: calc(1316px);
  min-height: 111px;
  z-index: 10;
}
@media screen and (max-width: 1500px) {
  .claimsPreviewMain .claimsPreviewContainer {
    max-width: 90vw;
    min-width: 90vw;
    margin-left: 20px;
  }
}
@media screen and (max-width: 836px) {
  .claimsPreviewMain .claimsPreviewContainer {
    max-width: 725px;
    min-width: 725px;
  }
}
.claimsPreviewMain .claimsPreviewContainerList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1284px;
  min-width: 1284px;
  margin-right: 16px;
  z-index: 10;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px 5px 5px 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer.expanded {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer.hovered {
  border-radius: 5px 5px 0px 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listTreeAddedContainer {
  width: calc(43% - 7px);
  display: flex;
  align-items: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNumber {
  font-size: 13px;
  color: #bfbfbf;
  width: 5px;
  margin-left: -10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer {
  display: flex;
  width: 43%;
  height: 29px;
  background-color: #d0d3e8;
  padding: 3px 9px 3px 9px;
  border-radius: 5px 0px 0px 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.tree {
  border-radius: 5px 5px 5px 5px;
  width: 100%;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.expanded {
  height: 75px;
  align-items: center;
  width: 25%;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.treeExpanded {
  height: 37px;
  align-items: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.consequence-on {
  height: 95px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName {
  font-size: 14px;
  color: white;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName.expanded {
  max-lines: 2;
  white-space: normal;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName .titleImg {
  width: 17px;
  margin-right: 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.selected {
  background-color: #321b59;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.selected.krogerus {
  background-color: #000000;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listTreeContectLine {
  width: 14px;
  height: 2px;
  background-color: #201a2d;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer {
  justify-self: flex-end;
  display: flex;
  width: 57%;
  height: 27px;
  border-radius: 0px 5px 5px 0px;
  flex-direction: column;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.tree {
  border-radius: 5px 5px 5px 5px;
  width: calc(57% - 7px);
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.expanded {
  height: 75px;
  width: 75%;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.treeExpanded {
  height: 37px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.consequence-on {
  height: 95px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.isHovered {
  border-radius: 0px 5px 0px 0px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: space-around;
  padding-left: 40px;
  background-color: white;
  border-radius: 0px 5px 5px 0px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.isHovered {
  border-radius: 0px 5px 0px 0px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .calculateButton {
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.tree {
  padding-left: 33px;
  border-radius: 5px 5px 5px 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.noTitle {
  background-color: #f7f7f7;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.small {
  flex: 0.8;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  max-width: 160px;
  min-width: 100px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.center {
  justify-content: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.start {
  justify-content: flex-start;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue {
  font-size: 16px;
  color: #201a2d;
  padding-top: 4px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client {
  color: #5a45c9;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client.krogerus {
  color: #000000;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing {
  color: #ff703e !important;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing.krogerus {
  color: #ffcb2b !important;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.noValue {
  color: #201a2d;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.date {
  color: #a1a6c7;
  margin-left: 10px;
  font-size: 15px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.small {
  font-size: 14px;
  color: #8e8e8e;
  padding-top: 0px;
  margin-top: -5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.consequence {
  margin-top: -12px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty {
  font-size: 16px;
  color: #201a2d;
  padding-top: 4px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.opposing {
  color: #ac2828 !important;
  margin-left: 0px !important;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.date {
  color: #a1a6c7;
  margin-left: 10px;
  font-size: 15px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .greyArea {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  opacity: 45%;
  width: 100%;
  height: 100%;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList {
  position: absolute;
  left: -10px;
  cursor: pointer;
  background-color: #462777;
  z-index: -2;
  width: calc(100% + 20px);
  height: 29px;
  border-radius: 5px;
  opacity: 0.7;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.top {
  top: -10px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.bottom {
  bottom: -10px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.expanded {
  height: 75px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList {
  z-index: 3;
  width: 182px;
  height: 123px;
  /* UI Properties */
  background-color: white;
  border: 1px solid #331a5b;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 8px 10px 10px;
  cursor: default;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .copyingClaimMessageContainerList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  margin-bottom: 3px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList:hover {
  font-weight: 600;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList {
  margin-left: 5px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList.red {
  color: #ac2828;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList:hover {
  font-weight: 600;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowImgList {
  width: 13px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleList {
  position: absolute;
  top: -3px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: 0;
  width: 34px;
  height: 35px;
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileTransparentCoverList {
  position: absolute;
  top: -10px;
  left: -34px;
  cursor: pointer;
  z-index: 0;
  width: 34px;
  height: 35px;
  border-radius: 5px 5px 5px 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleBackgroundList {
  position: absolute;
  top: -3px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: -1;
  width: 37px;
  height: 35px;
  border-radius: 5px 5px 5px 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleinvisibleLayerList {
  position: absolute;
  top: -10px;
  left: -42px;
  cursor: default;
  z-index: 0;
  width: 45px;
  height: 120px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsButtonContainerList {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #331a5b;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg {
  top: 27px;
  right: 0;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 4px 4px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg.expanded {
  top: 73px;
  background-color: #f7f7f7;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg.consequence {
  top: 93px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg .expandImgText {
  cursor: pointer;
  margin-left: 5px;
  color: #331a5b;
  font-size: 13px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg img {
  transform: rotate(180deg);
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg img.collapse {
  transform: rotate(0deg);
}
@media screen and (max-width: 1430px) {
  .claimsPreviewMain .claimsPreviewContainerList {
    max-width: 90vw;
    min-width: 90vw;
  }
}
@media screen and (max-width: 900px) {
  .claimsPreviewMain .claimsPreviewContainerList {
    max-width: 725px;
    min-width: 725px;
    margin-right: 0px;
  }
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv {
  width: 100%;
  height: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv {
  width: 43%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: 40px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv.forSum {
  justify-content: flex-end;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv.noSums {
  margin-right: 7px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv .listPreviewSortImg {
  padding-left: 5px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .listPreviewOtherTableNamesDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  max-width: 160px;
  position: relative;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .listPreviewOtherTableNamesDiv .listPreviewSortImg {
  position: absolute;
  right: -12px;
  padding-left: 5px;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .calculateButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName {
  font-size: 16px;
  color: #201a2d;
  text-align: end;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.forSum {
  color: #201a2d;
  font-weight: 600;
  margin-right: 5px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.noMargin {
  margin-right: 0px;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.client {
  color: #5a45c9;
  font-weight: 600;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.client.krogerus {
  color: #000000;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.opposing {
  color: #ff703e;
  font-weight: 600;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.opposing.krogerus {
  color: #ffcb2b;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.selectable {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableNameClickable {
  font-size: 16px;
  color: #201a2d;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableNameClickable.noSort {
  cursor: default;
}

.singleClaimPreviewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 255px;
  width: 312px;
  height: 123px;
  margin-right: 17px;
  margin-bottom: 30px;
}
.singleClaimPreviewContainer .partyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: #331a5b;
  margin-bottom: 1px;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  max-width: 70%;
}
.singleClaimPreviewContainer .partyContainer.krogerus {
  background-color: #000000;
}
.singleClaimPreviewContainer .partyContainer .partyContainerText {
  color: white;
  font-size: 14px;
  max-lines: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.singleClaimPreviewContainer .singlClaimUpperPartClaim {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #d0d3e8;
  border-radius: 5px 5px 0 0;
  width: 100%;
  min-height: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
  padding: 7px 10px 4px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.singleClaimPreviewContainer .singlClaimUpperPartClaim.forReport {
  border-radius: 0 5px 0 0;
}
.singleClaimPreviewContainer .singlClaimUpperPartClaim .claimTypetIdContainer {
  color: white;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 600;
  width: 78.5px;
  height: 23px;
}
.singleClaimPreviewContainer .singlClaimUpperPartClaim .verticalLine {
  height: 29px;
  width: 1px;
  background-color: white;
}
.singleClaimPreviewContainer .singlClaimUpperPartClaim .previewClaimName {
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  font-family: "poppins-local-medium";
  text-overflow: ellipsis;
  overflow: hidden;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim {
  width: 100%;
  height: 55px;
  min-height: 55px;
  border-radius: 0 0 5px 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 22.5px;
  height: 22.5px;
  width: 100%;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer {
  width: 170px;
  margin-left: 14.5px;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText {
  margin-left: 18px;
  cursor: pointer;
  color: #5a45c9;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.krogerus {
  color: #000000;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.negative {
  color: #ff703e;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.negative.krogerus {
  color: #ffcb2b;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span {
  color: #5a45c9;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.krogerus {
  color: #000000;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.negative {
  color: #ff703e;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.negative.krogerus {
  color: #ffcb2b;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .interestRateText {
  white-space: nowrap;
  margin-right: 15px;
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 22.5px;
  height: 22.5px;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow .interestDate {
  margin-right: 15px;
  color: #a1a6c7;
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow .interestDate.error {
  color: #ac2828;
}
.singleClaimPreviewContainer .pdfPreview {
  height: -moz-fit-content;
  height: fit-content;
}
.singleClaimPreviewContainer .moreInfoMenuContainer {
  position: relative;
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu {
  width: 100%;
  background-color: white;
  z-index: 16;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 5px 5px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu.moreInfoMenuPreviewPdf {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1607843137);
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListTitle {
  margin-top: 20px;
  font-family: "poppins-local-bold";
  margin-left: 14.5px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow .liabilityQuestion {
  word-wrap: normal;
  font-size: 16px;
  margin-left: 14px;
  min-width: 216px;
  max-width: 216px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow .liabilityProbability {
  width: 100%;
  font-size: 16px;
  margin-right: 15px;
  text-align: right;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  width: 100%;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow .totalLiabilityTitle {
  margin-left: 14.5px;
  font-size: 16px;
  font-weight: 600;
  min-width: 216px;
  max-width: 216px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow .totalLiabilityProbability {
  font-size: 16px;
  font-family: "poppins-local-medium";
  margin-right: 15px;
  width: 100%;
  text-align: right;
  align-self: center;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumTitle {
  margin-top: 15px;
  font-family: "poppins-local-bold";
  margin-left: 14.5px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent {
  margin-top: 5px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer {
  width: 300px;
  display: flex;
  flex-direction: row;
  margin-top: -5px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer .quantumSubtitleAmount {
  font-size: 14px;
  margin-left: 14.5px;
  font-family: "poppins-local-medium";
  min-width: 138px;
  line-height: 15px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer .quantumSubtitleExternalValue {
  margin-top: -3px;
  font-family: "poppins-local-medium";
  margin-left: 29px;
  line-height: 15px;
  height: 50px;
  text-align: center;
  font-size: 14px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 33px;
  margin: 10.5px 15px 10.5px 14.5px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .positive {
  color: #5a45c9 !important;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .positive.krogerus {
  color: #000000 !important;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .negative {
  color: #ff703e !important;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .negative.krogerus {
  color: #ffcb2b !important;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .quantumProbability {
  color: #201a2d;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer p {
  font-size: 12px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .quantumAmountAndProbabilityContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .externalConsequenceContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer {
  background-color: white;
  display: flex;
  flex-direction: row;
  z-index: 1;
  margin-top: 5px;
  margin-right: 226px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  cursor: pointer;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer.collapse {
  margin-top: -5px;
  margin-right: 215px;
  position: relative;
  z-index: 900;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonText {
  cursor: pointer;
  margin-left: 5px;
  color: #331a5b;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonOff {
  transform: rotate(180deg);
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 4px;
  margin-right: 5px;
}
.singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonOn {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 3px;
  margin-right: 5px;
}
.singleClaimPreviewContainer .active {
  background-color: #331a5b;
}
.singleClaimPreviewContainer .active.krogerus {
  background-color: #000000;
}
.singleClaimPreviewContainer .singlClaimUpperPartTree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #d0d3e8;
  border-radius: 5px;
  width: 100%;
  min-height: 60px;
  height: 60px;
  padding: 7px 10px 4px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimUpperPartTree .claimTypetIdContainer {
  color: white;
  margin-left: 9px;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 600;
  width: 78px;
  height: 100%;
  display: flex;
  align-items: center;
}
.singleClaimPreviewContainer .singlClaimUpperPartTree .verticalLine {
  height: 29px;
  width: 1px;
  background-color: white;
}
.singleClaimPreviewContainer .singlClaimUpperPartTree .previewClaimName {
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "poppins-local-medium";
}
.singleClaimPreviewContainer .claimPreviewPartLine {
  position: relative;
  height: 7px;
  width: 1px;
  background-color: #201a2d;
}
.singleClaimPreviewContainer .singlClaimDownPartTree {
  width: 100%;
  height: 55px;
  min-height: 55px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .calculateAmountButton {
  width: 185px;
  height: 44px;
  border: 1px solid #201a2d;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .calculateAmountButton .calculateAmountsButtonText {
  font-size: 16px;
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText {
  color: #5a45c9;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.krogerus {
  color: #000000;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.negative {
  color: #ff703e;
}
.singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.negative.krogerus {
  color: #ffcb2b !important;
}
.singleClaimPreviewContainer .active {
  background-color: #331a5b;
}
.singleClaimPreviewContainer .active.krogerus {
  background-color: #000000;
}
.singleClaimPreviewContainer .claimPositionHighlight {
  position: absolute;
  top: -10px;
  cursor: pointer;
  background-color: #462777;
  z-index: -2;
  width: 30px;
  height: 140px;
  border-radius: 5px;
  opacity: 0.7;
}
.singleClaimPreviewContainer .claimPositionHighlight.left {
  left: -10px;
}
.singleClaimPreviewContainer .claimPositionHighlight.right {
  right: -10px;
}
.singleClaimPreviewContainer .threeDotsMenuContainer {
  width: 182px;
  height: 123px;
  /* UI Properties */
  background-color: white;
  border: 1px solid #331a5b;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 8px 10px 10px;
  cursor: default;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .copyingClaimMessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  margin-bottom: 3px;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow:hover {
  font-weight: 600;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText {
  margin-left: 5px;
  cursor: pointer;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText.red {
  color: #ac2828;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText:hover {
  font-weight: 600;
}
.singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowImg {
  width: 13px;
  cursor: pointer;
}
.singleClaimPreviewContainer .moveTileHandle {
  position: absolute;
  top: 0px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: 0;
  width: 34px;
  height: 35px;
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px 0px 0px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.singleClaimPreviewContainer .moveTileTransparentCover {
  position: absolute;
  top: 0px;
  left: -34px;
  cursor: pointer;
  z-index: 0;
  width: 34px;
  height: 35px;
  border-radius: 5px 0px 0px 5px;
}
.singleClaimPreviewContainer .moveTileHandleBackground {
  position: absolute;
  top: 0px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: -1;
  width: 37px;
  height: 35px;
  border-radius: 5px 0px 0px 5px;
}
.singleClaimPreviewContainer .moveTileHandleinvisibleLayer {
  position: absolute;
  top: -10px;
  left: -42px;
  cursor: default;
  z-index: 0;
  width: 45px;
  height: 120px;
}
.singleClaimPreviewContainer .threeDotsButtonContainer {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #331a5b;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pdfPreview {
  height: -moz-fit-content;
  height: fit-content;
  align-items: flex-start;
}

.disputedAmountInfoContainer {
  margin-top: -10px;
  max-width: 1284px;
  min-width: 1284px;
  min-height: 54px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
@media screen and (max-width: 1500px) {
  .disputedAmountInfoContainer {
    max-width: 90vw;
    min-width: 90vw;
    margin-left: 20px;
  }
}
@media screen and (max-width: 836px) {
  .disputedAmountInfoContainer {
    max-width: 725px;
    min-width: 725px;
    margin-left: 20px;
  }
}
.disputedAmountInfoContainer .disputedAmountHoverContainer {
  min-width: 240px;
  background-color: white;
  height: 54px;
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .disputedAmountColumn {
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .disputedAmountColumn .disputedAmountInfoAmountContainer {
  margin-left: 15px;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .disputedAmountColumn .disputedAmountInfoAmountContainer .disputedAmountInfoAmount {
  color: #5a45c9;
  margin-left: 20px;
  text-align: left;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .disputedAmountColumn .disputedAmountInfoAmountContainer .disputedAmountInfoAmount.krogerus {
  color: #000000;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .disputedAmountColumn .disputedAmountInfoAmountContainer .disputedAmountInfoAmount.negative {
  color: #ff703e;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .disputedAmountColumn .disputedAmountInfoAmountContainer .disputedAmountInfoAmount.negative.krogerus {
  color: #ffcb2b;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .text {
  align-items: flex-start !important;
  margin-left: 10px;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .calculateAmountButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .calculateAmountButtonContainer .calculateAmountButton {
  height: 44px;
  border: 1px solid #201a2d;
  border-radius: 4px;
  cursor: pointer;
}
.disputedAmountInfoContainer .disputedAmountHoverContainer .calculateAmountButtonContainer .calculateAmountButton .calculateAmountsButtonText {
  font-size: 16px;
  cursor: pointer;
}

.barContainer {
  max-width: 1010px;
  margin-left: 78px;
  margin-right: 30px;
}
.barContainer .tagsection {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  z-index: 1;
}
.barContainer .tagsection .tag {
  text-align: center;
  position: relative;
  box-sizing: border-box;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.barContainer .tagsection .tag:first-of-type {
  border-radius: 50px 0px 0px 50px;
}
.barContainer .tagsection .tag:last-of-type {
  border-radius: 0px 50px 50px 0px;
}
.barContainer .tagsection .tag:only-of-type {
  border-radius: 50px 50px 50px 50px;
}
.barContainer .tagsection .tagText {
  position: relative;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: block;
  overflow: hidden;
  font-size: 16px;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
}
@media screen and (max-width: 1230px) {
  .barContainer .tagsection .tagText {
    font-size: 12px;
  }
}

.barChartHelperTextContainer {
  max-width: 1010px;
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 40px;
}
.barChartHelperTextContainer .barChartHelperText {
  float: right;
}

.liabilityContainer {
  position: relative;
  background-color: #ebf2fc;
  border-radius: 10px;
  padding: 15px 20px 24px 22px;
  margin-bottom: 60px;
  margin-top: 35px;
  width: 490px;
  margin-left: 78px;
  z-index: 2;
}
.liabilityContainer .infoElementContainer {
  position: absolute;
  top: 12px;
  right: 12px;
}
.liabilityContainer .liabilityTitleContainer .libailityTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.liabilityContainer .liabilityTitleContainer .liabilityHelperText {
  width: 375px;
  font-size: 16px;
}
.liabilityContainer .requirementsContainer {
  margin-top: 30px;
}
.liabilityContainer .addLiabilityContainer {
  margin-top: 35px;
}
.liabilityContainer .requirement {
  justify-content: flex-start !important;
  align-items: center;
  margin-top: 35px;
}
.liabilityContainer .requirement .inputNum {
  margin-left: 28px;
}
.liabilityContainer .requirement .inputNum .input-label-text-p {
  letter-spacing: -0.04em;
}
.liabilityContainer .requirement .liabilityMultiLine {
  margin-bottom: -38px;
}
.liabilityContainer .requirement .deleteContainer {
  position: relative;
  cursor: pointer;
  margin-left: 10px;
}
.liabilityContainer .requirement .deleteContainer img {
  height: 20.5px;
  width: 17.3px;
}
.liabilityContainer .requirementLabels label {
  margin-right: 165px;
}
.liabilityContainer .finalLiabilityTextContainer {
  margin-top: 30px;
}
.liabilityContainer .liabilityArrow {
  position: absolute;
  left: 47px;
  bottom: -28px;
  background-color: #ebf2fc;
  height: 65px;
  width: 65px;
  transform: rotate(45deg);
  z-index: -1;
}

.quantumContainer {
  position: relative;
  background-color: #e5e5e5;
  border-radius: 10px;
  padding: 15px 66px 24px 22px;
  margin-bottom: 60px;
  max-width: 1015px;
  margin-left: 78px;
  z-index: 2;
}
.quantumContainer .infoElementContainer {
  position: absolute;
  top: 12px;
  right: 12px;
}
.quantumContainer .dropdown,
.quantumContainer .inputNum,
.quantumContainer .inputdate,
.quantumContainer .inputNum-RO,
.quantumContainer .dataList {
  margin-left: 15px !important;
}
.quantumContainer .quantumTitleContainer .quantumTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.quantumContainer .quantumTitleContainer .quantumHelperText {
  width: 450px;
  font-size: 16px;
}
.quantumContainer .includeExternalConsequenceContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}
.quantumContainer .includeExternalConsequenceContainer img {
  margin-right: 5px;
  width: 32px;
  cursor: pointer;
}
.quantumContainer .includeExternalConsequenceContainer p {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}
.quantumContainer .includeExternalConsequenceContainer .infoElementContainer {
  position: relative;
  left: -2px;
  top: 0;
  right: 0;
}
.quantumContainer .includeExternalConsequenceContainer .infoElementContainer .infoClickComponentContainer img {
  width: 18px;
}
.quantumContainer .singleQuantumContainer {
  z-index: 1;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-top: 37px;
}
.quantumContainer .singleQuantumContainer .singleQuantum {
  position: relative;
  background-color: white;
  height: 93px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1230px) {
  .quantumContainer .singleQuantumContainer .singleQuantum {
    display: grid;
    grid-template-areas: "amount probability delete" "outOfCourt outOfCourt delete";
  }
}
.quantumContainer .singleQuantumContainer .singleQuantum .fullSuccessCoverLayer {
  position: absolute;
  background-color: transparent;
  z-index: 10;
  top: 5px;
  left: 10px;
}
.quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
  height: 100%;
  grid-area: outOfCourt;
  margin-left: 40px;
}
.quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer .single-quantum-add-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer .single-quantum-add-button-container p {
  margin-left: 12px;
  cursor: pointer;
}
@media screen and (max-width: 1230px) {
  .quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer {
    margin-left: 14px;
    margin-top: -20px;
  }
}
@media screen and (max-width: 1040px) {
  .quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer {
    margin-left: 10px;
  }
}
.quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer .externalConsequenceAmountContainer {
  height: 100%;
  position: relative;
  margin: 0px 20px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer .externalConsequenceAmountContainer .inputNum {
  margin-left: 0px !important;
}
.quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer .externalConsequenceAmountContainer img {
  position: absolute;
  right: -14px;
  top: 16px;
  cursor: pointer;
}
@media screen and (max-width: 1230px) {
  .quantumContainer .singleQuantumContainer .singleQuantum .singleExternalConsequenceContainer .externalConsequenceAmountContainer img {
    top: 5px;
  }
}
.quantumContainer .singleQuantumContainer .singleQuantum .greenRedOutOfCourt {
  margin-top: 7px;
}
.quantumContainer .singleQuantumContainer .singleQuantum .amountInputContainer {
  height: 100%;
  grid-area: amount;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
}
.quantumContainer .singleQuantumContainer .singleQuantum .amountInputContainer.fullAmountInputContainer {
  margin-top: 2px;
}
.quantumContainer .singleQuantumContainer .singleQuantum .amountInputContainer .amount .input-label-text-p {
  letter-spacing: -0.04em !important;
}
.quantumContainer .singleQuantumContainer .singleQuantum .probabilityContainer {
  height: 100%;
  position: relative;
  grid-area: probability;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quantumContainer .singleQuantumContainer .singleQuantum .probabilityContainer .input-label-text-p {
  letter-spacing: -0.04em !important;
}
.quantumContainer .addQuantumContainer {
  margin: 15px 0 0 0;
}
.quantumContainer .addQuantumButton {
  position: static;
  margin: auto 0;
}
.quantumContainer .requirementLabels label {
  margin-right: 165px;
}
.quantumContainer .quantumArrow {
  position: absolute;
  left: 47px;
  bottom: -28px;
  background-color: #e5e5e5;
  height: 65px;
  width: 65px;
  transform: rotate(45deg);
  z-index: -1;
}
.quantumContainer .deleteContainer {
  right: -31px;
  position: absolute;
  cursor: pointer;
  margin-left: 20px;
}
.quantumContainer .deleteContainer img {
  height: 20.5px;
  width: 17.3px;
}

.delete {
  position: absolute;
  top: 14px;
  right: 15px;
  cursor: pointer;
}
.delete .exitImg {
  height: 27px;
  cursor: pointer;
}

.IndependentSingleClaimPlaceholder {
  height: 3000px;
}

.claimContainer {
  animation: fadeIn 0.2s;
  margin-top: 3px !important;
  margin-bottom: 200px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.claimTitleContainer {
  background-color: #331a5b !important;
  border-radius: 10px 10px 0 0;
  min-height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding-right: 50px;
}
.claimTitleContainer.krogerus {
  background-color: #000000 !important;
}
.claimTitleContainer .tool-component-title {
  width: 100%;
  height: 58px;
  margin: 0;
}
.claimTitleContainer .tool-component-title .claimTitle {
  color: white;
  margin-left: 18px;
}
.claimTitleContainer .tool-component-title .claimLine {
  width: 2px;
  height: 30px;
  border-radius: 1px;
  margin-left: 15px;
  margin-right: 7px;
  background-color: white;
}
.claimTitleContainer .tool-component-title .titleClaimName {
  color: white;
  margin-left: 18px;
}

.valuOfIndependentClaimContainer {
  margin-left: 90px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}
.valuOfIndependentClaimContainer .valuOfIndependentClaimText,
.valuOfIndependentClaimContainer .valuOfIndependentClaimAmount {
  font-weight: bold;
  margin-right: 6px;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer {
  margin-top: -2px;
  position: relative;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButton {
  cursor: pointer;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer {
  position: absolute;
  width: 224px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  background-color: white;
  top: -8px;
  left: 30px;
  padding: 10px;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer .title {
  font-weight: bold;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer .valuOfIndependentClaimSection {
  margin-top: 9px;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer .valuOfIndependentClaimSection .valuOfIndependentClaimSectionHeader {
  display: flex;
  flex-direction: row;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer .valuOfIndependentClaimSection .valuOfIndependentClaimSectionHeader .valuOfIndependentClaimSectionTitle {
  font-style: italic;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer .valuOfIndependentClaimSection .valuOfIndependentClaimSectionHeader .valuOfIndependentClaimSectionImage {
  margin-left: 8px;
  margin-top: -8px;
}
.valuOfIndependentClaimContainer .valueOfIndependentClaimInfoButtonContainer .valueOfIndependentClaimInfoButtonPopUpContainer .valuOfIndependentClaimSection .valuOfIndependentClaimSectionValue {
  font-family: "poppins-local-medium";
}

.treeResultsContainerBackground {
  position: absolute;
  right: 44px;
  top: 25px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  z-index: 11;
}
.treeResultsContainerBackground .downloadImageComponentContainer {
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
}
.treeResultsContainerBackground .warningPopUpContainer {
  min-width: 500px;
  max-width: 528px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  margin-right: 50px;
}
.treeResultsContainerBackground .warningPopUpContainer .warningPopUpMessageContainer {
  margin-bottom: 25px;
}
.treeResultsContainerBackground .warningPopUpContainer .warningPopUpMessageContainer .warningPopUpMessage {
  text-align: center;
}
.treeResultsContainerBackground .warningPopUpContainer .warningPopUpMessageContainer .warningPopUpMessage .warningPopUpMessageSpan {
  color: #ff7336;
  text-decoration: underline;
}
.treeResultsContainerBackground .warningPopUpContainer .warningPopUpMessageContainer .warningPopUpMessage .warningPopUpMessageSpan strong {
  color: #ff7336;
}
.treeResultsContainerBackground .warningPopUpContainer .warningPopUpMessageContainer .warningPopUpMessage .warningPopUpMessageSpan:hover {
  cursor: pointer;
  font-weight: 600;
}
.treeResultsContainerBackground .warningPopUpContainer .warningPopUpButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* .cancelButton {
    margin-right: 40px;
  } */
}
.treeResultsContainerBackground .treeResultsContainer {
  /* @media screen and (max-width: 1330px) {
    top: 80px;
  } */
  position: relative;
  background-color: white;
  padding: 17px 12px 25px 18px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
}
@media screen and (max-width: 1000px) {
  .treeResultsContainerBackground .treeResultsContainer {
    top: 0px;
  }
}
.treeResultsContainerBackground .treeResultsContainer .switchContainer {
  transform: scale(0.9);
  margin-top: 0px;
}
.treeResultsContainerBackground .treeResultsContainer .treeResultsTitleContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
}
.treeResultsContainerBackground .treeResultsContainer .treeResultsTitleContainer .treeResultsTitleText {
  font-size: 16px;
  font-family: "poppins-local-medium";
  margin-right: 10px;
  margin-top: 4px;
}
.treeResultsContainerBackground .treeResultsContainer .treeResultsTitleContainer .switchTreeTableInterestState {
  margin-right: -10px;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  margin-left: -4px;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreeContainer .valueOfTreeImg {
  margin-bottom: 3px;
  margin-right: 7px;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreeContainer .valueOfTreeText {
  font-weight: bold;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreePrecentageChange {
  margin-left: 23px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreePrecentageChange .changeOfvalueOfTreeImg {
  margin-bottom: 3px;
  margin-right: 3px;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreePrecentageChange .valueOfTreePercentageChangeText {
  color: #47743d;
}
.treeResultsContainerBackground .treeResultsContainer .valueOfTreeAndRecentChangeContainer .valueOfTreePrecentageChange .valueOfTreePercentageChangeText.negative {
  color: #ac2828;
}
.treeResultsContainerBackground .treeResultsContainer .treeSelectionGraphContainer {
  margin-top: 23px;
}
.treeResultsContainerBackground .treeResultsContainer .treeSelectionGraphContainer .selectionGraphOnContainer .selectionGraphTitles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
}
.treeResultsContainerBackground .treeResultsContainer .treeSelectionGraphContainer .selectionGraphOnContainer .selectionGraphTitles .selectionGraphTitle {
  font-family: "poppins-local-medium";
  margin-right: 11px;
}
.treeResultsContainerBackground .treeResultsContainer .selectionGraphToggleText {
  text-decoration: underline;
  color: #ff7336;
  cursor: pointer;
}
.treeResultsContainerBackground .treeResultsContainer .selectionGraphToggleText:hover {
  color: #f86221;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer {
  /* position: absolute;
  right: 4px;
  top: -10px; */
  z-index: 10;
  word-break: keep-all;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .calculateTreeButton {
  height: 45px;
  width: 370px;
  background-color: #ff7336;
  padding: 1px 42px 1px 42px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 4px;
  cursor: pointer;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .calculateTreeButton:hover {
  background-color: #f86221;
  cursor: pointer;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .calculateTreeButton.small {
  width: 256px;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .calculateTreeButton .calculateTreeButtonText {
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .calculateTreeButton .MuiCircularProgress-root {
  margin-top: 5px;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .calculateTreeButton .MuiCircularProgress-circle {
  color: white;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .treeLimitReachedButton {
  background-color: #ac2828;
  height: 54px;
  padding: 1px 12px 1px 12px;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .treeLimitReachedButton:hover {
  background-color: #c42f37;
  cursor: pointer;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .treeLimitReachedButton .calculateTreeButtonText {
  font-size: 16px;
  white-space: normal;
  width: 230px;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .showTreeResultsButton {
  background-color: white;
  /* position: absolute;
  top: 0;
  right: 0px; */
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  height: 38px;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .showTreeResultsButton p {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "poppins-local-medium";
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer !important;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .minimiseTreeResultsButton {
  position: relative;
  width: 26px;
  cursor: pointer;
  height: 26px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .minimiseTreeResultsButton.showResults {
  margin-top: 4px;
}
.treeResultsContainerBackground .showTreeResultsButtonContainer .minimiseTreeResultsButton .minimiseTableTreeResultsButton {
  cursor: pointer;
}
.treeResultsContainerBackground .minimise {
  position: relative;
  right: 0px;
  left: 0px;
  /* @media screen and (max-width: 1330px) {
    top: 80px;
  } */
  top: 0px;
}

.tableInfo {
  align-self: flex-end;
  margin-top: 18px;
  text-align: right;
  right: 20px;
}

.circularProgressDiv {
  width: 110px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.treeTableContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 15px;
}
.treeTableContainer .treeTableTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 40px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg {
  position: absolute;
  right: 10px;
  width: 0.1px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg.column1 {
  right: 15px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg.column2 {
  right: 28px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg.column3 {
  right: 20px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg.column4 {
  right: 3px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg.column5 {
  right: 14px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableSortingArrowImg.column6 {
  right: 14px;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText {
  font-size: 14px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText.column1 {
  min-width: 55px !important;
  width: 55px !important;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText.column2 {
  min-width: 120px !important;
  width: 120px !important;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText.column3 {
  min-width: 190px !important;
  width: 190px !important;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText.column4 {
  min-width: 120px !important;
  width: 120px !important;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText.column5 {
  min-width: 120px !important;
  width: 120px !important;
}
.treeTableContainer .treeTableTitleContainer .treeTableTitleTextContainer .treeTableTitleText.column6 {
  min-width: 100px !important;
  width: 100px !important;
}
.treeTableContainer .tableRows {
  margin-top: 5px;
}
.treeTableContainer .tableRows .activeRow {
  background-color: #e1d6f4 !important;
}
.treeTableContainer .tableRows .activeRow.krogerus {
  background-color: #e1d6f4 !important;
}
.treeTableContainer .tableRows .row {
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  cursor: pointer;
  border-radius: 4px;
}
.treeTableContainer .tableRows .row:hover {
  background-color: #edeaf5;
  cursor: pointer;
}
.treeTableContainer .tableRows .row p {
  font-size: 14px;
  cursor: pointer;
}
.treeTableContainer .tableRows .row .column1 {
  min-width: 56px !important;
  width: 56px !important;
}
.treeTableContainer .tableRows .row .column2 {
  min-width: 116px !important;
  width: 116px !important;
}
.treeTableContainer .tableRows .row .column3 {
  min-width: 181px !important;
  width: 181px !important;
}
.treeTableContainer .tableRows .row .column4 {
  min-width: 116px !important;
  width: 116px !important;
}
.treeTableContainer .tableRows .row .column5 {
  min-width: 116px !important;
  width: 116px !important;
}
.treeTableContainer .tableRows .row .column6 {
  min-width: 106px !important;
  width: 106px !important;
}

.tableTreeResults {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  /* Track */
  /* Handle */
}
.tableTreeResults::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.tableTreeResults::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.tableTreeResults::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}

.noScroll {
  overflow-y: hidden !important;
}

.treeTableCellContainer {
  margin: 0 2px 0 2px;
}
.treeTableCellContainer .treeTableCell {
  color: #201a2d;
  text-align: center;
  margin-bottom: 3px;
  display: inline-block;
  white-space: nowrap;
}

/* Choose input number fields with no arrows for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Choose input number fields with no arrows for Firefox */
input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}

.treeComponent {
  position: relative;
  width: 100%;
}
.treeComponent .treeSideMenuContainer {
  position: absolute;
  left: 52px;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
.treeComponent .treeSideMenuContainer .treeSideMenuButton {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.treeComponent .treeSideMenuContainer .treeSideMenuButton:hover {
  background-color: #edeaf5;
}
.treeComponent .treeSideMenuContainer .treeSideMenuButton.selected {
  border: solid 1px #201a2d;
  background-color: #e1d6f4;
}
.treeComponent .treeSideMenuContainer .treeSideMenuSwitchContainer {
  position: relative;
  width: 45px;
  height: 88px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.treeComponent .treeSideMenuContainer .treeSideMenuSwitchContainer .treeSideMenuSwitch {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.treeComponent .treeSideMenuContainer .treeSideMenuSwitchContainer .sideMenuSwitchBackground {
  position: absolute;
  border-radius: 5px;
  background-color: #e1d6f4;
  width: 37px;
  height: 37px;
  z-index: 0;
  transition: all 0.3s ease;
}
.treeComponent .treeNameInputContainer {
  position: absolute;
  top: 20px;
  left: 160px;
  z-index: 10;
}
.treeComponent .treeContainer {
  display: flex;
  flex-direction: row;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground {
  position: relative;
  margin-top: 100px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer {
  display: grid;
  grid-template-rows: [eventTitle] auto [awardedAmountContainer] auto [reducedAwardedAmountContainer] auto [outOfCourtAmountContainer] auto [probabilityContainer] auto;
  padding: 35px 23px 16px 23px;
  -moz-column-gap: 21px;
       column-gap: 21px;
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .connectionCreatesLoopMessageContainer {
  position: absolute;
  left: -50px;
  top: 80px;
  width: 250px;
  z-index: 1000;
  background-color: pink;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1607843137);
  padding: 3px;
  text-align: center;
  border-radius: 3px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .connectionCreatesLoopMessageContainer.shake {
  animation: shake 0.5s;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventShadow {
  position: absolute;
  background-color: transparent;
  width: 239px;
  top: 37px;
  height: calc(100% - 65px);
  margin: 0px 0px 60px 24px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventMinimisedShadow {
  position: absolute;
  background-color: transparent;
  width: 145px;
  top: 27px;
  height: calc(100% - 54px);
  margin: 0px 0px 60px 23px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground {
  display: contents;
  margin-right: 21px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground:last-of-type {
  margin-right: 0px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .deleteTreeEventMenuContainer .deleteTreeEventMenuButton {
  top: 0;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventTitleContainer {
  grid-row: eventTitle;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #faf8ff;
  width: 240px;
  height: 100%;
  border-radius: 5px 5px 0 0;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventTitleContainer.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventTitleContainer.fadeout {
  animation: fadeOut 0.4s;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventTitleContainer .deleteTreeEventImgContainer {
  position: absolute;
  right: -15px;
  top: -12px;
  width: 28px;
  cursor: pointer;
  z-index: 1;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventTitleContainer .deleteTreeEventImgContainer .deleteTreeEventImg {
  width: 28px;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  padding: auto;
  text-align: center;
  margin: auto;
  font-size: 6.5px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventTitleContainer .treeEventTitleInput {
  margin-top: 18px;
  margin-bottom: 14px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection {
  margin-bottom: 5px;
  min-height: 47px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventAwardedAmountContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventAwardedAmountContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventAwardedAmountContainer {
  grid-row: awardedAmountContainer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #faf8ff;
  width: 240px;
  height: 100%;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventAwardedAmountContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventAwardedAmountContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventAwardedAmountContainer.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventAwardedAmountContainer.fadeout,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventAwardedAmountContainer.fadeout,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventAwardedAmountContainer.fadeout {
  animation: fadeOut 0.4s;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventAwardedAmountContainer.noInterest,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventAwardedAmountContainer.noInterest,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventAwardedAmountContainer.noInterest {
  margin-bottom: -10px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventReducedAmountContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventReducedAmountContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventReducedAmountContainer {
  grid-row: reducedAwardedAmountContainer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #faf8ff;
  width: 240px;
  height: 100%;
  margin-bottom: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventReducedAmountContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventReducedAmountContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventReducedAmountContainer.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventReducedAmountContainer.fadeout,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventReducedAmountContainer.fadeout,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventReducedAmountContainer.fadeout {
  animation: fadeOut 0.4s;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventOutOfCourtSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventOutOfCourtSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventOutOfCourtSection {
  grid-row: outOfCourtAmountContainer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #faf8ff;
  width: 240px;
  height: 100%;
  margin-bottom: -2px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventOutOfCourtSection.selected,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventOutOfCourtSection.selected,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventOutOfCourtSection.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer.treeEventOutOfCourtSection.fadeout,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer.treeEventOutOfCourtSection.fadeout,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection.treeEventOutOfCourtSection.fadeout {
  animation: fadeOut 0.4s;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAddSectionContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAddSectionContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer {
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer .treeEventAddSectionButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 211px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid transparent;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton.darkButton,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton.darkButton,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer .treeEventAddSectionButton.darkButton {
  border: 1px solid #201a2d;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonImg {
  margin-left: 7px;
  width: 25px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonText,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonText,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonText {
  margin-left: 7px;
  font-size: 14px;
  cursor: pointer;
  color: #8e8e8e;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonText.dark,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonText.dark,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer .treeEventAddSectionButton .treeEventAddSectionButtonText.dark {
  color: #201a2d;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection {
  position: relative;
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventAmountSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventAmountSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventAmountSection {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventAmountSection .treeEventRemoveSectionContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventAmountSection .treeEventRemoveSectionContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventAmountSection .treeEventRemoveSectionContainer {
  position: absolute;
  right: -9px;
  top: -12px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventAmountSection .treeEventRemoveSectionContainer .treeEventRemoveSectionImg,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventAmountSection .treeEventRemoveSectionContainer .treeEventRemoveSectionImg,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventAmountSection .treeEventRemoveSectionContainer .treeEventRemoveSectionImg {
  width: 26px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding: auto;
  margin-top: 14px;
  margin-bottom: 24px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection.editMode,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection.editMode,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection.editMode {
  margin-top: 6px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 17px;
  width: 240px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer .treeEventIncludeInterestButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer .treeEventIncludeInterestButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer .treeEventIncludeInterestButtonImg {
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer .treeEventIncludeInterestButtonText,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer .treeEventIncludeInterestButtonText,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventIncludeInterestButtonContainer .treeEventIncludeInterestButtonText {
  cursor: pointer;
  margin-left: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  margin: 4px 18px 3px 17px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestRateInput .input-label-text,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .inputdate .input-label-text,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestRateInput .input-label-text,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .inputdate .input-label-text,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestRateInput .input-label-text,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .inputdate .input-label-text {
  left: 6px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput {
  height: 43px;
  padding: 0 3px 0 3px !important;
  font-size: 14px !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator {
  font-size: 7px;
  margin: 1px 2px 0 7px;
  padding: 0;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator:hover,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator:hover,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator:focus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator:focus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .treeEventInterestDateInput::-webkit-calendar-picker-indicator:focus {
  outline-width: 0;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .error,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .error,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .error {
  border: 3px solid #ac2828;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .error:focus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .error:focus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventInterestContainer .error:focus {
  outline: none !important;
  border: 3px solid #ac2828 !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventErrorEmptySpace,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .treeEventErrorEmptySpace,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .treeEventErrorEmptySpace {
  height: 30px;
  width: 30px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .errorDateMessageText,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAmountInterestSection .treeEventInterestSection .errorDateMessageText,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventInterestSection .errorDateMessageText {
  position: absolute;
  top: 43px;
  color: #ac2828;
  width: 100%;
  font-size: 13px;
  text-align: right;
  margin-right: 10px;
  margin-top: 0px;
  line-height: 1.4;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAwardSignumSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAwardSignumSection,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAwardSignumSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 8.5px;
  margin-top: 2px;
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAwardSignumSection.outOfCourt,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAwardSignumSection.outOfCourt,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAwardSignumSection.outOfCourt {
  margin-top: -12px;
  margin-bottom: 13px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAwardedAmountContainer .treeEventAwardSignumSection label div,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventReducedAmountContainer .treeEventAwardSignumSection label div,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAwardSignumSection label div {
  margin-right: 6px !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAddSectionContainer {
  margin-top: 10px;
  margin-bottom: 15px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection {
  margin-top: 10px;
  margin-bottom: 15px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventOutOfCourtSection .treeEventAmountInterestSection .treeEventAmountSection {
  top: 1px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .switchContainer .switchMainButton {
  border: 1px solid #dcdcdc;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.168627451);
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .switchContainer .switchCircle-right,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .switchContainer .switchCircle-left {
  width: 34px;
  height: 34px;
  top: 6px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer {
  grid-row: probabilityContainer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #faf8ff;
  width: 240px;
  height: 100%;
  border-radius: 0 0 5px 5px;
  /* box-shadow: 5px 0px 5px -5px #00000029, -5px 0 5px -5px #00000029,
    -2px 5px 7px -4px #00000029; */
  margin-bottom: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer.fadeout {
  animation: fadeOut 0.4s;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer .treeEventProbabilityInput {
  margin-top: 15px;
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer .treeEventProbabilityInput.readOnly::-moz-selection {
  background: transparent;
  color: #201a2d;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer .treeEventProbabilityInput.readOnly::selection {
  background: transparent;
  color: #201a2d;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer .treeEventProbabilityInput.readOnly:focus {
  outline: none !important;
  border: 1px solid transparent !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventProbabilityContainer .treeEventProbabilityInput.shakeScenario {
  animation: shake 0.7s;
}
@keyframes shake {
  0% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAddNodeButtonContainer {
  position: absolute;
  bottom: -36px;
  left: 57px;
  margin: 0 !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAddNodeButtonContainer .treeEventAddNodeButton {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1019607843);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #faf8ff;
  border-radius: 5px;
  width: 123px;
  height: 41px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAddNodeButtonContainer .treeEventAddNodeButton.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAddNodeButtonContainer .treeEventAddNodeButton .treeEventAddNodeButtonImg {
  height: 19px;
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAddNodeButtonContainer .treeEventAddNodeButton .treeEventAddNodeButtonText {
  position: relative;
  margin-left: 5.5px;
  font-size: 14px;
  color: #8e8e8e;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeEventBackground .treeEventAddNodeButtonContainer .treeEventAddNodeButton .treeEventAddNodeButtonText.purple {
  color: #201a2d;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 31px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer.minimised {
  top: 10px;
  background-color: "transparent";
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot {
  width: 11px;
  height: 11px;
  border-radius: 6px;
  background-color: #faf8ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1019607843);
  top: 9px;
  left: 5px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot.minimised {
  background-color: transparent;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1019607843);
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot.clicked {
  position: absolute;
  left: calc(50% - 10px);
  top: 5px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: white;
  border: solid 1px black;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot.clicked.minimised {
  top: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot.active {
  background-color: #e1d6f4 !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotContainer .treeAddNodeDot.active.krogerus {
  background-color: #e1d6f4 !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotEventAvailable {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  opacity: 0.6;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotEventAvailable .treeAddNodeDotEventAvailableImg {
  z-index: -1;
  margin-top: 31px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .treeAddNodeDotEventAvailable .treeAddNodeDotEventAvailableImgMinimised {
  z-index: -1;
  margin-top: 5px;
  width: 74px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised {
  padding: 25px 23px 16px 23px;
  display: grid;
  grid-template-rows: [eventTitle] auto [eventDetails] auto [eventProbability] min-content;
  -moz-column-gap: 12px;
       column-gap: 12px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised {
  display: contents;
  width: 145px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  margin-left: 12px;
  background-color: white;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised:first-child {
  margin-left: 0px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventTitleContainerMinimised {
  grid-row: eventTitle;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 145px;
  min-height: 44px;
  background-color: #faf8ff;
  border-radius: 5px 5px 0 0;
  padding: 6px;
  text-align: center;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventTitleContainerMinimised.krogerus {
  background-color: #ffeaaa;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventTitleContainerMinimised.selected {
  background-color: #fff0ea;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventTitleContainerMinimised.noEventTitle {
  min-height: 12px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventTitleContainerMinimised .treeEventTitleMinimised {
  width: 100%;
  word-break: normal;
  font-weight: 600;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised {
  grid-row: eventDetails;
  background-color: white;
  border-radius: 0 0 5px 5px;
  z-index: 10;
  width: 145px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised {
  text-align: right;
  margin-left: 8px;
  margin-right: 11px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.positive {
  color: #5a45c9;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.positive.krogerus {
  color: #000000;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.negative,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.negative,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.negative,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.negative,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.negative {
  color: #ff703e;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.negative.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.negative.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.negative.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.negative.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.negative.krogerus {
  color: #ffcb2b;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventReducedAmountTextMinimised.onlyReduced,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventReducedAmountTextMinimised.onlyReduced,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventReducedAmountTextMinimised.onlyReduced,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventReducedAmountTextMinimised.onlyReduced,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventReducedAmountTextMinimised.onlyReduced {
  margin-bottom: 11px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventAwardedAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventAwardedAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventAwardedAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventAwardedAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventAwardedAmountTextMinimised {
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 20px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventOutOfCourtAmountTextMinimisedContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer {
  margin-top: 4px;
  margin-right: 8px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised {
  height: 20px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  padding: 0 1px 0 3px;
  line-height: 1.2;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border {
  border: 1px solid #5a45c9;
  border-radius: 2px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.border.krogerus {
  border: 1px solid #000000;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive {
  color: #5a45c9;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive.krogerus,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventOutOfCourtAmountTextMinimisedContainer .treeEventOutOfCourtAmountTextMinimised.positive.krogerus {
  color: #000000;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventInterestTextMinimised {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventInterestTextMinimised .treeEventInterestRateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventInterestTextMinimised .treeEventInterestRateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventInterestTextMinimised .treeEventInterestRateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventInterestTextMinimised .treeEventInterestRateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventInterestTextMinimised .treeEventInterestRateTextMinimised {
  color: #a1a6c7;
  margin-right: 14px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventInterestTextMinimised .treeEventInterestDateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventInterestTextMinimised .treeEventInterestDateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventInterestTextMinimised .treeEventInterestDateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventInterestTextMinimised .treeEventInterestDateTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventInterestTextMinimised .treeEventInterestDateTextMinimised {
  color: #a1a6c7;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventAwardedAmountTextMinimised.treeEventNoInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventInterestTextMinimised.treeEventNoInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventOutOfCourtAmountTextMinimisedContainer.treeEventNoInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventReducedAmountTextMinimised.treeEventNoInterestTextMinimised,
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventDetailsContainerMinimised .treeEventNoInterestTextMinimised.treeEventNoInterestTextMinimised {
  height: 23px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventProbabilityContainerMinimised {
  grid-row: eventProbability;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 12;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventProbabilityContainerMinimised .treeEventProbabilityCircleMinimised {
  position: absolute;
  bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  font-weight: 600;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer.minimised .treeEventBackgroundMinimised .treeEventProbabilityContainerMinimised .treeEventProbabilityCircleMinimised .treeEventProbabilityMinimised {
  font-weight: 900;
  font-size: 14px;
  margin-top: 2px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .active {
  background-color: #e1d6f4 !important;
}
.treeComponent .treeContainer .treeAppBackground .treeEventsContainer .active.krogerus {
  background-color: #e1d6f4 !important;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.treeComponent .treeContainer .treeAppBackground .treeNode {
  position: absolute;
  margin: 35px 0 0 40px;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 4;
}
.treeComponent .treeContainer .treeAppBackground .treeNode.minimised {
  margin: 35px 0 0 30px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode.fadeout {
  animation: fadeOut 0.4s;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0px 5px 5px 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground.minimised {
  background-color: transparent;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCreatesLoopMessageContainerNode {
  position: absolute;
  left: 120px;
  top: -50px;
  width: 250px;
  z-index: 1000;
  background-color: pink;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1607843137);
  padding: 3px;
  text-align: center;
  border-radius: 3px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCreatesLoopMessageContainerNode.shake {
  animation: shake 0.5s;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCircleNodeAvailable {
  position: absolute;
  left: calc(50% - 70px);
  top: -70px;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  opacity: 0.3;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCircleNodeAvailable img {
  transform: rotate(180deg);
  margin-top: -31px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCircle {
  position: absolute;
  left: calc(50% - 5px);
  top: -5px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  padding: auto;
  text-align: center;
  margin: auto;
  background-color: #331a5b;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCircle.selected {
  background-color: #ffc6af;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCircle.krogerus {
  background-color: #000000;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .connectionCircle.clicked {
  left: calc(50% - 10px);
  top: -20px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: white;
  border: solid 1px black;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeMoveHandle {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  position: absolute;
  left: -35px;
  top: 0px;
  height: 10px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px 0px 0px 4px;
  box-shadow: -3px 4px 7px -4px #999999;
  z-index: 5;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeMoveHandle.minimised {
  left: -25px;
  top: 16px;
  border-radius: 4px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeMoveHandle .treeNodeMoveHandleTransparentCover {
  position: absolute;
  left: 2px;
  bottom: 2px;
  width: 36px;
  height: 36px;
  background: transparent;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle {
  position: absolute;
  background-color: #331a5b;
  left: 21px;
  bottom: calc(100% - 17px);
  width: calc(100% - 42px);
  border-radius: 5px;
  min-height: 50px;
  padding: 0px 0px 10px 0px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle.krogerus {
  background-color: #000000;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle.selected {
  background-color: #ffc6af;
  color: black;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle.viewerPlaceholder {
  min-height: 11px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer {
  position: absolute;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer.deleteNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer.deleteNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer.deleteNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer.deleteNodeButtonContainer {
  right: -86px;
  width: 110px;
  top: -40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 50px;
  padding-top: 10px;
  padding-left: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer.duplicateNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer.duplicateNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer.duplicateNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer.duplicateNodeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  right: -115px;
  top: 8px;
  height: 42px;
  width: 140px;
  padding-left: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer.copyNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer.copyNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer.copyNodeButtonContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer.copyNodeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  right: -170px;
  top: 46px;
  width: 185px;
  height: 40px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer.selectNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer.selectNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer.selectNodeContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer.selectNodeContainer {
  left: -71px;
  top: -12px;
  height: 53px;
  width: 100px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer.selectNodeContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer.selectNodeContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer.selectNodeContainer.selected,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer.selectNodeContainer.selected {
  left: -92px;
  width: 121px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #331a5b;
  margin-right: 3px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer.hover {
  border: 2px solid #331a5b;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer.selectNodeImgContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer.selectNodeImgContainer {
  margin-right: 0px;
  margin-left: 3px;
  background-color: transparent;
  border: 0px solid transparent;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .deleteNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .duplicateNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .copyNodeButtonImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .selectNodeImg {
  cursor: pointer;
  z-index: 1;
  height: 15px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonImgContainer .selectNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .deleteNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .duplicateNodeImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .copyNodeButtonImg.selectNodeImg,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeImgContainer .selectNodeImg.selectNodeImg {
  height: 22px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer {
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  height: 26px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer p,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer p {
  color: #331a5b;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer p.hover,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer p.hover {
  font-weight: 600;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer.deleteNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer.deleteNodeButtonTextContainer {
  width: 58px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer.duplicateNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer.duplicateNodeTextContainer {
  width: 88px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer.copyNodeButtonTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer.copyNodeButtonTextContainer {
  padding-left: 5px;
  padding-right: 5px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .deleteNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .duplicateNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .copyNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .deleteNodeButtonContainer .selectNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .deleteNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .duplicateNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .copyNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .duplicateNodeContainer .selectNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .deleteNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .duplicateNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .copyNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .copyNodeButtonContainer .selectNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .deleteNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .duplicateNodeTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .copyNodeButtonTextContainer.selectNodeTextContainer,
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .selectNodeContainer .selectNodeTextContainer.selectNodeTextContainer {
  padding-left: 5px;
  padding-right: 5px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .createLineContainer {
  position: absolute;
  top: -65px;
  left: calc(50% - 46px);
  width: 76px;
  height: 73px;
  cursor: pointer;
  padding-top: 10px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .createLineDot {
  position: absolute;
  left: calc(50% - 5px);
  top: -5px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  padding: auto;
  text-align: center;
  margin: auto;
  background-color: #331a5b;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .treeNodeTItleHoverBottomReference {
  height: 0.1px;
  width: 100%;
  position: relative;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea {
  width: 100%;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea .treeNodeTitleTextarea {
  color: white;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  resize: none;
  bottom: 0px;
  padding: 10px 23px 5px 23px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea .treeNodeTitleTextarea::-moz-placeholder {
  color: rgb(201, 197, 197);
  font-weight: 400;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea .treeNodeTitleTextarea::placeholder {
  color: rgb(201, 197, 197);
  font-weight: 400;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea .treeNodeTitleTextarea.selected {
  background-color: #ffc6af;
  color: #201a2d;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea .treeNodeTitleTextarea.selected::-moz-placeholder {
  color: #462777;
  font-weight: 400;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeNodeTitle .container-treeNodeTitleTextarea .treeNodeTitleTextarea.selected::placeholder {
  color: #462777;
  font-weight: 400;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeAddEvent {
  position: absolute;
  right: -30px;
  top: calc(47% - 22.5px);
  height: 50px;
  width: 50px;
  border-radius: 4px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeAddEvent.purple {
  background-color: white;
  border: 1px solid #201a2d;
  width: 140px;
  cursor: pointer;
  right: -120px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeAddEvent .treeAddEventImg {
  cursor: pointer;
  margin-left: 7px;
  width: 28px;
}
.treeComponent .treeContainer .treeAppBackground .treeNode .treeNodeBackground .treeAddEvent .treeAddEventText {
  margin-left: 8px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeInfoForDebugging {
  position: fixed;
  top: 100px;
  right: 10px;
  background-color: rgba(216, 140, 251, 0.6470588235);
  z-index: 600;
  transform: scale(0.8);
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer {
  position: relative;
  width: 120px;
  height: 70px;
  cursor: pointer;
  padding-right: 60px;
  margin-left: 12%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineButtonImgContainer {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #331a5b;
  margin-right: 3px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineButtonImgContainer.hover {
  border: 2px solid #331a5b;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineButtonImgContainer .deleteLineButtonImg {
  cursor: pointer;
  z-index: 1;
  height: 15px;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineButtonTextContainer {
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  height: 26px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  padding: 4px 4px;
  left: 50px;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineButtonTextContainer p {
  color: #331a5b;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineButtonTextContainer p.hover {
  font-weight: 600;
}
.treeComponent .treeContainer .treeAppBackground .deleteLineButtonContainer .deleteLineText {
  background-color: white;
  color: red;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground {
  background-color: transparent;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  margin-left: 100px;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground.border {
  border: 1px solid black;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .deleteNodeOrEventMenuContainer {
  position: absolute;
  padding: 21px 15px 0px 15px;
  background-color: white;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .deleteNodeOrEventMenuContainer .deleteNodeOrEventMenuButton {
  margin-bottom: 16px;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: transparent;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter .circles {
  background-color: black;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter .circles .hoverForLineContainer {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter .circles .hoverForLineContainer .hoverForlineCircle {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter .circles .hoverForLineContainer .hoverForlineCircle.transparent {
  background-color: transparent;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter .circles .hoverForLineContainer .hoverForlineCircle .hoverForlineCircleInner {
  background-color: grey;
  opacity: 0.7;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .circlesOuter .circles .circleName {
  cursor: pointer;
  color: white;
}
.treeComponent .treeContainer .treeAppBackground .treeCanvasBackground .deleteNodeOrEventMenuTranparentLayer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: red;
  z-index: 6000;
}
.treeComponent .treeContainer .treeAppBackground .lineFadeOut {
  animation: fadeOut 0.4s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.treeComponent .treeContainer .treeAppBackground .lineHandle {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: white;
  border: solid 1px black;
  cursor: pointer;
}
.treeComponent .treeContainer .treeAppBackground .treeInfo {
  position: fixed;
  top: 150px;
  right: -50px;
  z-index: 300;
  height: 500px;
  width: 900px;
  overflow-y: scroll;
  pointer-events: none;
  transform: scale(0.8);
  transform-origin: top;
  display: flex;
  flex-direction: row;
}
.treeComponent .treeContainer .treeAppBackground .treeInfo p {
  font-size: 12px;
}
.treeComponent .treeContainer .treeAppBackground .treeInfo .nodesInfo,
.treeComponent .treeContainer .treeAppBackground .treeInfo .eventsInfo {
  overflow-y: scroll;
  height: 900px;
  pointer-events: all;
}
.treeComponent .treeContainer .treeAppBackground .treeInfo .nodeSection,
.treeComponent .treeContainer .treeAppBackground .treeInfo .eventsSection {
  width: 300px;
  margin-right: 3px;
  background-color: rgba(242, 193, 201, 0.471);
  border-radius: 4px;
  margin-bottom: 3px;
  padding: 2px;
}
.treeComponent .treeContainer .treeAppBackground .treeInfo .nodeSection.eventsSection,
.treeComponent .treeContainer .treeAppBackground .treeInfo .eventsSection.eventsSection {
  background-color: rgba(193, 242, 201, 0.471);
}
.treeComponent .treeContainer .treeAppBackground .treeEventNumber,
.treeComponent .treeContainer .treeAppBackground .treeNodeNumber {
  position: absolute;
  color: rgb(24, 121, 71);
  top: -10px;
  font-size: 20px;
}
.treeComponent .treeContainer .treeAppBackground .treeEventNumber.treeNodeNumber,
.treeComponent .treeContainer .treeAppBackground .treeNodeNumber.treeNodeNumber {
  color: rgb(186, 88, 63);
  top: -20px;
}
.treeComponent .translateTree {
  border-top: 2px solid #201a2d;
  width: 100%;
  z-index: 9;
  position: absolute;
  top: 0px;
}

.overlay {
  height: 1500px;
  width: 4000px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #f6f6f6;
  z-index: 1900;
  animation: fadeOut 1.5s;
  border-top: 0px solid #201a2d;
}

@keyframes fadeOut {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
button:focus {
  outline: 0;
}

textarea {
  resize: vertical;
}
textarea:focus {
  outline: 0;
}

.zoomTools {
  position: absolute;
  top: 20px;
  left: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.zoomTools img {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3529411765);
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1400px) {
  .treeContainer .showTreeResultsButton {
    display: none;
  }
}
.treePreviewForDownload {
  position: fixed;
  z-index: 12;
}

.emptySpace {
  min-height: 40px;
}

.claim h2 span {
  font-size: 12px;
}
.claim .errorMsg3 {
  left: 35px;
  top: 10px !important;
}
.claim .errorMessagesContainer .firstError {
  top: 5px;
}
.claim .errorMessagesContainer .secondError {
  top: 5px;
  left: 315px;
  width: 400px;
}

.claimRadioButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  z-index: 1px;
  margin-left: 55px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.nameAndAmountContainer {
  margin-top: 60px;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.nameAndAmountContainer :last-child .inputNum {
  margin-left: 15px !important;
}

.interestContainer {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
  max-width: 68%;
  min-width: 570px;
  width: 100%;
  margin-left: 40px;
}
.interestContainer .dropdown,
.interestContainer .inputNum,
.interestContainer .inputNum-RO {
  margin-left: 15px !important;
}
.interestContainer .inputdate {
  margin-left: 15px !important;
}
.interestContainer .interestInputsContainer {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-right: 20px;
}
.interestContainer .interestInputsContainer img {
  height: 17px;
}

.addInterestContainer {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  height: 30px;
  margin: 15px 25px;
  cursor: pointer;
}
.addInterestContainer .addSignContainer {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 30px 7px 30px 0px;
}
.addInterestContainer .addSignContainer img {
  position: absolute;
  width: 16px;
  font-size: 12px;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addInterestContainer p {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.lineForResponsiveness {
  height: 2px;
  background-color: #201a2d;
}

.legalFees .errorMsg {
  position: absolute;
  width: 300px;
  left: 35px;
  top: 33px;
  color: #eb3030;
}
.legalFees .errorMsg3 {
  position: absolute;
  width: 320px !important;
  left: 317px;
  top: -11px !important;
  color: #eb3030;
}
.legalFees .legalCostsContainer {
  display: flex;
  flex-direction: row;
}
.legalFees .legalCostsContainer button {
  align-self: flex-end;
  margin-left: 35px;
  margin-bottom: -3px;
}
.legalFees .legalCostsContainer .legalFeesCalculatorButton {
  visibility: hidden;
}
.legalFees .insuranceContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  padding-bottom: 10px;
  max-width: 67%;
  margin-left: 40px;
}
.legalFees .insuranceContainer .dropdown,
.legalFees .insuranceContainer .inputNum,
.legalFees .insuranceContainer .inputdate,
.legalFees .insuranceContainer .inputNum-RO {
  margin-left: 15px !important;
}
.legalFees .insuranceContainer .insuranceInputsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 10px;
}
.legalFees .deleteContainer {
  align-self: center;
  margin-left: 10px;
  margin-top: 28px;
  position: relative;
  cursor: pointer;
}
.legalFees .deleteContainer img {
  height: 17px;
}

.addInsuranceContainer {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  cursor: pointer;
  margin: 20px 0;
}
.addInsuranceContainer .addSignContainer {
  position: relative;
  width: 14px;
  height: 14px;
  margin: 30px 7px 30px 15px;
}
.addInsuranceContainer .addSignContainer img {
  position: absolute;
  width: 16px;
  font-size: 12px;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addInsuranceContainer p {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.excludeLegalFeesContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.excludeLegalFeesContainer img {
  margin-left: 55px;
  margin-right: 5px;
  width: 32px;
  cursor: pointer;
}
.excludeLegalFeesContainer p {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

#legalFeesComp {
  margin-bottom: 200px;
}

.partyLegalCostsComponentContainer {
  width: 1039px;
  margin-top: 34px;
  border: solid 1px #a699f2;
  border-radius: 16px;
  margin-left: 69px;
  padding-bottom: 100px;
}
@media screen and (max-width: 1320px) {
  .partyLegalCostsComponentContainer {
    width: 627px;
  }
}
.partyLegalCostsComponentContainer.noSecondTrial {
  padding-bottom: 70px;
}
.partyLegalCostsComponentContainer .partyLegalCostsTitle {
  margin-top: 14px;
  margin-left: 15px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer {
  margin-left: 30px;
  margin-top: 8px;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer.additional {
  margin-top: 90px;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .proceedingsTitle {
  color: #ff7336;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer {
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .estimatedCostsInputContainer,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputContainer,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 20px;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer .legalCostsSwitchButtonContainer,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer .legalCostsSwitchButtonContainer {
  min-width: 86px;
  height: 30px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.168627451);
  border: 1px solid #dcdcdc;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 20px !important;
  margin-bottom: 7px;
  padding: 2px;
  cursor: pointer;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton {
  cursor: pointer;
  background-color: transparent;
  border: solid 1px transparent;
  border-radius: 25px;
  padding: 1px 6px 1px 6px;
  min-width: 36px;
  max-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton.selected,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton.selected {
  background-color: #edeaf5;
  border: solid 1px #331a5b;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton .legalCostsSwitchButtonText,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton .legalCostsSwitchButtonText {
  color: #331a5b;
  cursor: pointer;
  font-size: 18px;
}
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton .legalCostsSwitchButtonText.currency,
.partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer .legalCostsSwitchButtonContainer .legalCostsSwitchButton .legalCostsSwitchButtonText.currency {
  font-size: 14px;
}
@media screen and (max-width: 1320px) {
  .partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer {
    margin-top: 20px;
    margin-left: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .recoverableCostsInputOuterContainer {
    margin-top: 30px;
    margin-left: 0px;
  }
  .partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .incurredCostsInputOuterContainer {
    margin-top: 80px;
    margin-left: 0px;
  }
  .partyLegalCostsComponentContainer .legalCostsInputOuterContainer .legalCostsInputContainer .legalCostsSwitchButtonContainer {
    margin-right: 20px !important;
  }
}

.bankruptcy {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}
.bankruptcy .errorMsg {
  left: 315px !important;
  width: 320px !important;
}

.inlcudeBankruptcy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 80px;
}
.inlcudeBankruptcy img {
  margin-left: 9px;
  margin-right: 5px;
  width: 32px;
  cursor: pointer;
}
.inlcudeBankruptcy p {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.bankruptcyHelperText {
  margin: 47px 0 35px 55px;
}

#bankruptcyComp .infoClickText p:first-of-type {
  margin-bottom: 16px;
}

.advancedTabSubTitleContainer {
  margin-top: 39px;
  margin-left: 44px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}
.advancedTabSubTitleContainer .bankruptcyImg {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.legalFeesDistributionRadioButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  z-index: 1px;
  margin-left: 55px;
}
.legalFeesDistributionRadioButtonContainer .legalFeesDistributionMethod {
  margin-bottom: 13px;
}

.legalFeesDistributionAnalysisButton {
  margin-left: 55px;
  margin-top: 50px;
  background-color: #ff7336;
  padding: 1px 42px 1px 42px;
  height: 45px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 4px;
  cursor: pointer;
}
.legalFeesDistributionAnalysisButton:hover {
  background-color: #f86221;
  cursor: pointer;
}
.legalFeesDistributionAnalysisButton .legalFeesDistributionAnalysisButtonText {
  color: white;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}
.legalFeesDistributionAnalysisButton .legalFeesDistributionAnalysisButtonText.disabled {
  cursor: auto;
}
.legalFeesDistributionAnalysisButton:disabled {
  background-color: #818181 !important;
  cursor: auto;
}

.legalFeesDistributionDetailsText {
  margin: 40px 0px 20px 55px;
}

.legalFeesDistributionDetails {
  margin-top: 62px;
  margin-bottom: 50px;
  box-sizing: unset;
  max-width: 1280px;
}
.legalFeesDistributionDetails .slider {
  border: 0;
}

.noLegalCostsIncludedBackground {
  background-color: white;
  position: absolute;
  width: 100%;
  margin-top: 10px;
  height: 80%;
  top: 10%;
  z-index: 2;
}

.noLegalCostsIncludedContainer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 80%;
  top: 15%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.noLegalCostsIncludedContainer .noLegalCostsIncludedMessageContainer {
  width: 674px;
  height: 85px;
  background-color: white;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 75px 0 75px;
  margin-top: 120px;
  margin-bottom: 10px;
}
.noLegalCostsIncludedContainer .noLegalCostsIncludedMessageContainer .noLegalCostsIncludedMessage {
  font-size: 20px;
  font-weight: 900;
  font-family: "poppins-local-bold";
  line-height: 1.25;
  text-align: center;
}

.legalFeesDistributionTransparentLayer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 80%;
  z-index: 3;
}

.legalFeesDistributionTransparentLayer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 80%;
  z-index: 3;
}

#legalFeesDistributionComp {
  margin-bottom: 200px;
  position: relative;
}

.lcdChipsContainer {
  max-width: 750px;
  min-height: 67px;
  min-width: 510px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.1607843137);
  border-radius: 34px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 5px;
}
.lcdChipsContainer .lcdChip {
  margin: 10px 10px 10px 10px;
  padding: 10px 15px 10px 15px;
  min-height: 45px;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.lcdChipsContainer .lcdChip .lcdChipText {
  line-height: 1.1;
  text-align: center;
}
.lcdChipsContainer .lcdChip .lcdChipText.notViewer {
  cursor: pointer;
}
.lcdChipsContainer .lcdChip.selected {
  background-color: #e1d6f4;
  border-radius: 23px;
  cursor: pointer;
}
.lcdChipsContainer .lcdChip:hover {
  cursor: default;
}
.lcdChipsContainer .lcdChip:hover.notViewer {
  cursor: pointer;
}

.LCDStandardText,
.LCDEachPartyPaysText {
  margin: 22px 50px 30px 50px;
  max-width: 830px;
}
.LCDStandardText span,
.LCDEachPartyPaysText span {
  cursor: pointer;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  color: #ff7336;
  text-decoration: underline;
}

.failedCaseMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  position: relative;
  margin-top: 55px;
  width: 700px;
}
.failedCaseMessageContainer .failedCaseMessageTitle {
  position: relative;
  font-size: 26px;
  margin-top: 15px;
  text-align: center;
}
.failedCaseMessageContainer .failedCaseMessageTextContainer {
  position: relative;
  width: 600px;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 25px;
}
.failedCaseMessageContainer .failedCaseMessageTextContainer p {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */ /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: text;
  cursor: text;
}
.failedCaseMessageContainer .failedCaseMessageTextContainer .failedCaseMessageText {
  font-size: 16px;
  line-height: 1.1;
}
.failedCaseMessageContainer .failedCaseMessageTextContainer .failedCaseMessageText .link {
  color: #ff7336;
  cursor: pointer;
}

.cancellingMessageContainer {
  padding-top: 220px;
}

.circularProgressDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loadingResultsContainer {
  position: relative;
  display: flex;
  margin-top: 198px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 500px;
}
.loadingResultsContainer .progressImagesContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: flex-end;
}
.loadingResultsContainer .progressImagesContainer .queue {
  height: 80px;
}
.loadingResultsContainer .progressImagesContainer .calculate {
  width: 86px;
}
.loadingResultsContainer .progressImagesContainer .results {
  width: 133px;
}
.loadingResultsContainer .progressImagesContainer .progressImageInnerContainer {
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.loadingResultsContainer .progressImagesContainer .statusImage {
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.loadingResultsContainer .progressImagesContainer .cancelBecauseOfUpdateMessageContainer {
  color: #ac2828;
}
@keyframes statusImage {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.loadingResultsContainer .progressImagesContainer .progressImageLabel {
  font-size: 16px;
  align-self: center;
  text-align: center;
  margin-top: 15px;
}
.loadingResultsContainer .progressContainer {
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1042px;
}
@media screen and (max-width: 1200px) {
  .loadingResultsContainer .progressContainer {
    width: 80vw;
  }
}
.loadingResultsContainer .progressContainer .progressBarContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 836px) {
  .loadingResultsContainer .progressContainer .progressBarContainer {
    width: 650px;
  }
}
.loadingResultsContainer .progressContainer .progressBarContainer .progressBarBackground {
  position: relative;
  width: 1042px;
  height: 27px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  margin-left: -40px;
}
@media screen and (max-width: 1200px) {
  .loadingResultsContainer .progressContainer .progressBarContainer .progressBarBackground {
    width: 80vw;
  }
}
@media screen and (max-width: 836px) {
  .loadingResultsContainer .progressContainer .progressBarContainer .progressBarBackground {
    width: 650px;
  }
}
.loadingResultsContainer .progressContainer .progressBarContainer .progressBarBackground .progressBar {
  height: 27px;
  border-radius: 50px;
  background-color: #331a5b;
}
.loadingResultsContainer .progressContainer .progressBarContainer .cancelContainer {
  position: absolute;
  right: -20px;
  top: 0px;
  cursor: pointer;
}
.loadingResultsContainer .progressContainer .progressBarContainer .cancelContainer .cancelCase {
  width: 28px;
  border-radius: 15px;
  height: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.loadingResultsContainer .progressContainer .progressBarContainer .cancelContainer .cancelCase .cancelCaseSquare {
  width: 10px;
  height: 10px;
  background-color: #331a5b;
}
.loadingResultsContainer .progressContainer .progressText {
  margin-top: 18px;
  font-size: 20px;
  text-align: center;
}

.executiveSummaryParagraphContainer {
  padding-right: 10px;
  position: relative;
  margin-top: 38px;
  display: flex;
  flex-direction: row;
}
.executiveSummaryParagraphContainer.forReport {
  padding-left: 80px;
}
.executiveSummaryParagraphContainer:first-of-type {
  margin-top: 52px;
  margin-bottom: -18px;
}
.executiveSummaryParagraphContainer:last-of-type {
  position: relative;
  margin-bottom: 20px;
}
.executiveSummaryParagraphContainer .executiveSummarySideTitleContainer {
  margin-left: 43px;
  width: 190px;
  max-height: 60px;
}
.executiveSummaryParagraphContainer .executiveSummarySideTitleContainer .executiveSummarySideTitle {
  line-height: 1.25;
  max-width: 155px;
  font-family: "poppins-local-bold";
}
.executiveSummaryParagraphContainer .valueOfCaseParagraph {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-direction: flex-start;
  position: relative;
}
.executiveSummaryParagraphContainer .valueOfCaseParagraph .valueOfCaseImg {
  position: absolute;
  left: -32px;
}
.executiveSummaryParagraphContainer .valueOfCaseParagraph #valueOfCase-2-image {
  top: 3px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText {
  max-width: 900px;
  cursor: text;
}
@media screen and (max-width: 1320px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText {
    max-width: 700px;
  }
}
@media screen and (max-width: 1090px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText {
    max-width: 600px;
  }
}
@media screen and (max-width: 980px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText {
    max-width: 500px;
  }
}
@media screen and (max-width: 870px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText {
    max-width: 450px;
  }
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText.forReport {
  max-width: 800px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText span {
  font-family: "poppins-local-bold";
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText span.noBold {
  font-family: "poppins-local";
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .link {
  font-family: "poppins-local-medium";
  color: #ff7336;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 300;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .infoCircle {
  cursor: pointer;
  margin-top: -2px;
  height: 14px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer {
  display: flex;
  flex-direction: row;
  width: 700px;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 1320px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer {
    width: 700px;
  }
}
@media screen and (max-width: 1090px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer {
    width: 600px;
  }
}
@media screen and (max-width: 980px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer {
    width: 500px;
  }
}
@media screen and (max-width: 870px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer {
    width: 450px;
  }
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer.forReport {
  width: 700px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 32%;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn.for-titles {
  align-items: flex-start !important;
  max-width: 36%;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .smallColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .smallColumn.divider {
  margin-bottom: 29px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .smallColumn.maxWidth {
  width: 100%;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText {
  width: 100%;
  color: #201a2d;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
@media screen and (max-width: 1000px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText {
    font-size: 14px;
  }
}
@media screen and (max-width: 870px) {
  .executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText {
    font-size: 12px;
  }
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.forReport {
  font-size: 16px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.bold {
  font-weight: bold;
  margin-bottom: 3px;
  text-align: end;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.bold.divider {
  margin-top: 29px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.semi-bold {
  font-family: "poppins-local-medium";
  margin-bottom: 3px;
  text-align: start;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.party {
  margin-bottom: 17px;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.invisible {
  visibility: hidden;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.description {
  margin-left: 17px;
  text-align: start;
}
.executiveSummaryParagraphContainer .executiveSummaryParagraphText .legalCostsContainer .legalCostsMainColumn .legalCostsText.value {
  text-align: end;
  overflow: visible;
}
.executiveSummaryParagraphContainer #calculateZopa {
  margin-top: 10px;
}
.executiveSummaryParagraphContainer .zopaParagraph {
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummaryParagraphContainer .zopaComponent {
  width: 980px;
  margin-left: -160px;
  margin-top: 15px;
}

.zopaGraphOuterContainer {
  height: 283px;
  width: 980px;
  padding-left: 140px;
  padding-top: 1px;
  position: relative;
}
.zopaGraphOuterContainer .zopaInnerContainer {
  height: 120px;
  width: 100px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer {
  height: 130px;
  margin-left: 0px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 111px;
  margin-left: 92px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar {
  height: 37px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTick {
  position: absolute;
  width: 1px;
  height: 32px;
  background-color: #201a2d;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTick.top {
  top: -13.5px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTick.bottom {
  top: 95px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickText {
  position: absolute;
  width: 230px;
  line-height: 1.1;
  font-size: 12px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickText.left {
  text-align: right;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickText.top {
  top: -28px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickText.bottom {
  top: 110px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickTop {
  position: absolute;
  width: 7.5px;
  height: 1px;
  background-color: #201a2d;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickTop.top {
  top: -13.5px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickTop.bottom {
  top: 126px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickNumber {
  position: absolute;
  text-align: right;
  width: 200px;
  top: 8px;
  font-size: 12px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickNumber.right {
  text-align: left;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickNumber.zopa {
  font-size: 16px;
  top: 43px;
  font-weight: bold;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .zopaTickNumber.client {
  font-size: 12px;
  top: 84px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph .zopaBar .nozopaText {
  width: 315px;
  position: absolute;
  top: 45px;
  left: 240px;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph #zopaGraphOpposingParty {
  background-color: #ff703e;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph #zopaGraphOpposingParty.krogerus {
  background-color: #ffcb2b;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph #zopaGraphZOPA {
  background-color: #000000;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph #zopaGraphZOPA.krogerus {
  background-color: #6f6f6f;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph #zopaGraphClient {
  background-color: #5a45c9;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .zopaGraph #zopaGraphClient.krogerus {
  background-color: #000000;
}
.zopaGraphOuterContainer .zopaInnerContainer .zopaGraphAppContainer .zopaGraphContainer .currencyText {
  position: absolute;
  left: 690px;
  top: 110px;
  font-size: 12px;
}
.zopaGraphOuterContainer .zopaLegendContainer {
  margin-top: 40px;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor {
  margin-left: 30px;
  margin-right: 9px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #201a2d;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor.opposingParty {
  background-color: #ff703e;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor.opposingParty.krogerus {
  background-color: #ffcb2b;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor.client {
  background-color: #5a45c9;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor.client.krogerus {
  background-color: #000000;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor.black {
  background-color: #000000;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendColor.black.krogerus {
  background-color: #6f6f6f;
}
.zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendText {
  font-size: 12px;
  margin-top: 4px;
}
@media screen and (max-width: 1080px) {
  .zopaGraphOuterContainer .zopaLegendContainer .zopaLegendRow .zopaLegendText {
    width: 400px;
  }
}
.zopaGraphOuterContainer .hiddenSectionLayerContainer {
  background-color: white;
  position: absolute;
  width: 105%;
  height: 120%;
  top: -40px;
  left: 0px;
  opacity: 0.8;
}

.includeOptionContainer {
  position: absolute;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.includeOptionContainer .includeOptionText {
  margin-right: 3px;
  cursor: pointer;
}
.includeOptionContainer .includeOptionImg {
  cursor: pointer;
}

.executiveSummary3Container {
  padding-left: 128px;
}
.executiveSummary3Container .fade {
  color: #d9d9d9 !important;
}
.executiveSummary3Container.forReport {
  padding-left: 0px;
}
@media screen and (max-width: 1100px) {
  .executiveSummary3Container {
    padding-left: 60px;
  }
  .executiveSummary3Container.forReport {
    padding-left: 0px;
  }
}
.executiveSummary3Container .claimsAndLegalCostsContainer.forReport {
  padding-left: 114px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 86px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer.forReport {
  margin-top: 30px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent:first-of-type {
  margin-right: 95px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent:first-of-type.forReport {
  margin-right: 50px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent:first-of-type.opposingPartyView {
  margin-right: 0px;
  margin-left: 95px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .partyNameContainer {
  margin-bottom: 3px;
  max-width: 470px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .partyNameContainer .partyName {
  font-size: 22px;
  font-family: "poppins-local-bold";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer {
  border: 1px solid #8b8b8b;
  border-radius: 5px;
  width: 470px;
  padding: 12px 12px 16px 25px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer.fade {
  border: 1px solid #d9d9d9;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .claimedAmountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .claimedAmountContainer .claimedAmountText {
  font-family: "poppins-local-medium";
  color: #8b8b8b;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .claimedAmountContainer .claimedAmountValue {
  font-family: "poppins-local-bold";
  font-size: 20px;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent {
  margin-top: 3px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent:last-of-type {
  margin-top: 12px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .estimatedLegalCostsContainer,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .recoverableCostsContainer,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .incurredCostsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .estimatedLegalCostsContainer .legalCostsTitle,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .recoverableCostsContainer .legalCostsTitle,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .incurredCostsContainer .legalCostsTitle {
  color: #8b8b8b;
  text-wrap: nowrap;
  font-size: 15.5px;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .estimatedLegalCostsContainer .legalCostsTitle.medium,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .recoverableCostsContainer .legalCostsTitle.medium,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .incurredCostsContainer .legalCostsTitle.medium {
  font-family: "poppins-local-medium";
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .estimatedLegalCostsContainer .legalCostsTitle.description,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .recoverableCostsContainer .legalCostsTitle.description,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .incurredCostsContainer .legalCostsTitle.description {
  margin-left: 19px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .estimatedLegalCostsContainer .legalCostsValue,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .recoverableCostsContainer .legalCostsValue,
.executiveSummary3Container .claimsAndLegalCostsContainer .partyClaimsAndLegalCostsContainer .partyClaimsAndLegalCostsComponent .claimsAndLegalCostsContainer .legalCostsContainer .legalCostsComponent .incurredCostsContainer .legalCostsValue {
  text-align: right;
  text-wrap: nowrap;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .proceedingsDateContainer {
  margin-top: -5px;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .proceedingsDateContainer .proceedingsDateComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .proceedingsDateContainer .proceedingsDateComponent .proceedingsDateText {
  font-size: 16px;
  color: #8b8b8b;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .claimsAndLegalCostsContainer .proceedingsDateContainer .proceedingsDateComponent .proceedingsDate {
  margin-left: 4px;
  font-family: "poppins-local-bold";
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .expectedValueContainer {
  margin-top: 80px;
}
.executiveSummary3Container .expectedValueContainer.forReport {
  padding-left: 114px;
}
.executiveSummary3Container .expectedValueContainer .expectedValueTitle {
  font-family: "poppins-local-bold";
  font-size: 22px;
  margin-bottom: 2px;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .expectedValueContainer .expectedValueTitle span {
  font-family: "poppins-local-bold";
}
.executiveSummary3Container .expectedValueContainer .expectedValueDescription {
  color: #8b8b8b;
  max-width: 1000px;
  margin-right: 20px;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.executiveSummary3Container .bestWorstScenarioContainer {
  margin-top: 80px;
}
.executiveSummary3Container .bestWorstScenarioContainer.forReport {
  padding-left: 114px;
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTitle {
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  font-family: "poppins-local-bold";
  font-size: 22px;
  margin-bottom: 2px;
}
.executiveSummary3Container .bestWorstScenarioContainer .scenarioProbability .scenarioProbabilityText,
.executiveSummary3Container .bestWorstScenarioContainer .scenarioProbability span {
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  color: #8b8b8b;
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #8b8b8b;
  border-radius: 5px;
  max-width: 855px;
  margin-right: 30px;
  margin-top: 8px;
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer.fade {
  border: 1px solid #d9d9d9;
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 2px;
  margin-bottom: 2px;
}
@media screen and (max-width: 1100px) {
  .executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow {
    margin-right: 5px;
  }
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column1 {
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  width: 300px;
  text-align: right;
  color: #8b8b8b;
}
@media screen and (max-width: 1100px) {
  .executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column1 {
    width: 30%;
  }
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column2,
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column3 {
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  width: 250px;
  text-align: right;
}
@media screen and (max-width: 1100px) {
  .executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column2,
  .executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column3 {
    display: block !important;
    width: 35%;
    min-width: 35%;
    max-width: 35%;
  }
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column2.financialOutcome .financialOutcomeTextContainer,
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column3.financialOutcome .financialOutcomeTextContainer {
  margin-left: 10%;
  width: 90%;
  align-self: flex-end;
}
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column2.financialOutcome .financialOutcomeTextContainer .financialOutcomeText,
.executiveSummary3Container .bestWorstScenarioContainer .bestWorstScenarioTableContainer .bestWorstScenarioTableRow .column3.financialOutcome .financialOutcomeTextContainer .financialOutcomeText {
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  font-size: 20px;
  font-family: "poppins-local-bold";
}
.executiveSummary3Container .newZopaContainer {
  margin-top: 60px;
}
.executiveSummary3Container .newZopaContainer.forReport {
  padding-left: 114px;
}
.executiveSummary3Container .newZopaContainer .newZopaTitle {
  font-family: "poppins-local-bold";
  font-size: 22px;
  margin-bottom: 2px;
}
.executiveSummary3Container .newZopaContainer .newZopaDescription {
  max-width: 1000px;
  margin-bottom: 10px;
  margin-right: 20px;
}
.executiveSummary3Container .newZopaContainer .newZopaDescription .newZopaDescriptionText {
  color: #8b8b8b;
}
@media screen and (max-width: 1000px) {
  .executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer {
    padding-left: 20px;
  }
}
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer .zopaTickText,
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer .zopaTickNumber {
  color: #8b8b8b;
}
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer .zopaTickText.zopa,
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer .zopaTickNumber.zopa {
  color: #201a2d;
}
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer #zopaGraphOpposingParty,
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer #zopaGraphClient {
  background-color: #d5d5d5 !important;
}
.executiveSummary3Container .newZopaContainer .zopaGraphOuterContainer #zopaGraphZOPA {
  background-color: #d0d3e8 !important;
}
.executiveSummary3Container .hiddenSectionLayerContainerZOPA {
  background-color: white;
  position: absolute;
  width: 105%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.8;
}
.executiveSummary3Container .calculateZopaContainer {
  margin-top: 35px;
}
.executiveSummary3Container .calculateZopaContainer .newZopaTitle {
  font-family: "poppins-local-bold";
  font-size: 22px;
  margin-bottom: 2px;
}
.executiveSummary3Container .calculateZopaContainer .calculateZopaButton {
  margin-top: 10px;
  margin-bottom: 30px;
}
.executiveSummary3Container .executiveSummaryLegendsContainer {
  margin-top: 60px;
}
.executiveSummary3Container .executiveSummaryLegendsContainer.forReport {
  padding-left: 114px;
}
.executiveSummary3Container .executiveSummaryLegendsContainer .executiveSummaryLegendText {
  font-size: 10px;
}
.executiveSummary3Container .previewPDF {
  padding-left: 114px;
}

.getPayGraphTitle {
  font-size: 22px;
  margin-top: 40px;
  font-family: "poppins-local-bold";
}

.getPayGraphSubtitle {
  font-size: 16px;
  margin-bottom: 40px;
  width: 100%;
}

.getPayChart {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.getPayChart .payGetGraphContainer {
  width: 800px;
  position: relative;
  margin-bottom: 20px;
}
.getPayChart .payGetGraphContainer .getPayGraphDivider {
  position: absolute;
  left: 373px;
  width: 6px;
  top: -20px;
  height: calc(100% + 40px);
  background-color: #d9d9d9;
  border-radius: 20px;
}
.getPayChart .payGetGraphContainer .getPayGraphRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}
.getPayChart .payGetGraphContainer .getPayGraphRow .getPayGraphRowLeftPart {
  text-align: right;
  width: 370px;
  max-width: 370px;
  margin-right: 17px;
}
.getPayChart .payGetGraphContainer .getPayGraphRow .getPayGraphRowLeftPart p {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.getPayChart .payGetGraphContainer .getPayGraphRow .getPayGraphRowRightPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 430px;
  max-width: 430px;
}
.getPayChart .payGetGraphContainer .getPayGraphRow .getPayGraphRowRightPart .getPayBar {
  height: 18px;
  border-radius: 0 19px 19px 0;
  margin-right: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.getPayChart .payGetGraphContainer .getPayGraphRow .getPayGraphRowRightPart .getPayBarPercentage {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}

.selectionAreaContainer {
  position: absolute;
}
.selectionAreaContainer .expectedValueLine {
  height: 1px;
  position: absolute;
  background-image: repeating-linear-gradient(89deg, #8e8e8e, #8e8e8e 5px, transparent 5px, transparent 10px);
}
.selectionAreaContainer .selectionArea {
  position: absolute;
  top: 0;
  background-color: rgba(150, 150, 150, 0.2);
}
.selectionAreaContainer .selectionArea.dragging {
  cursor: w-resize;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer {
  width: 100%;
  height: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 2px;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer.tree {
  margin-top: -17px;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer.negativeGraph {
  margin-top: 2px;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer.negativeGraph.tree {
  margin-top: -30px;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer .selectionAreaProbabilityText {
  font-weight: bold;
  height: 20px;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer .selectionAreaProbabilityText.notHighlight {
  color: #8e8e8e;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer .selectionAreaProbabilityText.small {
  min-width: 60px;
  text-align: center;
}
.selectionAreaContainer .selectionArea .selectionArea-probabilityContainer .selectionAreaProbabilityText.tree {
  font-size: 10px;
}
.selectionAreaContainer .handleContainer {
  position: "relative";
}
.selectionAreaContainer .handleContainer .handlePlaceholder {
  position: absolute;
  top: 0;
  width: 20px;
  cursor: ew-resize;
}
.selectionAreaContainer .handleContainer .handlePlaceholder.tree {
  width: 20px;
  left: 10px;
}
.selectionAreaContainer .handleContainer .handlePlaceholder.right {
  left: 20px;
}
.selectionAreaContainer .handleContainer .handleLine {
  position: absolute;
  top: 0;
  left: 18px;
  width: 3px;
  background-color: #8e8e8e;
  cursor: ew-resize;
}
.selectionAreaContainer .handleContainer .handleImage {
  position: absolute;
  top: -17px;
  left: 3px;
  box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1607843137), 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 20px;
  cursor: ew-resize;
}
.selectionAreaContainer .handleContainer .handleImage.tree {
  top: -15px;
  left: 5px;
  width: 30px;
}
.selectionAreaContainer .handleContainer .handleDeleteButton {
  position: absolute;
  top: 390px;
  left: 3px;
  box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1607843137), 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 20px;
  width: 35px;
  height: 35px;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectionAreaContainer .handleContainer .addSelectionAreaContainer {
  position: absolute;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectionAreaContainer .handleContainer .addSelectionAreaContainer.handleLeft-button {
  left: -35px;
}
.selectionAreaContainer .handleContainer .addSelectionAreaContainer.handleRight-button {
  left: 25px;
}
.selectionAreaContainer .handleContainer .addSelectionAreaContainer .addSelectionImg {
  background-color: white;
  width: 30px;
  border-radius: 15px;
  padding: 4px;
  box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1607843137), 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.selectionAreaContainer .handleContainer .amountText {
  position: absolute;
  top: -74px;
  left: 0px;
  width: 200px;
  font-size: 12px;
  transform: rotate(325deg);
}
.selectionAreaContainer .handleContainer .amountText.notHighlight {
  color: #8e8e8e;
}
.selectionAreaContainer .handleContainer .amountText.doubleLeft {
  left: -15px;
}
.selectionAreaContainer .handleContainer .amountText.doubleRight {
  left: 15px;
}
.selectionAreaContainer .handleContainer .amountText.small {
  font-size: 10px;
  transform: rotate(325deg);
  top: -74px;
  left: -10px;
}
.selectionAreaContainer .handleContainer .amountText.tree {
  font-size: 10px;
  top: -52px;
  width: 100px;
}
.selectionAreaContainer .handleContainer .amountText.tree.small {
  transform: rotate(280deg);
  top: -113px;
  left: -60px;
}
.selectionAreaContainer .handleContainer .amountText.negativeGraph {
  top: -74px;
}
.selectionAreaContainer .handleContainer .amountText.negativeGraph.tree {
  top: -54px;
}

.deleteButtonsBackground {
  height: 50px;
  position: absolute;
}

.showSelectionAreasTickContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.showSelectionAreasTickContainer .showSelectionAreasText {
  margin-left: 10px;
  cursor: pointer;
  cursor: pointer;
}
.showSelectionAreasTickContainer .tickImg {
  cursor: pointer;
}

.expectedValueBreakdownGraphContainer {
  margin-top: 40px;
}
.expectedValueBreakdownGraphContainer.forReport {
  margin-top: 0px;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownSubtitleForReport {
  margin-bottom: 20px;
  margin-left: -40px;
  width: 100%;
  text-align: center;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer {
  margin-left: 60px;
  margin-right: 150px;
}
@media screen and (max-width: 1200px) {
  .expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer {
    margin-right: 40px;
  }
}
@media screen and (max-width: 900px) {
  .expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer {
    margin-left: 0px;
  }
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer.forReport {
  margin-left: 0px;
  margin-right: 100px;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer {
  margin-bottom: 30px;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownTitle {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
  font-family: "poppins-local-medium";
  font-size: 18px;
}
@media screen and (max-width: 900px) {
  .expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownTitle {
    padding-left: 40px;
  }
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent {
  display: flex;
  flex-direction: row;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownTextContainer {
  width: 60%;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media screen and (max-width: 1200px) {
  .expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownTextContainer {
    width: 50%;
  }
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownTextContainer .valueBreakdownText {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
  height: 40px;
  display: flex;
  align-items: center;
  color: #8b8b8b;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownTextContainer .valueBreakdownText.expectedValue {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
  font-family: "poppins-local-medium";
  font-size: 18px;
  padding-left: 0px;
  color: #201a2d;
  margin-left: -40px;
}
@media screen and (max-width: 900px) {
  .expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownTextContainer .valueBreakdownText.expectedValue {
    padding-left: 40px;
  }
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph {
  width: 40%;
  display: flex;
  flex-direction: row;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph {
    width: 50%;
  }
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownValuesContainer {
  width: 50%;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownValuesContainer .breakdownValue {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  margin-left: 2px;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownValuesContainer .breakdownValue.expectedValue {
  font-family: "poppins-local-bold" !important;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownValuesDivider {
  position: absolute;
  width: 6px;
  left: calc(50% - 3px);
  top: -20px;
  height: calc(100% + 40px);
  background-color: #d9d9d9;
  border-radius: 20px;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownBarsContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownBarsContainer .breakdownBarComponent {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownBarsContainer .breakdownBarComponent .breakdownBar {
  height: 19px;
  display: flex;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0px 19px 19px 0px;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownBarsContainer .breakdownBarComponent .breakdownBar.positive {
  background-color: #47743d;
}
.expectedValueBreakdownGraphContainer .expectedValueBreakdownContainer .valueBreakdownContainer .valueBreakdownComponent .valueBreakdownGraph .breakdownBarsContainer .breakdownBarComponent .breakdownBar.negative {
  background-color: #ac2828;
}

.hoverBackground {
  max-width: 600px;
  min-width: 400px;
  max-height: 320px;
  overflow-y: auto;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3647058824);
  padding: 10px 5px 20px 10px;
  margin-top: -23px;
}
.hoverBackground .descriptionContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
}
.hoverBackground .descriptionContainer .descriptionText {
  font-size: 16px;
  margin-left: 20px;
}
.hoverBackground .hoverTitle {
  margin-top: 14px;
  margin-left: 10px;
  font-size: 16px;
  text-align: left !important;
  margin-right: 2px;
  font-weight: normal !important;
}
.hoverBackground .pathElementContainer {
  max-height: 246px;
  width: 415px;
  overflow-y: scroll;
  margin-top: 10px;
  /* width */
  /* Track */
  /* Handle */
}
.hoverBackground .pathElementContainer .pathElement {
  width: 390px;
  min-height: 50px;
  border-radius: 10px;
  margin-top: 12px;
  padding: 10px;
}
.hoverBackground .pathElementContainer .pathElement:first-of-type {
  margin-top: 0px;
}
.hoverBackground .pathElementContainer .pathElement .claimTitle {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  text-align: left !important;
}
.hoverBackground .pathElementContainer .pathElement .scenarioBreakdownContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
}
.hoverBackground .pathElementContainer .pathElement .scenarioBreakdownContainer:first-of-type {
  margin-top: 20px;
}
.hoverBackground .pathElementContainer .pathElement .scenarioBreakdownContainer .treeQuestions {
  font-size: 16px;
  font-weight: normal;
  margin-left: 20px;
  width: 230px;
  text-align: left !important;
  margin-right: 7px;
  margin-bottom: 15px;
  padding-bottom: 2px;
}
.hoverBackground .pathElementContainer .pathElement .scenarioBreakdownContainer .scenarioTitle {
  font-size: 16px;
  font-weight: normal;
  margin-left: 50px;
  width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  margin-right: 7px;
  padding-bottom: 2px;
}
.hoverBackground .pathElementContainer .pathElement .scenarioBreakdownContainer .amount {
  font-weight: normal;
  margin-right: 2px;
  font-size: 16px;
}
.hoverBackground .pathElementContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.hoverBackground .pathElementContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.hoverBackground .pathElementContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}

.hoverTextContainer {
  margin: 8px 0px;
}
.hoverTextContainer:first-of-type {
  margin-top: 20px;
}
.hoverTextContainer .hoverText {
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px !important;
  line-height: 1.6;
  text-align: left !important;
  margin-right: 10px !important;
}
.hoverTextContainer .hoverText span {
  font-weight: 600;
}

.hoverTriangle {
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid gray;
}

.descriptionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 7px 0px;
}
.descriptionRow .descriptionImgContainer {
  width: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.descriptionRow .descriptionImgContainer .descriptionImg {
  height: 20px;
}
.descriptionRow .descriptionText {
  font-size: 16px !important;
  font-weight: normal !important;
  margin-left: 9px !important;
  max-width: 350px;
}

.horizontalResultsContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.horizontalResultsContainer .headerBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 35px;
  width: 100%;
  background-color: #edeaf5;
  border-radius: 5px;
}
.horizontalResultsContainer .headerBackground.previewPdf {
  background-color: #e9e9e9;
}
.horizontalResultsContainer .line {
  margin-top: 7px;
  height: 1px;
  background-color: #c9c9c9;
}
.horizontalResultsContainer .headersContainer {
  z-index: 2;
  width: 510px;
  min-width: calc(490px);
}
.horizontalResultsContainer .headersContainer.bestWorstHeadersContainer {
  width: calc(590px);
  min-width: calc(590px);
}
.horizontalResultsContainer .headersContainer .title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.horizontalResultsContainer .headersContainer .title .resultsTableTitleInRow {
  font-size: 20px;
  color: #201a2d;
  margin-left: 30px;
  cursor: pointer;
}
.horizontalResultsContainer .headersContainer .title2 .resultsTableTitleInRow {
  color: #a1a6c7;
}
.horizontalResultsContainer .headersContainer .row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.horizontalResultsContainer .headersContainer .row .resultsTableTitleInRow {
  color: #2e394b;
  margin-left: 50px;
  cursor: pointer;
}
.horizontalResultsContainer .headersContainer .row .resultsTableTitleInRow span {
  font-weight: 600;
}
.horizontalResultsContainer .headersContainer .row11 .resultsTableTitleInRow {
  font-size: 20px;
  font-weight: 600;
}
.horizontalResultsContainer .headersContainer .row12 .resultsTableTitleInRow {
  font-weight: normal;
}
.horizontalResultsContainer .headersContainer .line {
  margin-left: 30px;
}
.horizontalResultsContainer .headersContainer img {
  height: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.horizontalResultsContainer .row,
.horizontalResultsContainer .title {
  height: 35px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.horizontalResultsContainer .row12 {
  margin-top: 6px;
}
.horizontalResultsContainer .resultsColumnsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 1;
  overflow-x: auto;
}
.horizontalResultsContainer .resultsColumnsContainer .resultsRows {
  width: 200px;
}
.horizontalResultsContainer .resultsColumnsContainer .resultsRows .title1 p {
  font-size: 16px;
  color: #201a2d;
  margin: auto;
  font-weight: 600;
  margin-right: 60px;
}
.horizontalResultsContainer .resultsColumnsContainer .resultsRows .row p {
  color: #201a2d;
  max-width: 140px;
  margin: auto;
  margin-right: 60px;
  text-align: right;
}
.horizontalResultsContainer .resultsColumnsContainer .resultsRows .row1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 60px;
}
.horizontalResultsContainer .resultsColumnsContainer .resultsRows .row11 p {
  font-weight: 600;
}
.horizontalResultsContainer .resultsColumnsContainer .resultsRows .resultsTitle {
  width: 200px;
}
.horizontalResultsContainer .maxMinColumnsContainer {
  overflow-x: auto;
  /* Track */
  /* Handle */
}
@media screen and (max-width: 685px) {
  .horizontalResultsContainer .maxMinColumnsContainer {
    overflow-x: visible;
  }
}
.horizontalResultsContainer .maxMinColumnsContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.horizontalResultsContainer .maxMinColumnsContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.horizontalResultsContainer .maxMinColumnsContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}

.textForHover:hover {
  color: #ff7336 !important;
  text-decoration: underline;
  cursor: pointer;
}

.negativeInResultsList {
  color: #ac2828 !important;
}

.horizontalResults {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  /* width */
  /* Track */
  /* Handle */
}
.horizontalResults::-webkit-scrollbar {
  height: 20px;
  border-radius: 10px;
}
.horizontalResults::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
}
.horizontalResults::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}

.resultsInfoPopUp {
  position: absolute;
  z-index: 5;
  text-align: left !important;
}

.settlementResult {
  -webkit-user-select: auto;
     -moz-user-select: auto;
          user-select: auto;
  cursor: text;
}
.settlementResult span {
  font-weight: 600;
}

.calculateSettlementContainer {
  margin-top: 5px;
}

#settlementDescription {
  margin-bottom: 25px;
}

.link {
  color: #ff7336;
  cursor: pointer;
}

.transparent-createPdr-Background {
  position: fixed;
  background-color: rgba(128, 128, 128, 0.8);
  align-self: center;
  left: 0px;
  top: 0px;
  width: 100vw !important;
  height: 400vw;
  z-index: 9;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
}
@media screen and (max-width: 1400px) {
  .transparent-createPdr-Background {
    align-items: flex-start;
    overflow-x: scroll;
  }
  .transparent-createPdr-Background .circularProgressDiv {
    margin-top: 500px;
    height: 100px;
    margin-left: 48%;
  }
}
.transparent-createPdr-Background .circularProgressDiv {
  margin-top: 500px;
  height: 100px;
}
.transparent-createPdr-Background .pdfMenuOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  margin-top: 130px;
  overflow-x: hidden;
  max-width: 1284px;
  min-width: 1284px;
  /* width */
  /* Track */
  /* Handle */
}
@media screen and (max-width: 1400px) {
  .transparent-createPdr-Background .pdfMenuOuterContainer {
    margin-left: 60px;
    overflow-x: scroll;
  }
}
.transparent-createPdr-Background .pdfMenuOuterContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  visibility: hidden;
}
.transparent-createPdr-Background .pdfMenuOuterContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  margin-top: 30px;
}
.transparent-createPdr-Background .pdfMenuOuterContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.transparent-createPdr-Background .pdfMenuOuterContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .loadingGifContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfMenuloadingImagesContainer {
  position: fixed;
  background-color: #f6f6f6;
  align-self: center;
  left: 0px;
  top: 0px;
  width: 100vw !important;
  height: 100vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfMenuloadingImagesContainer .pdfMenuloadingImages {
  font-size: 45px;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfMenuInnerContainer {
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  width: 98%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 27px 0px 64px 0px;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfMenuInnerContainer .exitButtonContainer {
  position: absolute;
  top: 11px;
  right: 40px;
  cursor: pointer;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfMenuInnerContainer .exitButtonContainer .exitButtonImg {
  cursor: pointer;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfMenuInnerContainer .circularProgressDiv {
  width: 100%;
  height: 100% !important;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .pdfDocumentPreviewContainer {
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
}
.transparent-createPdr-Background .pdfMenuOuterContainer .previewPdfContainer {
  width: 98%;
  background-color: white;
  margin-bottom: 200px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.transparent-createPdr-Background .hiddenClaimsContainer {
  visibility: hidden;
  height: 1px !important;
  overflow-y: hidden;
}
.transparent-createPdr-Background .sectionOrderMenuContainer {
  width: 971px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 14px 10px 44px 24px;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuTitle {
  font-size: 20px;
  font-weight: 600;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer {
  margin-top: 23px;
  margin-left: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer {
  width: 23px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer.aloneUp {
  justify-content: flex-start;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer.aloneDown {
  justify-content: flex-end;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer.hidden {
  visibility: hidden;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer .orderArrowUpImg,
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer .orderArrowDownImg {
  cursor: pointer;
  width: 23px;
  height: 20px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1607843137));
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer .orderArrowUpImg.orderArrowDownImg,
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .orderArrowsContainer .orderArrowDownImg.orderArrowDownImg {
  transform: rotate(180deg);
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .includeSectionContainer {
  cursor: pointer;
  margin-left: 8px;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .sectionTitleContainer {
  cursor: pointer;
  margin-left: 10px;
}
.transparent-createPdr-Background .sectionOrderMenuContainer .sectionOrderMenuSectionsContainer .sectionOrderComponentContainer .sectionTitleContainer .sectionTitle {
  cursor: pointer;
}

.dateOfTrialContainer {
  position: relative;
  margin-top: 26px;
  padding-left: 114px;
}
.dateOfTrialContainer .instanceAndDateContainer {
  margin-left: -100px;
  padding-left: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dateOfTrialContainer .instanceAndDateContainer .instanceTitle {
  font-size: 20px;
  font-weight: 600;
  margin-left: -4px;
  margin-top: 6px;
  padding: 2px 4px;
  cursor: text;
}
.dateOfTrialContainer .instanceAndDateContainer .instanceInput {
  border: #331a5b solid 1px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  margin-left: -4px;
  margin-top: 6px;
  padding: 1px 3px;
  width: 770px;
}
.dateOfTrialContainer .instanceAndDateContainer .instanceInput:focus {
  outline: none !important;
  border: 2px solid #331a5b !important;
  padding: 0px 2px;
}
.dateOfTrialContainer .dateOfTrialDateContainer {
  margin-left: -100px;
  padding-left: 100px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.dateOfTrialContainer .dateOfTrialDateContainer .editButtonContainer {
  left: 35px;
  top: -5px;
}
.dateOfTrialContainer .dateOfTrialDateContainer .dateOfTrialIntroText {
  cursor: text;
}
.dateOfTrialContainer .dateOfTrialDateContainer .dateOfTrialIntroTextInput {
  border: #331a5b solid 1px;
  border-radius: 4px;
  font-size: 16px;
  margin-left: -4px;
  margin-top: -2px;
  padding: 1px 3px;
  width: 240px;
  margin-bottom: -2px;
}
.dateOfTrialContainer .dateOfTrialDateContainer .dateOfTrialIntroTextInput:focus {
  outline: none !important;
  border: 2px solid #331a5b !important;
  padding: 0px 2px;
  margin-bottom: -2px;
}
.dateOfTrialContainer .dateOfTrialDateContainer .dateOfTrialDate {
  margin-left: 5px;
  font-weight: 600;
}
.dateOfTrialContainer .dateOfTrialDateContainer .dateOfTrialDate span {
  font-weight: 400;
}

.secondTrialProbsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  padding-left: 114px;
}
.secondTrialProbsContainer .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.secondTrialProbsContainer .row .probability {
  font-weight: 600;
  color: #201a2d;
}
.secondTrialProbsContainer .row .text {
  margin-left: 7px;
}
.secondTrialProbsContainer .bracketImg {
  margin-left: 20px;
  margin-right: 20px;
}

.legalCostsPreviewContainer {
  padding-left: 114px;
  display: flex;
  flex-direction: row;
  width: 970px;
  align-items: flex-start;
  justify-content: space-between;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 34%;
  margin-right: 8px;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn.for-titles {
  align-items: flex-start !important;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn.divider {
  margin-bottom: 29px;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn.maxWidth {
  width: 100%;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn .legalCostsPreviewEditButtonContainer {
  position: absolute;
  left: 50px;
  margin-top: -50px;
  width: 63px;
  height: 23.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn .legalCostsPreviewEditButtonContainer img {
  cursor: pointer;
  margin-right: 8px;
  width: 16px;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn .legalCostsPreviewEditButtonContainer p {
  cursor: pointer;
  margin-top: 1px;
  font-size: 15px;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewSmallColumn .proceedingsTextInput {
  width: 190px;
  height: 24px;
  font-size: 16px;
  margin-bottom: 3px;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText {
  width: 100%;
  color: #201a2d;
  font-size: 16px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: text;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.noEllipsis {
  overflow: initial;
  text-overflow: initial;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.bold {
  font-weight: bold;
  margin-bottom: 3px;
  text-align: end;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.bold.divider {
  margin-top: 29px;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.semi-bold {
  font-family: "poppins-local-medium";
  margin-bottom: 3px;
  text-align: start;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.editable {
  cursor: text;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.party {
  margin-bottom: 17px;
  font-weight: bold;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.invisible {
  visibility: hidden;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.description {
  margin-left: 17px;
  text-align: start;
}
.legalCostsPreviewContainer .legalCostsPreviewMainColumn .legalCostsPreviewText.value {
  text-align: end;
  overflow: visible;
}

.reportParagraphContainer {
  padding-left: 114px;
  margin-top: 10px;
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
}
.reportParagraphContainer .textarea-size {
  position: absolute;
  visibility: hidden;
  z-index: -1;
  white-space: pre-line;
  width: 1030px;
  padding: 5px 5px 60px 5px;
}
.reportParagraphContainer .textarea-size.claimParagraph {
  width: 666px;
  margin-left: 3px;
}
.reportParagraphContainer .reportParagraphTextAreaContainer {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
.reportParagraphContainer .reportParagraphTextArea {
  width: 1030px;
  box-sizing: border-box;
  border: #331a5b solid 1px;
  border-radius: 5px;
  overflow-y: hidden;
  color: #8b8b8b;
  resize: none;
  padding: 5px 5px 60px 5px;
}
.reportParagraphContainer .reportParagraphTextArea.claimParagraph {
  width: 666px;
}
.reportParagraphContainer .reportParagraphTextArea::-moz-placeholder {
  color: rgb(201, 197, 197);
}
.reportParagraphContainer .reportParagraphTextArea::placeholder {
  color: rgb(201, 197, 197);
}
.reportParagraphContainer .reportParagraphTextArea.hideBorders {
  border: white solid 1px;
}
.reportParagraphContainer .reportParagraphTextFromSnapshot {
  cursor: text;
  width: 1030px;
  border: transparent solid 1px;
  border-radius: 5px;
  resize: none;
  padding: 5px 5px 30px 5px;
  overflow-y: hidden;
}
.reportParagraphContainer .reportParagraphTextFromSnapshot.claimParagraph {
  width: 666px;
}
.reportParagraphContainer .reportParagraphTextFromSnapshot:hover {
  border: #331a5b solid 1px;
}
.reportParagraphContainer .saveParagraphContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.reportParagraphContainer .revertTextAndDeleteContainer {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer {
  margin-left: 10px;
  border-radius: 4px;
  border: transparent solid 0.5px;
  cursor: pointer;
  padding: 3px;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer.inactive {
  cursor: default;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer.inactive:hover {
  border: none;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer:hover {
  border: #331a5b solid 0.5px;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer .undoImg {
  width: 12px;
  cursor: pointer;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer .redoImg {
  width: 12px;
  cursor: pointer;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer .resetImg {
  cursor: pointer;
}
.reportParagraphContainer .revertTextAndDeleteContainer .thumbnailContainer .deleteImg {
  cursor: pointer;
}
.reportParagraphContainer .wordCounterDiv {
  position: absolute;
  left: 12px;
  bottom: 10px;
  color: #201a2d;
}
.reportParagraphContainer .wordCounterDiv.wordForClaims {
  left: 6px;
}

.previewPdfMargin {
  margin-left: 114px;
}

.fadeText {
  color: #d9d9d9 !important;
}

.reportSectionContainer,
.disclaimerContainer {
  position: relative;
  margin-top: 54px;
}
.reportSectionContainer .listPreviewMainContainer,
.disclaimerContainer .listPreviewMainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .listPreviewMainContainer.expanded,
.disclaimerContainer .listPreviewMainContainer.expanded {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.reportSectionContainer .listPreviewMainContainer.hovered,
.disclaimerContainer .listPreviewMainContainer.hovered {
  border-radius: 5px 5px 0px 5px;
}
.reportSectionContainer .listPreviewMainContainer .listTreeAddedContainer,
.disclaimerContainer .listPreviewMainContainer .listTreeAddedContainer {
  width: calc(43% - 7px);
  display: flex;
  align-items: center;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNumber,
.disclaimerContainer .listPreviewMainContainer .listPreviewNumber {
  font-size: 13px;
  color: #bfbfbf;
  width: 5px;
  margin-left: -10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer {
  display: flex;
  width: 43%;
  height: 29px;
  background-color: #d0d3e8;
  padding: 3px 9px 3px 9px;
  border-radius: 5px 0px 0px 5px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer.tree,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer.tree {
  border-radius: 5px 5px 5px 5px;
  width: 100%;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer.expanded,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer.expanded {
  height: 75px;
  align-items: center;
  width: 25%;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer.treeExpanded,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer.treeExpanded {
  height: 37px;
  align-items: center;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer.consequence-on,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer.consequence-on {
  height: 95px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer .listPreviewName,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer .listPreviewName {
  font-size: 14px;
  color: white;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer .listPreviewName.expanded,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer .listPreviewName.expanded {
  max-lines: 2;
  white-space: normal;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer .listPreviewName .titleImg,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer .listPreviewName .titleImg {
  width: 17px;
  margin-right: 5px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer.selected,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer.selected {
  background-color: #321b59;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewNameContainer.selected.krogerus,
.disclaimerContainer .listPreviewMainContainer .listPreviewNameContainer.selected.krogerus {
  background-color: #000000;
}
.reportSectionContainer .listPreviewMainContainer .listTreeContectLine,
.disclaimerContainer .listPreviewMainContainer .listTreeContectLine {
  width: 14px;
  height: 2px;
  background-color: #201a2d;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer {
  justify-self: flex-end;
  display: flex;
  width: 57%;
  height: 27px;
  border-radius: 0px 5px 5px 0px;
  flex-direction: column;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer.tree,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer.tree {
  border-radius: 5px 5px 5px 5px;
  width: calc(57% - 7px);
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer.expanded,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer.expanded {
  height: 75px;
  width: 75%;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer.treeExpanded,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer.treeExpanded {
  height: 37px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer.consequence-on,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer.consequence-on {
  height: 95px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer.isHovered,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer.isHovered {
  border-radius: 0px 5px 0px 0px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: space-around;
  padding-left: 40px;
  background-color: white;
  border-radius: 0px 5px 5px 0px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.isHovered,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.isHovered {
  border-radius: 0px 5px 0px 0px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .calculateButton,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .calculateButton {
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.tree,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.tree {
  padding-left: 33px;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.noTitle,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.noTitle {
  background-color: #f7f7f7;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.small,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.small {
  flex: 0.8;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  max-width: 160px;
  min-width: 100px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.center,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.center {
  justify-content: center;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.start,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.start {
  justify-content: flex-start;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue {
  font-size: 16px;
  color: #201a2d;
  padding-top: 4px;
  cursor: pointer;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client {
  color: #5a45c9;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client.krogerus,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client.krogerus {
  color: #000000;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing {
  color: #ff703e !important;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing.krogerus,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing.krogerus {
  color: #ffcb2b !important;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.noValue,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.noValue {
  color: #201a2d;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.date,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.date {
  color: #a1a6c7;
  margin-left: 10px;
  font-size: 15px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.small,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.small {
  font-size: 14px;
  color: #8e8e8e;
  padding-top: 0px;
  margin-top: -5px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.consequence,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.consequence {
  margin-top: -12px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty {
  font-size: 16px;
  color: #201a2d;
  padding-top: 4px;
  cursor: pointer;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.opposing,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.opposing {
  color: #ac2828 !important;
  margin-left: 0px !important;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.date,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.date {
  color: #a1a6c7;
  margin-left: 10px;
  font-size: 15px;
}
.reportSectionContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .greyArea,
.disclaimerContainer .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .greyArea {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  opacity: 45%;
  width: 100%;
  height: 100%;
}
.reportSectionContainer .listPreviewMainContainer .claimPositionHighlightList,
.disclaimerContainer .listPreviewMainContainer .claimPositionHighlightList {
  position: absolute;
  left: -10px;
  cursor: pointer;
  background-color: #462777;
  z-index: -2;
  width: calc(100% + 20px);
  height: 29px;
  border-radius: 5px;
  opacity: 0.7;
}
.reportSectionContainer .listPreviewMainContainer .claimPositionHighlightList.top,
.disclaimerContainer .listPreviewMainContainer .claimPositionHighlightList.top {
  top: -10px;
}
.reportSectionContainer .listPreviewMainContainer .claimPositionHighlightList.bottom,
.disclaimerContainer .listPreviewMainContainer .claimPositionHighlightList.bottom {
  bottom: -10px;
}
.reportSectionContainer .listPreviewMainContainer .claimPositionHighlightList.expanded,
.disclaimerContainer .listPreviewMainContainer .claimPositionHighlightList.expanded {
  height: 75px;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList {
  z-index: 3;
  width: 182px;
  height: 123px;
  /* UI Properties */
  background-color: white;
  border: 1px solid #331a5b;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 8px 10px 10px;
  cursor: default;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .copyingClaimMessageContainerList,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .copyingClaimMessageContainerList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  margin-bottom: 3px;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList:hover,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList:hover {
  font-weight: 600;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList {
  margin-left: 5px;
  cursor: pointer;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList.red,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList.red {
  color: #ac2828;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList:hover,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList:hover {
  font-weight: 600;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowImgList,
.disclaimerContainer .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowImgList {
  width: 13px;
  cursor: pointer;
}
.reportSectionContainer .listPreviewMainContainer .moveTileHandleList,
.disclaimerContainer .listPreviewMainContainer .moveTileHandleList {
  position: absolute;
  top: -3px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: 0;
  width: 34px;
  height: 35px;
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .listPreviewMainContainer .moveTileTransparentCoverList,
.disclaimerContainer .listPreviewMainContainer .moveTileTransparentCoverList {
  position: absolute;
  top: -10px;
  left: -34px;
  cursor: pointer;
  z-index: 0;
  width: 34px;
  height: 35px;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .listPreviewMainContainer .moveTileHandleBackgroundList,
.disclaimerContainer .listPreviewMainContainer .moveTileHandleBackgroundList {
  position: absolute;
  top: -3px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: -1;
  width: 37px;
  height: 35px;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .listPreviewMainContainer .moveTileHandleinvisibleLayerList,
.disclaimerContainer .listPreviewMainContainer .moveTileHandleinvisibleLayerList {
  position: absolute;
  top: -10px;
  left: -42px;
  cursor: default;
  z-index: 0;
  width: 45px;
  height: 120px;
}
.reportSectionContainer .listPreviewMainContainer .threeDotsButtonContainerList,
.disclaimerContainer .listPreviewMainContainer .threeDotsButtonContainerList {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #331a5b;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .listPreviewMainContainer .expandImg,
.disclaimerContainer .listPreviewMainContainer .expandImg {
  top: 27px;
  right: 0;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 4px 4px;
  cursor: pointer;
}
.reportSectionContainer .listPreviewMainContainer .expandImg.expanded,
.disclaimerContainer .listPreviewMainContainer .expandImg.expanded {
  top: 73px;
  background-color: #f7f7f7;
}
.reportSectionContainer .listPreviewMainContainer .expandImg.consequence,
.disclaimerContainer .listPreviewMainContainer .expandImg.consequence {
  top: 93px;
}
.reportSectionContainer .listPreviewMainContainer .expandImg .expandImgText,
.disclaimerContainer .listPreviewMainContainer .expandImg .expandImgText {
  cursor: pointer;
  margin-left: 5px;
  color: #331a5b;
  font-size: 13px;
}
.reportSectionContainer .listPreviewMainContainer .expandImg img,
.disclaimerContainer .listPreviewMainContainer .expandImg img {
  transform: rotate(180deg);
}
.reportSectionContainer .listPreviewMainContainer .expandImg img.collapse,
.disclaimerContainer .listPreviewMainContainer .expandImg img.collapse {
  transform: rotate(0deg);
}
.reportSectionContainer .emptyClaimsPreviewContainer,
.disclaimerContainer .emptyClaimsPreviewContainer {
  max-width: 1284px;
  width: 100%;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -25px;
  margin-left: 20px;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 47px;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .videoInfoButtonClaimsContainer,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .videoInfoButtonClaimsContainer {
  margin-left: 10px;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .emptyClaimsTitle,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .emptyClaimsTitle {
  color: #201a2d;
  font-size: 32px;
  font-weight: 600;
  margin-right: 4px;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .emptyClaimsTitle.error,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsTitleContainer .emptyClaimsTitle.error {
  color: #ac2828;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice .emptyClaimsInfoBulletList,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice .emptyClaimsInfoBulletList {
  padding-left: 25px;
  font-size: 16px;
  color: #201a2d;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice .emptyClaimsButtonContainer img,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice .emptyClaimsButtonContainer img {
  margin-left: 10px;
}
.reportSectionContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice:first-child,
.disclaimerContainer .emptyClaimsPreviewContainer .emptyClaimContainer .emptyClaimsChoiceDiv .emptyClaimsSingleChoice:first-child {
  margin-right: 170px;
}
.reportSectionContainer .pasteFromClipboardContainer,
.disclaimerContainer .pasteFromClipboardContainer {
  position: relative;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .pasteFromClipboardContainer:hover,
.disclaimerContainer .pasteFromClipboardContainer:hover {
  background-color: #e1d6f4;
}
.reportSectionContainer .pasteFromClipboardContainer .pasteFromClipboardImg,
.disclaimerContainer .pasteFromClipboardContainer .pasteFromClipboardImg {
  cursor: pointer;
}
.reportSectionContainer .pasteFromClipboardContainer .nothingToPasteTextContainer,
.disclaimerContainer .pasteFromClipboardContainer .nothingToPasteTextContainer {
  position: absolute;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  left: 40px;
  top: 10px;
  width: 200px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain,
.disclaimerContainer .claimsPreviewMain {
  margin-top: 53px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.reportSectionContainer .claimsPreviewMain.noClaims,
.disclaimerContainer .claimsPreviewMain.noClaims {
  margin-top: 150px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions.on-list,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions.on-list {
  margin-left: -7px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer {
  padding: 2px 2px 2px 2px;
  margin-bottom: 25px;
  margin-right: 8px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer.selected,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer.selected {
  background-color: #edeaf5;
  border: 1px solid #321b59;
  border-radius: 3px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer img,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewDisplayOptions .claimsPreviewOptionContainer img {
  width: 28px;
  height: 28px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewInterestOptionsContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewTopMenuContainer .claimsPreviewInterestOptionsContainer {
  margin-right: 15px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(1316px);
  min-width: calc(1316px);
  min-height: 111px;
  z-index: 10;
}
@media screen and (max-width: 1500px) {
  .reportSectionContainer .claimsPreviewMain .claimsPreviewContainer,
  .disclaimerContainer .claimsPreviewMain .claimsPreviewContainer {
    max-width: 90vw;
    min-width: 90vw;
    margin-left: 20px;
  }
}
@media screen and (max-width: 836px) {
  .reportSectionContainer .claimsPreviewMain .claimsPreviewContainer,
  .disclaimerContainer .claimsPreviewMain .claimsPreviewContainer {
    max-width: 725px;
    min-width: 725px;
  }
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1284px;
  min-width: 1284px;
  margin-right: 16px;
  z-index: 10;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer.expanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer.expanded {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer.hovered,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer.hovered {
  border-radius: 5px 5px 0px 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listTreeAddedContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listTreeAddedContainer {
  width: calc(43% - 7px);
  display: flex;
  align-items: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNumber,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNumber {
  font-size: 13px;
  color: #bfbfbf;
  width: 5px;
  margin-left: -10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer {
  display: flex;
  width: 43%;
  height: 29px;
  background-color: #d0d3e8;
  padding: 3px 9px 3px 9px;
  border-radius: 5px 0px 0px 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.tree,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.tree {
  border-radius: 5px 5px 5px 5px;
  width: 100%;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.expanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.expanded {
  height: 75px;
  align-items: center;
  width: 25%;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.treeExpanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.treeExpanded {
  height: 37px;
  align-items: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.consequence-on,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.consequence-on {
  height: 95px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName {
  font-size: 14px;
  color: white;
  max-lines: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName.expanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName.expanded {
  max-lines: 2;
  white-space: normal;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName .titleImg,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer .listPreviewName .titleImg {
  width: 17px;
  margin-right: 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.selected,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.selected {
  background-color: #321b59;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.selected.krogerus,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewNameContainer.selected.krogerus {
  background-color: #000000;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listTreeContectLine,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listTreeContectLine {
  width: 14px;
  height: 2px;
  background-color: #201a2d;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer {
  justify-self: flex-end;
  display: flex;
  width: 57%;
  height: 27px;
  border-radius: 0px 5px 5px 0px;
  flex-direction: column;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.tree,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.tree {
  border-radius: 5px 5px 5px 5px;
  width: calc(57% - 7px);
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.expanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.expanded {
  height: 75px;
  width: 75%;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.treeExpanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.treeExpanded {
  height: 37px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.consequence-on,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.consequence-on {
  height: 95px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.isHovered,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer.isHovered {
  border-radius: 0px 5px 0px 0px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: space-around;
  padding-left: 40px;
  background-color: white;
  border-radius: 0px 5px 5px 0px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.isHovered,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.isHovered {
  border-radius: 0px 5px 0px 0px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .calculateButton,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .calculateButton {
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.tree,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.tree {
  padding-left: 33px;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.noTitle,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.noTitle {
  background-color: #f7f7f7;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.small,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow.small {
  flex: 0.8;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  max-width: 160px;
  min-width: 100px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.center,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.center {
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.start,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo.start {
  justify-content: flex-start;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue {
  font-size: 16px;
  color: #201a2d;
  padding-top: 4px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client {
  color: #5a45c9;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client.krogerus,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.client.krogerus {
  color: #000000;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing {
  color: #ff703e !important;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing.krogerus,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.opposing.krogerus {
  color: #ffcb2b !important;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.noValue,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.noValue {
  color: #201a2d;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.date,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.date {
  color: #a1a6c7;
  margin-left: 10px;
  font-size: 15px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.small,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.small {
  font-size: 14px;
  color: #8e8e8e;
  padding-top: 0px;
  margin-top: -5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.consequence,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValue.consequence {
  margin-top: -12px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty {
  font-size: 16px;
  color: #201a2d;
  padding-top: 4px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.opposing,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.opposing {
  color: #ac2828 !important;
  margin-left: 0px !important;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.date,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .listPreviewSingleInfo .listPreviewInfoValueNoFitty.date {
  color: #a1a6c7;
  margin-left: 10px;
  font-size: 15px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .greyArea,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .listPreviewInfoContainer .listPreviewInfoRow .greyArea {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  opacity: 45%;
  width: 100%;
  height: 100%;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList {
  position: absolute;
  left: -10px;
  cursor: pointer;
  background-color: #462777;
  z-index: -2;
  width: calc(100% + 20px);
  height: 29px;
  border-radius: 5px;
  opacity: 0.7;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.top,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.top {
  top: -10px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.bottom,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.bottom {
  bottom: -10px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.expanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .claimPositionHighlightList.expanded {
  height: 75px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList {
  z-index: 3;
  width: 182px;
  height: 123px;
  /* UI Properties */
  background-color: white;
  border: 1px solid #331a5b;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 8px 10px 10px;
  cursor: default;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .copyingClaimMessageContainerList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .copyingClaimMessageContainerList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  margin-bottom: 3px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList:hover,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList:hover {
  font-weight: 600;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList {
  margin-left: 5px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList.red,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList.red {
  color: #ac2828;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList:hover,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowTextList:hover {
  font-weight: 600;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowImgList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsMenuContainerList .threeDotsMenuRowList .threeDotsMenuRowImgList {
  width: 13px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleList {
  position: absolute;
  top: -3px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: 0;
  width: 34px;
  height: 35px;
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileTransparentCoverList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileTransparentCoverList {
  position: absolute;
  top: -10px;
  left: -34px;
  cursor: pointer;
  z-index: 0;
  width: 34px;
  height: 35px;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleBackgroundList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleBackgroundList {
  position: absolute;
  top: -3px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: -1;
  width: 37px;
  height: 35px;
  border-radius: 5px 5px 5px 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleinvisibleLayerList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .moveTileHandleinvisibleLayerList {
  position: absolute;
  top: -10px;
  left: -42px;
  cursor: default;
  z-index: 0;
  width: 45px;
  height: 120px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsButtonContainerList,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .threeDotsButtonContainerList {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #331a5b;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg {
  top: 27px;
  right: 0;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 4px 4px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg.expanded,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg.expanded {
  top: 73px;
  background-color: #f7f7f7;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg.consequence,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg.consequence {
  top: 93px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg .expandImgText,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg .expandImgText {
  cursor: pointer;
  margin-left: 5px;
  color: #331a5b;
  font-size: 13px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg img,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg img {
  transform: rotate(180deg);
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg img.collapse,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewMainContainer .expandImg img.collapse {
  transform: rotate(0deg);
}
@media screen and (max-width: 1430px) {
  .reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList,
  .disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList {
    max-width: 90vw;
    min-width: 90vw;
  }
}
@media screen and (max-width: 900px) {
  .reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList,
  .disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList {
    max-width: 725px;
    min-width: 725px;
    margin-right: 0px;
  }
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv {
  width: 100%;
  height: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv {
  width: 43%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: 40px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv.forSum,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv.forSum {
  justify-content: flex-end;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv.noSums,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv.noSums {
  margin-right: 7px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv .listPreviewSortImg,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTitleDiv .listPreviewSortImg {
  padding-left: 5px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .listPreviewOtherTableNamesDiv,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .listPreviewOtherTableNamesDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  max-width: 160px;
  position: relative;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .listPreviewOtherTableNamesDiv .listPreviewSortImg,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .listPreviewOtherTableNamesDiv .listPreviewSortImg {
  position: absolute;
  right: -12px;
  padding-left: 5px;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .calculateButton,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewOtherTableNamesContainer .calculateButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName {
  font-size: 16px;
  color: #201a2d;
  text-align: end;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.forSum,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.forSum {
  color: #201a2d;
  font-weight: 600;
  margin-right: 5px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.noMargin,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.noMargin {
  margin-right: 0px;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.client,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.client {
  color: #5a45c9;
  font-weight: 600;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.client.krogerus,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.client.krogerus {
  color: #000000;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.opposing,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.opposing {
  color: #ff703e;
  font-weight: 600;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.opposing.krogerus,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.opposing.krogerus {
  color: #ffcb2b;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.selectable,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableName.selectable {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableNameClickable,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableNameClickable {
  font-size: 16px;
  color: #201a2d;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.reportSectionContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableNameClickable.noSort,
.disclaimerContainer .claimsPreviewMain .claimsPreviewContainerList .listPreviewTableNamesDiv .listPreviewTableNameClickable.noSort {
  cursor: default;
}
.reportSectionContainer .singleClaimPreviewContainer,
.disclaimerContainer .singleClaimPreviewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 255px;
  width: 312px;
  height: 123px;
  margin-right: 17px;
  margin-bottom: 30px;
}
.reportSectionContainer .singleClaimPreviewContainer .partyContainer,
.disclaimerContainer .singleClaimPreviewContainer .partyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: #331a5b;
  margin-bottom: 1px;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  max-width: 70%;
}
.reportSectionContainer .singleClaimPreviewContainer .partyContainer.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .partyContainer.krogerus {
  background-color: #000000;
}
.reportSectionContainer .singleClaimPreviewContainer .partyContainer .partyContainerText,
.disclaimerContainer .singleClaimPreviewContainer .partyContainer .partyContainerText {
  color: white;
  font-size: 14px;
  max-lines: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #d0d3e8;
  border-radius: 5px 5px 0 0;
  width: 100%;
  min-height: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
  padding: 7px 10px 4px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim.forReport,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim.forReport {
  border-radius: 0 5px 0 0;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .claimTypetIdContainer,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .claimTypetIdContainer {
  color: white;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 600;
  width: 78.5px;
  height: 23px;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .verticalLine,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .verticalLine {
  height: 29px;
  width: 1px;
  background-color: white;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .previewClaimName,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartClaim .previewClaimName {
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  font-family: "poppins-local-medium";
  text-overflow: ellipsis;
  overflow: hidden;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim {
  width: 100%;
  height: 55px;
  min-height: 55px;
  border-radius: 0 0 5px 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 22.5px;
  height: 22.5px;
  width: 100%;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer {
  width: 170px;
  margin-left: 14.5px;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText {
  margin-left: 18px;
  cursor: pointer;
  color: #5a45c9;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.krogerus {
  color: #000000;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.negative,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.negative {
  color: #ff703e;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.negative.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText.negative.krogerus {
  color: #ffcb2b;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span {
  color: #5a45c9;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.krogerus {
  color: #000000;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.negative,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.negative {
  color: #ff703e;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.negative.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .claimAmountTextContainer .claimAmountText span.negative.krogerus {
  color: #ffcb2b;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .interestRateText,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .firstRow .interestRateText {
  white-space: nowrap;
  margin-right: 15px;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 22.5px;
  height: 22.5px;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow .interestDate,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow .interestDate {
  margin-right: 15px;
  color: #a1a6c7;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow .interestDate.error,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartClaim .secondRow .interestDate.error {
  color: #ac2828;
}
.reportSectionContainer .singleClaimPreviewContainer .pdfPreview,
.disclaimerContainer .singleClaimPreviewContainer .pdfPreview {
  height: -moz-fit-content;
  height: fit-content;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer {
  position: relative;
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu {
  width: 100%;
  background-color: white;
  z-index: 16;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 5px 5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu.moreInfoMenuPreviewPdf,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu.moreInfoMenuPreviewPdf {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1607843137);
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListTitle,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListTitle {
  margin-top: 20px;
  font-family: "poppins-local-bold";
  margin-left: 14.5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow .liabilityQuestion,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow .liabilityQuestion {
  word-wrap: normal;
  font-size: 16px;
  margin-left: 14px;
  min-width: 216px;
  max-width: 216px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow .liabilityProbability,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .singleLiabilityRow .liabilityProbability {
  width: 100%;
  font-size: 16px;
  margin-right: 15px;
  text-align: right;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  width: 100%;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow .totalLiabilityTitle,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow .totalLiabilityTitle {
  margin-left: 14.5px;
  font-size: 16px;
  font-weight: 600;
  min-width: 216px;
  max-width: 216px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow .totalLiabilityProbability,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .liabilityListComponent .totalLiabilityRow .totalLiabilityProbability {
  font-size: 16px;
  font-family: "poppins-local-medium";
  margin-right: 15px;
  width: 100%;
  text-align: right;
  align-self: center;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumTitle,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumTitle {
  margin-top: 15px;
  font-family: "poppins-local-bold";
  margin-left: 14.5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent {
  margin-top: 5px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer {
  width: 300px;
  display: flex;
  flex-direction: row;
  margin-top: -5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer .quantumSubtitleAmount,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer .quantumSubtitleAmount {
  font-size: 14px;
  margin-left: 14.5px;
  font-family: "poppins-local-medium";
  min-width: 138px;
  line-height: 15px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer .quantumSubtitleExternalValue,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumSubtitlesContainer .quantumSubtitleExternalValue {
  margin-top: -3px;
  font-family: "poppins-local-medium";
  margin-left: 29px;
  line-height: 15px;
  height: 50px;
  text-align: center;
  font-size: 14px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 33px;
  margin: 10.5px 15px 10.5px 14.5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .positive,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .positive {
  color: #5a45c9 !important;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .positive.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .positive.krogerus {
  color: #000000 !important;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .negative,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .negative {
  color: #ff703e !important;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .negative.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .negative.krogerus {
  color: #ffcb2b !important;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .quantumProbability,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .quantumProbability {
  color: #201a2d;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer p,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer p {
  font-size: 12px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .quantumAmountAndProbabilityContainer,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .quantumAmountAndProbabilityContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .externalConsequenceContainer,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoMenu .quantumScenariosListComponent .quantumRowContainer .externalConsequenceContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer {
  background-color: white;
  display: flex;
  flex-direction: row;
  z-index: 1;
  margin-top: 5px;
  margin-right: 226px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer.collapse,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer.collapse {
  margin-top: -5px;
  margin-right: 215px;
  position: relative;
  z-index: 900;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonText,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonText {
  cursor: pointer;
  margin-left: 5px;
  color: #331a5b;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonOff,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonOff {
  transform: rotate(180deg);
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 4px;
  margin-right: 5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonOn,
.disclaimerContainer .singleClaimPreviewContainer .moreInfoMenuContainer .moreInfoButtonContainer .moreInfoButtonOn {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 3px;
  margin-right: 5px;
}
.reportSectionContainer .singleClaimPreviewContainer .active,
.disclaimerContainer .singleClaimPreviewContainer .active {
  background-color: #331a5b;
}
.reportSectionContainer .singleClaimPreviewContainer .active.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .active.krogerus {
  background-color: #000000;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartTree,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartTree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #d0d3e8;
  border-radius: 5px;
  width: 100%;
  min-height: 60px;
  height: 60px;
  padding: 7px 10px 4px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .claimTypetIdContainer,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .claimTypetIdContainer {
  color: white;
  margin-left: 9px;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 600;
  width: 78px;
  height: 100%;
  display: flex;
  align-items: center;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .verticalLine,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .verticalLine {
  height: 29px;
  width: 1px;
  background-color: white;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .previewClaimName,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimUpperPartTree .previewClaimName {
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "poppins-local-medium";
}
.reportSectionContainer .singleClaimPreviewContainer .claimPreviewPartLine,
.disclaimerContainer .singleClaimPreviewContainer .claimPreviewPartLine {
  position: relative;
  height: 7px;
  width: 1px;
  background-color: #201a2d;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree {
  width: 100%;
  height: 55px;
  min-height: 55px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .calculateAmountButton,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .calculateAmountButton {
  width: 185px;
  height: 44px;
  border: 1px solid #201a2d;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .calculateAmountButton .calculateAmountsButtonText,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .calculateAmountButton .calculateAmountsButtonText {
  font-size: 16px;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText {
  color: #5a45c9;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.krogerus {
  color: #000000;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.negative,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.negative {
  color: #ff703e;
}
.reportSectionContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.negative.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .singlClaimDownPartTree .row .claimAmountText.negative.krogerus {
  color: #ffcb2b !important;
}
.reportSectionContainer .singleClaimPreviewContainer .active,
.disclaimerContainer .singleClaimPreviewContainer .active {
  background-color: #331a5b;
}
.reportSectionContainer .singleClaimPreviewContainer .active.krogerus,
.disclaimerContainer .singleClaimPreviewContainer .active.krogerus {
  background-color: #000000;
}
.reportSectionContainer .singleClaimPreviewContainer .claimPositionHighlight,
.disclaimerContainer .singleClaimPreviewContainer .claimPositionHighlight {
  position: absolute;
  top: -10px;
  cursor: pointer;
  background-color: #462777;
  z-index: -2;
  width: 30px;
  height: 140px;
  border-radius: 5px;
  opacity: 0.7;
}
.reportSectionContainer .singleClaimPreviewContainer .claimPositionHighlight.left,
.disclaimerContainer .singleClaimPreviewContainer .claimPositionHighlight.left {
  left: -10px;
}
.reportSectionContainer .singleClaimPreviewContainer .claimPositionHighlight.right,
.disclaimerContainer .singleClaimPreviewContainer .claimPositionHighlight.right {
  right: -10px;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer {
  width: 182px;
  height: 123px;
  /* UI Properties */
  background-color: white;
  border: 1px solid #331a5b;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 8px 10px 10px;
  cursor: default;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .copyingClaimMessageContainer,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .copyingClaimMessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  margin-bottom: 3px;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow:hover,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow:hover {
  font-weight: 600;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText {
  margin-left: 5px;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText.red,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText.red {
  color: #ac2828;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText:hover,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowText:hover {
  font-weight: 600;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowImg,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsMenuContainer .threeDotsMenuRow .threeDotsMenuRowImg {
  width: 13px;
  cursor: pointer;
}
.reportSectionContainer .singleClaimPreviewContainer .moveTileHandle,
.disclaimerContainer .singleClaimPreviewContainer .moveTileHandle {
  position: absolute;
  top: 0px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: 0;
  width: 34px;
  height: 35px;
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px 0px 0px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .singleClaimPreviewContainer .moveTileTransparentCover,
.disclaimerContainer .singleClaimPreviewContainer .moveTileTransparentCover {
  position: absolute;
  top: 0px;
  left: -34px;
  cursor: pointer;
  z-index: 0;
  width: 34px;
  height: 35px;
  border-radius: 5px 0px 0px 5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moveTileHandleBackground,
.disclaimerContainer .singleClaimPreviewContainer .moveTileHandleBackground {
  position: absolute;
  top: 0px;
  left: -34px;
  cursor: pointer;
  background-color: white;
  z-index: -1;
  width: 37px;
  height: 35px;
  border-radius: 5px 0px 0px 5px;
}
.reportSectionContainer .singleClaimPreviewContainer .moveTileHandleinvisibleLayer,
.disclaimerContainer .singleClaimPreviewContainer .moveTileHandleinvisibleLayer {
  position: absolute;
  top: -10px;
  left: -42px;
  cursor: default;
  z-index: 0;
  width: 45px;
  height: 120px;
}
.reportSectionContainer .singleClaimPreviewContainer .threeDotsButtonContainer,
.disclaimerContainer .singleClaimPreviewContainer .threeDotsButtonContainer {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #331a5b;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reportSectionContainer .pdfPreview,
.disclaimerContainer .pdfPreview {
  height: -moz-fit-content;
  height: fit-content;
  align-items: flex-start;
}
.reportSectionContainer .weigthedValueInfoMessageContainer,
.disclaimerContainer .weigthedValueInfoMessageContainer {
  margin-top: 22px;
  padding-left: 114px;
  font-size: 12px;
}
.reportSectionContainer .claimsPreviewReportSectionContainer,
.disclaimerContainer .claimsPreviewReportSectionContainer {
  padding-left: 114px;
}
.reportSectionContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport,
.disclaimerContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport {
  min-width: 1020px;
  max-width: 1020px;
  z-index: 0;
}
.reportSectionContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .listPreviewTableNamesDiv,
.disclaimerContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .listPreviewTableNamesDiv {
  margin-left: 3px;
}
.reportSectionContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .listPreviewTitleDiv,
.disclaimerContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .listPreviewTitleDiv {
  margin-left: 1px;
}
.reportSectionContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .claimsOverviewListContainer,
.disclaimerContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .claimsOverviewListContainer {
  border-radius: 10px;
  min-height: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.reportSectionContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .claimsOverviewListContainer .listTitleDiv,
.disclaimerContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .claimsOverviewListContainer .listTitleDiv {
  width: 43%;
  margin-right: 40px;
}
.reportSectionContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .claimsOverviewListContainer .listPreviewOtherTableNamesDiv,
.disclaimerContainer .claimsPreviewReportSectionContainer #claimsPreviewContainerReport .claimsOverviewListContainer .listPreviewOtherTableNamesDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  max-width: 170px;
  position: relative;
}
.reportSectionContainer .claimsPreviewReportSectionContainer .claimsPreviewMain,
.disclaimerContainer .claimsPreviewReportSectionContainer .claimsPreviewMain {
  margin-left: 0;
}
.reportSectionContainer.customSectionContainer,
.disclaimerContainer.customSectionContainer {
  animation: fadeIn 1.5s;
}
.reportSectionContainer.fadeOut,
.disclaimerContainer.fadeOut {
  animation: fadeout 1.5s;
}
.reportSectionContainer .reportSectionRemoveMoveContainer,
.disclaimerContainer .reportSectionRemoveMoveContainer {
  position: absolute;
  top: -40px;
  right: 0px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.reportSectionContainer .reportSectionRemoveMoveContainer .deleteSectionImg,
.disclaimerContainer .reportSectionRemoveMoveContainer .deleteSectionImg {
  height: 19.1px;
  margin-right: 10px;
  cursor: pointer;
}
.reportSectionContainer .reportSectionRemoveMoveContainer .deleteSectionImg.hidden,
.disclaimerContainer .reportSectionRemoveMoveContainer .deleteSectionImg.hidden {
  visibility: hidden;
}
.reportSectionContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer,
.disclaimerContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.reportSectionContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgUp,
.disclaimerContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgUp {
  width: 33px;
  cursor: pointer;
}
.reportSectionContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgUp:hover,
.disclaimerContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgUp:hover {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1607843137));
}
.reportSectionContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgDown,
.disclaimerContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgDown {
  width: 33px;
  cursor: pointer;
  transform: rotate(180deg);
}
.reportSectionContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgDown:hover,
.disclaimerContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionImgContainer .moveImgDown:hover {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1607843137));
}
.reportSectionContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionText,
.disclaimerContainer .reportSectionRemoveMoveContainer .reportMoveSectionContainer .reportMoveSectionText {
  margin-left: 5px;
}
.reportSectionContainer .transparentSectionLayerContainer,
.disclaimerContainer .transparentSectionLayerContainer {
  top: 0px;
  position: absolute;
  width: 1100px;
  margin-left: 114px;
  height: 100%;
  z-index: 7;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.reportSectionContainer .transparentSectionLayerContainer.hiddenSectionLayerContainer,
.disclaimerContainer .transparentSectionLayerContainer.hiddenSectionLayerContainer {
  background-color: white;
  opacity: 0.85;
}
.reportSectionContainer .transparentSectionLayerContainer .inlcudeMessageContainer,
.disclaimerContainer .transparentSectionLayerContainer .inlcudeMessageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.reportSectionContainer .transparentSectionLayerContainer .inlcudeMessageContainer .notIncludedMessage,
.disclaimerContainer .transparentSectionLayerContainer .inlcudeMessageContainer .notIncludedMessage {
  height: 45px;
  width: 274px;
  border: 1px solid gray;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}
.reportSectionContainer .reportSectionTitleContainer,
.disclaimerContainer .reportSectionTitleContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
}
.reportSectionContainer .reportSectionTitleContainer .editTitleButtonContainer,
.disclaimerContainer .reportSectionTitleContainer .editTitleButtonContainer {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.reportSectionContainer .reportSectionTitleContainer .editTitleButtonContainer .editButtonImg,
.disclaimerContainer .reportSectionTitleContainer .editTitleButtonContainer .editButtonImg {
  cursor: pointer;
  width: 17px;
  margin-right: 8px;
}
.reportSectionContainer .reportSectionTitleContainer .editTitleButtonContainer .editButtonText,
.disclaimerContainer .reportSectionTitleContainer .editTitleButtonContainer .editButtonText {
  cursor: pointer;
  color: #331a5b;
}
.reportSectionContainer .containerTitle,
.disclaimerContainer .containerTitle {
  padding-left: 114px;
  font-size: 24px;
  font-weight: 600;
  color: #201a2d;
  cursor: text;
}
.reportSectionContainer .sectionTitleInput,
.disclaimerContainer .sectionTitleInput {
  border: #331a5b solid 1px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: 600;
  margin-left: calc(110px);
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 1px 3px;
  padding-left: 0px;
  width: 470px;
  color: #201a2d;
}
.reportSectionContainer .sectionTitleInput:focus,
.disclaimerContainer .sectionTitleInput:focus {
  outline: none !important;
  border: 2px solid #331a5b !important;
  padding: 0px 2px;
}
.reportSectionContainer .containerParagraph,
.disclaimerContainer .containerParagraph {
  padding-left: 114px;
  margin-top: 26px;
  font-weight: 300;
}
.reportSectionContainer .treePreviewOuterContainer,
.disclaimerContainer .treePreviewOuterContainer {
  margin-top: 20px;
  position: relative;
}
.reportSectionContainer .treePreviewOuterContainer .treePreviewContainer,
.disclaimerContainer .treePreviewOuterContainer .treePreviewContainer {
  transform-origin: top left;
  position: relative;
  width: 1050px;
  height: -moz-fit-content;
  height: fit-content;
}
.reportSectionContainer .treePreviewOuterContainer .treePreviewContainer .levels .level:last-of-type,
.disclaimerContainer .treePreviewOuterContainer .treePreviewContainer .levels .level:last-of-type {
  padding-bottom: 0px !important;
}
.reportSectionContainer .treePreviewOuterContainer .treePreviewContainer .independentClaimContainer,
.disclaimerContainer .treePreviewOuterContainer .treePreviewContainer .independentClaimContainer {
  margin-left: 0px;
}
.reportSectionContainer .treePreviewOuterContainer .treePreviewContainerTransparentLayer,
.disclaimerContainer .treePreviewOuterContainer .treePreviewContainerTransparentLayer {
  left: 0;
  position: absolute;
  z-index: 1;
  width: 1050px;
  background-color: transparent;
}
.reportSectionContainer .eReportContainer,
.disclaimerContainer .eReportContainer {
  margin-left: -50px;
}
.reportSectionContainer .claimsPreviewMemo,
.disclaimerContainer .claimsPreviewMemo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 80px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  height: 100px;
  max-width: 155px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn.twoColumns,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn.twoColumns {
  height: 50px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn.threeColumns,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn.threeColumns {
  height: 75px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn.descriptions,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn.descriptions {
  align-items: flex-start !important;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnText,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnText {
  text-align: start;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 155px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnText.invisible,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnText.invisible {
  visibility: hidden;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnText.bold,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnText.bold {
  font-weight: bold;
  text-align: center;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage {
  height: 14px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage.bigger,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage.bigger {
  height: 23px;
  margin-left: 7px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage.biggerOpposite,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage.biggerOpposite {
  height: 25px;
  margin-left: 8.5px;
}
.reportSectionContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage.lessMargin,
.disclaimerContainer .claimsPreviewMemo .claimsPreviewMemoColumn .claimsPreviewMemoColumnImage.lessMargin {
  height: 15px;
}
.reportSectionContainer .claimsContainer,
.disclaimerContainer .claimsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1250px;
  padding-left: 114px;
}
.reportSectionContainer .independentClaimPreviewContainer,
.disclaimerContainer .independentClaimPreviewContainer {
  margin-top: 40px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.reportSectionContainer .independentClaimPreviewContainer .reportParagraphContainer,
.disclaimerContainer .independentClaimPreviewContainer .reportParagraphContainer {
  margin-top: 0px;
}
.reportSectionContainer .independentClaimPreviewContainer .singleClaimPreviewContainer,
.disclaimerContainer .independentClaimPreviewContainer .singleClaimPreviewContainer {
  box-shadow: 0px 0px 0px white;
  border-radius: 5px;
  margin-bottom: 15px;
}
.reportSectionContainer .independentClaimPreviewContainer .independentClaimTileInnerContainer,
.disclaimerContainer .independentClaimPreviewContainer .independentClaimTileInnerContainer {
  padding-left: 4px;
  padding-bottom: 1px;
  margin-bottom: 1px;
}
.reportSectionContainer .graphSubtitleReport,
.disclaimerContainer .graphSubtitleReport {
  margin-top: 20px;
  margin-left: 114px;
}
.reportSectionContainer .graphContainer,
.disclaimerContainer .graphContainer {
  padding-left: 114px;
  margin: 0px;
  margin-top: 20px;
  position: relative;
}
.reportSectionContainer .graphContainer.distributionGraphReportContainer,
.disclaimerContainer .graphContainer.distributionGraphReportContainer {
  margin-top: 0px;
}
.reportSectionContainer .graphContainer.getPayGraphReportContainer,
.disclaimerContainer .graphContainer.getPayGraphReportContainer {
  padding-top: 0px;
}
.reportSectionContainer .graphContainer.selectionGraphReportContainer,
.disclaimerContainer .graphContainer.selectionGraphReportContainer {
  height: 550px;
  width: 1200px;
  margin-top: 0px;
  padding-top: 0px;
}
.reportSectionContainer .graphContainer.selectionGraphReportContainer .freeSpace,
.disclaimerContainer .graphContainer.selectionGraphReportContainer .freeSpace {
  height: 60px;
  width: 100%;
}
.reportSectionContainer .graphContainer.selectionGraphReportContainer .freeSpace.noRanges,
.disclaimerContainer .graphContainer.selectionGraphReportContainer .freeSpace.noRanges {
  height: 20px;
}
.reportSectionContainer .graphContainer .transparentLayer,
.disclaimerContainer .graphContainer .transparentLayer {
  position: absolute;
  height: 500px;
  width: 93%;
  top: 10px;
  left: 100px;
}
.reportSectionContainer .graphContainer #getPayPreviewContainer,
.disclaimerContainer .graphContainer #getPayPreviewContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.reportSectionContainer .graphContainer .chart,
.disclaimerContainer .graphContainer .chart {
  position: relative;
  width: 100%;
}
.reportSectionContainer .graphContainer .chart svg,
.disclaimerContainer .graphContainer .chart svg {
  margin-top: -40px;
}
.reportSectionContainer .graphContainer .chart .sub-chart,
.disclaimerContainer .graphContainer .chart .sub-chart {
  width: 100%;
}
.reportSectionContainer .graphContainer .chartBell svg,
.disclaimerContainer .graphContainer .chartBell svg {
  margin-top: -40px;
}
.reportSectionContainer .bestAndWorstScenarioContainer,
.disclaimerContainer .bestAndWorstScenarioContainer {
  margin-left: 114px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 0px;
  margin-bottom: 60px;
  margin-top: 20px;
  position: relative;
}
.reportSectionContainer .bestAndWorstScenarioContainer .transparentLayer,
.disclaimerContainer .bestAndWorstScenarioContainer .transparentLayer {
  position: absolute;
  top: 0px;
  z-index: 6;
  background-color: transparent;
  height: 100%;
  width: 100%;
}
.reportSectionContainer .enforcementDetailsContainer,
.disclaimerContainer .enforcementDetailsContainer {
  padding-left: 114px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}
.reportSectionContainer .enforcementDetailsContainer .enforcementPercentage,
.disclaimerContainer .enforcementDetailsContainer .enforcementPercentage {
  font-size: 20px;
  font-weight: 600;
}
.reportSectionContainer .enforcementDetailsContainer .enformentText,
.disclaimerContainer .enforcementDetailsContainer .enformentText {
  margin-left: 15px;
}

.editButtonContainer {
  position: absolute;
  left: 30px;
  top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.editButtonContainer.claims {
  left: 4px;
  top: initial;
  bottom: -20px;
}
.editButtonContainer .editButtonImg {
  cursor: pointer;
  width: 17px;
  margin-right: 8px;
}
.editButtonContainer .editButtonText {
  cursor: pointer;
  color: #331a5b;
}

.reportTreeParagraphContainer {
  z-index: 5;
  margin-top: 40px;
  margin-bottom: 0px;
}

.legalCostsDistributionPreviewAppContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 114px;
  margin-top: 20px;
}
.legalCostsDistributionPreviewAppContainer.noReport {
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 44px;
}
.legalCostsDistributionPreviewAppContainer .legalCostsDistributionPreviewAppInnerContainer {
  padding-bottom: 30px;
}
.legalCostsDistributionPreviewAppContainer .lFDAppTitle {
  margin-left: 110px;
  margin-bottom: -20px;
  max-width: 140px;
  text-align: center;
  color: #201a2d;
  font-size: 14px;
}

.previewPdfContainer {
  padding: 77px 114px 77px 0px;
}
.previewPdfContainer .mnmMessageContainer {
  left: 40px;
  top: 20px;
}
.previewPdfContainer .caseDetailsContainer {
  position: relative;
  margin-left: 114px;
  min-height: 160px;
  margin-bottom: 52px;
}
.previewPdfContainer .caseDetailsContainer .caseNameTitle {
  font-size: 36px;
  font-weight: 600;
  max-width: 811px;
}
.previewPdfContainer .caseDetailsContainer .scenarioNameTitle {
  max-width: 790px;
}
.previewPdfContainer .caseDetailsContainer .caseNameSubtitle {
  width: 540px;
  font-weight: 300;
  font-style: italic;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer {
  position: absolute;
  right: 5px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .eperotoLogo {
  width: 204px;
  height: 132px;
  margin-bottom: 11px;
  -o-object-fit: contain;
     object-fit: contain;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDateInput,
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDate {
  text-align: center;
  border: 1px solid #331a5b;
  border-radius: 3px;
  width: 180px;
  padding-top: 1px;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDateInput:focus,
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDate:focus {
  outline: none;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDateInput.currentDate,
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDate.currentDate {
  border: 0px solid white;
  padding-top: 2px;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDateInput.currentDate:hover,
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .currentDate.currentDate:hover {
  border: 1px solid #331a5b;
  padding-top: 1px;
}
.previewPdfContainer .caseDetailsContainer .eperotoLogoAndDateContainer .currentDateContainer .noDate {
  color: #8e8e8e;
}
.previewPdfContainer .reportAddSectionContainer {
  margin-left: calc(99px);
  margin-top: 65px;
  margin-bottom: 30px;
  padding-top: 19px;
  padding-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
}
.previewPdfContainer .reportAddSectionContainer .reportAddSectionBackgroundLine {
  height: 2px;
  background-color: #201a2d;
  width: 100%;
  position: relative;
}
.previewPdfContainer .reportAddSectionContainer .reportAddSectionBackgroundLine .reportAddSectionButtonContainer {
  width: 190px;
  background-color: white;
  position: absolute;
  top: -19px;
  left: calc(50% - 95px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.previewPdfContainer .reportAddSectionContainer .lowOpacityLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
}

.resultsOptionsContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent !important;
  box-shadow: none;
  padding-bottom: 0px;
}
@media screen and (max-width: 1500px) {
  .resultsOptionsContainer {
    width: 100% !important;
  }
}
@media screen and (max-width: 1400px) {
  .resultsOptionsContainer {
    width: 95% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resultsOptionsContainer {
    width: 90% !important;
    margin-left: 42px;
  }
}
.resultsOptionsContainer.fixed {
  position: fixed;
  top: 10px;
  left: 3%;
}
.resultsOptionsContainer.fixed .optionsContainer {
  border: 0.3px solid #8e8e8e;
}
@media screen and (max-width: 1400px) {
  .resultsOptionsContainer.fixed {
    width: 91% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resultsOptionsContainer.fixed {
    width: 85.5% !important;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1100px) {
  .resultsOptionsContainer.fixed {
    width: 85% !important;
    margin-left: 0px;
  }
}
@media screen and (max-width: 836px) {
  .resultsOptionsContainer.fixed {
    top: -33px;
    margin-left: 0px;
  }
}
.resultsOptionsContainer .optionsContainer {
  height: 58px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 500px;
  height: 36px;
  bottom: 28px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 18px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 12px;
  margin-left: 25px;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer.higher {
  bottom: 90px;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer .resultsPartySwitch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 18px;
  cursor: pointer;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer .resultsPartySwitch.first {
  margin-right: 5px;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer .resultsPartySwitch .img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer .resultsPartySwitch.selected {
  background-color: #e1d6f4;
}
.resultsOptionsContainer .optionsContainer .resultsPartySwitchContainer .resultsPartySwitch .resultsPartyText {
  color: #201a2c;
  font-size: 13px;
  cursor: pointer;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.resultsOptionsContainer .optionsContainer .incurredCostsButton,
.resultsOptionsContainer .optionsContainer .roundedResultsButton {
  font-size: 13px;
  padding: 7px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 850px) {
  .resultsOptionsContainer .optionsContainer .incurredCostsButton,
  .resultsOptionsContainer .optionsContainer .roundedResultsButton {
    padding: 4px;
  }
}
.resultsOptionsContainer .optionsContainer .incurredCostsButton img,
.resultsOptionsContainer .optionsContainer .roundedResultsButton img {
  width: 22px;
}
.resultsOptionsContainer .optionsContainer .incurredCostsButton .incurredCostsButtonText,
.resultsOptionsContainer .optionsContainer .incurredCostsButton .roundedResultsButtonText,
.resultsOptionsContainer .optionsContainer .roundedResultsButton .incurredCostsButtonText,
.resultsOptionsContainer .optionsContainer .roundedResultsButton .roundedResultsButtonText {
  margin-left: 6px;
  cursor: pointer;
}
.resultsOptionsContainer .optionsContainer .incurredCostsButton .incurredCostsButtonText.inactive,
.resultsOptionsContainer .optionsContainer .incurredCostsButton .roundedResultsButtonText.inactive,
.resultsOptionsContainer .optionsContainer .roundedResultsButton .incurredCostsButtonText.inactive,
.resultsOptionsContainer .optionsContainer .roundedResultsButton .roundedResultsButtonText.inactive {
  color: #8e8e8e;
}
.resultsOptionsContainer .optionsContainer .incurredCostsPopUpContainer {
  position: absolute;
  width: 220px;
  height: 80px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  top: 50px;
  left: 8px;
  padding: 8px;
}
.resultsOptionsContainer .optionsContainer .incurredCostsPopUpContainer .incurredCostsPopUpText {
  line-height: 1.2;
}
.resultsOptionsContainer .optionsContainer .incurredCostsPopUpContainer .oldNewSwitchText {
  cursor: pointer;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  color: #ff7336;
  text-decoration: underline;
  margin-top: 6px;
}
.resultsOptionsContainer #createPdfReportButton {
  margin-right: 10px;
}

.resultsContent {
  margin-top: 50px;
}

.graphContainer {
  margin: 7px 30px 10px 30px;
  padding-top: 50px;
  background-color: white;
}

#graphContainer-distributionGraph,
#graphContainer-selectionGraph,
#graphContainer-getPayGraph,
#graphContainer-detailedResults {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 1300px) {
  #graphContainer-selectionGraph {
    max-height: 480px;
    padding-top: 1px;
  }
}

#selectionGraphContainer {
  transform-origin: top center;
}

.graphContainerEreport {
  margin-top: 125px;
}

.popUpContainer {
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1607843137);
  border-radius: 20px;
  width: 840px;
  height: 410px;
  margin-top: 131px;
}
.popUpContainer .warningMessageContainer {
  position: relative;
  width: 840px;
  height: 410px;
  border-radius: 20px;
  padding-top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.popUpContainer .warningMessageContainer .exitImg {
  position: absolute;
  width: 27px;
  height: 27px;
  right: 12px;
  top: 12px;
}
.popUpContainer .warningMessageContainer .warningTitle {
  position: relative;
  font-size: 32px;
  margin-top: 70px;
  text-align: center;
}
.popUpContainer .warningMessageContainer .warningTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 52px;
  width: 608px;
}
.popUpContainer .warningMessageContainer .warningTextContainer .warningText {
  margin-bottom: 6px;
  text-align: center;
  font-size: 20px;
  line-height: 1.1;
}
.popUpContainer .warningMessageContainer .warningSubTextContainer {
  width: 680px;
  margin-top: 76px;
}
.popUpContainer .warningMessageContainer .warningSubTextContainer .warningSubText {
  font-size: 16px;
  text-align: center;
  line-height: 1.1;
}
.popUpContainer .warningMessageContainer .link {
  color: #2c79f7;
  cursor: pointer;
  text-decoration: underline;
}

.savingGrpahsScreen {
  position: fixed;
  width: 100vw;
  top: 82px;
  left: 0px;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}
.savingGrpahsScreen p {
  font-size: 24px;
}

.savingGrpahsScreenFadeOut {
  position: fixed;
  width: 100vw;
  top: 82px;
  left: 0px;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  animation-name: saving;
  animation-delay: 1s;
  animation-duration: 3s;
}
.savingGrpahsScreenFadeOut p {
  font-size: 24px;
}

@keyframes saving {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.close {
  position: absolute;
  top: 8px;
  left: 10px;
  cursor: pointer;
}

.tablePlaceContainer {
  min-width: 1200px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.tableContainer .table20Title {
  text-align: center;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.tableContainer .table20Row {
  position: relative;
  border-bottom: 1px solid gray;
  width: 650px;
  height: 50px;
}
.tableContainer .table20Row:last-of-type {
  border: none;
}
.tableContainer .table20RowText {
  position: absolute;
  bottom: 15px;
  width: 650px;
  text-align: center;
}

.tableSides {
  margin: auto;
  overflow: overlay;
}

.chart-selection-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: none !important;
}
.chart-selection-title .chart-selection-title-paragraph {
  max-width: 440px;
}
.chart-selection-title .chart-selection-title-paragraph.treeSelectionGraph {
  font-size: 14px;
}

.chart {
  position: relative;
  width: 100%;
}
.chart svg {
  margin-top: 0px;
}
.chart.treeChart svg {
  margin-top: 10px;
  height: 100%;
  margin-left: -10px;
  max-width: 100%;
  transform: scale(1);
  overflow: hidden;
}
.chart .sub-chart {
  width: 100%;
}
.chart .sub-chart.tree-negativeGraph {
  margin-top: 30px;
}

.chart2 {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-direction: row;
  height: 100%;
  position: relative;
  margin-top: 40px;
  left: 0%;
  top: 10%;
}
.chart2 .sub-chart {
  align-self: flex-start;
  width: 550px;
  height: 500px;
}
.chart2 .sub-chart2 {
  width: 550px;
  height: 500px;
}

.chart3 {
  height: 60%;
  width: 700px;
  position: relative;
  margin-top: 40px;
  left: 20%;
  top: 10%;
}

.chartBell {
  position: relative;
  width: 100%;
}

.probabilitySum {
  font-size: 10px;
  margin-top: 10px;
}

.handles {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 400px;
}

.selection-tabs {
  margin-top: 20px;
}
.selection-tabs.selectionGraph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.selection-tabs button {
  margin-right: 10px;
}

.treeSelectionTabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.treeSelectionTabsContainer .treeSelectionTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 360px;
  z-index: 1;
  position: "relative";
}
.treeSelectionTabsContainer .treeSelectionTabs .treeSelectionGraphChip {
  min-width: 90px;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 10px;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}
.treeSelectionTabsContainer .treeSelectionTabs .treeSelectionGraphChip .treeSelectionGraphImg {
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}
.treeSelectionTabsContainer .treeSelectionTabs .treeSelectionGraphChip .treeSelectionGraphText {
  cursor: pointer;
  font-size: 14px;
}
.treeSelectionTabsContainer .treeSelectionTabs .treeSelectionGraphChip.selected, .treeSelectionTabsContainer .treeSelectionTabs .treeSelectionGraphChip:hover {
  background-color: #e1d6f4;
}

.selection-chips {
  display: flex;
  flex-direction: row;
  margin-top: 47px;
}
.selection-chips .chipPlaceContainer {
  position: relative;
}

.saveGraphsButton {
  float: right;
  margin-right: 100px;
  margin-bottom: 13px;
}

.valueOfCaseContainer {
  margin-top: -20px;
}
.valueOfCaseContainer .valueOfCaseLabel {
  font-size: 24px;
  font-weight: normal;
}
.valueOfCaseContainer .valueOfCaseLabel span {
  font-size: 20px;
  font-weight: 600;
}
.valueOfCaseContainer .valueOfCase {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.textForExecutiveSummaryContainer {
  margin-top: 20px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph {
  position: relative;
  margin-top: 25px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph:last-of-type {
  position: relative;
  margin-top: 25px;
  margin-bottom: 20px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph .valueOfCaseContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph .valueOfCaseContainer .valueOfCaseImg {
  margin-left: -12px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph .valueOfCaseContainer .valueOfCaseImg.previewPdfValueOfCaseImg {
  margin-left: 0px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph .valueOfCaseContainer .valueOfCaseText {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.textForExecutiveSummaryContainer .eReportTextParagraph .valueOfCaseContainer .valueOfCaseText.hasMarginLeft {
  margin-left: 9px;
}
.textForExecutiveSummaryContainer p {
  max-width: 900px;
}
.textForExecutiveSummaryContainer p span {
  font-weight: 600;
}
.textForExecutiveSummaryContainer p span.noBold {
  font-weight: normal;
}
.textForExecutiveSummaryContainer .negative {
  color: #ac2828;
}
.textForExecutiveSummaryContainer .green {
  color: #47743d;
}

#eReportComp p {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */ /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: text;
}

.showCaseButton {
  padding: 7.5px 13px !important;
}

.hasNewFaturesOuterContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
}
@media screen and (max-width: 885px) {
  .hasNewFaturesOuterContainer {
    width: 885px;
  }
}
.hasNewFaturesOuterContainer .hasNewFeaturesContainer {
  position: relative;
  width: 780px;
  min-width: 780px;
  height: 285px;
  background-color: #fff9eb;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 885px) {
  .hasNewFaturesOuterContainer .hasNewFeaturesContainer {
    margin-left: 20px;
  }
}
.hasNewFaturesOuterContainer .hasNewFeaturesContainer .newFeaturesImg {
  position: absolute;
  left: 40px;
  top: 40px;
}
.hasNewFaturesOuterContainer .hasNewFeaturesContainer .hasNewFeaturesTitle {
  font-size: 26px;
  font-family: "poppins-local-bold";
}
.hasNewFaturesOuterContainer .hasNewFeaturesContainer .hasNewFeaturesText {
  width: 400px;
  text-align: center;
  margin-bottom: 40px;
}

.lowScreenMessageContainer {
  position: fixed;
  width: calc(100% - 120px);
  margin-left: 120px;
  bottom: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.lowScreenMessageContainer .shake {
  animation: shake 0.7s;
}
@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  10% {
    transform: rotate(-2deg);
  }
  20% {
    transform: rotate(2deg);
  }
  30% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  60% {
    transform: rotate(2deg);
  }
  70% {
    transform: rotate(-2deg);
  }
  80% {
    transform: rotate(2deg);
  }
  90% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.lowScreenMessageContainer .lowScreenMessageBox {
  cursor: pointer;
  min-height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 27px;
  margin-right: 120px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
}
.lowScreenMessageContainer .lowScreenMessageBox.non-clickable {
  cursor: default;
}
.lowScreenMessageContainer .lowScreenMessageBox.no-margin {
  margin-right: 20px;
}
.lowScreenMessageContainer .lowScreenMessageBox .lowScreenMessageImgContainer {
  margin-right: 11px;
  cursor: pointer;
}
.lowScreenMessageContainer .lowScreenMessageBox .lowScreenMessageImgContainer .lowScreenMessageImg {
  cursor: pointer;
}
.lowScreenMessageContainer .lowScreenMessageBox .lowScreenMessageTextContainer {
  display: flex;
  flex-direction: row;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}
.lowScreenMessageContainer .lowScreenMessageBox .lowScreenMessageTextContainer.non-clickable {
  cursor: default;
}
.lowScreenMessageContainer .lowScreenMessageBox .lowScreenMessageTextContainer .lowScreenMessageText {
  cursor: pointer;
}
.lowScreenMessageContainer .lowScreenMessageBox .lowScreenMessageTextContainer .lowScreenMessageText.non-clickable {
  cursor: default;
}
.lowScreenMessageContainer .hoverPink:hover {
  background-color: #ffc6c6 !important;
}
.lowScreenMessageContainer .hoverError:hover {
  background-color: #c42f37 !important;
}
.lowScreenMessageContainer .noInternetHover:hover {
  background-color: #c42f37;
}

@keyframes closeRecoveryAnimationContainer {
  to {
    margin-top: calc(100vh - 64px);
    width: 600px;
    height: 41px;
  }
}
.recoveryBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: rgba(128, 128, 128, 0.8);
}
.recoveryBackgroundPopUp .recoveryPopUp {
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  height: 225px;
  width: 630px;
  border-radius: 10px;
  padding-right: 28px;
  padding-left: 28px;
  padding-top: 21px;
  position: relative;
  margin-top: calc((100vh - 225px) / 5);
}
.recoveryBackgroundPopUp .recoveryPopUp.close {
  animation-name: closeRecoveryAnimationContainer;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  overflow: hidden;
}
.recoveryBackgroundPopUp .recoveryPopUp .title {
  color: #201a2c;
  font-size: 22px;
  font-weight: bold;
}
.recoveryBackgroundPopUp .recoveryPopUp .subTitle {
  color: #321b59;
  font-size: 16px;
}
.recoveryBackgroundPopUp .recoveryPopUp .buttonsContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.freemiumBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: rgba(128, 128, 128, 0.8);
}
.freemiumBackgroundPopUp .freemiumPopUp {
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  height: 485px;
  width: 496px;
  border-radius: 10px;
  padding-right: 46px;
  padding-left: 46px;
  padding-top: 45px;
  padding-bottom: 38px;
  position: relative;
  margin-top: calc((100vh - 485px) / 5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.freemiumBackgroundPopUp .freemiumPopUp .title {
  color: #201a2d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}
.freemiumBackgroundPopUp .freemiumPopUp .description {
  color: #201a2d;
  font-size: 16px;
  margin-bottom: 32px;
}
.freemiumBackgroundPopUp .freemiumPopUp .mainContent {
  color: #201a2d;
  font-size: 18px;
  margin-left: 13px;
  margin-bottom: 8px;
}
.freemiumBackgroundPopUp .freemiumPopUp .contentRow {
  margin-left: 24px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.freemiumBackgroundPopUp .freemiumPopUp .contentRow .bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #201a2d;
  margin-right: 5px;
}
.freemiumBackgroundPopUp .freemiumPopUp .contentRow .text {
  color: #201a2d;
  font-size: 16px;
}
.freemiumBackgroundPopUp .freemiumPopUp .contactButton {
  width: 100%;
  background-color: #321b59;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
}
.freemiumBackgroundPopUp .freemiumPopUp .contactButton .text {
  font-size: 16px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.freemiumBackgroundPopUp .freemiumPopUp .closeIcon {
  position: absolute;
  top: -28px;
  right: -28px;
  width: 56px;
  height: 56px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  border-radius: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.freemiumBackgroundPopUp .freemiumPopUp .freemiumIcon {
  position: absolute;
  top: 200px;
  right: 0px;
  height: 130px;
}

.zoomMessageBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: rgba(128, 128, 128, 0.8);
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp {
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  height: 336px;
  width: 496px;
  border-radius: 10px;
  padding-right: 46px;
  padding-left: 46px;
  padding-top: 46px;
  padding-bottom: 46px;
  position: relative;
  margin-top: calc((100vh - 150px) / 5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp .title {
  color: #201a2d;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp .subtitle {
  color: #201a2d;
  font-size: 1rem;
  text-align: left;
  padding-bottom: 42px;
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp .contentRow {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp .contentRow .bullet {
  margin-right: 5px;
  color: #201a2d;
  font-size: 16px;
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp .contentRow .text {
  color: #201a2d;
  font-size: 16px;
}
.zoomMessageBackgroundPopUp .zoomMessagePopUp .closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 27px;
  height: 27px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addRemoveAccessMenuContainer {
  width: 348px;
  position: absolute;
  top: 0px;
  right: -20px;
  height: 172px;
  padding: 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 1201;
}
.addRemoveAccessMenuContainer.big {
  height: 220px !important;
}
.addRemoveAccessMenuContainer .circularProgressDiv {
  height: 140px;
  min-height: 140px !important;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuAcceptedTextContainer {
  text-align: center;
  font-size: 20px;
  padding-top: 35px;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuTextContainer {
  width: 100%;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuTextContainer .addRemoveAccessMenuText {
  font-size: 20px;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer .addAccessButton {
  height: 40px;
  border: none;
  padding: 0 14px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  background-color: #331a5b;
  color: white;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer .addAccessButton:hover {
  background-color: #462777;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer .removeAccessButton {
  height: 40px;
  border: none;
  padding: 0 14px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  background-color: #ffdddd;
  color: #201a2d;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer .removeAccessButton:hover {
  background-color: #ffc6c6;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer .leaveAccessButton {
  height: 40px;
  border: 1px #331a5b solid;
  padding: 0 14px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  background-color: white;
  color: #331a5b;
}
.addRemoveAccessMenuContainer .addRemoveAccessMenuButtonsContainer .leaveAccessButton:hover {
  background-color: #edeaf5;
}

.transparent-Background-2 {
  position: fixed;
  top: -300px;
  left: -700px;
}

.accessComponentContainer {
  width: 100%;
  padding: 14px 55px 0px 33px;
  max-height: 80vh;
  min-height: 200px;
  overflow-y: auto;
  /* Track */
  /* Handle */
}
@media screen and (max-height: 620px) {
  .accessComponentContainer {
    max-height: 60vh;
  }
}
@media screen and (max-height: 330px) {
  .accessComponentContainer {
    min-height: none;
    max-height: 60vh;
  }
}
.accessComponentContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.accessComponentContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.accessComponentContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}
.accessComponentContainer .caseTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 22px;
}
.accessComponentContainer .caseTitleContainer .caseTitleText {
  font-size: 26px;
  color: #ff7336;
  font-weight: 600;
}
.accessComponentContainer .caseTitleContainer .caseTitleNameText {
  margin-left: 10px;
  font-size: 26px;
  color: #ff7336;
  font-weight: 600;
}
.accessComponentContainer .usersWithAccessContainer .usersTitleContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersTitleContainer {
  display: flex;
  width: 100%;
}
.accessComponentContainer .usersWithAccessContainer .usersTitleContainer .usersTitleTextContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersTitleContainer .usersTitleTextContainer {
  display: flex;
  justify-content: center;
  flex: 1;
}
.accessComponentContainer .usersWithAccessContainer .usersTitleContainer .usersTitleTextContainer.alone,
.accessComponentContainer .usersWithNoAccessContainer .usersTitleContainer .usersTitleTextContainer.alone {
  justify-content: flex-start !important;
  margin-bottom: 30px;
  margin-left: 10px;
}
.accessComponentContainer .usersWithAccessContainer .usersTitleContainer .usersTitleTextContainer .usersTitleText,
.accessComponentContainer .usersWithNoAccessContainer .usersTitleContainer .usersTitleTextContainer .usersTitleText {
  font-size: 18px;
  font-weight: 600;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer {
  margin-top: 5px;
  margin-bottom: 34px;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer {
  height: 49px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 17px;
  padding-right: 10px;
  align-items: center;
  margin-top: 16px;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer:first-of-type,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer:first-of-type {
  margin-top: 0px;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .userNameContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .userNameContainer {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .userRoleContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .userRoleContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .addRemoveButton,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .addRemoveButton {
  width: 168px;
  height: 29px;
  font-weight: 600;
  border-radius: 5px;
  padding: 1px 6px !important;
  border: hidden !important;
  background-color: #ffdddd;
  cursor: pointer;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .addRemoveButton:hover,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .addRemoveButton:hover {
  background-color: #ffc6c6;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .addRemoveButton:disabled,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .addRemoveButton:disabled {
  background-color: #bebebe !important;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .dark,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .dark {
  background-color: #331a5b;
  color: white;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .dark:hover,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .dark:hover {
  background-color: #462777;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .invertedDark,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .invertedDark {
  background-color: white;
  border: 1px solid !important;
  color: #331a5b;
}
.accessComponentContainer .usersWithAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .invertedDark:hover,
.accessComponentContainer .usersWithNoAccessContainer .usersListContainer .userContainer .addRemoveAccessButtonContainer .invertedDark:hover {
  background-color: #edeaf5;
}
.accessComponentContainer .usersWithAccessContainer .noAvailableUsersTextContainer .noAvailableUsersText,
.accessComponentContainer .usersWithNoAccessContainer .noAvailableUsersTextContainer .noAvailableUsersText {
  margin-left: 17px;
}

@keyframes closeAnimationContainer {
  to {
    left: 70px;
    top: calc(100vh - 80px);
    width: 145px;
    height: 35px;
  }
}
.fullScreenPopMessageContainer {
  position: fixed;
  width: 100vw;
  height: 80vh;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1900;
}
.fullScreenPopMessageContainer#fullScreenPopMessageContainer-AWADetails {
  height: 98vh;
  max-height: 800px;
  overflow-y: scroll;
}
.fullScreenPopMessageContainer.forInfoVideo {
  height: 100vh;
  overflow: scroll;
}
.fullScreenPopMessageContainer.close {
  animation-name: closeAnimationContainer;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  overflow: hidden;
}
.fullScreenPopMessageContainer .popUpContainerGeneral {
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  max-width: 1030px;
  min-width: 1030px;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@keyframes closeAnimation {
  from {
    height: 1235px;
  }
  to {
    width: 145px;
    height: 35px;
  }
}
.fullScreenPopMessageContainer .popUpContainerGeneral#popUpContainer-AWADetails {
  height: 600px;
  width: 860px;
  min-width: 860px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral.close {
  animation-name: closeAnimation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}
.fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
  max-width: 1045px;
  min-width: 860px;
  transform: scale(1) !important;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 50px;
}
@media screen and (max-height: 900px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 900px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
    margin-left: 10vw;
  }
}
@media screen and (max-width: 800px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
    margin-left: 20vw;
  }
}
@media screen and (max-width: 700px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
    margin-left: 40vw;
  }
}
@media screen and (max-width: 600px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
    margin-left: 60vw;
  }
}
@media screen and (max-width: 500px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral.biggerWidth {
    margin-left: 80vw;
  }
}
.fullScreenPopMessageContainer .popUpContainerGeneral .circularProgressDiv {
  max-height: 80vh;
  min-height: 200px;
}
@media screen and (max-height: 620px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral .circularProgressDiv {
    max-height: 60vh;
  }
}
@media screen and (max-height: 330px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral .circularProgressDiv {
    min-height: none;
    max-height: 60vh;
  }
}
@media screen and (max-width: 1080px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 880px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 780px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral {
    transform: scale(0.5);
  }
}
@media screen and (max-height: 340px) {
  .fullScreenPopMessageContainer .popUpContainerGeneral {
    margin-top: 50px;
    transform: scale(0.7);
  }
}
.fullScreenPopMessageContainer .popUpContainerGeneral .exitButtonContainer {
  position: absolute;
  top: 11px;
  right: 15px;
  cursor: pointer;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .exitButtonContainer .exitButtonImg {
  cursor: pointer;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .exitButtonContainer .exitX {
  font-size: 25px;
  cursor: pointer;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 40px 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .preTitleContainer {
  position: relative;
  margin-top: 30px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .preTitleContainer .bigDoneImage {
  width: 90px;
  height: 94px;
  color: #331a5b;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTitle {
  position: relative;
  font-size: 32px;
  margin-top: 70px;
  text-align: center;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTitle#popUp4-warningTitle-AWADetails {
  margin-top: 30px;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTitle.celebration {
  font-size: 50px;
  color: #321b59;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTitle.withPreTitle {
  margin-top: 30px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 52px;
  width: 490px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer {
  display: flex;
  flex-direction: column;
  margin-top: -80px;
  justify-content: center;
  align-items: center;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementCalcuationsTable {
  display: flex;
  flex-direction: column;
  width: 450px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementCalcuationsTable .settlementCalculationsRow {
  display: flex;
  flex-direction: row;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementCalcuationsTable .settlementCalculationsRow .bold {
  font-weight: bold;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementCalcuationsTable .settlementCalculationsRow .settlementCalculationsLeftPart {
  width: 250px;
  text-align: right;
  margin-right: 20px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementCalcuationsTable .settlementCalculationsRow .settlementCalculationsRightPart {
  width: 200px;
  text-align: right;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementCalcuationsTable .settlementCalculationsLine {
  background-color: #d8d8d8;
  width: 100%;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementDetailsContainer {
  margin-top: 30px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .settlementDetailsParagraphContainer .settlementDetailsContainer .settlementDetailsTitle {
  font-weight: bold;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .statusImage {
  margin-bottom: 20px;
  margin-top: -40px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer.celebration {
  width: 100%;
  font-size: 30px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .warningText {
  margin-bottom: 6px;
  text-align: center;
  font-size: 20px;
  line-height: 1.1;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  cursor: text;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningTextContainer .warningButton {
  margin-top: 10px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningSubTextContainer {
  width: 680px;
  margin-top: 76px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningSubTextContainer .warningSubText {
  font-size: 16px;
  text-align: center;
  line-height: 1.1;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .warningSubTextContainer.withPreTitle {
  margin-top: 45px;
}
.fullScreenPopMessageContainer .popUpContainerGeneral .warningMessageContainerGeneral .link {
  color: #ff7336;
  cursor: pointer;
  text-decoration: underline;
}

.fullScreenPopMessageBackground {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 130vw;
  height: 150vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1600;
}

.videoInfoButtonImg {
  cursor: pointer;
  margin-bottom: 0 !important;
  margin-top: 2px;
}

.videoInfoButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.videoInfoButtonContainer .fullScreenPopMessageBackground {
  width: 100vw;
  height: 100vh;
}
.videoInfoButtonContainer .fullScreenPopMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  align-self: center;
}
.videoInfoButtonContainer .fullScreenPopMessageContainer .videoInfoContainer {
  position: relative;
  max-width: 70%;
  max-width: 1310px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoInfoButtonContainer .fullScreenPopMessageContainer .videoInfoContainer .videoFromCms {
  max-width: 1310px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.videoInfoButtonContainer .fullScreenPopMessageContainer .videoInfoContainer .exitButtonContainer {
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
}
.videoInfoButtonContainer .fullScreenPopMessageContainer .videoInfoContainer .exitButtonContainer .exitButtonImg {
  cursor: pointer;
}
.videoInfoButtonContainer .fullScreenPopMessageContainer .videoInfoContainer .exitButtonContainer .exitX {
  font-size: 25px;
  cursor: pointer;
}

.deleteMessage {
  filter: blur(0);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 376px;
  height: 166px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 1;
  border-radius: 5px;
  padding: 7px;
}
.deleteMessage.for-disabled-action {
  justify-content: center;
}
.deleteMessage.for-reset {
  min-height: 196px;
  height: -moz-fit-content;
  height: fit-content;
}
.deleteMessage.for-reset.for-tfa {
  height: -moz-fit-content;
  height: fit-content;
}
.deleteMessage.permanent {
  height: 192px;
}
.deleteMessage.disabledMessage {
  z-index: 100;
  height: 160px;
  width: 220px;
  justify-content: center;
}
.deleteMessage .claimNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.deleteMessage .deleteQuestion {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.deleteMessage .deleteQuestion.disabledMessage {
  margin-top: 0px;
}
.deleteMessage p {
  font-size: 16px;
  word-break: normal;
}
.deleteMessage .buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}
.deleteMessage .DeleteButton {
  background-color: #ac2828;
}
.deleteMessage .DeleteButton:hover {
  background-color: #ac2828;
}

.deleteMessageContainerForPreview {
  filter: blur(0);
  position: absolute;
  left: -7px;
  top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 322px;
  height: 143px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 1;
  border-radius: 5px;
  padding: 7px;
}
.deleteMessageContainerForPreview.expanded {
  height: 100px;
  left: calc(100% - 315px);
}
.deleteMessageContainerForPreview.list {
  height: 45px;
  width: 550px;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  left: calc(100% - 540px);
}
.deleteMessageContainerForPreview .deleteQuestion {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.deleteMessageContainerForPreview .deleteQuestion.expanded {
  margin-top: 0px;
}
.deleteMessageContainerForPreview .deleteQuestion.list {
  justify-content: center;
  margin-top: 0px;
  margin-right: 50px;
}
.deleteMessageContainerForPreview p {
  font-size: 22px;
  word-break: normal;
}
.deleteMessageContainerForPreview .buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}
.deleteMessageContainerForPreview .buttonsContainer.expanded {
  margin-bottom: 0px;
}
.deleteMessageContainerForPreview .buttonsContainer.list {
  margin-bottom: 0px;
}
.deleteMessageContainerForPreview .DeleteButton {
  background-color: #ac2828;
}
.deleteMessageContainerForPreview .DeleteButton:hover {
  background-color: #ac2828;
}
.deleteMessageContainerForPreview .DeleteButton.list {
  margin-left: 20px;
}

.downloadingImageMessageBackground {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.downloadingImageMessageBackground .downloadingImageMessageContainer {
  margin-top: 240px;
  padding: 28px 35px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.downloadingImageMessageBackground .downloadingImageMessageContainer .downloadingImageMessageText {
  font-size: 16px;
}

/*Preset Classes*/
.flexRow, .liabilityContainer .requirement, .nameAndAmountContainer, .interestContainer .interestInputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexColumn, .quantumContainer .singleQuantumContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.transparent {
  position: fixed;
  z-index: 1;
  top: 82px;
  width: 2400px;
  height: 2600px;
  background-color: #201a2d;
}

/*---------------------------------------*/
.toolPageComponent {
  margin-top: 43px;
  margin-left: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
@media screen and (max-width: 836px) {
  .toolPageComponent {
    align-items: flex-start;
  }
}
.toolPageComponent.tutorialPageComponent {
  align-items: center;
}
@media screen and (max-width: 1590px) {
  .toolPageComponent.tutorialPageComponent {
    align-items: flex-start;
  }
}
.toolPageComponent.tutorialPageComponent .loadingUserManualTextContainer {
  align-self: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homescreen {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 836px) {
  .homescreen {
    width: 774px;
    align-items: flex-start;
  }
}
.homescreen.results {
  padding-left: 20px;
}
.homescreen.tutorialHomescreen {
  justify-content: flex-start;
  z-index: 1;
}
.homescreen .tool-component-placeholder {
  height: 600px;
}
.homescreen .tool-component-placeholder .movingShadow, .homescreen .tool-component-placeholder .myCasesBox .movingShadow1, .myCasesBox .homescreen .tool-component-placeholder .movingShadow1, .homescreen .tool-component-placeholder .myCasesBox .movingShadow2, .myCasesBox .homescreen .tool-component-placeholder .movingShadow2, .homescreen .tool-component-placeholder .myCasesBox .movingShadow3, .myCasesBox .homescreen .tool-component-placeholder .movingShadow3 {
  animation: moveShadow 2s infinite;
  position: absolute;
  height: 100%;
  left: 0px;
  width: 120px;
  background-color: gray;
  opacity: 0.05;
  box-shadow: 0px 0px 60px gray;
  filter: blur(4px);
}
@keyframes moveShadow {
  0% {
    left: 0px;
  }
  100% {
    left: 1184px;
  }
}
.homescreen .homescreen-container {
  padding: 0 30px;
  display: flex;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;
}

.select {
  position: relative;
}

.displayInput {
  display: none;
}

.range {
  width: 150px;
}

.loneTag {
  border-radius: 50px !important;
}

.loneTag > .slider-button {
  display: none !important;
}

.errorMsg {
  position: absolute;
  width: 380px;
  color: #eb3030;
  margin-top: 0px;
  left: 300px;
  top: 10px;
}

.errorMsg2 {
  position: absolute;
  color: #b00020;
  bottom: 35px;
  text-align: center;
  font-size: 12px !important;
  z-index: 17;
  width: 183px;
  margin-left: 3px;
}

h6 {
  color: #2e394b;
  font-size: 10px;
}

html {
  scroll-behavior: auto;
  scroll-margin: 150px;
}

.empty {
  height: 350px;
  visibility: hidden;
}

.Question {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.Question-2 {
  text-align: center;
  margin: 40px;
  font-size: 14px;
  font-weight: 600;
}

label {
  font-size: 12px;
}

.notAvailable {
  text-align: center;
  vertical-align: center;
}

.blur > * {
  filter: blur(2px);
}

.progressBar {
  border-radius: 15px;
}

.progressValue {
  left: 50%;
}

.loader-container2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
}

.chart-selection-title {
  text-align: center;
  min-width: 430px;
  max-width: 520px;
  height: 42px;
  font-size: 12px;
  margin-bottom: 20px;
}
.chart-selection-title p {
  font-size: 16px;
  font-weight: 400;
}
.chart-selection-title span {
  font-weight: 600;
  word-wrap: keep-all;
  white-space: nowrap;
}

.presets {
  margin: 5px;
  border: solid 1px #2e394b;
  border-radius: 4px;
  width: 220px;
  font-size: 10px;
}

.selectsAndInputs {
  margin: 5px;
  border: solid 1px #2e394b;
  border-radius: 4px;
  padding: 7px;
  width: 300px;
  font-size: 10px;
}

.scenarios-combinations {
  font-size: 12px;
}

.noGraph {
  position: relative;
  left: 2%;
}

.invisible {
  visibility: hidden;
}

.finalProbs {
  margin-top: 40px;
}

.bar-title {
  margin-bottom: 15px !important;
  font-size: 10px;
  font-weight: 600;
}

.arrow {
  width: 0.1px;
}

.bracketDiv {
  position: fixed;
  left: 10%;
  bottom: 5%;
  width: 86%;
}

.brackets {
  width: 100%;
  height: 100%;
}

.number100 {
  font-size: 0.8vw;
  position: relative;
  margin-top: -2px;
  left: 50%;
}

.preset-button {
  float: left;
  width: 100px;
}

.questionContainer {
  position: relative;
  align-self: center;
  background-color: white;
  margin: 0 auto;
  top: 50%;
  width: 600px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  padding: 20px;
  z-index: 999;
}

.transparent-Background {
  position: fixed;
  background-color: transparent;
  align-self: center;
  left: 0px;
  top: 0px;
  width: 100vw !important;
  height: 100vw;
  z-index: 899;
  cursor: default;
}

.yes-no-button {
  margin: 40px;
  width: 100px;
}

.yes-no-button:last-of-type {
  float: right;
  width: 100px;
}

.requirements {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.overview {
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  position: fixed;
  top: 85px;
  left: 3%;
  width: 100%;
}

.overview1 {
  display: flex;
  flex-direction: row;
  margin-top: -30px;
}

.overviewGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px -300px 0px 40px;
  width: 400px;
}

.overview h6 {
  width: -moz-fit-content;
  width: fit-content;
}

.overview p {
  font-size: 10px;
  font-weight: 600;
}

.overview-frame {
  position: relative;
  background-color: white;
  border: solid black 1px;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
  margin-right: 15px;
  margin-top: -15px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.underline {
  text-decoration: underline;
}

.reqLabel2 {
  text-align: left;
}


.saved {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02857em;
  padding: 6.8px 23.5px;
  padding-top: 14px;
}

.popUp-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 16;
}

.calculation-button-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}
@media screen and (max-width: 1040px) {
  .calculation-button-component {
    visibility: hidden;
  }
}

.analyzeCaseButtonComponent {
  position: relative;
}

.legalPageContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.legalPageContainer .legalPageInnerContainer {
  width: 70%;
}
.legalPageContainer .legalPageInnerContainer .legalPageHeader {
  width: 100%;
  margin-top: 20px;
}
.legalPageContainer .legalPageInnerContainer .legalPageHeader .eperotoLogo {
  width: 100px;
}
.legalPageContainer .legalPageInnerContainer .legalPageTitle {
  margin-top: 90px;
  margin-left: 40px;
}
.legalPageContainer .legalPageInnerContainer .legalPageTitle .legalPageTitleText {
  font-size: 36px;
  font-weight: 600;
}
.legalPageContainer .legalPageInnerContainer .legalPageMainText {
  margin-top: 50px;
  margin-left: 40px;
}

.react-pdf__Document .react-pdf__Page {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100% !important;
}
.react-pdf__Document .react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Document .react-pdf__Page__canvas {
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
  width: 1224px !important;
  height: 1584px !important;
  margin-bottom: 40px;
}
.react-pdf__Document .react-pdf__Page__annotations {
  display: none;
}

.sideMenuAndTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}
@media screen and (max-width: 1590px) {
  .sideMenuAndTextContainer {
    margin-top: calc(30px + 65px);
  }
}
.sideMenuAndTextContainer.tutorialComponent .userManualSideMenuContainer {
  height: calc(100vh - 180px) !important;
}
.sideMenuAndTextContainer.tutorialComponent .userManualTextComponentContainer {
  height: calc(100vh - 180px) !important;
}
.sideMenuAndTextContainer .userManualSideMenuContainer {
  width: 265px;
  min-width: 265px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  height: calc(100vh - 150px);
  overflow-y: auto;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1607843137);
  z-index: 1;
  /* Track */
  /* Handle */
}
@media screen and (max-width: 1590px) {
  .sideMenuAndTextContainer .userManualSideMenuContainer {
    margin-left: 42px;
  }
}
.sideMenuAndTextContainer .userManualSideMenuContainer .h1Title {
  font-size: 20px;
}
.sideMenuAndTextContainer .userManualSideMenuContainer .h2Title {
  font-size: 16px;
  margin-left: 12px;
}
.sideMenuAndTextContainer .userManualSideMenuContainer .titleText {
  cursor: pointer;
  line-height: 1.2;
  margin-bottom: 6px;
}
.sideMenuAndTextContainer .userManualSideMenuContainer .titleText:hover {
  color: #ff7336 !important;
}
.sideMenuAndTextContainer .userManualSideMenuContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.sideMenuAndTextContainer .userManualSideMenuContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.sideMenuAndTextContainer .userManualSideMenuContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}
.sideMenuAndTextContainer .userManualTextComponentContainer {
  max-width: 1130px;
  min-width: 620px;
  margin-right: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1607843137);
  margin-left: 40px;
  height: calc(100vh - 150px);
  /* Track */
  /* Handle */
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextTitle {
  font-size: 26px;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h1,
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h2,
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer a,
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer p {
  -webkit-user-select: initial !important;
     -moz-user-select: initial !important;
          user-select: initial !important;
  cursor: default;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h1 a[target=_blank],
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h2 a[target=_blank],
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer a a[target=_blank],
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer p a[target=_blank] {
  text-decoration: underline !important;
  color: #ff7336 !important;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h1 a[target=_blank]:hover,
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h2 a[target=_blank]:hover,
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer a a[target=_blank]:hover,
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer p a[target=_blank]:hover {
  font-weight: bold;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h1 a {
  cursor: default !important;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h1 {
  font-size: 20px;
  color: #ff7336;
  margin-top: 20px;
  margin-bottom: 10px;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h1 a {
  font-size: 20px;
  color: #ff7336;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h2 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #ff7336;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer h2 a {
  font-size: 16px;
  color: #ff7336;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer .wp-block-video {
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer .wp-block-video video {
  min-width: 90%;
  max-width: 100%;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer figure {
  max-width: 90%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer figure img {
  z-index: 0;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer .customLink {
  text-decoration: underline !important;
  color: #ff7336 !important;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextContainer .customLink:hover {
  font-weight: bold;
}
.sideMenuAndTextContainer .userManualTextComponentContainer .userManualTextEmpty {
  height: 400px;
}
.sideMenuAndTextContainer .userManualTextComponentContainer::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.sideMenuAndTextContainer .userManualTextComponentContainer::-webkit-scrollbar-track {
  background-color: #edeaf5;
  border-radius: 10px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.sideMenuAndTextContainer .userManualTextComponentContainer::-webkit-scrollbar-thumb {
  background: #331a5b;
  border-radius: 10px;
  border: #edeaf5 2px solid;
}

.tutorialTileBackground {
  position: relative;
  width: 413px;
  height: 243px;
  background-color: white;
  box-shadow: 0px 5px 35px #cbcbcb;
  border-radius: 10px;
  padding: 28px 26px 5px 26px;
  margin-right: 49px;
  margin-bottom: 40px;
  cursor: pointer;
}
.tutorialTileBackground:hover {
  background-color: #edeaf5;
}
.tutorialTileBackground .tutorialTileTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileTitleContainer .thumbnailContainer {
  width: 56px;
  max-height: 56px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileTitleContainer .thumbnailContainer .tutorialTileThumbnail {
  max-height: 53px;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileTitleContainer .titleContainer {
  max-width: 282px;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileTitleContainer .titleContainer .tutorialTileTitle {
  font-size: 26px;
  font-weight: 500;
  color: #ff7336;
  line-height: 1.1;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileSummaryContainer {
  max-width: 360px;
  max-height: 85px;
  margin-top: 20px;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileSummaryContainer .tutorialTileSummary {
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileEstimatedTimeContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  bottom: 10px;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileEstimatedTimeContainer .estimatedTimeImg {
  width: 28px;
  margin-right: 9px;
  margin-bottom: 5px;
  cursor: pointer;
}
.tutorialTileBackground .tutorialTileEstimatedTimeContainer .tutorialTileEstimatedTimeText {
  cursor: pointer;
}

.tutorialBreadCrumpbContainer {
  width: calc(1284px + 200px);
  margin-top: 20px;
  margin-bottom: -15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1590px) {
  .tutorialBreadCrumpbContainer {
    margin-top: 85px;
    margin-left: 20px;
    margin-bottom: -80px;
  }
}
.tutorialBreadCrumpbContainer .breadcrumbTitleLeft {
  margin-right: 5px;
}
.tutorialBreadCrumpbContainer .breadcrumbTitleLeft .breadcrumbTitleLeftText {
  cursor: pointer;
}
.tutorialBreadCrumpbContainer .breadcrumbTitleLeft .breadcrumbTitleLeftText:hover {
  font-weight: 600;
}
.tutorialBreadCrumpbContainer .breadcrumbTitleLeft .breadcrumbTitleLeftTextHidden {
  font-weight: 600;
  visibility: hidden;
  height: 0;
}
.tutorialBreadCrumpbContainer .breadcrumbTitleRight {
  color: #ff7336;
  font-weight: 600;
  margin-left: 5px;
}

.tutorialTilesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: calc(1284px + 200px);
  min-width: 420px;
  margin-top: 30px;
  margin-left: 40px;
}
@media screen and (max-width: 1590px) {
  .tutorialTilesContainer {
    margin-top: calc(30px + 65px);
  }
}
@media screen and (max-width: 1470px) {
  .tutorialTilesContainer {
    width: 85vw;
    justify-content: flex-start;
  }
}

.infoDescriptionText {
  margin-right: 10px;
}
.infoDescriptionText.withColor {
  color: #321b59;
}

.infoValueText {
  color: #ff7336;
}
.infoValueText.clickable {
  font-weight: bold;
  font-size: 17px;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.infoValueText.clickable.disabled {
  color: grey;
  cursor: default;
}
.infoValueText.only {
  margin-left: 0px !important;
}
.infoValueText.justBlack {
  font-weight: bold;
  color: #201a2d;
}

.switchContainer {
  display: flex;
  flex-direction: row;
  height: 38px;
  width: 610px;
  align-items: center;
  margin-top: 10px;
}
.switchContainer .switchText {
  color: #321b59;
  margin-left: 20px;
  margin-right: 25px;
}
.switchContainer .switchText.disabled {
  color: #9f9f9f;
}
.switchContainer.first {
  margin-top: 30px;
}
.switchContainer button {
  margin-left: auto;
}

.termsContainer {
  margin-left: 73px;
  margin-top: 88px;
  display: flex;
  flex-direction: row;
}
.termsContainer .termsText {
  margin-left: 5px;
  margin-right: 50px;
  min-width: 115px;
  cursor: pointer;
}
.termsContainer .termsText:hover {
  font-weight: 600;
}

.popUpBox {
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1725490196);
  width: 550px;
  margin-left: calc((100vw - 550px) / 2);
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 30px;
  position: relative;
}
.popUpBox .titleContainer {
  width: 100%;
  overflow-wrap: break-word;
}
.popUpBox .titleContainer.no-first {
  margin-top: 40px;
}
.popUpBox .rowContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;
}
.popUpBox .rowContainer.first-container {
  margin-top: 35px;
}
.popUpBox .rowContainer.input-container {
  margin-top: 45px;
}
.popUpBox .rowContainer.input-container-first {
  margin-top: 50px;
}
.popUpBox .rowContainer button {
  margin-top: 30px;
}
.popUpBox .exitButtonImgContainer {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}
.popUpBox .exitButtonImgContainer .exitButtonImg {
  cursor: pointer;
}

#breadcrumbPathTitleTooltip,
#reportSortOrderButtonTooltip {
  z-index: 11 !important;
}

#scenarioNameBreadCrumbTooltip {
  z-index: 15 !important;
  margin-left: 150px !important;
  margin-top: -13px !important;
}

/*Choose Global Setting*/
@font-face {
  font-family: "poppins-local";
  src: url(../resources/fonts/Poppins/Poppins-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "poppins-local-medium";
  src: url(../resources/fonts/Poppins/Poppins-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "poppins-local-bold";
  src: url(../resources/fonts/Poppins/Poppins-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "roboto-local";
  src: url(../resources/fonts/Roboto/Roboto-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "roboto-local-medium";
  src: url(../resources/fonts/Roboto/Roboto-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: "roboto-local-bold";
  src: url(../resources/fonts/Roboto/Roboto-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
html * {
  font-family: "poppins-local", sans-serif, verdana;
  color: #201a2d;
  letter-spacing: -0.01em;
}

.svgFont {
  font-family: "poppins-local", sans-serif, verdana;
}

body {
  background-color: #f6f6f6;
  overscroll-behavior-x: none;
}

input::-moz-selection {
  background-color: #462777;
  color: white;
}

input::selection {
  background-color: #462777;
  color: white;
}

.robotoNumbers {
  font-family: "roboto-local" !important;
}

.robotoNumbersMedium {
  font-family: "roboto-local-medium" !important;
}

.robotoNumbersBold {
  font-family: "roboto-local-medium" !important;
  font-weight: 900;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 * {
  cursor: default;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

select:not([multiple]):not([size]) {
  background-image: none !important;
}

input[type=date],
input[type=month] {
  font: caption !important;
  font-family: "Poppins", verdana, sans-serif;
}

label,
img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

a {
  cursor: pointer !important;
  text-decoration: none !important;
}
a link {
  cursor: pointer !important;
  text-decoration: none !important;
}
a:visited {
  text-decoration: none;
}

.dropdownAutocomplete {
  position: relative;
}

.marginLeft {
  margin-left: 40px;
}

/*---------------------------------------*/
/*Preset Classes*/
.flexRow, .liabilityContainer .requirement, .nameAndAmountContainer, .interestContainer .interestInputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexColumn, .quantumContainer .singleQuantumContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*---------------------------------------*/
.loadingGif {
  width: 150px;
  box-shadow: 0px 0px 10px #f4f4f4;
}

.introButtons {
  position: relative;
  background-color: #2e394b;
  width: 300px;
}
.introButtons .introButton {
  height: 80px;
  width: 300px;
  font-size: 20px;
  font-family: sans-serif;
  text-decoration: none;
  color: black;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
}

.invisibleInput {
  height: 0px;
  width: 0px;
  padding: 0;
  border: transparent 1px solid;
}

.homeBackground {
  height: 100vh;
  width: 100wh;
  background-color: #2e394b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homeBackground .homeLogo {
  height: 120px;
  width: 192px;
  position: relative;
  margin-bottom: 10px;
}

.loginBackground {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  padding-left: 49px;
  top: 0;
  left: 0;
  z-index: -100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f6f6f6;
}
.loginBackground .loginBox {
  background-color: white;
  width: 600px;
  height: 550px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 200px;
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBackground .loginBox .form {
  margin: auto;
  margin-top: 70px;
  width: 300px;
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.loginBackground .loginBox .instructions {
  font-size: 13px;
  margin-bottom: 40px;
}
.loginBackground .loginBox .errorClass {
  position: absolute;
  color: #eb3030 !important;
  background-color: white;
  top: 142px;
  left: 150px;
}
.loginBackground .loginBox .inputLabel {
  font-size: 10px;
}
.loginBackground .loginBox #id_username,
.loginBackground .loginBox #id_password,
.loginBackground .loginBox #id_email {
  width: 300px;
  height: 28px;
  margin-bottom: 20px;
}
.loginBackground .loginBox .submitButton {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
  width: 300px;
  height: 34px;
  background-color: #2e394b;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roboto", sans-serif !important;
  border-style: none;
  outline-style: none;
}
.loginBackground .loginBox .visibleTick {
  width: 22px;
  height: 22px;
}
.loginBackground .loginBox .hiddenTick {
  visibility: hidden;
  position: fixed;
}
.loginBackground .loginBox .showPassword,
.loginBackground .loginBox .rememberMe {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 3px;
}
.loginBackground .loginBox .tickText {
  font-size: 13px;
  margin: 0;
  margin-left: 10px;
}
.loginBackground .loginBox .forgot {
  font-size: 13px;
  color: #2c79f7;
  text-decoration: none;
}
.loginBackground .loginBox .loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.loginBackground .loginBox .loginForm .loginInputLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.loginBackground .loginBox .loginForm .mailImg, .loginBackground .loginBox .loginForm .lockImg {
  position: absolute;
  left: 36px;
  width: 18px;
  margin-top: 15px;
}
.loginBackground .loginBox .loginForm .lockImg {
  margin-top: 12px;
}
.loginBackground .loginBox .loginForm .loginInput {
  padding-left: 12%;
  -webkit-appearance: none;
  font-size: 15px;
  color: #201a2d;
  font-weight: 600;
  margin: 12px auto !important;
  padding-bottom: 3px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #ffdc94;
  width: 250px;
}
.loginBackground .loginBox .loginForm .loginInput:focus {
  outline-width: 0;
}
.loginBackground .loginBox .loginForm .RememberMeContainer {
  margin-top: 8px;
  margin-left: 28px;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.loginBackground .loginBox .loginForm .RememberMeContainer label {
  font-size: 11px;
  font-weight: 400;
  margin-left: 4px;
}
.loginBackground .loginBox .loginForm input[type=checkbox] {
  border-radius: 0;
  height: 24px;
  width: 24px;
}
.loginBackground .loginBox .loginForm .SignInButton {
  width: 295px;
  height: 35px;
  border: 0;
  border-radius: 0;
  background-color: #201a2d;
  color: #ffdc94;
  font-weight: 600;
  font-size: 15px;
  margin-left: 10px;
}
.loginBackground .loginBox .loginForm .forgot {
  color: #ffdc94;
  align-self: flex-start;
  margin-left: 30px;
  font-size: 13px;
  margin-top: 6px;
}

.progressBarContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
}
.progressBarContainer .cancelBecauseOfUpdateMessageContainer {
  color: #ac2828;
}
.progressBarContainer .progressBarBackground {
  position: relative;
  width: 103px;
  height: 8px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1607843137);
}
.progressBarContainer .progressBarBackground .progressBar {
  height: 8px;
  border-radius: 4px;
  background-color: #331a5b;
}
.progressBarContainer .progressText {
  font-size: 14px;
  text-align: center;
}
.progressBarContainer .cancelContainer {
  position: absolute;
  right: -4px;
  top: 5px;
  cursor: pointer;
}
.progressBarContainer .cancelContainer .cancelCase {
  width: 28px;
  border-radius: 15px;
  height: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.progressBarContainer .cancelContainer .cancelCase .cancelCaseSquare {
  width: 10px;
  height: 10px;
  background-color: #331a5b;
}

@media screen and (max-height: 600px) {
  .loginBackground {
    overflow: auto;
  }
}
.MuiTooltip-popper {
  z-index: 9 !important;
}

[id^=treeInterestViewDropDown-],
#claimsInterestViewOptionDropdown {
  font-size: 15px !important;
  padding-bottom: 13px !important;
}

#weightedListTitle-tooltip,
#tilesPreviewButton-tooltip,
#listPreviewButton-tooltip,
#expandedPreviewButton-tooltip {
  z-index: 10 !important;
}

.MuiInputBase-root {
  background-color: white;
}

#menu-select-phonePrefix {
  z-index: 7000;
}

.MuiPopover-root {
  z-index: 7000 !important;
}

.downloadImageContainer {
  background-color: white;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.downloadImageContainer:hover {
  background-color: #edeaf5;
}
.downloadImageContainer.inactive {
  cursor: default;
}
.downloadImageContainer.inactive:hover {
  background-color: white;
}

.animateDown50 {
  animation: animateDown 0.5s forwards;
}

.animateUp50 {
  animation: animateUp 0.5s forwards;
}

.fadeout {
  animation: fadeout 0.4s forwards;
}

@keyframes animateUp {
  0% {
    transform: translateY(46px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes animateDown {
  0% {
    transform: translateY(-46px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=App.css.map */