@import './claimsPreview/addClaim/addClaim';
@import './claimsPreview/claimsPreview';
@import './claimsPreview/disputedAmountInfo';
@import './independentClaim/independentClaim';
@import './tree/tree';

.emptySpace {
  min-height: 40px;
}

.claim {
  h2 {
    span {
      font-size: 12px;
    }
  }
  .errorMsg3 {
    left: 35px;
    top: 10px !important;
  }
  .errorMessagesContainer {
    .firstError {
      top: 5px;
    }
    .secondError {
      top: 5px;
      left: 315px;
      width: 400px;
    }
  }
}
.claimRadioButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  z-index: 1px;
  margin-left: 55px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.nameAndAmountContainer {
  margin-top: 60px;
  @extend .flexRow;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  :last-child {
    .inputNum {
      margin-left: 15px !important;
    }
  }
}

.interestContainer {
  position: relative;

  padding-bottom: 10px;
  margin-bottom: 30px;
  max-width: 68%;
  min-width: 570px;

  width: 100%;

  margin-left: 40px;
  .dropdown,
  .inputNum,
  .inputNum-RO {
    margin-left: 15px !important;
  }
  .inputdate {
    margin-left: 15px !important;
  }

  .interestInputsContainer {
    @extend .flexRow;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-right: 20px;
    img {
      height: 17px;
    }
  }
}

.addInterestContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  height: 30px;
  margin: 15px 25px;
  cursor: pointer;

  .addSignContainer {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin: 30px 7px 30px 0px;
    img {
      position: absolute;
      width: 16px;
      font-size: 12px;
      margin: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p {
    cursor: pointer;

    font-size: 12px;
    font-weight: $boldWeight;
  }
}

.lineForResponsiveness {
  height: 2px;
  background-color: $ageanMidnight;
}
