.downloadingImageMessageBackground {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: $backGroundGray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .downloadingImageMessageContainer {
    margin-top: 240px;
    padding: 28px 35px;
    background-color: white;
    border-radius: $borderRadiusMedium;
    box-shadow: 0px 3px 6px #00000029;
    .downloadingImageMessageText {
      font-size: 16px;
    }
  }
}
