.transparent-createPdr-Background {
  position: fixed;
  background-color: $transparentBackgroundGray;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  left: 0px;
  top: 0px;
  width: 100vw !important;
  height: 400vw;
  z-index: 9;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 1400px) {
    align-items: flex-start;
    overflow-x: scroll;
    .circularProgressDiv {
      margin-top: 500px;
      height: 100px;
      margin-left: 48%;
    }
  }
  cursor: default;
  .circularProgressDiv {
    margin-top: 500px;
    height: 100px;
  }
  .pdfMenuOuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;

    position: relative;
    width: 100%;
    margin-top: 130px;

    overflow-x: hidden;
    max-width: $toolComponentMaxWidth;
    min-width: $toolComponentMaxWidth;
    @media screen and (max-width: 1400px) {
      margin-left: 60px;
      overflow-x: scroll;
    }
    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      border-radius: 10px;
      visibility: hidden;
    }

    /* width */
    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      border-radius: 10px;
      margin-top: 30px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $scrollBarBackground;
      border-radius: 10px;
      box-shadow: -1px 3px 6px #00000029;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $lakersUniform;
      border-radius: 10px;
      border: $scrollBarHandleBorder;
    }

    .loadingGifContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pdfMenuloadingImagesContainer {
      position: fixed;
      background-color: $backGroundGray;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      left: 0px;
      top: 0px;
      width: 100vw !important;
      height: 100vw;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .pdfMenuloadingImages {
        font-size: 45px;
      }
    }

    .pdfMenuInnerContainer {
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: $borderRadiusLarge;
      width: 98%;
      height: fit-content;
      padding: 27px 0px 64px 0px;
      .exitButtonContainer {
        position: absolute;
        top: 11px;
        right: 40px;
        cursor: pointer;
        .exitButtonImg {
          cursor: pointer;
        }
      }
      .circularProgressDiv {
        width: 100%;
        height: 100% !important;
      }
    }
    .pdfDocumentPreviewContainer {
      width: 100%;
      margin-top: 20px;
      border-radius: $borderRadiusLarge;
    }
    .previewPdfContainer {
      width: 98%;

      background-color: white;
      margin-bottom: 200px;
      box-shadow: 0px 3px 6px #00000029;
      // border-radius: $borderRadiusLarge;
    }
  }
  .hiddenClaimsContainer {
    visibility: hidden;
    height: 1px !important;
    overflow-y: hidden;
  }
  .sectionOrderMenuContainer {
    width: 971px;
    background-color: white;
    border-radius: $borderRadiusLarge;
    margin-bottom: 40px;
    padding: 14px 10px 44px 24px;
    .sectionOrderMenuTitle {
      font-size: 20px;
      font-weight: 600;
    }
    .sectionOrderMenuSectionsContainer {
      margin-top: 23px;
      margin-left: 10px;
      width: fit-content;
      .sectionOrderComponentContainer {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-right: 10px;
        cursor: pointer;
        .orderArrowsContainer {
          width: 23px;
          height: 46px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &.aloneUp {
            justify-content: flex-start;
          }
          &.aloneDown {
            justify-content: flex-end;
          }
          &.hidden {
            visibility: hidden;
          }
          cursor: pointer;
          .orderArrowUpImg,
          .orderArrowDownImg {
            cursor: pointer;
            width: 23px;
            height: 20px;
            filter: drop-shadow(0px 0px 2px #00000029);

            &.orderArrowDownImg {
              transform: rotate(180deg);
            }
          }
        }
        .includeSectionContainer {
          cursor: pointer;
          margin-left: 8px;
        }
        .sectionTitleContainer {
          cursor: pointer;
          margin-left: 10px;
          .sectionTitle {
            cursor: pointer;
          }
        }
      }
    }
  }
}
