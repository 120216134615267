@import './tabs/tabs';
@import './tabs/toolComponent';
@import './tabs/1_proceedings/proceedings';
@import './tabs/2_claims/claims';
@import './tabs/3_legalCosts/legalCosts';
@import './tabs/4_advanced/advanced';
@import './tabs/5_legalCostsDistribution/legalCostsDistribution';
@import './tabs/6_loadingResults/loadingResults';
@import './tabs/7_results/results';
@import '../../components/popUps/lowScreenMessage';
@import '../../components/popUps/recoveryScenarioPopUp';
@import '../../components/popUps/freemiumMessage';
@import '../../components/popUps/zoomMessagePopUp';
@import '../../components/popUps/fullScreenPopMessage';
@import '../../components/infoHover/infoVideoButton/videoInfoButtonComponent';
@import '../../components/deleteWindow/deleteWindow';
@import '../../components/deleteWindow/deleteWindowForPreview';
@import '../../components/downloadImage/downloadImage';

/*Preset Classes*/
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.transparent {
  position: fixed;
  z-index: 1;
  top: 82px;
  width: 2400px;
  height: 2600px;
  background-color: $ageanMidnight;
}

/*---------------------------------------*/

.toolPageComponent {
  margin-top: 43px;
  margin-left: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  @media screen and (max-width: 836px) {
    align-items: flex-start;
  }
  &.tutorialPageComponent {
    align-items: center;
    @media screen and (max-width: 1590px) {
      align-items: flex-start;
    }
    .loadingUserManualTextContainer {
      align-self: center;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.homescreen {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 836px) {
    width: 774px;
    align-items: flex-start;
  }
  &.results {
    padding-left: 20px;
  }
  &.tutorialHomescreen {
    justify-content: flex-start;

    z-index: 1;
  }

  .tool-component-placeholder {
    height: 600px;
    .movingShadow {
      animation: moveShadow 2s infinite;
      position: absolute;
      height: 100%;
      left: 0px;
      width: 120px;
      background-color: gray;
      opacity: 0.05;
      box-shadow: 0px 0px 60px gray;
      filter: blur(4px);
    }
  }

  @keyframes moveShadow {
    0% {
      left: 0px;
    }
    100% {
      left: 1184px;
    }
  }

  .homescreen-container {
    padding: 0 30px;
    display: flex;
    margin: auto;
    box-sizing: border-box;
    overflow: hidden;
  }
}

.select {
  position: relative;
}

.displayInput {
  display: none;
}

.range {
  width: 150px;
}

.loneTag {
  border-radius: 50px !important;
}

.loneTag > .slider-button {
  display: none !important;
}

.errorMsg {
  position: absolute;
  width: 380px;
  color: #eb3030;
  margin-top: 0px;
  left: 300px;
  top: 10px;
}

.errorMsg2 {
  position: absolute;
  color: #b00020;
  bottom: 35px;
  text-align: center;
  font-size: 12px !important;
  z-index: 17;
  width: 183px;
  margin-left: 3px;
}

h6 {
  color: #2e394b;
  font-size: 10px;
}

html {
  scroll-behavior: auto;
  scroll-margin: 150px;
}

.empty {
  height: 350px;
  visibility: hidden;
}

.Question {
  text-align: center;
  font-size: 14px;
  font-weight: $boldWeight;
}

.Question-2 {
  text-align: center;
  margin: 40px;
  font-size: 14px;
  font-weight: $boldWeight;
}

label {
  font-size: 12px;
}

.notAvailable {
  text-align: center;
  vertical-align: center;
}

.blur > * {
  filter: blur(2px);
}

.progressBar {
  border-radius: 15px;
}

.progressValue {
  left: 50%;
}

.loader-container2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}

.menu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.chart-selection-title {
  text-align: center;
  min-width: 430px;
  max-width: 520px;
  height: 42px;
  font-size: 12px;
  margin-bottom: 20px;
  p {
    font-size: 16px;
    font-weight: 400;
  }
  span {
    font-weight: $boldWeight;
    word-wrap: keep-all;
    white-space: nowrap;
  }
}

.presets {
  margin: 5px;
  border: solid 1px #2e394b;
  border-radius: 4px;
  width: 220px;
  font-size: 10px;
}

.selectsAndInputs {
  margin: 5px;
  border: solid 1px #2e394b;
  border-radius: 4px;
  padding: 7px;
  width: 300px;
  font-size: 10px;
}

.scenarios-combinations {
  font-size: 12px;
}

.noGraph {
  position: relative;
  left: 2%;
}

.invisible {
  visibility: hidden;
}

.finalProbs {
  margin-top: 40px;
}

.bar-title {
  margin-bottom: 15px !important;
  font-size: 10px;
  font-weight: $boldWeight;
}

.arrow {
  width: 0.1px;
}

.bracketDiv {
  position: fixed;
  left: 10%;
  bottom: 5%;
  width: 86%;
}

.brackets {
  width: 100%;
  height: 100%;
}

.number100 {
  font-size: 0.8vw;
  position: relative;
  margin-top: -2px;
  left: 50%;
}

.preset-button {
  float: left;
  width: 100px;
}

.questionContainer {
  position: relative;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-color: white;
  margin: 0 auto;
  top: 50%;
  width: 600px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  padding: 20px;
  z-index: 999;
}

.transparent-Background {
  position: fixed;
  background-color: transparent;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  left: 0px;
  top: 0px;
  width: 100vw !important;
  height: 100vw;
  z-index: 899;
  cursor: default;
  //backdrop-filter: blur(2px);
}

.yes-no-button {
  margin: 40px;
  width: 100px;
}

.yes-no-button:last-of-type {
  float: right;
  width: 100px;
}

.requirements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.overview {
  position: relative;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 15px;
  position: fixed;
  top: 85px;
  left: 3%;
  width: 100%;
}

.overview1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -30px;
}

.overviewGroup {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 5px -300px 0px 40px;
  width: 400px;
}

.overview h6 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.overview p {
  font-size: 10px;
  font-weight: $boldWeight;
}

.overview-frame {
  position: relative;
  background-color: white;
  border: solid black 1px;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
  margin-right: 15px;
  margin-top: -15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.underline {
  text-decoration: underline;
}

.reqLabel2 {
  text-align: left;
}

/*# sourceMappingURL=Tool.css.map */

.saved {
  //margin-left: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: $boldWeight;
  font-size: 16px;

  letter-spacing: 0.02857em;
  padding: 6.8px 23.5px;
  padding-top: 14px;
}

.popUp-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 16;
}

.calculation-button-component {
  @media screen and (max-width: 1040px) {
    visibility: hidden;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}

.analyzeCaseButtonComponent {
  position: relative;
}
