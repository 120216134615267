@import '../../styles/variables';
@import './LegalFeesDistributionComponents/SingleSegmentComponents/singleSegment';

.lFDAppContainer {
  max-width: 1270px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  margin-left: 430px;

  @media screen and (max-width: 1500px) {
    margin-left: 30%;
  }
  @media screen and (max-width: 1160px) {
    margin-left: 270px;
  }

  .lFDAppNew {
    max-width: 600px;
    width: 100%;
    padding-right: 20px;
    .lFDAppHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 90px;

      .addRemoveSegmentContainer {
        float: right;
        margin-right: -6px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .addRemoveButtonsContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          box-shadow: 0px 1px 6px #00000029;
          border-radius: $borderRadiusMedium;
          .imgContainer {
            border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
            &:hover {
              background-color: $hovercraftPurple;
            }
          }
          .remove {
            border-radius: 0 0 $borderRadiusMedium $borderRadiusMedium;
          }
          .addRemoveSegmentImg {
            height: 21px;
            width: 21px;
            cursor: pointer;
            padding: 2px;
          }
          .inactive {
            cursor: default;
            &:hover {
              background-color: transparent;
            }
          }
        }

        .addRemoveSegmentText {
          font-size: 20px;
          font-weight: $boldWeight;
          margin: 0 6px;
          width: 40px;
          text-align: center;
        }
      }
    }
    .lFDAppTitle {
      line-height: 1.25;
      &#lFDAppTitleLeft {
        position: absolute;
        left: -235px;
        text-align: right;
        width: 210px;
        @media screen and (max-width: 1050px) {
          left: -270px;
        }
        @media screen and (max-width: 960px) {
          left: -215px;
          font-size: 15px;
          width: 125px;
        }
        @media screen and (max-width: 885px) {
          left: -260px;
          font-size: 15px;
          width: 125px;
        }
      }
      &#lFDAppTitleRight {
        position: absolute;
        width: 233px;
        left: 52px;
        text-align: left;
        @media screen and (max-width: 1050px) {
          left: 22px;
        }
        @media screen and (max-width: 960px) {
          left: -32px;
        }
        @media screen and (max-width: 885px) {
          left: -98px;
          font-size: 15px;
        }
      }

      width: 245px;

      color: $ageanMidnight;
      font-size: 18px;
    }
    .distributionButtonsContainer {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1050px) {
        margin-left: 380px;
      }
      @media screen and (max-width: 960px) {
        margin-left: 330px;
      }
      @media screen and (max-width: 885px) {
        margin-left: 255px;
      }
      margin-left: 410px;

      .imgContainer {
        border-radius: 5px;
        box-shadow: 0px 3px 6px #00000029;
        background-color: #ffffff;
        width: 62px;
        height: 42px;

        &.first {
          margin-right: 25px;
        }

        &.column {
          width: 44px;
          height: 84px;

          position: absolute;
          top: 231px;
          left: -275px;
          @media screen and (max-width: 1160px) {
            left: -255px;
          }
          @media screen and (max-width: 960px) {
            display: none;
          }
        }
      }

      .linearImg,
      .resetImg {
        cursor: pointer;
      }
    }
    .lFDAppBodyNew {
      position: relative;
      padding-bottom: 10px;
      z-index: 1;

      @media screen and (max-width: 1050px) {
        margin-left: -30px;
      }
      @media screen and (max-width: 960px) {
        margin-left: -80px;
      }
      @media screen and (max-width: 885px) {
        margin-left: -150px;
      }

      .transparentLayer {
        background-color: transparent;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        cursor: default;
        width: 100%;
        z-index: 2;
      }
      .limitSegmentContainer {
        .limitSegmentHoverArea {
          position: absolute;
          min-width: 60px;
          min-height: 60px;
          width: fit-content;
          left: -37px;
          &.top {
            margin-top: -62px;
            left: -37px;
            top: -2px;

            .limitSegmentButtonContainer {
              align-items: flex-end;
            }
          }
          .limitSegmentButtonContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .limitSegmentButtonTextContainer {
              display: inline-block;
              min-width: 450px;
              height: 60px;
              background-color: white;
              border-radius: 10px;
              box-shadow: 0px 3px 6px #00000029;
              padding: 11px 11px 11px 11px;
              cursor: pointer;
              &:hover {
                background-color: $springLevanter;
              }
              .limitSegmentButtonText {
                cursor: pointer;
                line-height: 1.3;
                text-align: center;
                height: 40px;
                max-width: 100%; /* Allow the text to wrap within the div */
                display: -webkit-box;
                -webkit-line-clamp: 2; /* Display a maximum of 2 lines */
                -webkit-box-orient: vertical;
                word-break: break-word; /* Allow breaking words if necessary */
              }
            }
            border-radius: 5px;
          }
        }
        .deleteLimitSegmentImgContainer {
          position: relative;

          border-radius: 30px;
          width: 40px;
          height: 40px;
          background-color: white;
          box-shadow: 0px 3px 6px #00000029;
          &:hover {
            background-color: $springLevanter;
          }
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .deleteSegmentImg {
            cursor: pointer;
          }
        }
      }
      .limitSegmentArrowImgContainer {
        position: absolute;
        top: -38px;
        left: -37px;
        &.bottom {
          top: 0px;
        }
      }
      .limitSegmentArrowImg {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        margin-top: -1px;
        &.bottom {
          margin-top: 1px;
          transform: rotate(180deg);
          transform: scaleY(-1);
        }
      }
      .singleSegmentContainerNew {
        position: relative;
        height: 100%;
        z-index: 10;
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        width: 642px;
        &.limit {
          position: absolute;
          top: -63px;
          left: 0px;
          &.bottom {
            top: 9px;
          }
        }

        .legalFeesRangeContainer {
          padding: 16px 27px 16px 7px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 540px;
          height: 100%;
          .legalFeesRangeTextContainer {
            width: 50px;
            text-align: left;
            line-height: 20px;
            .legalFeesRangeText {
              width: 270px;
              font-size: 16px;
              font-family: 'poppins-local';
              text-align: center;
              overflow: hidden; /* Hide overflowing text */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Display a maximum of 2 lines */
              -webkit-box-orient: vertical;
              word-break: break-word; /* Allow breaking words if necessary */
              &.oneLine {
                -webkit-line-clamp: 1; /* Display a maximum of 2 lines */
              }
              &.bold {
                font-weight: $boldWeight;
              }
            }
          }
          .pdfPreviewlegalFeesRangeTextContainer {
            width: 433px;
            .pdfPreviewLegalFeesRangeText {
              width: 433px;
            }
          }
          .legalFeesRangeSliderContainer {
            position: relative;
            width: 100%;
            margin-left: 230px;
            margin-top: -1px;
            .rangeBackground {
              width: 100%;
              height: 10.5px;
              z-index: 0;
            }
            .legalFeesRangeSlider {
              margin-top: 3px;
              -webkit-appearance: none;
              background-color: transparent;
              top: 0px;
              left: 0px;
              position: absolute;
              cursor: pointer;

              width: 100%;
              height: 19px;
              z-index: 2;
              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                background-color: white;
                opacity: 1;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #2e394b;
                border-radius: 24px;
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .pdfPreviewSingleSegment {
        box-shadow: none;
        border-radius: 0;
        width: 800px;
      }
    }

    .awardedAmountLCDIndicator {
      width: 9px;
      height: 9px;
      border-radius: 10px;
      position: absolute;
      border: 1.5px solid black;
      left: 9.4px;
      background-color: white;
    }

    .rangeBarContainerBackground {
      position: absolute;
      height: 500px;
      width: 24px;
      padding-left: 4px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      img {
        width: 25px;
      }
    }
    .rangesBarContainer {
      position: relative;
      height: 500px;
      width: 25px;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      .rangesBarSegment {
        position: absolute;
        width: 40px;
        .segmentTriangleImg {
          height: 100%;
          width: 50px;
          position: absolute;
          right: -13.5px;
          z-index: -1;
        }

        .addSegmentContainer {
          position: absolute;
          left: -80px;
          width: 130px;
          height: 100%;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .addSegmentImg {
            cursor: pointer;
            border-radius: 5px;

            box-shadow: 0px 0px 6px #00000029;
          }
        }
        .rangeBarAmountContainer {
          width: 200px;
          height: 32px;
          position: absolute;
          left: -220px;
          top: -18px;
          &.top {
            top: -30px;
          }
          &.bottom {
            top: -10px;
          }
          @media screen and (max-width: 1050px) {
            width: 140px;
            left: -160px;
          }
          @media screen and (max-width: 885px) {
            width: 110px;
            left: -130px;
          }

          .rangeBarAmountTextContainer {
            height: 32px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            float: right;
            padding: 5px;
            padding-left: 25px;
            border-radius: 5px;

            position: relative;
            .deleteSegmentImgContainer {
              position: absolute;
              left: -22px;
              top: -5px;
              border-radius: 30px;
              width: 40px;
              height: 40px;
              background-color: white;
              box-shadow: 0px 3px 6px #00000029;
              &:hover {
                background-color: $springLevanter;
              }
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .deleteSegmentImg {
                cursor: pointer;
              }
            }

            .rangeBarAmountText {
              text-align: right;
              font-weight: bold;
              font-size: 18px;
              @media screen and (max-width: 1050px) {
                font-size: 13px;
              }
              @media screen and (max-width: 885px) {
                font-size: 9px;
              }
              &.hoverable {
                cursor: text;
              }
              cursor: default;

              &.positive {
                color: $clientColor;
                &.krogerus {
                  color: $krogerusClientColor;
                }
              }

              &.negative {
                color: $opposingPartyColor;
                &.krogerus {
                  color: $krogerusOpposingPartyColor;
                }
              }
            }
            .rangeBarAmountTextInput {
              margin-right: -13px;
              .MuiInputBase-input {
                text-align: right;
              }
            }
          }
        }
      }
      .rangeBarHandle {
        cursor: pointer;
        position: absolute;
        height: 18px;
        width: 39px;
        background-color: white;
        border: 1px solid #2e394b;
        border-radius: 5px;
        box-shadow: 0px 3px 6px #00000029;
        top: -7px;
        left: -5px;
        z-index: 1;
      }
    }
  }
}
