@import '../../../styles/variables';

.chipContainer {
  height: 40px;
  padding: 12px 34px;
  border: 1px solid $lakersUniformHover;
  width: fit-content;
  background-color: white;
  margin-right: 22px;
  cursor: pointer;
  &:hover {
    background-color: $hovercraftPurple;
  }
  border-radius: 20px;
  .chipImage {
    margin-top: -5px;
    cursor: pointer;
    height: 20px;
    vertical-align: unset !important;
  }
}
