.settlementResult {
  user-select: auto;
  cursor: text;
  span {
    font-weight: $boldWeight;
  }
}

.calculateSettlementContainer {
  margin-top: 5px;
}

#settlementDescription {
  margin-bottom: 25px;
}

.link {
  color: $popCulture;
  cursor: pointer;
}
