.includeOptionContainer {
  position: absolute;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  .includeOptionText {
    margin-right: 3px;
    cursor: pointer;
  }
  .includeOptionImg {
    cursor: pointer;
  }
}
