.treeSideMenuContainer {
  position: absolute;
  left: 52px;
  top: 100px; //40px
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  .treeSideMenuButton {
    width: 45px;
    height: 45px;
    border-radius: $borderRadiusMedium;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $hovercraftPurple;
    }
    cursor: pointer;
    &.selected {
      border: solid 1px $ageanMidnight;
      background-color: $springLevanter;
    }
  }

  .treeSideMenuSwitchContainer {
    position: relative;
    width: 45px;
    height: 88px;
    border-radius: $borderRadiusMedium;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .treeSideMenuSwitch {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;

      // &:hover {
      //   background-color: $hovercraftPurple;
      // }
    }
    .sideMenuSwitchBackground {
      position: absolute;
      border-radius: $borderRadiusMedium;
      background-color: $springLevanter;
      width: 37px;
      height: 37px;
      z-index: 0;
      transition: all 0.3s ease;
    }
  }
}

.treeNameInputContainer {
  position: absolute;
  top: 20px;
  left: 160px;
  z-index: 10;
}
