@import '../../styles/variables';

$Blue: #2e394b;
$PlaceHolderGrey: #979797;
$White: #fff;
$Red: #b00020;
$Grey: #818181;
$PH: 14px; //Padding Horizontally
$PV: 13px; //Padding Vertically
$PVs: 8.5px; //Padding Vertically small
$PVdate: 0.5px; //Padding Vertically date

// Remove
p,
h1,
h2,
h3,
h4,
h5,
h6 * {
  cursor: default;
  margin: 0;
}

.inputDate {
  &.smallField {
    .MuiFormHelperText-root {
      margin-top: -1px;
      //color: #818181;
    }
  }
}
.stickySuffixContainer {
  position: absolute;
  top: 80px;
  height: 20px;
  display: flex;
  flex-direction: row;
  .stickySuffixPlaceholder {
    opacity: 0;
  }
  .stickySuffix {
    cursor: text;
    position: absolute;
    top: -61px;
    right: -7px;
    font-family: 'Helvetica';
    font-size: 18px;
    font-weight: 500;
  }
}

.input {
  input::-webkit-datetime-edit-day-field:focus,
  input::-webkit-datetime-edit-month-field:focus,
  input::-webkit-datetime-edit-year-field:focus {
    background-color: $lakersUniformHover;
    color: white;
    outline: none;
  }
  position: relative;
  line-height: 0;
  height: auto;
  p {
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  .input-label {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    position: relative;

    .input-container {
      height: 57px;
      display: flex;
      flex-direction: row;
      position: relative;
    }

    //label/placeholder

    .input-label-text {
      position: absolute;
      z-index: 1;
      transition: width 0.2s, top 0.2s, font-size 0.2s, padding 0.2s, left 0.2s;
      white-space: nowrap;
      overflow: hidden;
      height: auto;
      border-radius: 2px;
    }
    .input-label-text-label {
      color: red;
      top: -8.5px;
      left: 12.5px;
      font-size: 13px;
      font-weight: $boldWeight;
      pointer-events: none;

      &.smallfield-label-num {
        top: -9px;
      }
      .input-label-text-p {
        padding: 0 3px;
      }
      &.input-label-selected {
        color: $lakersUniformHover;
      }
      .input-label-background {
        width: auto;
        background-color: $White;
        height: 3px;
        position: relative;
        top: -4.5px;
        z-index: -1;
      }
      .smallfield-label-background {
        top: -4px;
      }
    }

    input {
      border-color: $Grey;
      border-width: 1px;
      border-style: solid;
      padding: $PVs $PH;
      border-radius: 4px;
      font-size: 16px;
      width: 100%;
      background-color: $White;
      outline: $White 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
      &[type='date'],
      [type='month'] {
        height: 50px;
        &::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }
      }
      &.input-inputfield-selected {
        border-color: $lakersUniformHover;
        border-width: 2px;
        padding: calc(#{$PVs} - 1px) calc(#{$PH} - 1px);
      }
    }
    .smallfield {
      height: 43px;
      font-size: 14px;
    }
    input:focus {
      outline: none;
    }
    input.input-noButton::-webkit-outer-spin-button,
    input.input-noButton::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .inputNum-prefix {
      position: relative;
      line-height: 1;
      font-size: 16px;
      height: 50px;
      .input-prefix-content {
        position: absolute;
        width: 45px;
        left: 10px;
        top: 19%;
        color: #8e8e8e;
      }
    }

    .input-suffix {
      position: relative;
      line-height: 1;
      font-size: 16px;
      height: 50px;

      .input-suffix-content {
        position: absolute;
        top: 57%;
        transform: translateY(-50%);
        right: calc(100% + #{$PVs});
        p {
          margin-left: 0px;
          word-break: normal;
        }
        img {
          height: 22px;
          width: auto;
          margin-left: 0px;
          cursor: pointer;
        }
      }
      .input-suffix-content {
        position: absolute;
        top: 57%;
        transform: translateY(-50%);
        right: calc(100% + #{$PVs} + #{$PVs} + 6px) !important;
        p {
          margin-left: 0px;
        }
        img {
          height: 22px;
          width: auto;
          margin-left: 0px;
          cursor: pointer;
        }
      }
      .input-suffix-content-smallfield {
        top: 21px;
      }
    }
  }
}
.input-helper-text {
  margin: 5px 14px 0 14px;
  line-height: 1.4;
  height: 8px;
  width: fit-content;
  p {
    height: 13px;
    font-size: 12px;

    margin-top: 12px;
    margin-block-start: 0;
    margin-block-end: 0.5em;
    color: $Grey;
  }
  .input-error-message {
    font-size: 12px;

    color: $Red;
    font-weight: 900 !important;
    width: fit-content;
    letter-spacing: -0.7px;
  }
}

/*Datalist*/
.dataList {
  .input-label .input-suffix .input-suffix-content {
    display: flex;
    flex-direction: row-reverse;
    .input-dropDown-button {
      height: 100%;
      margin-top: 5px;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 12px;
        padding: 0 2px 1px;
        margin: 0;
        cursor: pointer;

        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }
    }
  }
  .input-dropdown-container {
    position: relative;

    .input-dropdown {
      position: absolute;
      top: -1px;
      z-index: 300;
      background-color: white;
      border-width: thin;
      border-color: $lakersUniformHover;
      border-style: none solid solid solid;
      width: calc(100% - 6px);
      font-size: 16px;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 10px $PH;
        list-style: none;
        font-size: 16px;
      }
      li:first-child {
        padding: 16px $PH 10px;
        list-style: none;
        font-size: 16px;
      }
      li:hover {
        background-color: rgb(233, 232, 232);
      }
    }
    .input-dropdown-background {
      position: absolute;
      top: -$PV;
      background-color: $White;
      border-width: thin;
      border-color: $Blue;
      border-style: none solid none solid;
      width: calc(100% - 2px);
      height: $PV;
      margin: 0;
      padding: 0;
      z-index: -30;
    }
  }
}

.input.boldBorder {
  .input-label {
    input {
      border-width: 2px;
      padding: $PVs $PH;
      &.input-inputfield-selected {
        border-width: 3px;
        padding: calc(#{$PVs} - 1px) calc(#{$PH} - 1px);
      }
    }
    .input-suffix {
      .input-suffix-content {
        right: calc(100% + #{$PVs} + 1px);
      }
    }
  }
}

.suffix-hidden {
  display: none;
}

//Error styling
.input-error.input-label {
  .input-label-text-label {
    p {
      color: $Red;
      font-weight: 900 !important;
    }
    z-index: 1;
    &.input-label-selected {
      p {
        color: $Red;
        font-weight: 900 !important;
      }
    }
  }

  input {
    border-color: $Red !important;
    color: $Red;
    &.input-inputfield-selected {
      border-color: $Red !important;
    }
  }
  .input-suffix {
    p {
      color: $Red;
    }
  }
  .input-helper-text {
    color: $Red;
  }
  &.dataList {
    .input-dropdown-container {
      .input-dropdown {
        border: thin $Red;
        border-style: none solid solid solid;
      }
    }
  }
}

.input.boldBorder {
  .input-label {
    input {
      border-width: 2px;
      border-style: solid;
      padding: calc(#{$PVs} - 1px) calc(#{$PH} - 1px);

      &.input-inputfield-selected {
        border-width: 3px;
        padding: calc(#{$PVs} - 2px) calc(#{$PH} - 2px);
      }
    }
  }

  &.inputdate {
    .input-label {
      input {
        padding: $PVdate $PH;

        &.input-inputfield-selected {
          padding: calc(#{$PVdate} - 1px) calc(#{$PH} - 1px);
        }
      }
    }
  }
}
