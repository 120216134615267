.popUpBox {
  background-color: white;
  box-shadow: 0px 0px 30px #0000002c;
  width: 550px;
  margin-left: calc((100vw - 550px) / 2);
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 30px;
  position: relative;

  .titleContainer {
    width: 100%;
    overflow-wrap: break-word;

    &.no-first {
      margin-top: 40px;
    }
  }

  .rowContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;

    margin-top: 10px;
    &.first-container {
      margin-top: 35px;
    }

    &.input-container {
      margin-top: 45px;
    }

    &.input-container-first {
      margin-top: 50px;
    }

    button {
      margin-top: 30px;
    }
  }

  .exitButtonImgContainer {
    @include exitButtonImgContainer();
  }
}
