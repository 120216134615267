$descriptionColor: #321b59;

.infoDescriptionText {
  margin-right: 10px;
  &.withColor {
    color: $descriptionColor;
  }
}

.infoValueText {
  color: $popCulture;

  &.clickable {
    font-weight: bold;
    font-size: 17px;
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;

    &.disabled {
      color: grey;
      cursor: default;
    }
  }

  &.only {
    margin-left: 0px !important;
  }

  &.justBlack {
    font-weight: bold;
    color: #201a2d;
  }
}
