@import '../../../styles/variables';

$industrialEggYolk: #ffdc94;
$industrialEggYolkHover: #ffd68b;

$Red: #ff695a;
$Purple: #914696;
$Grey: #818181;

$PV: 8.5px; //Padding Vertically
$PH: 34px; //Padding Horizontally
$PVs: 6.8px; //Padding Vertically
$PHs: 27.2px; //Padding Horizontally

.button {
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  font-weight: $boldWeight;
  font-size: 16px;
  letter-spacing: 0.02857em;
  border-radius: 4px;
  padding: $PV $PH;
  line-height: 1.3;
  outline: none;
  text-transform: uppercase;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  &.middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: inline-flex;
  align-items: center;
  &.button-NoUpperCase {
    text-transform: none;
  }

  &.danger {
    background-color: $greekEaster !important;
  }

  &.button:disabled {
    background-color: $sundayGray !important;
    cursor: default;
    box-shadow: none !important;
    border: none !important;
  }

  .button-container {
    height: 18px;
  }

  .button-icon {
    height: 18px;
  }

  &.button-type-contained {
    box-shadow: 0px 2px 2px #00000029;

    &.loading {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .circularProgressDiv {
      width: 80%;
    }
    .MuiCircularProgress-root {
      margin-top: 5px;
    }
    .MuiCircularProgress-circle {
      color: white;
    }
    background-color: $lakersUniform;
    .button-text {
      color: white;
    }
  }
  &.button-type-contained:hover {
    background-color: $lakersUniformHover;
    box-shadow: 0px 2px 2px #00000058;
  }
  &.button-type-contained:active {
    background-color: $lakersUniform;
  }

  &.button-type-outlined {
    background-color: white;
    border: thin $lakersUniform solid;
    padding: calc(#{$PV - 1px}) calc(#{$PH} - 1px);
    box-shadow: none;

    .button-text {
      color: $lakersUniform;
    }
    &.button-with-icon.button-icon-on-side-left {
      padding: calc(#{$PV - 1px}) calc(#{$PH - 13px}) calc(#{$PV - 1px})
        calc(#{$PH - 24px});
    }
    &.button-with-icon.button-icon-on-side-right {
      padding: calc(#{$PV - 1px}) calc(#{$PH - 24px}) calc(#{$PV - 1px})
        calc(#{$PH - 13px});
    }
  }
  &.button-type-outlined:hover {
    background-color: $hovercraftPurple;
  }
  &.button-type-outlined:active {
    background-color: white;
  }

  &.button-type-yellow {
    background-color: $industrialEggYolk;
    border: thin $lakersUniform solid;
    padding: calc(#{$PV - 1px}) calc(#{$PH} - 1px);
    .button-text {
      color: $lakersUniform;
    }
    &.button-with-icon.button-icon-on-side-left {
      padding: calc(#{$PV - 1px}) calc(#{$PH - 13px}) calc(#{$PV - 1px})
        calc(#{$PH - 24px});
    }
    &.button-with-icon.button-icon-on-side-right {
      padding: calc(#{$PV - 1px}) calc(#{$PH - 24px}) calc(#{$PV - 1px})
        calc(#{$PH - 13px});
    }
  }
  &.button-type-yellow:hover {
    background-color: $industrialEggYolkHover;
  }
  &.button-type-yellow:active {
    background-color: $industrialEggYolk;
  }

  &.button-type-text {
    background-color: white;
    box-shadow: 0px 0px 0px white !important;

    .button-text {
      color: $lakersUniform;
    }
  }
  &.button-type-text:hover {
    background-color: $hovercraftPurple;
  }
  &.button-type-text:active {
    background-color: white;
  }

  &.button-type-nonactive {
    background-color: $sundayGray;
    cursor: default;
    .button-text {
      color: white;
    }
  }

  &.button-with-icon {
    //display: flex;

    &.button-icon-on-side-left {
      flex-direction: row-reverse;
      padding: calc(#{$PV + 0px}) calc(#{$PH - 11px}) calc(#{$PV + 0px})
        calc(#{$PH - 24px});
      img {
        margin-right: 12px;
      }
    }
    &.button-icon-on-side-right {
      flex-direction: row;
      padding: calc(#{$PV + 0px}) calc(#{$PH - 24px}) calc(#{$PV + 0px})
        calc(#{$PH - 11px});
      img {
        margin-left: 12px;
      }
    }
  }
  &.noText {
    padding: calc(#{$PV + 0px}) 0px calc(#{$PV + 0px}) 10px !important;
  }
}

//small
.button.button-type-small {
  font-size: 13px;
  padding: $PVs $PHs;

  .button-container {
    height: 14.4px;
  }

  .button-icon {
    height: 14.4px;
  }

  &.button-type-outlined {
    padding: calc(#{$PVs - 1px}) calc(#{$PHs} - 1px);

    &.button-with-icon.button-icon-on-side-left {
      padding: calc(#{$PVs - 1px}) calc(#{$PHs - 13px}) calc(#{$PVs - 1px})
        calc(#{$PHs - 13px});
    }
    &.button-with-icon.button-icon-on-side-right {
      padding: calc(#{$PVs - 1px}) calc(#{$PHs - 24px}) calc(#{$PVs - 1px})
        calc(#{$PHs - 13px});
    }
  }

  &.button-type-yellow {
    padding: calc(#{$PVs - 1px}) calc(#{$PHs} - 1px);

    &.button-with-icon.button-icon-on-side-left {
      padding: calc(#{$PVs - 1px}) calc(#{$PHs - 13px}) calc(#{$PVs - 1px})
        calc(#{$PHs - 24px});
    }
    &.button-with-icon.button-icon-on-side-right {
      padding: calc(#{$PVs - 1px}) calc(#{$PHs - 24px}) calc(#{$PVs - 1px})
        calc(#{$PHs - 13px});
    }
  }

  &.button-with-icon {
    &.button-icon-on-side-left {
      flex-direction: row-reverse;
      padding: calc(#{$PVs + 0px}) calc(#{$PHs - 24px}) calc(#{$PVs + 0px})
        calc(#{$PHs - 11px});
      img {
        margin-right: 9.6px;
      }
    }
    &.button-icon-on-side-right {
      padding: calc(#{$PVs + 0px}) calc(#{$PHs - 24px}) calc(#{$PVs + 0px})
        calc(#{$PHs - 11px});
      img {
        margin-left: 9.6px;
      }
    }
  }
}

.button.button-type-very-small {
  font-size: 12px;
  padding: $PVs 6.8px;

  .button-container {
    height: 14.4px;
  }
}

.button-type-nonactiveText {
  font-family: 'Roboto', sans-serif;
  font-weight: $boldWeight;
  font-size: 16px;
  color: $lakersUniform;
  border: thin White solid;
  padding: calc(#{$PV - 1px}) calc(#{$PH} - 1px);

  box-shadow: 0px 3px 6px white;
  background-color: transparent;
  cursor: default;
  &.button-with-icon.button-icon-on-side-left {
    padding: calc(#{$PV - 1px}) calc(#{$PH - 13px}) calc(#{$PV - 1px})
      calc(#{$PH - 24px});
  }
  &.button-with-icon.button-icon-on-side-right {
    padding: calc(#{$PV - 1px}) calc(#{$PH - 24px}) calc(#{$PV - 1px})
      calc(#{$PH - 13px});
  }
}
