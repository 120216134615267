@import './sideMenuComponents/sideMenuTitle';
@import './sideMenuComponents/sideMenuLogOut';
@import '../../components/checklistComponent/checklistComponent';
@import '../../components/checklistComponent/infoVideo';

.transparentSideMenu {
  position: fixed;
  background-color: transparent;
  left: 0px;
  top: 0px;
  width: 162px;
  height: 100%;
  z-index: 12;
}
.sideMenuContainer {
  position: fixed;
  @media only screen and (max-width: 836px) {
    position: absolute;
  }
  background-color: transparent;
  left: 0px;
  top: 43px;
  width: 49px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
  .upperSideMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $backGroundGray;

    .upperSideMenufiller {
      position: relative;
      width: 100%;
      background-color: white;
      height: 65px;
      border-radius: 0 0 $borderRadiusLarge 0;
      box-shadow: 0px 5px 6px #00000029;
      &.nocorner {
        border-radius: 0 0 0 0;
      }
      .cornerFiller {
        position: absolute;
        right: -10px;
        top: 0px;
      }
    }
  }
  .downSideMenu {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .appVersion {
      position: absolute;
      width: 35px;
      top: 10px;
      .appVersionText {
        color: $ageanMidnight;
        font-size: 8px;
      }
    }
    .downSideMenufiller {
      width: 100%;
      height: 100%;
      background-color: white;
      box-shadow: 0px 5px 6px #00000029;
      z-index: 1;

      border-radius: 0 $borderRadiusLarge 0 0;
      &.nocorner {
        border-radius: 0 0 0 0;
      }
    }
  }
}

.sideMenuContainerOpen {
  width: 162px;
}
