.getPayGraphTitle {
  font-size: 22px;
  margin-top: 40px;
  font-family: 'poppins-local-bold';
}
.getPayGraphSubtitle {
  font-size: 16px;
  margin-bottom: 40px;
  width: 100%;
}
.getPayChart {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  .payGetGraphContainer {
    width: 800px;
    position: relative;
    margin-bottom: 20px;
    .getPayGraphDivider {
      position: absolute;
      left: 373px;
      width: 6px;
      top: -20px;
      height: calc(100% + 40px);
      background-color: $sundayGray;
      border-radius: 20px;
    }

    .getPayGraphRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 14px;
      .getPayGraphRowLeftPart {
        text-align: right;
        width: 370px;
        max-width: 370px;
        margin-right: 17px;
        p {
          user-select: auto !important;
          cursor: text;
        }
      }
      .getPayGraphRowRightPart {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: 430px;
        max-width: 430px;
        .getPayBar {
          height: 18px;
          border-radius: 0 19px 19px 0;
          margin-right: 5px;
          box-shadow: 0px 3px 6px #00000029;
        }
        .getPayBarPercentage {
          user-select: auto !important;
          cursor: text;
        }
      }
    }
  }
}
