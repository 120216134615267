.dateOfTrialContainer {
  position: relative;
  margin-top: 26px;
  padding-left: $reportPaddingLeft;
  .instanceAndDateContainer {
    margin-left: -100px;
    padding-left: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .instanceTitle {
      font-size: 20px;
      font-weight: $boldWeight;
      margin-left: -4px;
      margin-top: 6px;
      padding: 2px 4px;
      cursor: text;
    }
    .instanceInput {
      border: $lakersUniform solid 1px;
      border-radius: $borderRadiusSmall;
      font-size: 20px;
      font-weight: $boldWeight;
      margin-left: -4px;
      margin-top: 6px;
      padding: 1px 3px;
      width: 770px;
      &:focus {
        outline: none !important;
        border: 2px solid $lakersUniform !important;
        padding: 0px 2px;
      }
    }
  }
  .dateOfTrialDateContainer {
    margin-left: -100px;
    padding-left: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;

    .editButtonContainer {
      left: 35px;
      top: -5px;
    }

    .dateOfTrialIntroText {
      cursor: text;
    }

    .dateOfTrialIntroTextInput {
      border: $lakersUniform solid 1px;
      border-radius: $borderRadiusSmall;
      font-size: 16px;
      margin-left: -4px;
      margin-top: -2px;
      padding: 1px 3px;
      width: 240px;
      margin-bottom: -2px;
      &:focus {
        outline: none !important;
        border: 2px solid $lakersUniform !important;
        padding: 0px 2px;
        margin-bottom: -2px;
      }
    }

    .dateOfTrialDate {
      margin-left: 5px;
      font-weight: $boldWeight;
      span {
        font-weight: 400;
      }
    }
  }
}
