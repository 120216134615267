@import './resultsComponents/1_executiveSummary/executiveSummary';
@import './resultsComponents/2_graphs/graphs';
@import './resultsComponents/4_valuesBreakdown/valuesBreakdown';
@import './resultsComponents/3_detailedResults/detailedResults';
@import './resultsComponents/4_settlement/settlement';
@import './resultsComponents/5_pdfReport/createPdfReportComponent';

.resultsContent {
  margin-top: 50px;
}

.graphContainer {
  margin: 7px 30px 10px 30px;
  padding-top: 50px;
  background-color: white;
}

#graphContainer-distributionGraph,
#graphContainer-selectionGraph,
#graphContainer-getPayGraph,
#graphContainer-detailedResults {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#graphContainer-selectionGraph {
  @media screen and (max-width: 1300px) {
    max-height: 480px;
    padding-top: 1px;
  }
}

#selectionGraphContainer {
  transform-origin: top center;
}

.graphContainerEreport {
  margin-top: 125px;
}

.popUpContainer {
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 30px #00000029;

  border-radius: 20px;
  width: 840px;
  height: 410px;
  margin-top: 131px;
  .warningMessageContainer {
    position: relative;
    width: 840px;
    height: 410px;
    border-radius: 20px;
    padding-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .exitImg {
      position: absolute;
      width: 27px;
      height: 27px;
      right: 12px;
      top: 12px;
    }
    .warningTitle {
      position: relative;
      font-size: 32px;
      margin-top: 70px;
      text-align: center;
    }
    .warningTextContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 52px;
      width: 608px;

      .warningText {
        margin-bottom: 6px;
        text-align: center;
        font-size: 20px;
        line-height: 1.1;
      }
    }
    .warningSubTextContainer {
      width: 680px;
      margin-top: 76px;
      .warningSubText {
        font-size: 16px;
        text-align: center;
        line-height: 1.1;
      }
    }
    .link {
      color: #2c79f7;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.savingGrpahsScreen {
  position: fixed;
  width: 100vw;
  top: 82px;
  left: 0px;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  p {
    font-size: 24px;
  }
}

.savingGrpahsScreenFadeOut {
  position: fixed;
  width: 100vw;
  top: 82px;
  left: 0px;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  animation-name: saving;
  animation-delay: 1s;
  animation-duration: 3s;

  p {
    font-size: 24px;
  }
}

@keyframes saving {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.close {
  position: absolute;
  top: 8px;
  left: 10px;
  cursor: pointer;
}
.tablePlaceContainer {
  min-width: 1200px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .table20Title {
    text-align: center;
    height: 50px;

    font-size: 16px;
    font-weight: $boldWeight;
    width: 100%;
  }
  .table20Row {
    position: relative;
    border-bottom: 1px solid gray;
    width: 650px;
    height: 50px;
    &:last-of-type {
      border: none;
    }
  }
  .table20RowText {
    position: absolute;
    bottom: 15px;
    width: 650px;

    text-align: center;
  }
}

.tableSides {
  margin: auto;
  overflow: overlay;
}
.chart-selection-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: none !important;
  .chart-selection-title-paragraph {
    max-width: 440px;
    &.treeSelectionGraph {
      font-size: 14px;
    }
  }
}

.chart {
  position: relative;
  width: 100%;
  svg {
    margin-top: 0px;
  }
  &.treeChart {
    svg {
      margin-top: 10px;
      height: 100%;
      margin-left: -10px;
      max-width: 100%;
      transform: scale(1);
      overflow: hidden;
    }
  }
  .sub-chart {
    width: 100%;
    &.tree-negativeGraph {
      margin-top: 30px;
    }
  }
}

.chart2 {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-direction: row;

  height: 100%;
  position: relative;
  margin-top: 40px;
  left: 0%;
  top: 10%;
  .sub-chart {
    align-self: flex-start;
    width: 550px;
    height: 500px;
  }
  .sub-chart2 {
    width: 550px;
    height: 500px;
  }
}

.chart3 {
  height: 60%;
  width: 700px;
  position: relative;
  margin-top: 40px;
  left: 20%;
  top: 10%;
}

.chartBell {
  position: relative;
  width: 100%;
}

.probabilitySum {
  font-size: 10px;
  margin-top: 10px;
}

.handles {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 400px;
}

.selection-tabs {
  margin-top: 20px;
  &.selectionGraph {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  button {
    margin-right: 10px;
  }
}

.treeSelectionTabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .treeSelectionTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    z-index: 1;
    position: 'relative';
    .treeSelectionGraphChip {
      min-width: 90px;
      width: fit-content;
      padding-right: 10px;
      height: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;
      background-color: transparent;
      cursor: pointer;
      .treeSelectionGraphImg {
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
      }
      .treeSelectionGraphText {
        cursor: pointer;
        font-size: 14px;
      }

      &.selected,
      &:hover {
        background-color: $springLevanter;
      }
    }
  }
}

.selection-chips {
  display: flex;
  flex-direction: row;
  margin-top: 47px;
  .chipPlaceContainer {
    position: relative;
  }
}

.saveGraphsButton {
  float: right;
  margin-right: 100px;
  margin-bottom: 13px;
}

.valueOfCaseContainer {
  margin-top: -20px;
  .valueOfCaseLabel {
    font-size: 24px;
    font-weight: normal;
    span {
      font-size: 20px;
      font-weight: $boldWeight;
    }
  }

  .valueOfCase {
    font-size: 20px;
    font-weight: $boldWeight;
    text-align: left;
  }
}

.textForExecutiveSummaryContainer {
  margin-top: 20px;
  .eReportTextParagraph {
    position: relative;
    margin-top: 25px;
    &:last-of-type {
      position: relative;
      margin-top: 25px;

      margin-bottom: 20px;
    }
    .valueOfCaseContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 25px;
      .valueOfCaseImg {
        &.previewPdfValueOfCaseImg {
          margin-left: 0px;
        }
        margin-left: -12px;
      }
      .valueOfCaseText {
        font-size: 20px;
        font-weight: $boldWeight;
        margin-top: 10px;
        &.hasMarginLeft {
          margin-left: 9px;
        }
      }
    }
  }
  p {
    max-width: 900px;
    span {
      font-weight: $boldWeight;
      &.noBold {
        font-weight: normal;
      }
    }
  }
  .negative {
    color: $greekEaster;
  }
  .green {
    color: $infantryWest;
  }
}

#eReportComp {
  p {
    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Old versions of Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text;
  }
}

.showCaseButton {
  padding: 7.5px 13px !important;
}

.hasNewFaturesOuterContainer {
  width: 100%;
  @media screen and (max-width: 885px) {
    width: 885px;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  .hasNewFeaturesContainer {
    position: relative;
    width: 780px;
    min-width: 780px;
    @media screen and (max-width: 885px) {
      margin-left: 20px;
    }
    height: 285px;
    background-color: $lightYellow;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .newFeaturesImg {
      position: absolute;
      left: 40px;
      top: 40px;
    }
    .hasNewFeaturesTitle {
      font-size: 26px;
      font-family: 'poppins-local-bold';
    }
    .hasNewFeaturesText {
      width: 400px;
      text-align: center;
      margin-bottom: 40px;
    }
  }
}
