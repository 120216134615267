.infoClickComponentContainer {
  position: relative;
}
.infoClickComponentContainer.noHelperText {
  margin-top: -20px;
}
.infoClickComponentContainer .infoCircle {
  position: relative;
  cursor: pointer;
  margin-left: 7px;
  z-index: 1;
}
.infoClickComponentContainer .infoClickTextContainer {
  width: 200px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  position: absolute;
  top: -10px;
  left: 35px;
  z-index: 3;
}
.infoClickComponentContainer .infoClickTextContainer.withTitle {
  width: 300px;
}
.infoClickComponentContainer .infoClickTextContainer .infoClickText {
  font-size: 16px;
  line-height: 1.2;
}
.infoClickComponentContainer .infoClickTextContainer .infoClickText.withTitle {
  margin-bottom: 25px;
}
.infoClickComponentContainer .infoClickTextContainer .infoClickTitle {
  font-size: 16px;
  line-height: 1.2;
  font-weight: bold;
  color: #331a5b;
}
.infoClickComponentContainer .infoClickTransparent {
  width: 21px;
  height: 21px;
  z-index: 4;
  position: absolute;
  top: -5px;
  left: 8px;
  cursor: pointer;
}

.infoHoverTextContainer {
  width: 200px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
}
.infoHoverTextContainer .infoHoverText {
  font-size: 16px;
  line-height: 1.2;
  margin: 1px 0;
  word-break: keep-all;
}

.hoverComponentContainer {
  position: absolute;
  z-index: 900;
}/*# sourceMappingURL=HoverComponent.css.map */