@import './addRemoveAccessMenu';

.accessComponentContainer {
  width: 100%;
  padding: 14px 55px 0px 33px;
  @media screen and (max-height: 620px) {
    max-height: 60vh;
  }
  @media screen and (max-height: 330px) {
    min-height: none;
    max-height: 60vh;
  }
  max-height: 80vh;
  min-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $scrollBarBackground;
    border-radius: 10px;
    box-shadow: -1px 3px 6px #00000029;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $lakersUniform;
    border-radius: 10px;
    border: $scrollBarHandleBorder;
  }
  .caseTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;
    .caseTitleText {
      font-size: 26px;
      color: $popCulture;
      font-weight: $boldWeight;
    }
    .caseTitleNameText {
      margin-left: 10px;
      font-size: 26px;
      color: $popCulture;
      font-weight: $boldWeight;
    }
  }
  .usersWithAccessContainer,
  .usersWithNoAccessContainer {
    .usersTitleContainer {
      display: flex;
      width: 100%;

      .usersTitleTextContainer {
        display: flex;
        justify-content: center;
        flex: 1;

        &.alone {
          justify-content: flex-start !important;
          margin-bottom: 30px;
          margin-left: 10px;
        }

        .usersTitleText {
          font-size: 18px;
          font-weight: $boldWeight;
        }
      }
    }
    .usersListContainer {
      margin-top: 5px;
      margin-bottom: 34px;
      .userContainer {
        height: 49px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: $borderRadiusMedium;
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 17px;
        padding-right: 10px;
        align-items: center;
        &:first-of-type {
          margin-top: 0px;
        }

        margin-top: 16px;
        .userNameContainer {
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }

        .userRoleContainer {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .addRemoveAccessButtonContainer {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          position: relative;

          .addRemoveButton {
            width: 168px;
            height: 29px;
            font-weight: $boldWeight;
            border-radius: $borderRadiusMedium;
            padding: 1px 6px !important;
            border: hidden !important;
            background-color: $laCandyfloss;
            &:hover {
              background-color: $laCandyflossHover;
            }
            &:disabled {
              background-color: #bebebe !important;
            }
            cursor: pointer;
          }
          .dark {
            background-color: $lakersUniform;
            &:hover {
              background-color: $lakersUniformHover;
            }
            color: white;
          }
          .invertedDark {
            background-color: white;
            &:hover {
              background-color: $hovercraftPurple;
            }
            border: 1px solid !important;
            color: $lakersUniform;
          }
        }
      }
    }

    .noAvailableUsersTextContainer {
      .noAvailableUsersText {
        margin-left: 17px;
      }
    }
  }
}
