.saveMenuContainer,
.saveMenuContainer2 {
  position: absolute;

  width: 780px;
  height: 155px;

  background-color: white;
  box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
  border-radius: 5px;
  z-index: 900;
  padding: 28px 30px 10px 29px;
  box-sizing: border-box;
  .circularProgressDiv {
    width: 100%;
    height: 100%;
  }
  .saveMenuMessage {
    text-align: left;
    p {
      font-size: 16px;
    }
  }
  .saveMenuButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
    }
  }
}

.saveMenuContainer2 {
  top: 82px;
  right: -20px;
}

@media screen and (max-width: 930px) {
  .saveMenuContainer,
  .saveMenuContainer2 {
    position: absolute;
    width: 300px;
    height: 270px;
    .saveMenuMessage p {
      margin-top: 2px;
    }
    .saveMenuButtons {
      margin-top: 10px;
      padding-right: 0px;
      flex-direction: column !important;
      align-items: center;
      button {
        margin: 15px 0 0 0 !important;
      }
      .continueButton {
        margin-right: 5px;
      }
    }
  }
  .saveMenuContainer2 {
    right: 0px;
    top: 80px;
  }
}
