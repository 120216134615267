.executiveSummary3Container {
  .fade {
    color: $sundayGray !important;
  }
  padding-left: 128px;
  &.forReport {
    padding-left: 0px;
  }
  @media screen and (max-width: 1100px) {
    padding-left: 60px;
    &.forReport {
      padding-left: 0px;
    }
  }
  .claimsAndLegalCostsContainer {
    &.forReport {
      padding-left: 114px;
    }
    .partyClaimsAndLegalCostsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 86px;
      &.forReport {
        margin-top: 30px;
      }

      .partyClaimsAndLegalCostsComponent {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        &:first-of-type {
          margin-right: 95px;
          &.forReport {
            margin-right: 50px;
          }
          &.opposingPartyView {
            margin-right: 0px;
            margin-left: 95px;
          }
        }
        .partyNameContainer {
          margin-bottom: 3px;
          max-width: 470px;
          .partyName {
            font-size: 22px;
            font-family: 'poppins-local-bold';
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: text;
            user-select: auto !important;
          }
        }
        .claimsAndLegalCostsContainer {
          border: 1px solid $grayForReport;
          &.fade {
            border: 1px solid $sundayGray;
          }
          border-radius: 5px;
          width: 470px;
          padding: 12px 12px 16px 25px;
          .claimedAmountContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .claimedAmountText {
              font-family: 'poppins-local-medium';
              color: $grayForReport;
              cursor: text;
              user-select: auto !important;
            }
            .claimedAmountValue {
              font-family: 'poppins-local-bold';
              font-size: 20px;
              cursor: text;
              user-select: auto !important;
            }
          }
          .legalCostsContainer {
            .legalCostsComponent {
              margin-top: 3px;
              &:last-of-type {
                margin-top: 12px;
              }
              .estimatedLegalCostsContainer,
              .recoverableCostsContainer,
              .incurredCostsContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .legalCostsTitle {
                  color: $grayForReport;
                  text-wrap: nowrap;
                  font-size: 15.5px;
                  cursor: text;
                  user-select: auto !important;
                  &.medium {
                    font-family: 'poppins-local-medium';
                  }
                  &.description {
                    margin-left: 19px;
                  }
                }
                .legalCostsValue {
                  text-align: right;
                  text-wrap: nowrap;
                  cursor: text;
                  user-select: auto !important;
                }
              }
            }
          }
        }
      }
    }
    .proceedingsDateContainer {
      margin-top: -5px;
      .proceedingsDateComponent {
        display: flex;
        flex-direction: row;
        align-items: center;
        .proceedingsDateText {
          font-size: 16px;
          color: $grayForReport;
          cursor: text;
          user-select: auto !important;
        }
        .proceedingsDate {
          margin-left: 4px;
          font-family: 'poppins-local-bold';
          cursor: text;
          user-select: auto !important;
        }
      }
    }
  }
  .expectedValueContainer {
    &.forReport {
      padding-left: 114px;
    }
    margin-top: 80px;
    .expectedValueTitle {
      font-family: 'poppins-local-bold';
      font-size: 22px;
      margin-bottom: 2px;
      cursor: text;
      user-select: auto !important;
      span {
        font-family: 'poppins-local-bold';
      }
    }

    .expectedValueDescription {
      color: $grayForReport;
      max-width: 1000px;
      margin-right: 20px;
      cursor: text;
      user-select: auto !important;
    }
  }
  .bestWorstScenarioContainer {
    &.forReport {
      padding-left: 114px;
    }
    margin-top: 80px;
    .bestWorstScenarioTitle {
      cursor: text;
      user-select: auto !important;
      font-family: 'poppins-local-bold';
      font-size: 22px;
      margin-bottom: 2px;
    }
    .scenarioProbability {
      .scenarioProbabilityText,
      span {
        cursor: text;
        user-select: auto !important;
        color: $grayForReport;
      }
    }
    .bestWorstScenarioTableContainer {
      display: flex;
      flex-direction: column;
      border: 1px solid $grayForReport;
      &.fade {
        border: 1px solid $sundayGray;
      }
      border-radius: 5px;
      max-width: 855px;
      margin-right: 30px;
      margin-top: 8px;
      .bestWorstScenarioTableRow {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top: 2px;
        margin-bottom: 2px;
        @media screen and (max-width: 1100px) {
          margin-right: 5px;
        }
        .column1 {
          cursor: text;
          user-select: auto !important;
          width: 300px;
          @media screen and (max-width: 1100px) {
            width: 30%;
          }
          text-align: right;
          color: $grayForReport;
        }
        .column2,
        .column3 {
          cursor: text;
          user-select: auto !important;
          width: 250px;
          @media screen and (max-width: 1100px) {
            display: block !important;
            width: 35%;
            min-width: 35%;
            max-width: 35%;
          }
          text-align: right;
          &.financialOutcome {
            @media screen and (max-width: 1100px) {
            }
            .financialOutcomeTextContainer {
              margin-left: 10%;
              width: 90%;
              align-self: flex-end;
              .financialOutcomeText {
                cursor: text;
                user-select: auto !important;
                font-size: 20px;
                font-family: 'poppins-local-bold';
              }
            }
          }
        }
      }
    }
  }
  .newZopaContainer {
    margin-top: 60px;
    &.forReport {
      padding-left: 114px;
    }
    .newZopaTitle {
      font-family: 'poppins-local-bold';
      font-size: 22px;
      margin-bottom: 2px;
    }
    .newZopaDescription {
      max-width: 1000px;
      margin-bottom: 10px;
      margin-right: 20px;
      .newZopaDescriptionText {
        color: $grayForReport;
      }
    }
    .zopaGraphOuterContainer {
      @media screen and (max-width: 1000px) {
        padding-left: 20px;
      }
      .zopaTickText,
      .zopaTickNumber {
        color: $grayForReport;
        &.zopa {
          color: $ageanMidnight;
        }
      }
      #zopaGraphOpposingParty,
      #zopaGraphClient {
        background-color: $zopaBackground !important;
      }
      #zopaGraphZOPA {
        background-color: $mariaViolet !important;
      }
    }
  }
  .hiddenSectionLayerContainerZOPA {
    background-color: white;
    position: absolute;
    width: 105%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.8;
  }
  .calculateZopaContainer {
    margin-top: 35px;
    .newZopaTitle {
      font-family: 'poppins-local-bold';
      font-size: 22px;
      margin-bottom: 2px;
    }
    .calculateZopaButton {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
  .executiveSummaryLegendsContainer {
    margin-top: 60px;
    &.forReport {
      padding-left: 114px;
    }
    .executiveSummaryLegendText {
      font-size: 10px;
    }
  }
  .previewPDF {
    padding-left: 114px;
  }
}
