.freemiumBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: $transparentBackgroundGray;

  .freemiumPopUp {
    background-color: white;
    box-shadow: 0px 0px 30px #0000002c;
    height: 485px;
    width: 496px;
    border-radius: 10px;
    padding-right: 46px;
    padding-left: 46px;
    padding-top: 45px;
    padding-bottom: 38px;
    position: relative;
    margin-top: calc((100vh - 485px) / 5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
      color: #201a2d;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .description {
      color: #201a2d;
      font-size: 16px;
      margin-bottom: 32px;
    }

    .mainContent {
      color: #201a2d;
      font-size: 18px;
      margin-left: 13px;
      margin-bottom: 8px;
    }

    .contentRow {
      margin-left: 24px;
      margin-bottom: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .bullet {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #201a2d;
        margin-right: 5px;
      }

      .text {
        color: #201a2d;
        font-size: 16px;
      }
    }

    .contactButton {
      width: 100%;
      background-color: #321b59;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      cursor: pointer;

      .text {
        font-size: 16px;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .closeIcon {
      position: absolute;
      top: -28px;
      right: -28px;
      width: 56px;
      height: 56px;
      box-shadow: 0px 0px 30px #0000002c;
      border-radius: 30px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .freemiumIcon {
      position: absolute;
      top: 200px;
      right: 0px;
      height: 130px;
    }
  }
}
