.legalPageContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .legalPageInnerContainer {
    width: 70%;
    .legalPageHeader {
      width: 100%;
      margin-top: 20px;
      .eperotoLogo {
        width: 100px;
      }
    }
    .legalPageTitle {
      margin-top: 90px;
      margin-left: 40px;
      .legalPageTitleText {
        font-size: 36px;
        font-weight: $boldWeight;
      }
    }
    .legalPageMainText {
      margin-top: 50px;
      margin-left: 40px;
    }
  }
}
.react-pdf__Document {
  .react-pdf__Page {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100% !important;
  }

  .react-pdf__Page__textContent {
    display: none;
  }
  .react-pdf__Page__canvas {
    user-select: text;
    width: 1224px !important;
    height: 1584px !important;
    margin-bottom: 40px;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
}
