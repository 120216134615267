.barContainer {
  max-width: 1010px;
  margin-left: 78px;
  margin-right: 30px;

  .tagsection {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    z-index: 1;

    .tag {
      text-align: center;
      position: relative;
      box-sizing: border-box;
      height: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &:first-of-type {
        border-radius: 50px 0px 0px 50px;
      }

      &:last-of-type {
        border-radius: 0px 50px 50px 0px;
      }
      &:only-of-type {
        border-radius: 50px 50px 50px 50px;
      }
    }

    .tagText {
      position: relative;
      font-weight: 700;
      user-select: none;
      display: block;
      overflow: hidden;
      @media screen and (max-width: 1230px) {
        font-size: 12px;
      }
      font-size: 16px;
      height: fit-content;
      margin: auto 0;
    }
  }
}

.barChartHelperTextContainer {
  max-width: 1010px;
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 40px;
  .barChartHelperText {
    float: right;
  }
}
