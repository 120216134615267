.deleteMessageContainerForPreview {
  filter: blur(0);
  position: absolute;
  left: -7px;
  top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 322px;
  height: 143px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;

  &.expanded {
    height: 100px;
    left: calc(100% - 315px);
  }

  &.list {
    height: 45px;
    width: 550px;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    left: calc(100% - 540px);
  }

  .deleteQuestion {
    width: 100%;
    text-align: center;
    margin-top: 10px;

    &.expanded {
      margin-top: 0px;
    }

    &.list {
      justify-content: center;
      margin-top: 0px;
      margin-right: 50px;
    }
  }

  border-radius: 5px;
  padding: 7px;

  p {
    font-size: 22px;
    word-break: normal;
  }

  .buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;

    &.expanded {
      margin-bottom: 0px;
    }

    &.list {
      margin-bottom: 0px;
    }
  }

  .DeleteButton {
    background-color: #ac2828;

    &:hover {
      background-color: #ac2828;
    }

    &.list {
      margin-left: 20px;
    }
  }
}
