.treeResultsContainerBackground {
  position: absolute;
  right: 44px;
  top: 25px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  z-index: 11;

  .downloadImageComponentContainer {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
  }
  .warningPopUpContainer {
    min-width: 500px;
    max-width: 528px;
    display: flex;
    flex-direction: column;
    padding: 18px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 50px;
    .warningPopUpMessageContainer {
      margin-bottom: 25px;
      .warningPopUpMessage {
        text-align: center;
        .warningPopUpMessageSpan {
          color: $popCulture;
          text-decoration: underline;
          strong {
            color: $popCulture;
          }
          &:hover {
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
    .warningPopUpButtonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      /* .cancelButton {
        margin-right: 40px;
      } */
    }
  }

  .treeResultsContainer {
    /* @media screen and (max-width: 1330px) {
      top: 80px;
    } */
    @media screen and (max-width: 1000px) {
      top: 0px;
    }

    .switchContainer {
      transform: scale(0.9);
      margin-top: 0px;
    }
    position: relative;
    background-color: white;
    padding: 17px 12px 25px 18px;
    box-shadow: 0px 3px 30px #00000029;
    border-radius: 10px;
    height: fit-content;
    .treeResultsTitleContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 1;
      .treeResultsTitleText {
        font-size: 16px;
        font-family: 'poppins-local-medium';
        margin-right: 10px;
        margin-top: 4px;
      }
      .switchTreeTableInterestState {
        margin-right: -10px;
      }
    }
    .valueOfTreeAndRecentChangeContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 30px;
      margin-left: -4px;
      .valueOfTreeContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .valueOfTreeImg {
          margin-bottom: 3px;
          margin-right: 7px;
        }
        .valueOfTreeText {
          font-weight: bold;
        }
      }
      .valueOfTreePrecentageChange {
        margin-left: 23px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .changeOfvalueOfTreeImg {
          margin-bottom: 3px;
          margin-right: 3px;
        }
        .valueOfTreePercentageChangeText {
          color: $infantryWest;
          &.negative {
            color: $greekEaster;
          }
        }
      }
    }
    .treeSelectionGraphContainer {
      margin-top: 23px;
      .selectionGraphOnContainer {
        .selectionGraphTitles {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 18px;
          .selectionGraphTitle {
            font-family: 'poppins-local-medium';
            margin-right: 11px;
          }
        }
      }
    }
    .selectionGraphToggleText {
      text-decoration: underline;
      color: $popCulture;
      cursor: pointer;
      &:hover {
        color: $popCultureHover;
      }
    }
  }
  .showTreeResultsButtonContainer {
    /* position: absolute;
    right: 4px;
    top: -10px; */
    z-index: 10;
    word-break: keep-all;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .calculateTreeButton {
      height: 45px;
      width: 370px;
      background-color: $popCulture;
      padding: 1px 42px 1px 42px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: $borderRadiusSmall;
      cursor: pointer;
      &:hover {
        background-color: $popCultureHover;
        cursor: pointer;
      }
      &.small {
        width: 256px;
      }
      .calculateTreeButtonText {
        cursor: pointer;
        color: white;
        font-size: 18px;
        font-weight: $boldWeight;
        white-space: nowrap;
      }
      .MuiCircularProgress-root {
        margin-top: 5px;
      }
      .MuiCircularProgress-circle {
        color: white;
      }
    }
    .treeLimitReachedButton {
      background-color: $greekEaster;
      height: 54px;
      padding: 1px 12px 1px 12px;
      &:hover {
        background-color: $greekEasterHover;
        cursor: pointer;
      }
      .calculateTreeButtonText {
        font-size: 16px;
        white-space: normal;
        width: 230px;
      }
    }

    .showTreeResultsButton {
      background-color: white;
      /* position: absolute;
      top: 0;
      right: 0px; */
      width: fit-content;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 0px 0px 5px 5px;
      height: 38px;

      p {
        margin-left: 10px;
        margin-right: 10px;
        font-family: 'poppins-local-medium';

        width: fit-content;
        white-space: nowrap;
        cursor: pointer !important;
      }
    }
    .minimiseTreeResultsButton {
      position: relative;
      width: 26px;
      cursor: pointer;
      height: 26px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      box-shadow: 0px 3px 6px #00000029;
      &.showResults {
        margin-top: 4px;
      }

      .minimiseTableTreeResultsButton {
        cursor: pointer;
      }
    }
  }

  .minimise {
    position: relative;
    right: 0px;
    left: 0px;
    /* @media screen and (max-width: 1330px) {
      top: 80px;
    } */
    top: 0px;
  }
}

.tableInfo {
  align-self: flex-end;
  margin-top: 18px;
  text-align: right;
  right: 20px;
}

.circularProgressDiv {
  width: 110px;
  display: flex;

  flex-direction: row;

  justify-content: center;

  align-items: center;

  height: 100%;
}

.treeTableContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 15px;
  .treeTableTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    .treeTableTitleTextContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      align-items: center;
      height: 40px;
      .treeTableSortingArrowImg {
        position: absolute;
        right: 10px;
        width: 0.1px;
        cursor: pointer;
        user-select: none;
        &.column1 {
          right: 15px;
        }
        &.column2 {
          right: 28px;
        }
        &.column3 {
          right: 20px;
        }
        &.column4 {
          right: 3px;
        }
        &.column5 {
          right: 14px;
        }
        &.column6 {
          right: 14px;
        }
      }

      .treeTableTitleText {
        font-size: 14px;
        line-height: 1;

        text-align: center;
        cursor: pointer;
        &.column1 {
          min-width: 55px !important;
          width: 55px !important;
        }
        &.column2 {
          min-width: 120px !important;
          width: 120px !important;
        }
        &.column3 {
          min-width: 190px !important;
          width: 190px !important;
        }
        &.column4 {
          min-width: 120px !important;
          width: 120px !important;
        }
        &.column5 {
          min-width: 120px !important;
          width: 120px !important;
        }
        &.column6 {
          min-width: 100px !important;
          width: 100px !important;
        }
      }
    }
  }
  .tableRows {
    margin-top: 5px;
    .activeRow {
      background-color: $springLevanter !important;
      &.krogerus {
        background-color: $krogerusActiveColor !important;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      //border-bottom: 0.1px solid #00000029;
      padding-top: 4px;
      cursor: pointer;
      border-radius: $borderRadiusSmall;
      &:hover {
        background-color: $hovercraftPurple;
        cursor: pointer;
      }
      p {
        font-size: 14px;
        cursor: pointer;
      }
      .column1 {
        min-width: 56px !important;
        width: 56px !important;
      }
      .column2 {
        min-width: 116px !important;
        width: 116px !important;
      }
      .column3 {
        min-width: 181px !important;
        width: 181px !important;
      }
      .column4 {
        min-width: 116px !important;
        width: 116px !important;
      }
      .column5 {
        min-width: 116px !important;
        width: 116px !important;
      }
      .column6 {
        min-width: 106px !important;
        width: 106px !important;
      }
    }
  }
}
.tableTreeResults {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $scrollBarBackground;
    border-radius: 10px;
    box-shadow: -1px 3px 6px #00000029;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $lakersUniform;
    border-radius: 10px;
    border: $scrollBarHandleBorder;
  }
}
.noScroll {
  overflow-y: hidden !important;
}
.treeTableCellContainer {
  margin: 0 2px 0 2px;
  .treeTableCell {
    color: $ageanMidnight;
    text-align: center;
    margin-bottom: 3px;
    display: inline-block;
    white-space: nowrap;
  }
}
