.emptyClaimsPreviewContainer {
  max-width: $toolComponentMaxWidth;
  width: 100%;

  .emptyClaimContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -25px;
    margin-left: 20px;

    .emptyClaimsTitleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 47px;

      .videoInfoButtonClaimsContainer {
        margin-left: 10px;
      }

      .emptyClaimsTitle {
        color: #201a2d;
        font-size: 32px;
        font-weight: $boldWeight;
        margin-right: 4px;

        &.error {
          color: #ac2828;
        }
      }
    }

    .emptyClaimsChoiceDiv {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      .emptyClaimsSingleChoice {
        max-width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .emptyClaimsInfoBulletList {
          padding-left: 25px;
          font-size: 16px;
          color: #201a2d;
        }

        .emptyClaimsButtonContainer {
          img {
            margin-left: 10px;
          }
        }
      }

      .emptyClaimsSingleChoice:first-child {
        margin-right: 170px;
      }
    }
  }
}
