@import './headerComponents/headerUndoRedo';
@import './headerComponents/headerPath';
@import './headerComponents/headerSave';

.headerContainer {
  @media only screen and (max-width: 836px) {
    min-width: 836px;
    position: absolute;
  }
  position: fixed;

  top: 0px;
  left: 0px;
  background-color: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 10;
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .leftHeader {
    max-width: calc(100vw - 520px);
  }
  .rightHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 32px;
    .changeLanguageContainer {
      display: flex;
      flex-direction: row;
      margin-right: 20px;
      position: relative;
      cursor: pointer;
      .languagePrefix {
        cursor: pointer;
      }
      .languageFlagImg {
        cursor: pointer;
        margin-right: 3px;
        width: 23px;
      }

      .languageDropdownContainer {
        position: absolute;
        top: 43px;
        right: 0px;
        width: 175px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        .languageDropdownItem {
          display: flex;
          flex-direction: row;
          height: 40px;
          border-radius: 10px;
          align-items: center;
          padding: 0px 10px;
          border-radius: 0px;
          &:first-of-type {
            border-radius: 10px 10px 0px 0px;
          }
          &:last-of-type {
            border-radius: 0px 0px 10px 10px;
          }
          &:hover {
            background-color: $hovercraftPurple;
          }
          cursor: pointer;
          .languageFlagImg {
            margin-right: 6px;
            width: 23px;
          }
        }
      }
    }
  }
}
