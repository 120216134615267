@import '../../styles/variables';
.infoClickComponentContainer {
  position: relative;
  &.noHelperText {
    margin-top: -20px;
  }
  .infoCircle {
    position: relative;

    cursor: pointer;
    margin-left: 7px;

    z-index: 1;
  }
  .infoClickTextContainer {
    width: 200px;
    height: fit-content;
    padding: 8px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    position: absolute;
    top: -10px;
    left: 35px;
    z-index: 3;

    &.withTitle {
      width: 300px;
    }

    .infoClickText {
      font-size: 16px;
      line-height: 1.2;

      &.withTitle {
        margin-bottom: 25px;
      }
    }

    .infoClickTitle {
      font-size: 16px;
      line-height: 1.2;
      font-weight: bold;
      color: $lakersUniform;
    }
  }
  .infoClickTransparent {
    width: 21px;
    height: 21px;
    z-index: 4;
    position: absolute;
    top: -5px;
    left: 8px;
    cursor: pointer;
  }
}

.infoHoverTextContainer {
  width: 200px;
  height: fit-content;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  .infoHoverText {
    font-size: 16px;
    line-height: 1.2;
    margin: 1px 0;
    word-break: keep-all;
  }
}

.hoverComponentContainer {
  position: absolute;
  z-index: 900;
}
