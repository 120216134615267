.selectionAreaContainer {
  position: absolute;

  .expectedValueLine {
    height: 1px;
    position: absolute;
    background-image: repeating-linear-gradient(
      89deg,
      $mondayGray,
      $mondayGray 5px,
      transparent 5px,
      transparent 10px
    );
  }

  .selectionArea {
    position: absolute;
    top: 0;

    background-color: rgba(150, 150, 150, 0.2);

    &.dragging {
      cursor: w-resize;
    }

    .selectionArea-probabilityContainer {
      width: 100%;
      height: 25px;
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 2px;
      &.tree {
        margin-top: -17px;
      }
      &.negativeGraph {
        margin-top: 2px;
        &.tree {
          margin-top: -30px;
        }
      }
      .selectionAreaProbabilityText {
        font-weight: bold;
        height: 20px;

        &.notHighlight {
          color: $mondayGray;
        }
        &.small {
          min-width: 60px;
          text-align: center;
          // background-color: red;
        }
        &.tree {
          font-size: 10px;
        }
      }
    }
  }
  .handleContainer {
    position: 'relative';
    .handlePlaceholder {
      position: absolute;
      top: 0;
      width: 20px;
      &.tree {
        width: 20px;

        left: 10px;
      }
      cursor: ew-resize;

      &.right {
        left: 20px;
      }
    }
    .handleLine {
      position: absolute;
      top: 0;
      left: 18px;
      width: 3px;
      background-color: $mondayGray;
      cursor: ew-resize;
    }
    .handleImage {
      position: absolute;
      top: -17px;
      left: 3px;
      box-shadow: inset 0px -1px 2px #00000029, 0px 3px 6px #00000029;
      &.tree {
        top: -15px;
        left: 5px;
        width: 30px;
      }
      border-radius: 20px;
      cursor: ew-resize;
    }
    .handleDeleteButton {
      position: absolute;
      top: 390px;
      left: 3px;
      box-shadow: inset 0px -1px 2px #00000029, 0px 3px 6px #00000029;
      border-radius: 20px;
      width: 35px;
      height: 35px;
      background-color: white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .addSelectionAreaContainer {
      position: absolute;

      &.handleLeft-button {
        left: -35px;
      }
      &.handleRight-button {
        left: 25px;
      }
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .addSelectionImg {
        background-color: white;
        width: 30px;
        border-radius: 15px;
        padding: 4px;
        box-shadow: inset 0px -1px 2px #00000029, 0px 3px 6px #00000029;
        cursor: pointer;
      }
    }
    .amountText {
      position: absolute;
      top: -74px;
      left: 0px;
      width: 200px;
      font-size: 12px;

      &.notHighlight {
        color: $mondayGray;
      }
      &.doubleLeft {
        left: -15px;
      }
      &.doubleRight {
        left: 15px;
      }
      transform: rotate(325deg);
      &.small {
        font-size: 10px;
        transform: rotate(325deg);
        top: -74px;
        left: -10px;
      }
      &.tree {
        font-size: 10px;
        top: -52px;
        width: 100px;
        &.small {
          transform: rotate(280deg);
          top: -113px;
          left: -60px;
        }
      }

      &.negativeGraph {
        top: -74px;
        &.tree {
          top: -54px;
        }
      }
    }
  }
}
.deleteButtonsBackground {
  height: 50px;
  position: absolute;
}

.showSelectionAreasTickContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  .showSelectionAreasText {
    margin-left: 10px;
    cursor: pointer;
    cursor: pointer;
  }
  .tickImg {
    cursor: pointer;
  }
  cursor: pointer;
}
