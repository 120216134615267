.disputedAmountInfoContainer {
  margin-top: -10px;
  @media screen and (max-width: 1500px) {
    max-width: 90vw;
    min-width: 90vw;
    margin-left: 20px;
  }
  @media screen and (max-width: 836px) {
    max-width: $toolComponentMinWidth;
    min-width: $toolComponentMinWidth;
    margin-left: 20px;
  }
  max-width: $toolComponentMaxWidth;
  min-width: $toolComponentMaxWidth;

  min-height: 54px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  .disputedAmountHoverContainer {
    min-width: 240px;
    background-color: white;
    height: 54px;
    margin-left: 10px;
    border-radius: $borderRadiusSmall;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .disputedAmountColumn {
      margin-top: 3px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .disputedAmountInfoAmountContainer {
        margin-left: 15px;

        .disputedAmountInfoAmount {
          color: $clientColor;
          &.krogerus {
            color: $krogerusClientColor;
          }
          margin-left: 20px;
          text-align: left;
          &.negative {
            color: $opposingPartyColor;
            &.krogerus {
              color: $krogerusOpposingPartyColor;
            }
          }
        }
      }
    }
    .text {
      align-items: flex-start !important;
      margin-left: 10px;
    }
    .calculateAmountButtonContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .calculateAmountButton {
        height: 44px;
        border: 1px solid $ageanMidnight;
        border-radius: $borderRadiusSmall;

        cursor: pointer;
        .calculateAmountsButtonText {
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
