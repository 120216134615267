.secondTrialProbsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  padding-left: $reportPaddingLeft;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .probability {
      font-weight: $boldWeight;
      color: $ageanMidnight;
    }
    .text {
      margin-left: 7px;
    }
  }
  .bracketImg {
    margin-left: 20px;
    margin-right: 20px;
  }
}
