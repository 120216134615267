@import './tabs/2_userManual/userManual';
@import './tabs/1_tutorials/tutorialTile';
@import './tabs/1_tutorials/tutorialBreadCrumb';

.tutorialTilesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  width: calc(#{$toolComponentMaxWidth} + 200px);

  min-width: 420px;
  margin-top: $tutorialsMainContainerTopMargin;
  margin-left: 40px;
  @media screen and (max-width: 1590px) {
    //.tutorialTabsContainer becomes fixed so this has to go down
    margin-top: calc(#{$tutorialsMainContainerTopMargin} + 65px);
  }
  @media screen and (max-width: 1470px) {
    width: 85vw;
    justify-content: flex-start;
  }
}
