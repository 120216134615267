.checklistContainer {
  position: fixed;
  left: 70px;
  bottom: 25px;
  z-index: 900;
  .checklistMenuContainer {
    padding: 5px 11px;
    background-color: white;
    border-radius: $borderRadiusMedium;
    box-shadow: 0px 3px 6px #321b5965;
    @media screen and (max-width: 940px) {
      zoom: 0.8;
    }
    .checklistText {
      margin-left: 5px;
      font-size: 16px;
      color: $lakersUniform;
      cursor: pointer;
      &:hover {
        font-weight: $boldWeight;
      }
    }

    &.on {
      padding: 7px 11px 7px 11px;
    }
    .checklistMenuRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &.title {
        margin-bottom: 5px;
      }

      .checklistMenuRowImgContainer {
        width: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 3px;
      }
      .checklistMenuRowText {
        margin-left: 5px;
        color: $lakersUniform;
        min-width: 115px;
        cursor: pointer;
        &:hover {
          font-weight: $boldWeight;
        }

        &.done {
          text-decoration: line-through;
        }
      }
      .checklistMenuTitleRowText {
        margin-left: 5px;
        color: $lakersUniform;
        min-width: 115px;
        font-weight: $boldWeight;
      }
    }
  }
}

.open {
  left: 180px;
}
