.addClaimContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  min-width: 255px;
  z-index: -1;
  .addClaimsMenuButtonContainer {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: $borderRadiusMedium;
    border: 1px solid $ageanMidnight;
    box-shadow: 0px 3px 6px #00000029;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: $hovercraftPurple;
    }
    .addClaimButtonImg {
      width: 29px;
      height: 29px;
    }
  }

  .addClaimMenu1Container,
  .addClaimMenu2Container {
    .addClaimButtonContainer {
      img {
        margin-bottom: 10px !important;
        margin-left: 10px;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    button {
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

#claimsComp {
  @media screen and (max-width: 1170px) {
    padding: 35px 10px;
  }
  padding: 35px 30px;
}

.addClaimText {
  @media screen and (max-width: 1170px) {
    margin-left: 45px;
  }

  margin-left: 25px;
}

.claims {
  margin-top: 0px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  height: 130px;
  .addClaimButton {
    position: relative;
  }
  .mainButton {
    white-space: nowrap;
  }
  .treeButtonContainer {
    @media screen and (max-width: 1170px) {
      margin-left: 20px !important;
    }
  }
  .claimOrCounterclaimContainer {
    @media screen and (max-width: 1170px) {
    }
    width: 400px;
    margin: 0px 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .cancelAddClaim {
      @media screen and (min-width: 1040px) and (max-width: 1070px) {
        margin-left: -8px !important;
      }
      position: relative;
      align-self: center;
      height: 17px;
      margin-left: 5px;
      cursor: pointer;
    }
    button {
      margin: 0 20px 0 0;
      white-space: nowrap;
    }
  }
  .treeMenuContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    position: absolute;
    border-radius: 10px;
    box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
    padding: 20px;
    background-color: white;
    width: 350px;
    right: 10px;
    top: -25px;
    z-index: 16;
    .inputText {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }

    .treeMenuButtonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 0px;
      width: 330px;

      .createButton {
        white-space: nowrap;
      }
    }
  }
}
