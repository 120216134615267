.quantumContainer {
  position: relative;

  background-color: #e5e5e5;
  border-radius: 10px;
  padding: 15px 66px 24px 22px;
  margin-bottom: 60px;
  max-width: 1015px;

  margin-left: 78px;
  z-index: 2;

  .infoElementContainer {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .dropdown,
  .inputNum,
  .inputdate,
  .inputNum-RO,
  .dataList {
    margin-left: 15px !important;
  }

  .quantumTitleContainer {
    .quantumTitle {
      font-size: 20px;
      font-weight: $boldWeight;
      margin-bottom: 10px;
    }
    .quantumHelperText {
      width: 450px;
      font-size: 16px;
    }
  }

  .includeExternalConsequenceContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    img {
      margin-right: 5px;
      width: 32px;
      cursor: pointer;
    }
    p {
      cursor: pointer;

      font-size: 16px;
      font-weight: 400;
    }
    .infoElementContainer {
      position: relative;
      left: -2px;
      top: 0;
      right: 0;
      .infoClickComponentContainer {
        img {
          width: 18px;
        }
      }
    }
  }

  .singleQuantumContainer {
    @extend .flexColumn;
    z-index: 1;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-top: 37px;

    .singleQuantum {
      @media screen and (max-width: 1230px) {
        display: grid;
        grid-template-areas:
          'amount probability delete'
          'outOfCourt outOfCourt delete';
      }
      position: relative;
      background-color: white;
      height: 93px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .fullSuccessCoverLayer {
        position: absolute;
        background-color: transparent;
        z-index: 10;
        top: 5px;
        left: 10px;
      }

      .singleExternalConsequenceContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 500px;
        height: 100%;
        grid-area: outOfCourt;

        .single-quantum-add-button-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          p {
            margin-left: 12px;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 1230px) {
          margin-left: 14px;
          margin-top: -20px;
        }

        @media screen and (max-width: 1040px) {
          margin-left: 10px;
        }
        margin-left: 40px;
        .externalConsequenceAmountContainer {
          height: 100%;
          position: relative;
          margin: 0px 20px auto 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .inputNum {
            margin-left: 0px !important;
          }
          img {
            position: absolute;
            right: -14px;
            @media screen and (max-width: 1230px) {
              top: 5px;
            }
            top: 16px;
            cursor: pointer;
          }
        }
      }
      .greenRedOutOfCourt {
        margin-top: 7px;
      }
      .amountInputContainer {
        height: 100%;

        grid-area: amount;
        display: flex;
        flex-direction: column;
        &.fullAmountInputContainer {
          margin-top: 2px;
        }
        margin-top: 20px;
        justify-content: center;
        .amount {
          .input-label-text-p {
            letter-spacing: -0.04em !important;
          }
        }
      }
      .probabilityContainer {
        height: 100%;
        position: relative;
        grid-area: probability;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .input-label-text-p {
          letter-spacing: -0.04em !important;
        }
      }
    }
  }
  .addQuantumContainer {
    margin: 15px 0 0 0;
  }
  .addQuantumButton {
    position: static;
    margin: auto 0;
  }

  .requirementLabels {
    label {
      margin-right: 165px;
    }
  }
  .quantumArrow {
    position: absolute;
    left: 47px;
    bottom: -28px;
    background-color: #e5e5e5;
    height: 65px;
    width: 65px;
    transform: rotate(45deg);
    z-index: -1;
  }
  .deleteContainer {
    right: -31px;

    position: absolute;
    cursor: pointer;
    margin-left: 20px;
    img {
      height: 20.5px;
      width: 17.3px;
    }
  }
}
