$enabledColor: #321b59;
$disabledColor: #9f9f9f;

.switchContainer {
  display: flex;
  flex-direction: row;
  height: 38px;
  width: 610px;
  align-items: center;
  margin-top: 10px;

  .switchText {
    color: $enabledColor;
    margin-left: 20px;
    margin-right: 25px;
    &.disabled {
      color: $disabledColor;
    }
  }

  &.first {
    margin-top: 30px;
  }

  button {
    margin-left: auto;
  }
}

.termsContainer {
  margin-left: 73px;
  margin-top: 88px;
  display: flex;
  flex-direction: row;

  .termsText {
    margin-left: 5px;
    margin-right: 50px;
    min-width: 115px;
    cursor: pointer;
    &:hover {
      font-weight: $boldWeight;
    }
  }
}
