.singleSegmentContainer {
  min-height: 45px;
  margin-top: 1px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px #00000029;
  border-radius: 10px;

  .possibleOutcomeRangeContainer {
    height: 45px;
    width: 308px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
    .outcomeContainer {
      max-width: 140px;
      min-width: 140px;
      padding: 0 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .outcomeRangeAmount {
        font-weight: 400;
        color: $clientColor;
        &.krogerus {
          color: $krogerusClientColor;
        }
      }
      .red {
        color: $opposingPartyColor;
        &.krogerus {
          color: $krogerusOpposingPartyColor;
        }
      }
      .outcomeRangePercentage {
        font-size: 12px;
        color: #8e8e8e;
        margin-top: -3px;
      }
    }
    .maxmin {
      width: 100%;
      max-width: 308px !important;
      min-width: 308px !important;
      p {
        text-align: center;
      }
      .outcomeRangeAmount {
        font-weight: $boldWeight + 100;
      }
    }
    .dashContainer {
      width: 28px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .dash {
        font-size: 20px;
        color: #8e8e8e;
      }
    }
  }
  .legalFeesRangeContainer {
    padding: 7px 27px 7px 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 642px;
    .legalFeesRangeTextContainer {
      width: 233px;
      text-align: center;
      line-height: 20px;
      padding-top: 5px;
      &.forNewLCD {
        width: 100%;
        max-width: 642px;
      }
      .legalFeesRangeText {
        width: 233px;
        @media screen and (max-width: 900px) {
          width: 20vw;
        }
        font-size: 15px;
        &.forNewLCD {
          width: 100%;
          max-width: 642px;
          @media screen and (max-width: 900px) {
            width: 40vw;
          }
        }
      }
      .bold {
        font-weight: $boldWeight;
      }
    }
    .pdfPreviewlegalFeesRangeTextContainer {
      width: 560px;
      .pdfPreviewLegalFeesRangeText {
        width: 560px;
      }
    }
    .legalFeesRangeSliderContainer {
      position: relative;
      width: 100%;
      margin-left: 26px;
      margin-top: 4px;
      .rangeBackground {
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .legalFeesRangeSlider {
        margin-top: 3px;
        -webkit-appearance: none;
        background-color: transparent;
        top: 0px;
        left: 0px;
        position: absolute;
        cursor: pointer;

        width: 100%;
        height: 20px;
        z-index: 2;
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          background-color: white;
          opacity: 1;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #2e394b;
          border-radius: 5px;
          width: 18px;
          height: 39px;
        }
      }
    }
  }
}
.pdfPreviewSingleSegment {
  box-shadow: none;
  border-radius: 0;
  width: 900px;
  &.forNewLCD {
    width: 100%;
  }
}
