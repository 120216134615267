@import './barChart/barChart';
@import './liability/liability';
@import './liability/singleRequirement';
@import './quantum/quantum';
@import './quantum/singleQuantum';

.delete {
  position: absolute;
  top: 14px;
  right: 15px;
  cursor: pointer;
  .exitImg {
    height: 27px;
    cursor: pointer;
  }
}

.IndependentSingleClaimPlaceholder {
  height: 3000px;
}

.claimContainer {
  animation: fadeIn 0.2s;
  margin-top: 3px !important;
  margin-bottom: 200px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.claimTitleContainer {
  background-color: $lakersUniform !important;
  &.krogerus {
    background-color: $krogerusClientColor !important;
  }
  border-radius: $borderRadiusLarge $borderRadiusLarge 0 0;
  min-height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding-right: 50px;
  .tool-component-title {
    width: 100%;
    height: 58px;
    margin: 0;
    .claimTitle {
      color: white;
      margin-left: 18px;
    }
    .claimLine {
      width: 2px;
      height: 30px;
      border-radius: 1px;
      margin-left: 15px;
      margin-right: 7px;
      background-color: white;
    }
    .titleClaimName {
      color: white;
      margin-left: 18px;
    }
  }
}

.valuOfIndependentClaimContainer {
  margin-left: 90px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  .valuOfIndependentClaimText,
  .valuOfIndependentClaimAmount {
    font-weight: bold;
    margin-right: 6px;
  }
  .valueOfIndependentClaimInfoButtonContainer {
    margin-top: -2px;
    position: relative;
    .valueOfIndependentClaimInfoButton {
      cursor: pointer;
    }
    .valueOfIndependentClaimInfoButtonPopUpContainer {
      position: absolute;
      width: 224px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      background-color: white;
      top: -8px;
      left: 30px;
      padding: 10px;
      .title {
        font-weight: bold;
      }
      .valuOfIndependentClaimSection {
        margin-top: 9px;
        .valuOfIndependentClaimSectionHeader {
          display: flex;
          flex-direction: row;
          .valuOfIndependentClaimSectionTitle {
            font-style: italic;
          }
          .valuOfIndependentClaimSectionImage {
            margin-left: 8px;
            margin-top: -8px;
          }
        }
        .valuOfIndependentClaimSectionValue {
          font-family: 'poppins-local-medium';
        }
      }
    }
  }
}
