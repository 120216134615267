@import './treeTable/treeTable.scss';

/* Choose input number fields with no arrows for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Choose input number fields with no arrows for Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.treeComponent {
  position: relative;
  width: 100%;
  @import './treeSideMenu/treeSideMenu';
  .treeContainer {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    position: relative;
    .treeAppBackground {
      position: relative;
      margin-top: 100px;
      @import './treeComponents/treeEvent';
      @import './treeComponents/treeNode';
      .treeInfoForDebugging {
        position: fixed;
        top: 100px;
        right: 10px;
        background-color: #d88cfba5;
        z-index: 600;
        transform: scale(0.8);
      }
      .deleteLineButtonContainer {
        position: relative;
        width: 120px;
        height: 70px;
        cursor: pointer;
        padding-right: 60px;
        margin-left: 12%;
        .deleteLineButtonImgContainer {
          @include treeButtonWithLabelImgContainer();

          .deleteLineButtonImg {
            @include treeButtonWithLabelImg();
          }
        }
        .deleteLineButtonTextContainer {
          @include treeButtonWithLabelTextContainer();
          position: absolute;
          padding: 4px 4px;
          left: 50px;
        }
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .deleteLineText {
          background-color: white;
          color: red;
          cursor: pointer;
        }
      }
      .treeCanvasBackground {
        background-color: transparent;
        &.border {
          border: 1px solid black;
        }
        width: fit-content;
        height: fit-content;
        position: relative;
        margin-left: 100px;

        .deleteNodeOrEventMenuContainer {
          position: absolute;
          padding: 21px 15px 0px 015px;
          background-color: white;
          border-radius: $borderRadiusSmall;
          z-index: 10;
          box-shadow: 0px 4px 6px #00000029;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .deleteNodeOrEventMenuButton {
            margin-bottom: 16px;
          }
        }

        .circlesOuter {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          background-color: transparent;

          .circles {
            background-color: black;

            width: 50px;
            height: 50px;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            .hoverForLineContainer {
              position: relative;
              width: 50px;
              height: 50px;
              border-radius: 25px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .hoverForlineCircle {
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                //background-color: red;
                cursor: pointer;
                &.transparent {
                  background-color: transparent;
                }
                .hoverForlineCircleInner {
                  background-color: grey;
                  opacity: 0.7;
                  width: 15px;
                  height: 15px;
                  border-radius: 10px;
                  cursor: pointer;
                }
              }
            }
            .circleName {
              cursor: pointer;
              color: white;
            }
          }
        }
        .deleteNodeOrEventMenuTranparentLayer {
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100vw;
          height: 100vh;
          background-color: red;
          z-index: 6000;
        }
      }

      .lineFadeOut {
        @include fadeOutAnimation();
      }

      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      .lineHandle {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: white;
        border: solid 1px black;
        cursor: pointer;
      }

      .treeInfo {
        position: fixed;
        top: 150px;
        right: -50px;
        z-index: 300;
        height: 500px;
        width: 900px;
        overflow-y: scroll;
        pointer-events: none;
        p {
          font-size: 12px;
        }
        transform: scale(0.8);
        transform-origin: top;
        display: flex;
        flex-direction: row;

        .nodesInfo,
        .eventsInfo {
          overflow-y: scroll;
          height: 900px;
          pointer-events: all;
        }

        .nodeSection,
        .eventsSection {
          width: 300px;
          margin-right: 3px;
          background-color: rgba(242, 193, 201, 0.471);
          &.eventsSection {
            background-color: rgba(193, 242, 201, 0.471);
          }
          border-radius: 4px;
          margin-bottom: 3px;
          padding: 2px;
        }
      }

      .treeEventNumber,
      .treeNodeNumber {
        position: absolute;
        color: rgb(24, 121, 71);
        &.treeNodeNumber {
          color: rgb(186, 88, 63);
          top: -20px;
        }
        top: -10px;

        font-size: 20px;
      }
    }
  }

  .translateTree {
    border-top: 2px solid $ageanMidnight;

    width: 100%;
    z-index: 9;
    position: absolute;
    top: 0px;
  }
}

//Commented Out in TreeComponent - Revert if needed
.overlay {
  height: 1500px;
  width: 4000px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #f6f6f6;
  z-index: 1900;
  animation: fadeOut 1.5s;
  border-top: 0px solid $ageanMidnight;
}

@keyframes fadeOut {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

button {
  &:focus {
    outline: 0;
  }
}

textarea {
  &:focus {
    outline: 0;
  }
  resize: vertical;
}

.zoomTools {
  position: absolute;
  top: 20px;
  left: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  img {
    box-shadow: 0px 3px 6px #0000005a;
    cursor: pointer;
    border-radius: 10px;

    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1400px) {
  .treeContainer {
    .showTreeResultsButton {
      display: none;
    }
  }
}

.treePreviewForDownload {
  position: fixed;
  z-index: 12;
}
