#breadcrumbPathTitleTooltip,
#reportSortOrderButtonTooltip {
  z-index: 11 !important;
}

#scenarioNameBreadCrumbTooltip {
  z-index: 15 !important;
  margin-left: 150px !important;
  margin-top: -13px !important;
}
