.previewPdfMargin {
  margin-left: $reportPaddingLeft;
}
.fadeText {
  color: $sundayGray !important;
}

.reportSectionContainer,
.disclaimerContainer {
  @import '../../../../../2_claims/claimsPreview/independentClaimPreviewList/independentClaimPreviewList';
  @import '../../../../../2_claims/claimsPreview/claimsPreview';

  .weigthedValueInfoMessageContainer {
    margin-top: 22px;
    padding-left: $reportPaddingLeft;
    font-size: 12px;
  }

  .claimsPreviewReportSectionContainer {
    padding-left: $reportPaddingLeft;
    #claimsPreviewContainerReport {
      min-width: 1020px;
      max-width: 1020px;
      z-index: 0;
      .listPreviewTableNamesDiv {
        margin-left: 3px;
      }
      .listPreviewTitleDiv {
        margin-left: 1px;
      }
      .claimsOverviewListContainer {
        border-radius: 10px;
        min-height: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 5px;
        .listTitleDiv {
          width: 43%;
          margin-right: 40px;
        }
        .listPreviewOtherTableNamesDiv {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;
          max-width: 170px;
          position: relative;
        }
      }
    }
    .claimsPreviewMain {
      margin-left: 0;
    }
  }
  &.customSectionContainer {
    animation: fadeIn 1.5s;
  }
  &.fadeOut {
    animation: fadeout 1.5s;
  }
  position: relative;
  margin-top: 54px;
  .reportSectionRemoveMoveContainer {
    position: absolute;
    top: -40px;
    right: 0px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .deleteSectionImg {
      height: 19.1px;
      margin-right: 10px;
      cursor: pointer;
      &.hidden {
        visibility: hidden;
      }
    }
    .reportMoveSectionContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .reportMoveSectionImgContainer {
        .moveImgUp {
          width: 33px;
          cursor: pointer;
          &:hover {
            filter: drop-shadow(0px 0px 2px #00000029);
          }
        }
        .moveImgDown {
          width: 33px;
          cursor: pointer;
          transform: rotate(180deg);
          &:hover {
            filter: drop-shadow(0px 0px 2px #00000029);
          }
        }
      }
      .reportMoveSectionText {
        margin-left: 5px;
      }
    }
  }
  .transparentSectionLayerContainer {
    top: 0px;
    position: absolute;
    width: 1100px;
    margin-left: 114px;
    height: 100%;
    z-index: 7;
    background-color: transparent;
    &.hiddenSectionLayerContainer {
      background-color: white;
      opacity: 0.85;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .inlcudeMessageContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .notIncludedMessage {
        height: 45px;
        width: 274px;
        border: 1px solid gray;
        background-color: white;
        border-radius: $borderRadiusSmall;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
      }
    }
  }
  .reportSectionTitleContainer {
    position: relative;
    display: flex;
    flex-direction: row;

    width: fit-content;
    .editTitleButtonContainer {
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .editButtonImg {
        cursor: pointer;
        width: 17px;
        margin-right: 8px;
      }
      .editButtonText {
        cursor: pointer;
        color: $lakersUniform;
      }
    }
  }
  .containerTitle {
    padding-left: $reportPaddingLeft;
    font-size: 24px;
    font-weight: $boldWeight;
    color: $ageanMidnight;
    cursor: text;
  }
  .sectionTitleInput {
    border: $lakersUniform solid 1px;
    border-radius: $borderRadiusSmall;
    font-size: 24px;
    font-weight: $boldWeight;
    margin-left: calc(#{$reportPaddingLeft - 4px});
    margin-top: -2px;
    margin-bottom: -2px;
    padding: 1px 3px;
    padding-left: 0px;
    width: 470px;
    color: $ageanMidnight;
    &:focus {
      outline: none !important;
      border: 2px solid $lakersUniform !important;
      padding: 0px 2px;
    }
  }
  .containerParagraph {
    padding-left: $reportPaddingLeft;
    margin-top: 26px;
    font-weight: 300;
  }
  .treePreviewOuterContainer {
    margin-top: 20px;

    position: relative;
    .treePreviewContainer {
      transform-origin: top left;

      position: relative;
      width: 1050px;
      height: fit-content;
      .levels {
        .level:last-of-type {
          padding-bottom: 0px !important;
        }
      }
      .independentClaimContainer {
        margin-left: 0px;
      }
    }
    .treePreviewContainerTransparentLayer {
      left: 0;
      position: absolute;
      z-index: 1;
      width: 1050px;
      background-color: transparent;
    }
  }
  .eReportContainer {
    margin-left: -50px;
  }
  .claimsPreviewMemo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 80px;

    .claimsPreviewMemoColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;
      height: 100px;
      max-width: 155px;

      &.twoColumns {
        height: 50px;
      }

      &.threeColumns {
        height: 75px;
      }

      &.descriptions {
        align-items: flex-start !important;
      }

      .claimsPreviewMemoColumnText {
        text-align: start;
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 155px;

        &.invisible {
          visibility: hidden;
        }

        &.bold {
          font-weight: bold;
          text-align: center;
        }
      }

      .claimsPreviewMemoColumnImage {
        height: 14px;

        &.bigger {
          height: 23px;
          margin-left: 7px;
        }

        &.biggerOpposite {
          height: 25px;
          margin-left: 8.5px;
        }

        &.lessMargin {
          height: 15px;
        }
      }
    }
  }
  .claimsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1250px;
    padding-left: $reportPaddingLeft;
  }
  .independentClaimPreviewContainer {
    margin-top: 40px;
    padding: 5px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .reportParagraphContainer {
      margin-top: 0px;
    }
    .singleClaimPreviewContainer {
      box-shadow: 0px 0px 0px white;
      border-radius: 5px;
      margin-bottom: 15px;
    }
    .independentClaimTileInnerContainer {
      padding-left: 4px;
      padding-bottom: 1px;
      margin-bottom: 1px;
    }
  }
  .graphSubtitleReport {
    margin-top: 20px;
    margin-left: $reportPaddingLeft;
  }
  .graphContainer {
    padding-left: $reportPaddingLeft;
    margin: 0px;
    margin-top: 20px;
    position: relative;
    &.distributionGraphReportContainer {
      margin-top: 0px;
    }
    &.getPayGraphReportContainer {
      padding-top: 0px;
    }
    &.selectionGraphReportContainer {
      height: 550px;
      width: 1200px;
      margin-top: 0px;
      padding-top: 0px;
      .freeSpace {
        height: 60px;
        width: 100%;
        &.noRanges {
          height: 20px;
        }
      }
    }

    .transparentLayer {
      position: absolute;
      height: 500px;
      width: 93%;
      top: 10px;
      left: 100px;
    }

    #getPayPreviewContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .chart {
      position: relative;
      width: 100%;
      svg {
        margin-top: -40px;
      }
      .sub-chart {
        width: 100%;
      }
    }
    .chartBell {
      svg {
        margin-top: -40px;
      }
    }
  }
  .bestAndWorstScenarioContainer {
    margin-left: $reportPaddingLeft;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 0px;
    margin-bottom: 60px;
    margin-top: 20px;
    position: relative;
    .transparentLayer {
      position: absolute;
      top: 0px;
      z-index: 6;
      background-color: transparent;
      height: 100%;
      width: 100%;
    }
  }
  .enforcementDetailsContainer {
    padding-left: $reportPaddingLeft;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;
    .enforcementPercentage {
      font-size: 20px;
      font-weight: $boldWeight;
    }
    .enformentText {
      margin-left: 15px;
    }
  }
}

.editButtonContainer {
  position: absolute;
  left: 30px;
  top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  &.claims {
    left: 4px;
    top: initial;
    bottom: -20px;
  }
  cursor: pointer;
  .editButtonImg {
    cursor: pointer;
    width: 17px;
    margin-right: 8px;
  }
  .editButtonText {
    cursor: pointer;
    color: $lakersUniform;
  }
}

.reportTreeParagraphContainer {
  z-index: 5;
  margin-top: 40px;
  margin-bottom: 0px;
}
.legalCostsDistributionPreviewAppContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: $reportPaddingLeft;
  margin-top: 20px;
  &.noReport {
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 44px;
  }
  .legalCostsDistributionPreviewAppInnerContainer {
    padding-bottom: 30px;
  }
  .lFDAppTitle {
    margin-left: 110px;
    margin-bottom: -20px;
    max-width: 140px;
    text-align: center;
    color: $ageanMidnight;
    font-size: 14px;
  }
}
