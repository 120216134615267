.sideMenuLogOutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 5px 6px #00000029;
  z-index: 2;
  .mnmActivationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .mnmActivationText {
      font-size: 10px;
    }
  }
  .userAvatarContainer {
    position: relative;
    margin-bottom: 12px;
    .userThumbnailContainer {
      &.bigUserThumbnailContainer {
        width: 45px;
        height: 45px;
        border-radius: 35px;
        .userThumbnailText {
          cursor: pointer;
          font-size: 16px;
        }
      }
      width: 39px;
      height: 39px;
      border-radius: 20px;
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .userThumbnailText {
        cursor: pointer;
        font-size: 13px;
      }
    }

    .userHoverContainer {
      position: absolute;
      left: 70px;
      top: 0px;
      width: fit-content;
      height: 51px;
      background-color: white;
      border-radius: $borderRadiusSmall;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      box-shadow: 0px 3px 6px #0000004d;
      padding: 0px 10px;
      .greeting {
        color: $popCulture;
        font-size: 16px;
        font-weight: $boldWeight;
        white-space: nowrap;
      }
      .userName {
        font-size: 16px;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
  }
  .logOutContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 81px;
    .logoutText {
      font-size: 14px;
      margin-right: 5.5px;
      cursor: pointer;
    }
    .logOutMenuContainer {
      padding: 28px 20px;
      position: fixed;
      width: 318px;
      height: 155px;
      box-shadow: 0px 3px 6px #0000004d;
      left: 174px;
      bottom: 70px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      border-radius: $borderRadiusMedium;
      z-index: 900;
      .circularProgressDiv {
        width: 100%;
      }
      .logOutMenuTextContainer {
        .logOutMenuText {
          text-align: center;
          font-size: 16px;
          margin-bottom: 37px;
        }
      }
      .logOutMenuButtonsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }
}
