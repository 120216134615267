.addRemoveAccessMenuContainer {
  width: 348px;
  position: absolute;
  top: 0px;
  right: -20px;
  height: 172px;
  padding: 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: $borderRadiusSmall;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1201;
  &.big {
    height: 220px !important;
  }
  .circularProgressDiv {
    height: 140px;
    min-height: 140px !important;
  }
  .addRemoveAccessMenuAcceptedTextContainer {
    text-align: center;
    font-size: 20px;
    padding-top: 35px;
  }
  .addRemoveAccessMenuTextContainer {
    width: 100%;
    .addRemoveAccessMenuText {
      font-size: 20px;
    }
  }
  .addRemoveAccessMenuButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .addAccessButton {
      height: 40px;
      border: none;
      padding: 0 14px;
      border-radius: $borderRadiusSmall;
      cursor: pointer;
      font-weight: $boldWeight;
      background-color: $lakersUniform;
      color: white;
      &:hover {
        background-color: $lakersUniformHover;
      }
    }
    .removeAccessButton {
      height: 40px;
      border: none;
      padding: 0 14px;
      border-radius: $borderRadiusSmall;
      cursor: pointer;
      font-weight: $boldWeight;
      background-color: $laCandyfloss;
      color: $ageanMidnight;
      &:hover {
        background-color: $laCandyflossHover;
      }
    }
    .leaveAccessButton {
      height: 40px;
      border: 1px $lakersUniform solid;
      padding: 0 14px;
      border-radius: $borderRadiusSmall;
      cursor: pointer;
      font-weight: $boldWeight;
      background-color: white;
      color: $lakersUniform;
      &:hover {
        background-color: $hovercraftPurple;
      }
    }
  }
}
.transparent-Background-2 {
  position: fixed;
  top: -300px;
  left: -700px;
}
