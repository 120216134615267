.singlClaimUpperPartTree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $mariaViolet;
  border-radius: $borderRadiusMedium;
  width: 100%;
  min-height: 60px;
  height: 60px;
  padding: 7px 10px 4px 10px;
  user-select: none;
  cursor: pointer;
  .claimTypetIdContainer {
    color: white;
    margin-left: 9px;
    margin-right: 8px;
    font-size: 16px;
    font-weight: $boldWeight;
    width: 78px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .verticalLine {
    height: 29px;
    width: 1px;
    background-color: white;
  }

  .previewClaimName {
    color: white;

    font-size: 14px;
    text-align: center;
    width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'poppins-local-medium';
  }
  .errors {
    @extend .sharable-error;
  }
}

.claimPreviewPartLine {
  position: relative;
  height: 7px;
  width: 1px;
  background-color: $ageanMidnight;
}

.singlClaimDownPartTree {
  width: 100%;
  height: 55px;
  min-height: 55px;
  border-radius: $borderRadiusMedium;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;

  .calculateAmountButton {
    width: 185px;
    height: 44px;
    border: 1px solid $ageanMidnight;
    border-radius: $borderRadiusSmall;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    cursor: pointer;
    .calculateAmountsButtonText {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .claimAmountText {
      color: $clientColor;
      &.krogerus {
        color: $krogerusClientColor;
      }
      text-align: center;
      margin-left: 20px;
      cursor: pointer;

      &.negative {
        color: $opposingPartyColor;
        &.krogerus {
          color: $krogerusOpposingPartyColor !important;
        }
      }
    }
  }
}

.active {
  background-color: $lakersUniform;
  &.krogerus {
    background-color: $krogerusClientColor;
  }
}
