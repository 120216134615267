.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .logoContainer {
    position: fixed;
    top: 20px;
    left: 20px;

    .logoImg {
      height: 70.44px;
      width: 127px;
    }
  }

  .messageContainer {
    width: 298px;
    height: 109px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 30px #c9c9c9;
    border-radius: 10px;

    .messageText {
      color: #201a2d;
      font-size: 16px;
    }
  }
}
