/*Here add all the custom fonts as follows*/
/*CustomFont*/
//   @font-face {
//     font-family: 'CustomFont-Local-Regular';
//     src: url(../resources/fonts/CustomFont/CustomFont-Regular.ttf);
//     font-style: normal;
//     font-stretch: 100%;
//   }
//   @font-face {
//     font-family: 'CustomFont-Local-Medium';
//     src: url(../resources/fonts/CustomFont/CustomFont-Light.ttf);
//     font-style: normal;
//     font-stretch: 100%;
//   }
//   @font-face {
//     font-family: 'CustomFont-Local-Bold';
//     src: url(../resources/fonts/CustomFont/CustomFont-Bold.ttf);
//     font-style: normal;
//     font-stretch: 100%;
//   }

/*Prompt*/
@font-face {
  font-family: 'Prompt-Local-Regular';
  src: url(../resources/fonts/Prompt/Prompt-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Prompt-Local-Medium';
  src: url(../resources/fonts/Prompt/Prompt-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Prompt-Local-Bold';
  src: url(../resources/fonts/Prompt/Prompt-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*Gotham*/
@font-face {
  font-family: 'Gotham-Local-Regular';
  src: url(../resources/fonts/Gotham/Gotham-Medium.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Gotham-Local-Medium';
  src: url(../resources/fonts/Gotham/Gotham-Medium.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Gotham-Local-Bold';
  src: url(../resources/fonts/Gotham/Gotham-Bold.otf);
  font-style: normal;
  font-stretch: 100%;
}

/*Arial*/
@font-face {
  font-family: 'Arial-Local-Regular';
  src: url(../resources/fonts/Arial/Arial-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Arial-Local-Medium';
  src: url(../resources/fonts/Arial/Arial-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Arial-Local-Bold';
  src: url(../resources/fonts/Arial/Arial-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*Heebo*/
@font-face {
  font-family: 'Heebo-Local-Regular';
  src: url(../resources/fonts/Heebo/Heebo-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Heebo-Local-Medium';
  src: url(../resources/fonts/Heebo/Heebo-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Heebo-Local-Bold';
  src: url(../resources/fonts/Heebo/Heebo-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*Rubik*/
@font-face {
  font-family: 'Rubik-Local-Regular';
  src: url(../resources/fonts/Rubik/Rubik-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Rubik-Local-Medium';
  src: url(../resources/fonts/Rubik/Rubik-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Rubik-Local-Bold';
  src: url(../resources/fonts/Rubik/Rubik-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*Calibri*/
@font-face {
  font-family: 'Calibri-Local-Regular';
  src: url(../resources/fonts/Calibri/Calibri-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Calibri-Local-Medium';
  src: url(../resources/fonts/Calibri/Calibri-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Calibri-Local-Bold';
  src: url(../resources/fonts/Calibri/Calibri-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*PlayfairDisplay*/
@font-face {
  font-family: 'PlayfairDisplay-Local-Regular';
  src: url(../resources/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'PlayfairDisplay-Local-Medium';
  src: url(../resources/fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'PlayfairDisplay-Local-Bold';
  src: url(../resources/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*Roboto*/
@font-face {
  font-family: 'Roboto-Local-Regular';
  src: url(../resources/fonts/Roboto/Roboto-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Roboto-Local-Medium';
  src: url(../resources/fonts/Roboto/Roboto-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Roboto-Local-Bold';
  src: url(../resources/fonts/Roboto/Roboto-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

/*Chap*/
@font-face {
  font-family: 'Chap-Local-Regular';
  src: url(../resources/fonts/Chap/ChapLight.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Chap-Local-Medium';
  src: url(../resources/fonts/Chap/ChapMedium.otf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'Chap-Local-Bold';
  src: url(../resources/fonts/Chap/ChapMedium.otf);
  font-style: normal;
  font-stretch: 100%;
}
