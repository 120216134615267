@import './variables';
@import './customFonts';
@import './cssFolders/pages/notSupportedBrowser/notSupportedBrowser';
@import './cssFolders/pages/userLoginWorkflow/userPopUp';
@import './cssFolders/pages/userLoginWorkflow/microsoftLoginRedirectPage';
@import '../styles/cssFolders/pageWrappers/pageSaveWrapper';
@import '../styles/cssFolders/pages/myCases_myScenarios/myCases_myScenarios';
@import '../styles/cssFolders/pages/toolPage/toolPage';
@import '../styles/cssFolders/pages/legal_pages/legalPages';
@import '../styles/cssFolders/pages/academy/academy';
@import '../styles/cssFolders/pages/settings/settings';
@import '../styles/cssFolders/components/tooltips/tooltips';

/*Choose Global Setting*/
@font-face {
  font-family: 'poppins-local';
  src: url(../resources/fonts/Poppins/Poppins-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'poppins-local-medium';
  src: url(../resources/fonts/Poppins/Poppins-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'poppins-local-bold';
  src: url(../resources/fonts/Poppins/Poppins-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'roboto-local';
  src: url(../resources/fonts/Roboto/Roboto-Regular.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'roboto-local-medium';
  src: url(../resources/fonts/Roboto/Roboto-Medium.ttf);
  font-style: normal;
  font-stretch: 100%;
}
@font-face {
  font-family: 'roboto-local-bold';
  src: url(../resources/fonts/Roboto/Roboto-Bold.ttf);
  font-style: normal;
  font-stretch: 100%;
}

html * {
  font-family: 'poppins-local', sans-serif, verdana;
  color: $ageanMidnight;
  letter-spacing: -0.01em;
}

.svgFont {
  font-family: 'poppins-local', sans-serif, verdana;
}

body {
  background-color: $backGroundGray;
  overscroll-behavior-x: none;
}

input::selection {
  background-color: $lakersUniformHover;
  color: white;
}

.robotoNumbers {
  font-family: 'roboto-local' !important;
}
.robotoNumbersMedium {
  font-family: 'roboto-local-medium' !important;
}
.robotoNumbersBold {
  font-family: 'roboto-local-medium' !important;
  font-weight: 900;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 * {
  cursor: default;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

select:not([multiple]):not([size]) {
  background-image: none !important;
}

input[type='date'],
input[type='month'] {
  font: caption !important;
  font-family: 'Poppins', verdana, sans-serif;
}

label,
img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

a {
  cursor: pointer !important;

  text-decoration: none !important;

  link {
    cursor: pointer !important;

    text-decoration: none !important;
  }
  &:visited {
    text-decoration: none;
  }
}

.dropdownAutocomplete {
  position: relative;
}

.marginLeft {
  margin-left: 40px;
}

/*---------------------------------------*/

/*Preset Classes*/
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*---------------------------------------*/
.loadingGif {
  width: 150px;
  box-shadow: 0px 0px 10px #f4f4f4;
}
.introButtons {
  position: relative;

  background-color: #2e394b;
  width: 300px;
  .introButton {
    height: 80px;
    width: 300px;
    font-size: 20px;
    font-family: sans-serif;
    text-decoration: none;
    color: black;
    border-radius: 20px;
    margin: 10px;
    cursor: pointer;
  }
}

.invisibleInput {
  height: 0px;
  width: 0px;
  padding: 0;
  border: transparent 1px solid;
  //visibility: hidden; this doesn't work for the focus
}

.homeBackground {
  height: 100vh;
  width: 100wh;
  background-color: #2e394b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .homeLogo {
    height: 120px;
    width: 192px;
    position: relative;
    margin-bottom: 10px;
  }
}

.loginBackground {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  padding-left: 49px;
  top: 0;
  left: 0;
  z-index: -100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $backGroundGray;
  .loginBox {
    background-color: white;
    width: 600px;
    height: 550px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 200px;
    margin: auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      margin: auto;
      margin-top: 70px;
      width: 300px;
      height: 390px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .instructions {
      font-size: 13px;
      margin-bottom: 40px;
    }

    .errorClass {
      position: absolute;
      color: #eb3030 !important;
      background-color: white;
      top: 142px;
      left: 150px;
    }
    .inputLabel {
      font-size: 10px;
    }
    #id_username,
    #id_password,
    #id_email {
      width: 300px;
      height: 28px;
      margin-bottom: 20px;
    }

    .submitButton {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      width: 300px;
      height: 34px;
      background-color: #2e394b;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      font-family: 'Roboto', sans-serif !important;
      border-style: none;
      outline-style: none;
    }
    .visibleTick {
      width: 22px;
      height: 22px;
    }
    .hiddenTick {
      visibility: hidden;
      position: fixed;
    }
    .showPassword,
    .rememberMe {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 3px;
    }
    .tickText {
      font-size: 13px;
      margin: 0;
      margin-left: 10px;
    }
    .forgot {
      font-size: 13px;
      color: #2c79f7;
      text-decoration: none;
    }
    .loginForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .loginInputLine {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .mailImg {
        position: absolute;
        left: 36px;
        width: 18px;
        margin-top: 15px;
      }
      .lockImg {
        @extend .mailImg;
        margin-top: 12px;
      }
      .loginInput {
        padding-left: 12%;
        -webkit-appearance: none;
        &:focus {
          outline-width: 0;
        }
        font-size: 15px;
        color: $ageanMidnight;
        font-weight: $boldWeight;
        margin: 12px auto !important;
        padding-bottom: 3px;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid $industrialEggYolk;
        width: 250px;
      }

      .RememberMeContainer {
        margin-top: 8px;
        margin-left: 28px;
        align-self: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        label {
          font-size: 11px;
          font-weight: 400;
          margin-left: 4px;
        }
      }

      input[type='checkbox'] {
        border-radius: 0;
        height: 24px;
        width: 24px;
      }

      .SignInButton {
        width: 295px;
        height: 35px;
        border: 0;
        border-radius: 0;
        background-color: $ageanMidnight;
        color: $industrialEggYolk;
        font-weight: $boldWeight;
        font-size: 15px;
        margin-left: 10px;
      }

      .forgot {
        color: $industrialEggYolk;
        align-self: flex-start;
        margin-left: 30px;
        font-size: 13px;
        margin-top: 6px;
      }
    }
  }
}
.progressBarContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 160px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  .cancelBecauseOfUpdateMessageContainer {
    color: $greekEaster;
  }
  .progressBarBackground {
    position: relative;
    width: 103px;
    height: 8px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 2px #00000029;
    .progressBar {
      height: 8px;
      border-radius: 4px;
      background-color: $lakersUniform;
    }
  }
  .progressText {
    font-size: 14px;
    text-align: center;
  }

  .cancelContainer {
    position: absolute;
    right: -4px;
    top: 5px;
    cursor: pointer;
    .cancelCase {
      width: 28px;
      border-radius: 15px;
      height: 28px;
      cursor: pointer;
      box-shadow: 0px 3px 6px #00000029;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      .cancelCaseSquare {
        width: 10px;
        height: 10px;
        background-color: $lakersUniform;
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .loginBackground {
    overflow: auto;
  }
}

.MuiTooltip-popper {
  z-index: 9 !important;
}

[id^='treeInterestViewDropDown-'],
#claimsInterestViewOptionDropdown {
  font-size: 15px !important;
  padding-bottom: 13px !important;
}

#weightedListTitle-tooltip,
#tilesPreviewButton-tooltip,
#listPreviewButton-tooltip,
#expandedPreviewButton-tooltip {
  z-index: 10 !important;
}

.MuiInputBase-root {
  background-color: white;
}

#menu-select-phonePrefix {
  z-index: 7000;
}

.MuiPopover-root {
  z-index: 7000 !important;
}

.downloadImageContainer {
  background-color: white;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  &:hover {
    background-color: $hovercraftPurple;
  }
  &.inactive {
    cursor: default;
    &:hover {
      background-color: white;
    }
  }
}

.animateDown50 {
  animation: animateDown 0.5s forwards;
}
.animateUp50 {
  animation: animateUp 0.5s forwards;
}

.fadeout {
  animation: fadeout 0.4s forwards;
}

@keyframes animateUp {
  0% {
    transform: translateY(46px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes animateDown {
  0% {
    transform: translateY(-46px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
