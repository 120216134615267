.savingCaseMessageBackground {
  position: fixed;
  top: 43px;
  left: 0px;
  z-index: -2;
  &.generatingReportMessage {
    z-index: 9;
  }
  width: 100%;
  height: 100%;
  background-color: $backGroundGray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .savingCaseMessageContainer {
    margin-top: 240px;
    padding: 28px 35px;
    background-color: white;
    border-radius: $borderRadiusMedium;
    box-shadow: 0px 3px 6px #00000029;

    .savingCaseMessageText {
      font-size: 16px;
      text-align: center;
    }
  }
}

.saveCaseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 33px;

  .savedMessageContainer {
    position: absolute;
    width: 120px;
    text-align: center;
    top: 60px;
    right: 0px;
    border-radius: 5px;
    box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
    background-color: white;
    padding: 10px;
    z-index: 999;
    animation-name: savedMessageAnimation;
    animation-duration: 3s;
    .savedScenarioText {
      font-size: 14px;
    }
  }
  .left {
    right: 128px;
  }
  .savedErrorContainer {
    position: absolute;
    width: 120px;
    text-align: center;
    top: 60px;
    right: 0px;
    border-radius: 5px;
    box-shadow: 2.5px 2.5px 12.5px rgb(153, 153, 153);
    background-color: $laCandyfloss;
    padding: 10px;
    z-index: 999;
    animation-name: savedMessageAnimation;
    animation-duration: 3s;
    .savedScenarioText {
      font-size: 14px;
    }
  }

  .saveAsButtonContainer {
    width: fit-content;
    position: relative;
    margin-right: 20px;
    .saveAsButtonSmall {
      border-radius: 5px;
      border: 1px solid $ageanMidnight;
      cursor: pointer;
      height: 40px;
      padding: 0 10px;
      &:hover {
        background-color: $hovercraftPurple;
      }
      .saveAsImg {
        height: 20px;
      }
      &.saveAsButtonSmall:disabled {
        cursor: auto;
        background-color: $sundayGray;
        border: none;
      }
    }
    .saveAsNewMenuContainer {
      position: absolute;
      top: 0px;
      width: 470px;
      height: 140px;
      right: 100px;

      transform: translate(50%, 0%);
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      z-index: 900;

      .SaveAsButtonsContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .saveTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      cursor: pointer;
      margin-right: 4px;
    }
  }
  .saveButtonContainer {
    position: relative;
    .button-text {
      color: $lakersUniform;
      width: 45px;
      text-align: left;

      &.disabled {
        color: $mondayGray !important;
      }
    }
    .tickImg {
      top: 10px;
      right: 6px;
      position: absolute;
      height: 13px;
      align-self: center;
    }
  }
}

@keyframes savedMessageAnimation {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.moreMenu {
  position: absolute;
  top: 44px;
  right: 160px;
  width: 244px;
  background-color: white;
  border: 1px solid $ageanMidnight;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .moreMenuOption {
    width: 100%;
    cursor: pointer;

    :hover {
      background-color: $hovercraftPurple;
    }

    .moreMenuText {
      padding: 15px;
      color: #321b59;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;

      &.first {
        border-radius: 5px 5px 0px 0px;
      }

      &.last {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
}

.moreBackgroundPopUp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5999;
  background-color: $transparentBackgroundGray;

  .morePopUp {
    background-color: white;
    box-shadow: 0px 0px 30px #0000002c;
    height: 280px;
    width: 680px;
    border-radius: 10px;
    padding-right: 28px;
    padding-left: 28px;
    padding-top: 21px;
    position: relative;
    margin-top: calc((100vh - 280px) / 5);

    &.loading {
      width: 485px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.withSubtitle {
      height: 400px;
      margin-top: calc((100vh - 400px) / 5);
    }

    .title {
      color: #201a2c;
      font-size: 22px;
      font-weight: bold;
    }

    .subTitle {
      color: #321b59;
      font-size: 16px;
    }

    .loadingMessage {
      text-align: center;
      color: #321b59;
      font-size: 20px;
    }

    .inputContainer {
      margin-top: 45px;
      margin-bottom: 57px;

      &.withSubtitle {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .exitButtonImgContainer {
      position: absolute;
      top: 11px;
      right: 11px;
      cursor: pointer;

      .exitButtonImg {
        cursor: pointer;
      }
    }
  }
}
