.switchContainer {
  height: 30px;
  position: relative;
  cursor: pointer;
}
.switchContainer .switchMainButton {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.168627451);
  border: 1px solid #dcdcdc;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  padding: auto;
}
.switchContainer .switchMainButton.switchState2 {
  justify-content: flex-start;
}
.switchContainer .switchLabelTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 180px;
}
.switchContainer .switchLabelTextContainer.inTree {
  min-width: 160px;
}
.switchContainer .switchLabelTextContainer .switchLabel {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 12px;
  cursor: pointer;
  margin: auto;
  width: 100%;
}
.switchContainer .switchLabelTextContainer .switchLabel.inTree {
  font-size: 12px;
  font-weight: 400;
}
.switchContainer .switchCircle-right {
  position: absolute;
  top: 5px;
  right: -2px;
  width: 37px;
  height: 37px;
  border: 2px solid;
  border-radius: 19px;
  transform: translate(0, -5px);
  background: transparent radial-gradient(closest-side at 50% 50%, #ffffff 0%, #fafafa 83%, #e9e9e9 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}
.switchContainer .switchCircle-left {
  position: absolute;
  top: 5px;
  left: 0px;
  width: 37px;
  height: 37px;
  border: 2px solid;
  border-radius: 19px;
  transform: translate(0, -5px);
  background: transparent radial-gradient(closest-side at 50% 50%, #ffffff 0%, #fafafa 83%, #e9e9e9 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}/*# sourceMappingURL=SwitchButton.css.map */