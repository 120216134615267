@mixin singleEventBackground($gridRow) {
  grid-row: $gridRow;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $beePassion;
  &.selected {
    background-color: $newBornPeach;
  }
  &.fadeout {
    @include fadeOutAnimation();
  }
  width: 240px;
  height: 100%;
}

@mixin fadeOutAnimation() {
  animation: fadeOut 0.4s;
}
.treeEventsContainer {
  display: grid;
  grid-template-rows: [eventTitle] auto [awardedAmountContainer] auto [reducedAwardedAmountContainer] auto [outOfCourtAmountContainer] auto [probabilityContainer] auto;
  padding: 35px 23px 16px 23px;
  column-gap: 21px;
  position: relative;

  .connectionCreatesLoopMessageContainer {
    position: absolute;
    left: -50px;
    top: 80px;
    width: 250px;
    z-index: 1000;
    background-color: pink;
    box-shadow: 0px 2px 2px #00000029;
    padding: 3px;
    text-align: center;
    border-radius: 3px;
    &.shake {
      animation: shake 0.5s;
    }
  }

  .treeEventShadow {
    position: absolute;
    background-color: transparent;
    width: 239px;
    top: 37px;
    height: calc(100% - 65px);

    margin: 0px 0px 60px 24px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .treeEventMinimisedShadow {
    position: absolute;
    background-color: transparent;
    width: 145px;
    top: 27px;
    height: calc(100% - 54px);

    margin: 0px 0px 60px 23px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .treeEventBackground {
    display: contents;
    margin-right: 21px;
    &:last-of-type {
      margin-right: 0px;
    }

    .deleteTreeEventMenuContainer {
      .deleteTreeEventMenuButton {
        top: 0;
      }
    }

    .treeEventTitleContainer {
      @include singleEventBackground(eventTitle);
      border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;

      .deleteTreeEventImgContainer {
        position: absolute;
        right: -15px;
        top: -12px;
        width: 28px;
        cursor: pointer;
        z-index: 1;
        .deleteTreeEventImg {
          width: 28px;
          cursor: pointer;
          border-radius: 10px;
          border: none;
          padding: auto;
          text-align: center;
          margin: auto;
          font-size: 6.5px;
        }
      }

      .treeEventTitleInput {
        margin-top: 18px;
        margin-bottom: 14px;
      }
    }

    .treeEventAwardedAmountContainer,
    .treeEventReducedAmountContainer,
    .treeEventOutOfCourtSection {
      &.treeEventAwardedAmountContainer {
        @include singleEventBackground(awardedAmountContainer);
        &.noInterest {
          margin-bottom: -10px;
        }
      }

      &.treeEventReducedAmountContainer {
        @include singleEventBackground(reducedAwardedAmountContainer);
        margin-bottom: 10px;
      }

      &.treeEventOutOfCourtSection {
        @include singleEventBackground(outOfCourtAmountContainer);
        margin-bottom: -2px;
      }

      margin-bottom: 5px;
      min-height: 47px;

      .treeEventAddSectionContainer {
        position: relative;
        .treeEventAddSectionButton {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          width: 211px;
          height: 44px;
          border-radius: $borderRadiusSmall;
          border: 1px solid transparent;
          &.darkButton {
            border: 1px solid $ageanMidnight;
            background-color: white;
            box-shadow: 0px 3px 6px #00000029;
          }
          .treeEventAddSectionButtonImg {
            margin-left: 7px;
            width: 25px;
            cursor: pointer;
          }
          .treeEventAddSectionButtonText {
            margin-left: 7px;
            font-size: 14px;
            cursor: pointer;
            color: $mondayGray;
            &.dark {
              color: $ageanMidnight;
            }
          }
        }
      }

      .treeEventAmountInterestSection {
        position: relative;
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1px;
        .treeEventAmountSection {
          position: relative;
          width: fit-content;
          .treeEventRemoveSectionContainer {
            position: absolute;
            right: -9px;
            top: -12px;
            .treeEventRemoveSectionImg {
              width: 26px;
              cursor: pointer;
            }
          }
        }

        .treeEventInterestSection {
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          padding: auto;
          margin-top: 14px;
          &.editMode {
            margin-top: 6px;
          }
          margin-bottom: 24px;
          .treeEventIncludeInterestButtonContainer {
            display: flex;
            flex-direction: row;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            margin-left: 17px;
            width: 240px;
            .treeEventIncludeInterestButtonImg {
              cursor: pointer;
            }
            .treeEventIncludeInterestButtonText {
              cursor: pointer;
              margin-left: 10px;
            }
          }

          .treeEventInterestContainer {
            display: flex;
            flex-direction: row;
            position: relative;
            justify-content: space-between;
            margin: 4px 18px 3px 17px;

            .treeEventInterestRateInput,
            .inputdate {
              .input-label-text {
                left: 6px;
              }
            }

            .treeEventInterestDateInput {
              height: 43px;
              padding: 0 3px 0 3px !important;

              font-size: 14px !important;

              &::-webkit-calendar-picker-indicator {
                font-size: 7px;
                margin: 1px 2px 0 7px;
                padding: 0;
                &:hover {
                  cursor: pointer;
                }
                &:focus {
                  outline-width: 0;
                }
              }
            }

            .error {
              border: 3px solid $greekEaster;
              &:focus {
                outline: none !important;
                border: 3px solid $greekEaster !important;
              }
            }
          }
          .treeEventErrorEmptySpace {
            height: 30px;
            width: 30px;
          }
          .errorDateMessageText {
            position: absolute;
            top: 43px;
            color: $greekEaster;
            width: 100%;
            font-size: 13px;
            text-align: right;
            margin-right: 10px;
            margin-top: 0px;
            line-height: 1.4;
          }
        }
      }
      .treeEventAwardSignumSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 8.5px;
        margin-top: 2px;
        &.outOfCourt {
          margin-top: -12px;
          margin-bottom: 13px;
        }
        position: relative;
        label {
          div {
            margin-right: 6px !important;
          }
        }
      }
    }

    .treeEventOutOfCourtSection {
      .treeEventAddSectionContainer {
        margin-top: 10px;
        margin-bottom: 15px;
      }
      .treeEventAmountInterestSection {
        margin-top: 10px;
        margin-bottom: 15px;
        .treeEventAmountSection {
          top: 1px;
        }
      }
    }

    .switchContainer {
      .switchMainButton {
        border: 1px solid #dcdcdc;
        box-shadow: 0px 1px 3px #0000002b;
      }
      .switchCircle-right,
      .switchCircle-left {
        width: 34px;
        height: 34px;
        top: 6px;
      }
    }

    .treeEventProbabilityContainer {
      @include singleEventBackground(probabilityContainer);
      border-radius: 0 0 $borderRadiusMedium $borderRadiusMedium;
      /* box-shadow: 5px 0px 5px -5px #00000029, -5px 0 5px -5px #00000029,
        -2px 5px 7px -4px #00000029; */
      margin-bottom: 10px;
      .treeEventProbabilityInput {
        margin-top: 15px;
        position: relative;

        &.readOnly {
          &::selection {
            background: transparent;
            color: $ageanMidnight;
          }
          &:focus {
            outline: none !important;
            border: 1px solid transparent !important;
          }
        }
        &.shakeScenario {
          animation: shake 0.7s;
        }

        @keyframes shake {
          0% {
            transform: rotate(5deg);
          }
          20% {
            transform: rotate(-5deg);
          }
          40% {
            transform: rotate(5deg);
          }
          60% {
            transform: rotate(-5deg);
          }
          80% {
            transform: rotate(5deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    }

    .treeEventAddNodeButtonContainer {
      position: absolute;
      bottom: -36px;
      left: 57px;
      margin: 0 !important;
      .treeEventAddNodeButton {
        box-shadow: 0px 4px 4px #0000001a;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $beePassion;
        &.selected {
          background-color: $newBornPeach;
        }
        border-radius: $borderRadiusMedium;
        width: 123px;
        height: 41px;
        cursor: pointer;
        .treeEventAddNodeButtonImg {
          height: 19px;
          position: relative;
        }

        .treeEventAddNodeButtonText {
          position: relative;
          margin-left: 5.5px;
          font-size: 14px;
          color: $mondayGray;
          &.purple {
            color: $ageanMidnight;
          }
        }
      }
    }
  }
  .treeAddNodeDotContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 31px;
    &.minimised {
      top: 10px;
      background-color: 'transparent';
    }

    .treeAddNodeDot {
      width: 11px;
      height: 11px;
      border-radius: 6px;
      background-color: $beePassion;
      box-shadow: 0px 4px 4px #0000001a;

      &.selected {
        background-color: $newBornPeach;
      }
      &.minimised {
        background-color: transparent;
        box-shadow: 0px 0px 0px #0000001a;
      }
      &.clicked {
        position: absolute;
        left: calc(50% - 10px);
        top: 5px;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background-color: white;
        border: solid 1px black;
        cursor: pointer;
        &.minimised {
          top: 10px;
        }
      }
      &.active {
        background-color: $springLevanter !important;
        &.krogerus {
          background-color: $krogerusActiveColor !important;
        }
      }

      top: 9px;
      left: 5px;
    }
  }
  .treeAddNodeDotEventAvailable {
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 70px;
    opacity: 0.6;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .treeAddNodeDotEventAvailableImg {
      z-index: -1;
      margin-top: 31px;
    }
    .treeAddNodeDotEventAvailableImgMinimised {
      z-index: -1;
      margin-top: 5px;
      width: 74px;
    }
  }
  &.minimised {
    padding: 25px 23px 16px 23px;
    display: grid;
    grid-template-rows: [eventTitle] auto [eventDetails] auto [eventProbability] min-content;
    column-gap: 12px;
    .treeEventBackgroundMinimised {
      display: contents;
      width: 145px;
      box-shadow: 0px 3px 6px #00000029;
      margin-left: 12px;
      background-color: white;
      &:first-child {
        margin-left: 0px;
      }
      .treeEventTitleContainerMinimised {
        grid-row: eventTitle;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 145px;
        min-height: 44px;
        background-color: $beePassion;
        &.krogerus {
          background-color: $krogerusTreeBackground;
        }
        &.selected {
          background-color: $newBornPeach;
        }
        &.noEventTitle {
          min-height: 12px;
        }
        border-radius: 5px 5px 0 0;
        padding: 6px;
        text-align: center;
        .treeEventTitleMinimised {
          width: 100%;
          word-break: normal;
          font-weight: 600;
        }
      }
      .treeEventDetailsContainerMinimised {
        grid-row: eventDetails;
        background-color: white;
        border-radius: 0 0 5px 5px;
        z-index: 10;
        width: 145px;
        .treeEventAwardedAmountTextMinimised,
        .treeEventInterestTextMinimised,
        .treeEventOutOfCourtAmountTextMinimisedContainer,
        .treeEventReducedAmountTextMinimised,
        .treeEventNoInterestTextMinimised {
          text-align: right;
          margin-left: 8px;
          margin-right: 11px;
          height: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &.positive {
            color: $clientColor;
            &.krogerus {
              color: $krogerusClientColor;
            }
          }
          &.negative {
            color: $opposingPartyColor;
            &.krogerus {
              color: $krogerusOpposingPartyColor;
            }
          }
          &.treeEventReducedAmountTextMinimised {
            &.onlyReduced {
              margin-bottom: 11px;
            }
          }
          &.treeEventAwardedAmountTextMinimised {
            margin-top: 12px;
            margin-bottom: 4px;
            margin-left: 20px;
          }
          &.treeEventOutOfCourtAmountTextMinimisedContainer {
            margin-top: 4px;
            .treeEventOutOfCourtAmountTextMinimised {
              &.border {
                border: 1px solid $clientColor;
                &.krogerus {
                  border: 1px solid $krogerusClientColor;
                }
                border-radius: 2px;
              }
              &.positive {
                color: $clientColor;
                &.krogerus {
                  color: $krogerusClientColor;
                }
              }
              height: 20px;
              width: fit-content;
              margin-left: auto;
              padding: 0 1px 0 3px;
              line-height: 1.2;
            }
            margin-right: 8px;
          }
          &.treeEventInterestTextMinimised {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 7px;
            .treeEventInterestRateTextMinimised {
              color: $ukNavy;
              margin-right: 14px;
            }
            .treeEventInterestDateTextMinimised {
              color: $ukNavy;
            }
          }
          &.treeEventNoInterestTextMinimised {
            height: 23px;
          }
        }
      }
      .treeEventProbabilityContainerMinimised {
        grid-row: eventProbability;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 12;
        .treeEventProbabilityCircleMinimised {
          position: absolute;
          bottom: 7px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 39px;
          height: 39px;
          border-radius: 20px;
          background-color: white;
          box-shadow: 0px 3px 6px #00000029;
          font-weight: 600;

          .treeEventProbabilityMinimised {
            font-weight: 900;
            font-size: 14px;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .active {
    background-color: $springLevanter !important;
    &.krogerus {
      background-color: $krogerusActiveColor !important;
    }
  }
}
