.deleteMessage {
  filter: blur(0);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 376px;
  height: 166px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;

  &.for-disabled-action {
    justify-content: center;
  }

  &.for-reset {
    min-height: 196px;
    height: fit-content;

    &.for-tfa {
      height: fit-content;
    }
  }

  &.permanent {
    height: 192px;
  }

  &.disabledMessage {
    z-index: 100;
    height: 160px;
    width: 220px;
    justify-content: center;
  }

  .claimNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .deleteQuestion {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    &.disabledMessage {
      margin-top: 0px;
    }
  }
  border-radius: 5px;
  padding: 7px;
  p {
    font-size: 16px;
    word-break: normal;
  }
  .buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
  }
  .DeleteButton {
    background-color: #ac2828;

    &:hover {
      background-color: #ac2828;
    }
  }
}
